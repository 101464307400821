import * as React from 'react'

import AbstractCapacityPoolList from './AbstractCapacityPoolList'

const CapacityPoolTemplateList = (props) => {
  return (
    <AbstractCapacityPoolList reference="capacitypooltemplate" {...props} />
  )
}

export default CapacityPoolTemplateList
