
import * as React from 'react'
import {
  AutocompleteInput,
  ReferenceInput
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { FilterWithSave } from '@react-admin/ra-preferences'
import { DateTimeInput } from '../../Components'

const ServiceRealizationFilters = props => (
  <FilterWithSave {...props}>
    <ReferenceInput source="service_base" reference="servicebase" alwaysOn>
      <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable/>
    </ReferenceInput>
    <DateTimeInput
      label="Start after"
      parse={(v) => v ? v._d : v}
      source="start"
      alwaysOn
    />
    <DateTimeInput
      label="End before"
      parse={(v) => v ? v._d : v}
      source="end"
    />
  </FilterWithSave>
)

export default ServiceRealizationFilters
