import * as React from 'react'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { Create, SimpleForm, TextInput, required, SelectInput, NumberInput } from 'react-admin'
import ChannelTextInput from '../Components/Inputs/ChannelTextInput'

const GeolocationCreate = props => {
  useDefineAppLocation('geolocation.create')
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='ident' validate={required()}/>
        <SelectInput source='enabled' choices={[{ id: 'true', name: 'true' }, { id: 'false', name: 'false' }]} validate={required()}/>
        <TextInput source='address' validate={required()}/>
        <TextInput source='latitude' validate={required()}/>
        <TextInput source='longitude' validate={required()}/>
        <ChannelTextInput editable placement="left-start" source="content.title"/>
        <ChannelTextInput editable placement="left-start" source="content.description"/>
        <NumberInput defaultValue={12} source='content.google_map_zoom' fullWidth/>
      </SimpleForm>
    </Create>
  )
}

export default GeolocationCreate
