import * as React from 'react'
import {
  Datagrid,
  Button,
  FunctionField,
  useRefresh,
  useTranslate,
  DeleteWithConfirmButton
} from 'react-admin'
import EditIcon from '@material-ui/icons/Edit'
import DialogForm from './DialogForm'
import { PropTypes } from 'prop-types'
import AddIcon from '@material-ui/icons/Add'

const CreateButton = (props) => (
  <Button label="Create" onClick={props.handleClick} {...props}>
    <AddIcon />
  </Button>
)

const DatagridWithDialog = ({ children, ...props }) => {
  const { resource, defaultValue, data, form, noCreate, noDelete, noEdit, noRefresh, rowClick, ...rest } = props
  const translate = useTranslate()
  const [open, setOpen] = React.useState(false)
  const [formRecord, setFormRecord] = React.useState()
  const refresh = useRefresh()

  const handleEditOpen = (record) => {
    setFormRecord(record)
    setOpen(true)
  }

  const handleCreateOpen = () => {
    setFormRecord()
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      {Object.entries(data).length === 0
        ? (
        <>
          <CreateButton label={resource} handleClick={handleCreateOpen} />
          <DialogForm
            open={open}
            handleClose={handleClose}
            formRecord={formRecord}
            resource={resource}
            defaultValue={defaultValue}
            noRefresh={noRefresh}
          >
            {form}
          </DialogForm>
        </>
          )
        : (
        <>
          <Datagrid rowClick={rowClick === 'edit' ? (e, b, r) => handleEditOpen(r) : null} {...rest}>
            {children}
            <FunctionField
              label={noCreate ? '' : <CreateButton handleClick={handleCreateOpen} />}
              render={(record) => (
                <div style={{ display: 'flex' }}>
                  {!noEdit
                    ? <Button onClick={() => handleEditOpen(record)}>
                    <EditIcon />
                  </Button>
                    : null
                  }
                  {!noDelete
                    ? <DeleteWithConfirmButton
                     label=""
                     onSuccess={() => refresh()}
                     record={record}
                     confirmTitle={translate('menu.delete_confirm.title', { _: 'Delete %{resource} %{ident}', resource: props.resource, ident: record.ident ? record.ident : record.id })}
                     confirmContent={translate('menu.delete_confirm.content_no_tn', { _: 'Are you sure you want to delete this item?' })}
                     redirect={false}
                    />
                    : null
                  }

                </div>
              )}
            />
          </Datagrid>
          <DialogForm
            open={open}
            handleClose={handleClose}
            formRecord={formRecord}
            resource={resource}
            defaultValue={defaultValue}
            noRefresh={noRefresh}
          >
            {form}
          </DialogForm>
        </>
          )}
    </>
  )
}

CreateButton.propTypes = {
  handleClick: PropTypes.func
}

DatagridWithDialog.propTypes = {
  children: PropTypes.element,
  resource: PropTypes.string,
  defaultValue: PropTypes.objects,
  data: PropTypes.object,
  form: PropTypes.element,
  rowClick: PropTypes.string,
  noCreate: PropTypes.bool,
  noEdit: PropTypes.bool,
  noDelete: PropTypes.bool,
  noRefresh: PropTypes.bool
}
export default DatagridWithDialog
