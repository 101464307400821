import * as React from 'react'
import { Fragment, memo } from 'react'

import {
  BulkDeleteButton,
  BulkExportButton,
  List,
  ReferenceField,
  TextField,
  EditButton,
  FunctionField,
  Pagination,
  Datagrid,
  useUpdate,
  useUpdateMany,
  useTranslate,
  useRefresh,
  Button,
  useNotify
} from 'react-admin' // eslint-disable-line import/no-unresolved
import RefreshIcon from '@material-ui/icons/Sync'
import SyncFailedIcon from '@material-ui/icons/SyncProblem'
import red from '@material-ui/core/colors/red'
import { createStyles, makeStyles, Grid, Tooltip, IconButton } from '@material-ui/core'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
// import { EditableDatagrid } from '@react-admin/ra-editable-datagrid'
import { EditableDatagridCustomActions } from '../../Components/Actions'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'

import { PriceInfoField } from '../../Components'
import ListActions from '../../Components/Toolbar/ListActions'
import statusRowStyle from '../../Components/statusRowStyle'
import DateField from '../../Components/Datefield'
import exporter from '../../Components/exporter'

import { paymentStatusColor } from '../../Utils/StatusColors'
import PaymentForm from './PaymentForm'
import PaymentFilters from './SearchFilters'
import { API_URL, httpClient } from '../../dataProvider'
import { number, arrayOf } from 'prop-types'

// eslint-disable-next-line react/display-name
const PaymentListBulkActions = memo(props => (
  <Fragment>
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
    <BulkRefreashPaymentStatusBtn {...props} />
  </Fragment>
))

const useIconStyle = makeStyles((theme) =>
  createStyles({
    idle: {
      color: theme.palette.primary.main
    },
    loading: {
      color: theme.palette.primary.main,
      animation: '$spin 500ms linear infinite'
    },
    success: {
      color: theme.palette.secondary
    },
    error: {
      color: red[500]
    },
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)'
      },
      '100%': {
        transform: 'rotate(0deg)'
      }
    }
  })
)

const fetchPaymentStatus = paymentId => httpClient(`${API_URL}/payment/${paymentId}/fetch_status`)

const BulkRefreashPaymentStatusBtn = props => {
  const [isFetching, setIsFetching] = React.useState(false)
  const [state, setState] = React.useState('idle')
  const [updateStatus, { loading }] = useUpdateMany()
  const classes = useIconStyle()
  const notify = useNotify()
  const refresh = useRefresh()

  const fetchStatus = async () => {
    try {
      setIsFetching(true)
      let allOk = true
      const data = (await Promise.all(props.selectedIds.map(async paymentId => {
        const response = await fetchPaymentStatus(paymentId)

        if (response.status !== 200) {
          allOk = false
          return
        }
        const status = response.json.status

        return { id: paymentId, status: status }
      }))).filter(v => !!v) // remove undefined

      const ids = data.map(v => v.id)

      await updateStatus('payment', ids, data, { undoable: false })

      if (!allOk) throw Error
      await notify('notifications.payment_state_fetch.bulk.success', 'success', undefined, false)

      setState('success')
      await refresh()
    } catch (error) {
      await notify('notifications.payment_state_fetch.bulk.failed', 'warning', undefined, false)
      setState('error')
      return console.error(error)
    } finally {
      setIsFetching(false)
    }
  }

  return <Button label='simple.action.refresh_payment_status_bulk' onClick={fetchStatus} disabled={isFetching || loading}>
    <RefreshIcon className={classes[state]}/>
  </Button>
}

BulkRefreashPaymentStatusBtn.propTypes = {
  selectedIds: arrayOf(number)
}

const PaymentStatusComponent = record => {
  const [isFetching, setIsFetching] = React.useState(false)
  const [state, setState] = React.useState('idle')
  const [updateStatus, { loading }] = useUpdate()
  const classes = useIconStyle()
  const notify = useNotify()

  const fetchStatus = async () => {
    if (isFetching) return

    setIsFetching(true)
    setState('loading')
    try {
      const response = await fetchPaymentStatus(record.id)

      if (response.status !== 200) return console.error(response)
      const newStatus = response.json.status
      if (record.status !== newStatus) await updateStatus('payment', record.id, { status: newStatus }, record)
      await notify('notifications.payment_state_fetch.single.success', 'success', undefined, false)
      setState('success')
    } catch (error) {
      setState('error')
      await notify('notifications.payment_state_fetch.single.failed', 'warning', undefined, false)
      return console.error(error)
    } finally {
      setIsFetching(false)
    }
  }

  const translate = useTranslate()

  return (
  <Grid container direction='row'>
    <Tooltip placement="left" title={translate('simple.action.refresh_payment_status')}>
        <Grid item xs={4} >
          <IconButton onClick={fetchStatus} disabled={isFetching || loading}>
            {state === 'error'
              ? <SyncFailedIcon className={classes.error} />
              : <RefreshIcon className={classes[state]}/>}
          </IconButton>
        </Grid>
    </Tooltip>
    <Grid container item xs={8} style={{ color: paymentStatusColor(record.status) }} >
      <div style={{ marginTop: 'auto', marginBottom: 'auto' }}>
        {record.status}
      </div>
    </Grid>
  </Grid>
  )
}

const PaymentStatusField = props => {
  return <FunctionField source="status" render={record => <PaymentStatusComponent {...record }/>} />
}

const PaymentList = props => {
  useDefineAppLocation('accounting.payment')
  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="payment.list.columns" />}
        bulkActionButtons={<PaymentListBulkActions />}
        filters={<PaymentFilters />}
        sort={{ field: 'created_at', order: 'DESC' }}
        exporter={(data) => exporter(data, 'Payment')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
        empty={false}
      >
        <Datagrid
          actions={<EditableDatagridCustomActions/>}
          createForm={<PaymentForm />}
          editForm={<PaymentForm />}
          rowStyle={(record) => record ? statusRowStyle(paymentStatusColor(record.status)) : null}
          // rowClick='edit'
        >
          <DateField source="created_at" showTime/>
          <TextField source="payment_type" />
          <PaymentStatusField />
          <PriceInfoField label="Amount" />
          <TextField source="reference_number" />
          <ReferenceField source="order" reference="order" link={true}>
            <FunctionField render={(record) => <span>{record.code}</span>} />
          </ReferenceField>
          <ReferenceField source="order" reference="order" label="Customer" link={false}>
            <ReferenceField source="customer" reference="customer" link={false}>
              <TextField source="email"/>
            </ReferenceField>
          </ReferenceField>
          <ListActionToolbar>
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

export default PaymentList
