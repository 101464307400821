import { DeleteWithConfirmButton, SaveButton, Toolbar } from 'ra-ui-materialui'
import * as React from 'react'
import PropTypes from 'prop-types'
import lodashGet from 'lodash/get'
import { useTranslate } from 'ra-core'

const SaveDeleteToolbar = props => {
  const translate = useTranslate()
  const deleteLabel = props.deleteLabel === undefined ? props.record.ident === undefined ? props.record.id : props.record.ident : lodashGet(props.record, props.deleteLabel, '')
  return (
  <Toolbar {...props}>
    <SaveButton disabled={props.pristine} />
    <DeleteWithConfirmButton
      style={{ marginRight: 0, marginLeft: 'auto' }}
      confirmTitle={translate('menu.delete_confirm.title', { _: 'Delete %{resource} %{ident}', resource: props.resource, ident: deleteLabel })}
      confirmContent={translate('menu.delete_confirm.content_no_tn', { _: 'Are you sure you want to delete this item?' })}
    />
  </Toolbar>
  )
}

SaveDeleteToolbar.propTypes = {
  pristine: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  deleteLabel: PropTypes.string
}

export default SaveDeleteToolbar
