import * as React from 'react'
import { Route } from 'react-router-dom'

import ServiceOfferingBulkEdit from './Services/ServiceOffering/BulkEdit'
import PublicationBulkEditDataTags from './Cms/Publication/Components/BulkEditDataTags'
// import ServiceOfferingCalendar from './ServiceOffering/Calendar'

export default [
  <Route exact path='/serviceoffering/bulkedit/:ids' key="serviceoffering_bulkedit_route" component={ServiceOfferingBulkEdit}/>,
  <Route exact path='/publication/bulkeditdatatags/:ids' key="publication_bulkeditdatatags_route" component={PublicationBulkEditDataTags}/>
  // <Route exact path='/calendar' key="calendar_route" component={ServiceOfferingCalendar} />
]
