import * as React from 'react'

import {
  useGetList,
  DeleteButton,
  Button,
  useVersion,
  useGetOne,
  LinearProgress,
  SelectInput,
  AutocompleteInput,
  ReferenceInput,
  TextInput,
  required
} from 'react-admin'
import {
  Card,
  CardContent,
  Grid,
  Typography
} from '@material-ui/core'
import { Add, Cancel } from '@material-ui/icons'
import { Form } from 'react-final-form'
import SendButton from '../Suppliers/SupplierMessage/SendButton'

import { PropTypes } from 'prop-types'
import LocalDate from './LocalDate'
import getConfig from './core_config'

import SupplierMessageStatusBubble from '../Suppliers/SupplierMessage/SupplierMessageStatusBubble'
import ResendMessage from '../Suppliers/SupplierMessage/ResendMessage'
import GoToButton from '../Suppliers/SupplierMessage/GoToButton'

const getSupplierMessage = (serviceSetup) => {
  const version = useVersion()
  const { data, ids } = useGetList(
    'suppliermessage',
    { page: 1, perPage: 1000 },
    { field: 'sent_at', order: 'DESC' },
    { service_setup: serviceSetup },
    { version: version }
  )
  return { data, ids }
}

const SupplierField = props => {
  const { data, error, loading } = useGetOne('supplier', props.supplierId)
  if (error) return 'ERROR'
  if (loading) return (<LinearProgress />)
  return (
    <>
      {data.name} – {data.email}
    </>
  )
}

const SupplierMessageType = () => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <SelectInput source="message_type" choices={config.supplier_message_type} defaultValue="order" validate={required()} fullWidth/>
  )
}

const SupplierAddForm = (props) => {
  return (
  <Card style={{ marginBottom: 5 }} variant="outlined">
    <CardContent style={{ padding: 16 }}>
        <Form onSubmit={() => {}}>
          {formProps => (
            <form style={{ width: '100%' }} onSubmit={formProps.handleSubmit}>
              <Grid container xs={12} spacing={2}>
                <Grid item xs={4}>
                  <SupplierMessageType/>
                </Grid>
                <Grid item xs={8}>
                  <ReferenceInput source="supplier" reference="supplier" validate={required()} sort={{ field: 'name', order: 'ASC' }}>
                    <AutocompleteInput optionText="name" fullWidth resettable/>
                  </ReferenceInput>
                </Grid>
                <Grid item></Grid>
                <Grid item xs={12}>
                  <TextInput multiline source="message" fullWidth/>
                </Grid>
                <Grid item xs={10}>
                  <SendButton record={{ ...formProps.form.getState().values, service_setup: props.record.item_object.id }} redirect={props.redirect ? props.redirect : `/order/${props.record.order}`} {...formProps} />
                </Grid>
              </Grid>
            </form>
          )}
        </Form>
    </CardContent>
  </Card>
  )
}

const SupplierMessagePanel = props => {
  if (props.record === undefined || props.record.item_object === undefined) return <></>
  const { data, ids } = getSupplierMessage(props.record.item_object.id)
  const finalForm = []
  const [adding, setAdding] = React.useState(false)
  const handleAdd = () => {
    setAdding(!adding)
  }
  if (props.noAdd !== undefined) {
    finalForm.push(
      <Typography variant="h5" style={{ marginBottom: 9 }}>Supplier messages</Typography>
    )
  } else {
    finalForm.push(
      <>
        <Button variant="contained" label={adding ? 'Cancel' : 'Add supplier message'} style={{ margin: 10 }} onClick={handleAdd}>
          {adding ? <Cancel /> : <Add />}
        </Button>
        {adding ? <SupplierAddForm {...props} /> : null}
      </>
    )
  }
  const panel = ids.map(id => {
    return (
      <Card key={id} style={{ marginBottom: 5 }} variant="outlined">
        <CardContent style={{ paddingLeft: 18, paddingRight: 0, paddingTop: 12, paddingBottom: 12 }}>
          <Grid container xs={12} spacing={2}>
            {/* <Grid item xs={12} style={{ textAlign: 'center' }}>
              <Typography>{data[id].type.charAt(0).toUpperCase() + data[id].type.slice(1)}{data[id].response_valid_until === null ? null : ' valid until ' + LocalDate(data[id].response_valid_until)}</Typography>
            </Grid> */}
            <Grid item xs={11}>
              <Typography variant="h6">
              <SupplierField supplierId={data[id].supplier} />
              </Typography>
            </Grid>
            <Grid item container xs={1} style={{ justifyContent: 'right' }}>
              <SupplierMessageStatusBubble status={data[id].status} />
            </Grid>
            <Grid item xs={4}>
              Request sent: {LocalDate(data[id].sent_at, true)}
            </Grid>
            <Grid item xs={8}>
            {data[id].message === null
              ? 'No message'
              : data[id].message
              }
            </Grid>
            {
              !data[id].response_at
                ? <Grid item xs={12}>
                  {data[id].status === 'pending' ? 'Waiting for response' : 'No response'}
                </Grid>
                : <>
                <Grid item xs={4}>
                  Response: {LocalDate(data[id].response_at, true)}
                </Grid>
                <Grid item xs={8} style={{ fontWeight: '800', background: data[id].response_text ? 'Khaki' : undefined }}>
                  { data[id].response_text }
                </Grid>
              </>
            }
            <Grid item xs={12} style={{ textAlign: 'right' }}>

              {data[id].status !== 'canceled'
                ? <ResendMessage record={data[id]}/>

                : null
              }
              <GoToButton record={data[id]}></GoToButton>

            {props.noDelete
              ? null
              : <DeleteButton record={data[id]} resource="suppliermessage" redirect={false} mutationMode="pessimistic" />

            }
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    )
  })
  finalForm.push(panel)
  return finalForm
}

SupplierAddForm.propTypes = {
  record: PropTypes.object.isRequired,
  redirect: PropTypes.string
}

SupplierField.propTypes = {
  supplierId: PropTypes.int
}

export default SupplierMessagePanel
