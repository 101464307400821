import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, fetchEnd, fetchStart, useNotify } from 'react-admin'
import ReplayIcon from '@material-ui/icons/Replay'
import CancelIcon from '@material-ui/icons/Cancel'
import { useDispatch } from 'react-redux'
import { API_URL, httpClient } from '../../../dataProvider'

import { TextField, Switch, Typography, Checkbox } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const resendDocuments = (id, hooks, resource, email, useCustomer, sendVouchers, sendInvoice) => {
  const data = { useCustomer: useCustomer, email: email, sendVouchers: sendVouchers, sendInvoice: sendInvoice }
  hooks.dispatch(fetchStart())
  httpClient(`${API_URL}/${resource}/${id}/resend_vouchers`, {
    method: 'POST',
    body: JSON.stringify(data)
  }).then((response) => {
    if (response.status !== 200) hooks.notify('Error with the API')
    else {
      hooks.dispatch(fetchEnd())
    }
  }).catch(() => {
    hooks.notify('Error cannot clone')
  })
}

const ResendDocumentsButton = (props) => {
  const { id, resource } = props
  const [showDialog, setShowDialog] = React.useState(false)
  const [useCustomer, setUseCustomer] = React.useState(true)
  const [email, setEmail] = React.useState()
  const [sendVouchers, setSendVouchers] = React.useState(true)
  const [sendInvoice, setSendInvoice] = React.useState(false)
  const dispatch = useDispatch()
  const notify = useNotify()
  const hooks = { dispatch: dispatch, notify: notify }

  const handleClick = () => {
    setShowDialog(true)
  }
  const handleClose = () => {
    setShowDialog(false)
  }
  const resend = () => {
    handleClose()
    resendDocuments(id, hooks, resource, email, useCustomer, sendVouchers, sendInvoice)
  }

  return (
    <>
      <Button onClick={handleClick} label="Resend Documents">
        <ReplayIcon/>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleClose}
        aria-label="resend-vouchers"
      >
        <DialogTitle>Resend Documents</DialogTitle>
        <DialogContent>
          <Typography variant="body1">Include vouchers <Checkbox checked={sendVouchers} onChange={(e) => setSendVouchers(e.target.checked)} /></Typography>
          <Typography variant="body1">Include invoice<Checkbox checked={sendInvoice} onChange={(e) => setSendInvoice(e.target.checked)} /></Typography>
          <Typography variant="body1">Use customer email? <Switch checked={useCustomer} onChange={(e) => setUseCustomer(e.target.checked)}/></Typography>
          {useCustomer
            ? null
            : <TextField label="Email" defaultValue={email} onChange={(e) => setEmail(e.target.value)} fullWidth/>
          }
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleClose}
          >
            <CancelIcon/>
          </Button>
          <Button
            label="Resend Documents"
            onClick={resend}
          >
            <ReplayIcon/>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ResendDocumentsButton.propTypes = {
  id: PropTypes.number,
  resource: PropTypes.string
}

export default ResendDocumentsButton
