import * as React from 'react'
import { Fragment, memo } from 'react'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  required,
  SearchInput,
  SelectInput,
  SimpleForm,
  AutocompleteInput,
  TextField
} from 'react-admin' // eslint-disable-line import/no-unresolved
import { CreateDialog, EditDialog } from '@react-admin/ra-form-layout'
import ListActions from '../../Components/Toolbar/ListActions'
import { PriceInfoField, ResetViewsButton } from '../../Components'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { useSelectedColumns } from '@react-admin/ra-preferences'
import exporter from '../../Components/exporter'
import DateField from '../../Components/Datefield'
import getConfig from '../../Components/core_config'

const ServiceBaseFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

// eslint-disable-next-line react/display-name
const ExpenditureListBulkActions = memo(props => (
  <Fragment>
    <ResetViewsButton {...props} />
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const expenditureColumns = {
  id: <TextField source="id" />,
  status: <TextField source="status" />,
  supplier: <ReferenceField source="supplier" reference="supplier" link={false} >
    <FunctionField render={record => `${record.name}(${record.email})`} />
  </ReferenceField>,
  ticket: <TextField source="ticket" />,
  buy_price_item: <ReferenceField source="buy_price_item" reference="buypriceitem" link={false}>
    <TextField source="ident"/>
  </ReferenceField>,
  price: <PriceInfoField label="Total price" />,
  created_at: <DateField source="created_at" />,
  modified_at: <DateField source="modified_at" />
}

const ExpenditureList = (props) => {
  const columns = useSelectedColumns({
    preferences: 'expenditure.list.columns',
    columns: expenditureColumns,
    omit: ['id']
  })
  useDefineAppLocation('order.expenditure')
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="expenditure.list.columns" columns={expenditureColumns} />}
        bulkActionButtons={<ExpenditureListBulkActions />}
        filters={<ServiceBaseFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        exporter={(data) => exporter(data, 'Expenditure')}
        perPage={25}
        empty={false}
      >
        <Datagrid rowClick="edit" optimized>
          {columns}
        </Datagrid>
      </List>
      <CreateDialog {...props}>
        <SimpleForm>
          <ReferenceInput source="buy_price_item" reference="buypriceitem" >
            <SelectInput optionText="ident" />
          </ReferenceInput>
          <ReferenceInput source="supplier" reference="supplier" validate={required()} >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
          <ReferenceInput source="ticket" reference="ticket" >
            <SelectInput optionText={record => JSON.stringify(record.content)} />
          </ReferenceInput>
          <NumberInput source="price" validate={required()} />
          <NumberInput source="accounting_price" />
          <SelectInput source="currency" choices={config.currencies_choices} validate={required()} />
          <SelectInput source="status" choices={config.expenditure_status} validate={required()} />
        </SimpleForm>
      </CreateDialog>
      <EditDialog {...props}>
        <SimpleForm>
          <ReferenceInput source="buy_price_item" reference="buypriceitem" >
            <SelectInput optionText="ident" />
          </ReferenceInput>
          <ReferenceInput source="supplier" reference="supplier" validate={required()} >
            <AutocompleteInput optionText="name"/>
          </ReferenceInput>
          <ReferenceInput source="ticket" reference="ticket" >
            <SelectInput optionText={record => JSON.stringify(record.content)} />
          </ReferenceInput>
          <NumberInput source="price" validate={required()} />
          <NumberInput source="accounting_price" />
          <SelectInput source="currency" choices={config.currencies_choices} validate={required()} />
          <SelectInput source="status" choices={config.expenditure_status} validate={required()} />
        </SimpleForm>
      </EditDialog>
    </>
  )
}

export default ExpenditureList
