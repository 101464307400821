import * as React from 'react'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { Create, SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput } from 'react-admin'
import { parse } from 'query-string'
import PropTypes from 'prop-types'

const ServiceOfferingConfigCreate = props => {
  useDefineAppLocation('catalog.serviceofferingconfig.edit')
  const { service_base_id: strServicebaseId } = parse(props.location.search)
  const servicebaseId = strServicebaseId ? parseInt(strServicebaseId, 10) : ''
  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ service_base: servicebaseId, ...props.location.filterValues }} >
        <TextInput source="ident" validate={required()}/>
        <ReferenceInput label="Service Base" source="service_base" reference="servicebase">
          <AutocompleteInput
            optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)}
            resettable
          />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}

ServiceOfferingConfigCreate.propTypes = {
  location: PropTypes.object
}

export default ServiceOfferingConfigCreate
