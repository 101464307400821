import { httpClient } from '../dataProvider'
import { fetchEnd, fetchStart } from 'ra-core'

const downloadPDF = (url, documentName, hooks) => {
  hooks.dispatch(fetchStart())
  httpClient(url, { method: 'GET' }).then((response, data) => {
    // https://stackoverflow.com/questions/71036244/how-to-convert-base64-string-to-pdf-in-react-js
    const linkSource = `data:application/pdf;base64,${response.json.data[0]}`
    const downloadLink = document.createElement('a')
    downloadLink.href = linkSource
    downloadLink.download = documentName
    downloadLink.target = '_blank'
    downloadLink.click()
    hooks.dispatch(fetchEnd())
  })
}
export default downloadPDF
