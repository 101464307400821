import * as React from 'react'
import { DateField as RaDateField } from 'ra-ui-materialui'
import { DATE_FULL_OPTIONS, DATE_OPTIONS } from '.'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import set from 'lodash/set'

const DateField = props => {
  const { record, label, showTime, source, variant = 'body2', ...rest } = props
  const value = get(record, source)
  const newRecord = { ...record }
  value && set(newRecord, source, value)
  return (
    <RaDateField
      variant={variant}
      locales="cs-CZ"
      label={label}
      options={!showTime ? DATE_OPTIONS : DATE_FULL_OPTIONS}
      record={value ? newRecord : record}
      source={source}
      {...rest}
    />
  )
}

DateField.propTypes = {
  showTime: PropTypes.bool,
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string,
  variant: PropTypes.string
}

export default DateField
