import * as React from 'react'
import PropTypes from 'prop-types'

const ViewTitle = (title, props) => {
  return (
    <div style={{
      fontSize: '1.4rem',
      color: '#2196f3',
      textAlign: 'left',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      fontWeight: '400',
      lineHeight: '1.43',
      letterSpacing: '0.01071em',
      verticalAlign: 'inherit'
    }}
    >{title.title}</div> // title itself is an object containing title argument
  )
}
ViewTitle.propTypes = {
  title: PropTypes.string.isRequired
}

export const generalTitle = 'AGP Admin'
export { ViewTitle }
