import * as React from 'react'
import { Breadcrumb, BreadcrumbItem, useAppLocationState } from '@react-admin/ra-navigation'

const MyBreadcrumbItems = (items, link) => {
  if (items === undefined || items.length === 0) return null
  const shiftedItems = items.slice(1)
  let linkTo
  if (shiftedItems[0] === 'list') {
    linkTo = `/${items[0]}`
  } else if (!isNaN(shiftedItems[0])) {
    linkTo = `/${items[0]}/${shiftedItems[0]}`
  } else {
    linkTo = `/${shiftedItems[0]}`
  }
  return (
      <BreadcrumbItem name={items[0]} label={items[0]} to={link === undefined ? null : link}>
        {MyBreadcrumbItems(shiftedItems, linkTo)}
      </BreadcrumbItem>
  )
}

const BreadcrumbFromLocation = props => {
  const [location] = useAppLocationState()
  const bcItems = String(location.path).split('.')
  return (
    <Breadcrumb>
        {MyBreadcrumbItems(bcItems)}
    </Breadcrumb>
  )
}

export default BreadcrumbFromLocation
