import * as React from 'react'
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  SearchInput,
  TextField,
  TextInput,
  useRefresh
} from 'react-admin'

import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'
import { useDidMount } from '../../Components/Utils'
import { API_URL, httpClient } from '../../dataProvider'
import { OldestChannelTokenField, ChannelExpand } from './Components'

const ChannelFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput
      source="ident"
      defaultValue=""
    />
  </Filter>
)

const getTotalTranslations = async () => {
  const data = await httpClient(`${API_URL}/channeltext/total`, { method: 'GET' })
    .then((response, data) => {
      return response.json
    })
  return data.total
}

const ChannelList = props => {
  useDefineAppLocation('settings.channel')
  const [totalTranslations, setTotalTranslations] = React.useState(0)
  const didMount = useDidMount()
  const refresh = useRefresh()
  React.useEffect(() => {
    if (didMount) {
      getTotalTranslations().then(r => {
        setTotalTranslations(r)
        refresh()
      })
    }
  })
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<ChannelFilter />}
        sort={{ field: 'id', order: 'ASC' }}
        exporter={(data) => exporter(data, 'Channel')}
        perPage={25}
      >
        <Datagrid rowClick={'expand'} expand={<ChannelExpand/>} optimized>
          <TextField source="ident" />
          <FunctionField label="Next expiring token" render={record => <OldestChannelTokenField channelId={record.id}/> } />
          <FunctionField label="Translated/NonTranslated" render={record => `${record.translated_total}/${totalTranslations}`}/>
          <FunctionField label="Translation Progress" render={record => `${(((parseInt(record.translated_total)) / totalTranslations) * 100).toFixed(2)}%`}/>
          <ListActionToolbar>
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

export default ChannelList
