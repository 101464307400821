export const ENTITY_TITLE = 'Avantgarde Prague'

export const DATE_FORMAT = 'DD. MM. yyyy, HH:mm'

export const DATE_FULL_OPTIONS = { hour12: false, year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit' }
export const DATE_OPTIONS = { hour12: false, year: 'numeric', month: 'numeric', day: 'numeric' }

export const CALENDAR_DATE_OPTIONS = {
  hour: '2-digit',
  minute: '2-digit',
  hour12: false
}
export const AttributesTypeList = [
  { id: 'string', name: 'Text' },
  { id: 'int', name: 'Number' },
  { id: 'bool', name: 'Boolean' },
  { id: 'date', name: 'Date' },
  { id: 'time', name: 'Time' },
  { id: 'datetime', name: 'Datetime' }
]

export const channelMapping = {
  en: 'AGP-EN',
  fr: 'AGP-FR',
  cs: 'AGP-CZ'
}

export { default as ConnectedSetups } from './ConnectedSetups'
export { default as DateTimeInput } from './DateTimeInput'
export { default as DynamicField } from './DynamicField'
export { default as DynamicInput } from './DynamicInput'
export { default as MultipleField } from './MultipleField'
export { default as PriceInfoField } from './PriceInfoField'
export { default as PublicationContentDisplay } from './PublicationContent/DisplayForm'
export { default as PublicationSnippetEdit } from './PublicationContent/SnippetEditForm'
export { default as RemoveButton } from './RemoveButton'
export { default as ResetViewsButton } from './ResetViewsButton'
export { default as BulkEnableButton } from './BulkEnableButton'
export { default as BulkDisableButton } from './BulkDisableButton'
export { default as SubMenu } from './SubMenu'
export { default as EditActions } from './Toolbar/EditActions'
export { default as ShowActions } from './Toolbar/ShowActions'
