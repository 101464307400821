import * as React from 'react'
import { Fragment, memo } from 'react'

import { useMediaQuery } from '@material-ui/core'
import lodashGet from 'lodash/get'
import jsonExport from 'jsonexport/dist'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  downloadCSV,
  EditButton,
  Filter,
  List,
  SearchInput,
  SimpleList,
  TextField,
  TextInput,
  UrlField
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { ResetViewsButton } from '../Components'
import { generalTitle, ViewTitle } from '../Utils/titles'
import { PropTypes } from 'prop-types'
import ListActionToolbar from '../Components/Toolbar/ListActionToolbar'

const adminUserFilter = props => (
  <Filter {...props}>
    <SearchInput source="id" alwaysOn />
    <TextInput
      source="id"
      defaultValue=""
    />
  </Filter>
)

const exporter = adminUser => {
  const data = adminUser.map(sb => ({
    ...adminUser,
    backlinks: lodashGet(sb, 'backlinks', []).map(
      backlink => backlink.url
    )
  }))
  jsonExport(data, (_err, csv) => downloadCSV(csv, 'user'))
}

// eslint-disable-next-line react/display-name
const AdminUserListBulkActions = memo(props => (
  <Fragment>
    <ResetViewsButton {...props} />
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const rowClick = (id, basePath, record) => {
  return 'show'
}

const adminUserPanel = ({ id, record, resource }) => (
  <div dangerouslySetInnerHTML={{ __html: record.technical_description }} />
)

const AdminUserList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  return (
    <>
      <ViewTitle title="Admin users" />
      <List
        {...props}
        bulkActionButtons={<AdminUserListBulkActions />}
        filters={<adminUserFilter />}
        sort={{ field: 'name', order: 'DESC' }}
        exporter={exporter}
        title={generalTitle}
        perPage={25}
      >
        {isSmall
          ? (
          <SimpleList
            primaryText={record => record.name}
            secondaryText={record => record.email}
          />
            )
          : (
          <Datagrid rowClick={rowClick} expand={adminUserPanel} optimized>
            <TextField source="id" />
            <TextField source="name" label="Name" />
            <UrlField source="email" label="Email"/>
            <ListActionToolbar>
              <EditButton />
            </ListActionToolbar>
          </Datagrid>
            )}
      </List>
    </>
  )
}
adminUserPanel.propTypes = {
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.object
}
export default AdminUserList
