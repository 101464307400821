import * as React from 'react'
import {
  Edit,
  FormTab,
  required,
  PasswordInput,
  TabbedForm,
  TextInput
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { EditActions } from '../Components'
import { generalTitle, ViewTitle } from '../Utils/titles'
import { PropTypes } from 'prop-types'

const AdminUserEdit = ({ permissions, ...props }) => (
  <>
    <ViewTitle title="Edit admin user" />
    <Edit title={generalTitle} actions={<EditActions />} {...props}>
      <TabbedForm warnWhenUnsavedChanges>
        <FormTab label="Base" >
          <TextInput disabled source="id" />
          <TextInput fullWidth source={'name'} validate={required()} resettable />
          <TextInput fullWidth source={'email'} validate={required()} resettable />
          <PasswordInput fullWidth source={'password'} validate={required()} resettable />
        </FormTab>
      </TabbedForm>
    </Edit>
  </>
)

AdminUserEdit.propTypes = {
  permissions: PropTypes.arrayOf(String).isRequired
}

export default AdminUserEdit
