import * as React from 'react'
import { TextInput } from 'react-admin'
import { PropTypes } from 'prop-types'
import { Grid } from '@material-ui/core'
import { useDidMount, getChannelTransFuncParam } from '../Utils'
import ChannelTextTooltip from '../Tooltips/ChannelTextTooltip'

const ChannelTextInput = props => {
  const { source, record, label, ...rest } = props
  if (source === undefined) return 'No source provided'
  const [fieldValue, setFieldValue] = React.useState()
  const didMount = useDidMount()
  React.useEffect(() => {
    if (didMount) {
      let value = record
      source.split('.').forEach(element => {
        if (value !== undefined) {
          value = value[element]
        }
      })
      setFieldValue(getChannelTransFuncParam(value))
    }
  })
  return (
    <>
      <Grid item container xs={12}>
        <Grid item xs={11}>
          <TextInput
            label={label}
            source={source}
            onChange={(e) => setFieldValue(getChannelTransFuncParam(e.target.value))}
            helperText={(props.helperText ? props.helperText + ', ' : '') + ' use {{get_channel_trans(...)}}'}
            fullWidth
          />
        </Grid>
        <Grid item container xs={1} style={{ justifyContent: 'center' }}>
          <ChannelTextTooltip fieldValue={fieldValue} {...rest}/>
        </Grid>
      </Grid>
    </>
  )
}

ChannelTextInput.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  helperText: PropTypes.string,
  record: PropTypes.object
}

export default ChannelTextInput
