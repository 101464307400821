import * as React from 'react'
import { Edit } from 'react-admin'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

import { EditActions } from '../../Components'
import { WithProps } from '../../Utils'

import AccoutingDocumentEditForm from './EditForm'

const AccoutingDocumentEdit = props => {
  useDefineAppLocation('accounting.accountingdocument.edit')
  return (
    <Edit actions={<EditActions />} {...props}>
      <WithProps>{({ record, ...props }) =>
        <AccoutingDocumentEditForm record={record} {...props} />
      }</WithProps>
    </Edit>
  )
}

export default AccoutingDocumentEdit
