import { useGetList } from 'react-admin'

const getExchangeRates = () => {
  const { data, error, loading } = useGetList(
    'currencyexchange',
    { page: 1, perPage: 100 },
    { field: 'created_at', order: 'DESC' }
  )
  if (error) return 'ERROR'
  if (loading) return 'Loading'
  return data
}
export default getExchangeRates
