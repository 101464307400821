import { useDefineAppLocation } from '@react-admin/ra-navigation'
import * as React from 'react'
import {
  AutocompleteArrayInput,
  BooleanField,
  Datagrid,
  Edit,
  ReferenceManyField,
  SimpleForm,
  TextField,
  FileInput,
  FileField,
  TextInput,
  useRecordContext
} from 'react-admin'
import { EditActions } from '../../Components'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import SupplierBaseForm from './Forms'
import { Grid, Typography, Card, CardContent } from '@material-ui/core'
import MancubFileBrowser from '../../Components/MancubFileBrowser'

const SupplierEditForm = props => {
  const record = useRecordContext()
  const choices = []
  record.tags_list.forEach(tag =>
    choices.push({ id: tag, name: tag })
  )
  return (
  <SimpleForm warnWhenUnsavedChanges toolbar={<SaveDeleteToolbar deleteLabel='name'/>} {...props}>
    <Grid container spacing={4} style={{ width: '100%' }}>
      <Grid item xs={4}>
        <Typography variant="h5">General informations</Typography>
        <br/>
        <SupplierBaseForm/>
        <AutocompleteArrayInput
          onCreate={() => {
            const newTagName = prompt('Enter a new tag')
            const newTag = { id: newTagName, name: newTagName }
            choices.push(newTag)
            return newTag
          }}
          choices={choices}
          source="tags_list"
          fullWidth
        />
        <br/>
        <Typography variant="h5">Voucher informations</Typography>
        <br/>
        <TextInput label="Title" source="config.title" fullWidth/>
        <TextInput label="Logo" source="config.logo" fullWidth/>
        <TextInput label="Hotline" source="config.hotline" fullWidth/>
        <TextInput label="Web" source="config.web" fullWidth/>
      </Grid>
      <Grid item xs={8}>
        <Typography variant="h5">Service connections</Typography>
        <br/>
        <ReferenceManyField reference="servicesupplier" target="supplier">
          <Datagrid>
            <TextField label="Service Base" source="service_base.ident"/>
            <BooleanField source="display_on_voucher"/>
            <BooleanField source="send_booking_order"/>
          </Datagrid>
        </ReferenceManyField>
        <br/>
        <Card>
          <CardContent>
            <Typography variant="h5">Documents</Typography>
            <br/>
            <MancubFileBrowser path={`supplier/${record.id}`} initSafe height="300px">
              <FileInput source="file" label="Documents" multiple>
                <FileField source="src" title="title" />
              </FileInput>
            </MancubFileBrowser>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  </SimpleForm>
  )
}

const SupplierEdit = props => {
  useDefineAppLocation('supplier.list.edit')
  return (
    <>
      <Edit
        actions={<EditActions/>}
        {...props}
      >
        <SupplierEditForm/>
      </Edit>
    </>
  )
}

export default SupplierEdit
