/* eslint-disable */

import * as React from 'react'
import {
  Edit,
  required,
  SelectInput,
  FormWithRedirect,
  TextInput,
  TextField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceField,
} from 'react-admin' // eslint-disable-line import/no-unresolved




import {
  DateTimeInput,
  EditActions
} from '../../Components'
import { Grid } from '@material-ui/core'

import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { WithProps } from '../../Utils'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'

import { ServiceBaseCard, ServiceOfferingConfigCard, ServiceOfferingTemplateCard } from '../'

import { ChannelTextInput } from '../../Components/Inputs'
import getConfig from '../../Components/core_config'

import DateField from '../../Components/Datefield'
import { ReferenceManyFieldAccordion } from '../../Components/Fields'
import HoursInput from '../../Components/Inputs/HoursInput'


const ServiceRealizationsExpand = props => {
  return (
      <ReferenceManyFieldAccordion
        title="Service Realizations"
        reference="servicerealization"
        target="service_offerings"
        defaultExpanded={true}
      >
        <TextField source="id" />
        <TextField source="status" />
        <DateField source="start" showTime />
      </ReferenceManyFieldAccordion>
  )
}



const ServiceOfferingEditForm = props => {
  useDefineAppLocation('catalog.serviceoffering.edit')
  const [soTemplate, setSoTemplate] = React.useState(props.record.service_offering_template)
  const [soConfig, setSoConfig] = React.useState(props.record.service_offering_config)
  const handleChangeTemplate = (event) => {
    setSoTemplate(event)
  }
  const handleChangeConfig = (event) => {
    setSoConfig(event)
  }
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    // <SimpleForm warnWhenUnsavedChanges record={props.record} {...props} toolbar={<SaveDeleteToolbar />} >
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
        <div style={{ padding: 20 }}>
          <Grid container spacing={2} style={{ width: '100%' }} {...props}>
            <Grid container direction="column" style={{ height: 'fit-content' }} item md={6}>
                <Grid item xs={12}>
                  <ReferenceField source="service_base" reference="servicebase" link={false}>
                    <ServiceBaseCard />
                  </ReferenceField>
                </Grid>
                <TextInput source="ident" fullWidth />
                <ChannelTextInput label="Title" source="title" editable {...props} />
                <SelectInput choices={config.service_offering_status} source="status" validate={required()} fullWidth />
                <ReferenceInput
                  onChange={handleChangeConfig}
                  label="Offering config"
                  source="service_offering_config"
                  reference="serviceofferingconfig"
                  filter={{ service_base: props.record.service_base }}
                  validate={required()}
                  fullWidth
                >
                  <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable />
                </ReferenceInput>
                

                <ReferenceInput
                  onChange={handleChangeTemplate}
                  label="Offering Template"
                  source="service_offering_template"
                  reference="serviceofferingtemplate"
                  filter={{ service_base: props.record.service_base }}
                  validate={required()}
                  fullWidth
                >
                  <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable />
                </ReferenceInput>

                <HoursInput label='Override min_lead_time' source='config_override.min_lead_time'/>

                <DateTimeInput source="realization_start_from" validate={required()} {...props} fullWidth />
                <DateTimeInput source="realization_start_to" validate={required()} {...props} fullWidth />
                <Grid item xs={12}>
                  {props.record.service_base_detail.realization_strategy === 'pre_created' ? <ServiceRealizationsExpand /> : null}
                </Grid>
            </Grid>
            <Grid container item md={6} spacing={1}>
              <Grid item xs={12}>
                <ReferenceField record={{ id: soConfig }} source="id" reference="serviceofferingconfig" link={false}>
                  <ServiceOfferingConfigCard withAttributes/>
                </ReferenceField>
              </Grid>
              <Grid item xs={12}>
                <ReferenceField record={{ id: soTemplate }} source="id" reference="serviceofferingtemplate" link={false}>
                  <ServiceOfferingTemplateCard withAttributes/>
                </ReferenceField>
              </Grid>

            </Grid>
          </Grid>
        </div>
        <SaveDeleteToolbar {...formProps} />
        </form>
      )}
    />
  )
}

const ServiceOfferingEdit = props => (
  <Edit actions={<EditActions />} {...props}>
    <WithProps>{({ record, ...props }) =>
      <ServiceOfferingEditForm record={record} {...props} />
    }</WithProps>
  </Edit>
)

ServiceOfferingEditForm.propTypes = {
  record: PropTypes.object.isRequired
}

export default ServiceOfferingEdit
