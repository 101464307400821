import React from 'react'
import {
  Create,
  SimpleForm
} from 'react-admin'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import CreateToolbar from '../../Components/CreateToolbar'
import CustomerBaseForm from './Forms'

const CustomerCreate = ({ permissions, ...props }) => {
  useDefineAppLocation('customer.list.create')
  return (
  <>
    <Create {...props}>
      <SimpleForm initialValues={props.location.filterValues} toolbar={<CreateToolbar />} >
        <CustomerBaseForm/>
      </SimpleForm>
    </Create>
  </>
  )
}

CustomerCreate.propTypes = {
  location: PropTypes.object,
  permissions: PropTypes.arrayOf(String).isRequired
}

export default CustomerCreate
