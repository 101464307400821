import * as React from 'react'
import {
  Button,
  SimpleForm,
  ReferenceInput,
  useGetList,
  AutocompleteInput,
  required,
  SelectInput
} from 'react-admin'
import { Link } from 'react-router-dom'
import { CompleteCalendar } from '@react-admin/ra-calendar'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { FilterWithSave, usePreferences } from '@react-admin/ra-preferences'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ListActions from '../../Components/Toolbar/ListActions'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import { CALENDAR_DATE_OPTIONS, DateTimeInput } from '../../Components'
import getConfig from '../../Components/core_config'
import { useGenColor, useWeekendStyle } from '../../Components/Utils'

const ServiceRealizationFilter = props => (
  <FilterWithSave {...props}>
    <ReferenceInput source="service_base" reference="servicebase" alwaysOn>
      <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable/>
    </ReferenceInput>
  </FilterWithSave>
)

const getTitles = () => {
  const { data, ids } = useGetList(
    'servicebase',
    { page: 1, perPage: 10 },
    {}
  )
  const rMap = new Map()
  ids.forEach(id => {
    rMap.set(data[id].id, data[id].ident)
  })
  return rMap
}

const ServiceRealizationCalendar = props => {
  const titles = getTitles()
  const classes = useWeekendStyle()
  useDefineAppLocation('order.calendar')
  const strWoResource = String(props.location.pathname).replace('/realizationcalendar/', '')
  const firstDay = usePreferences('calendar.firstday', 1)[0]
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
  <CompleteCalendar {...props}
    ListProps={{
      title: 'Realization calendar',
      actions: <ListActions noReset {...props} />,
      filters: <ServiceRealizationFilter/>
    }}
    CalendarProps={{
      classes: classes,
      convertToEvent: (event) => ({
        id: String(event.id),
        title: titles.get(event.service_base),
        start: event.start,
        end: event.end,
        backgroundColor: useGenColor(titles.get(event.service_base))
      }),
      eventTimeFormat: CALENDAR_DATE_OPTIONS,
      firstDay: firstDay
    }}
    CreateDialogProps={{
      fullWidth: true,
      title: 'Create service realization'
    }}
    EditDialogProps={{
      fullWidth: true,
      title: 'Edit service realization'
    }}
  >
    <SimpleForm toolbar={<SaveDeleteToolbar />}>
      <ReferenceInput label="Service base" fullWidth source="service_base" reference="servicebase">
        <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable validate={required()}/>
      </ReferenceInput>
      <DateTimeInput
        source="start"
        validate={required()}
      />
      <DateTimeInput
      source="end"
      />
      <SelectInput source="status" choices={config.service_realization_status}/>
      <Button startIcon={<ExitToAppIcon />} label="Go to service realizations" component={Link} to={`/servicerealization/${strWoResource}`}/>
    </SimpleForm>
  </CompleteCalendar>
  )
}

ServiceRealizationCalendar.propTypes = {
  location: PropTypes.object.isRequired,
  pathname: PropTypes.node.isRequired
}

export default ServiceRealizationCalendar
