import * as React from 'react'

import { PropTypes } from 'prop-types'

import { API_URL } from '../../dataProvider'
import { useDispatch } from 'react-redux'
import { useNotify } from 'ra-core'

import { IconButton } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'

import downloadPDF from '../../Utils/downloadPDF'

const AccountingDownloadPDF = props => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const hooks = { dispatch: dispatch, notify: notify }
  const url = API_URL + `/accountingdocument/${props.record.id}/download`
  const documentName = `${props.record.document_number}.pdf`
  const getPDF = (e) => {
    e.stopPropagation()
    downloadPDF(url, documentName, hooks)
  }
  return (
    <IconButton color="primary" onClick={getPDF}>
      <PictureAsPdfIcon/>
    </IconButton>
  )
}

AccountingDownloadPDF.propTypes = {
  record: PropTypes.object
}

export default AccountingDownloadPDF
