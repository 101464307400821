import frenchMessages from 'ra-language-french'
import { raTreeLanguageFrench } from '@react-admin/ra-tree'
import { raRelationshipsLanguageFrench } from '@react-admin/ra-relationships'

const customFrenchMessages = {
  simple: {
    action: {
      enable: 'Tout activer',
      disable: 'Tout désactiver',
      datatagsedit: 'Editer les datatags',
      create: 'Création',
      refresh_payment_status: 'Actualiser le statut du paiement',
      refresh_payment_status_bulk: 'Mise à jour des états de la sélection'
    },
    ui_help: 'UI_HELP'
  },
  notifications: {
    dateupdated: 'Date mise à jour',
    apierror: 'Erreur avec l\'API',
    payment_state_fetch: {
      bulk: {
        failed: 'Impossible de mettre à jour tous les éléments sélectionnés',
        success: 'États mis à jour avec succès'
      },
      single: {
        failed: 'Impossible de mettre à jour le statut du paiement',
        success: 'L\'état du paiement a été mis à jour avec succès'
      }
    }
  },
  menu: {
    monday: 'Lundi',
    first_day: 'Premier jour de la semaine',
    save_close: 'Sauvegarder et fermer',
    save_continue: 'Sauvegarder et continuer',
    filters: {
      from_today: 'A ce jour',
      reset: 'Réinitialiser les filtres',
      ident: 'Ident',
      start_after: 'Commence après',
      end_before: 'La fin avant'
    },
    sunday: 'Dimanche',
    theme: 'Changer de theme',
    navigation: {
      accounting: 'Comptabilité',
      advanced: 'Paramètres avancés',
      accountingdocuments: 'Factures',
      payments: 'Paiements',
      publications: 'Publications',
      channels: 'Channels',
      translations: 'Traductions',
      images: 'Images',
      documents: 'Fichiers',
      geolocations: 'Geolocations',
      contacts: 'Contacts',
      redirects: 'Redirections',
      menuitems: 'Items de menu',
      menutree: 'Arborescence du menu',
      baskets: 'Paniers',
      channelusers: 'Utilisateurs',
      expenditures: 'Expenditures',
      orderitems: 'Items commandes',
      realizationcalendar: 'Calendrier réalisations',
      servicerealizations: 'Réalisations de service',
      bookings: 'Réservations',
      customers: 'Clients',
      orders: 'Commandes',
      settings: 'Paramètres',
      services: 'Services',
      serviceofferings: 'Offres de service',
      offeringcalendar: 'Calendrier d\'offres',
      serviceofferingtemplates: 'Attributs',
      serviceofferingconfig: 'Prix',
      suppliers: 'Fournisseurs',
      suppliermessages: 'Messages fournisseurs',
      currencyexchange: 'Conversion de devise'
    }
  },
  'ra-preferences': {
    saved_queries: {
      new_label: 'Sauvegarder les filtres actuels...',
      new_dialog_title: 'Sauvegarde du filtre',
      query_name: 'Nom du filtre',
      remove_dialog_title: 'Supprimer le filtre ?',
      remove_message: 'Etes-vous sûr de vouloir supprimer ce filtre de votre liste?',
      remove_label_with_name: 'Supprimer le filtre %{name}'
    }
  },
  resources: {
    accountingdocument: {
      fields: {
        type: 'Type de document'
      }
    },
    channel: {
      fields: {
        technical_name: 'Nom technique',
        config: 'Configuration'
      },
      helper: {
        ident: 'Identifiant du channel',
        technical_name: 'Nom utilisé dans l\'admin',
        config: 'Configuration du channel'
      }
    },
    channeltext: {
      fields: {
        key: 'Ident',
        value: 'Valeur'
      },
      helper: {
        key: 'Identifiant de la traduction',
        value: 'Valeur de la traduction',
        channel: 'Channel de la traduction'
      }
    },
    channeltoken: {
      helper: {
        token: 'Généré automatiquement',
        expiration: 'Si laissé vide, automatiquement à 4 semaines',
        label: 'Un label optionel'
      }
    },
    channeluser: {
      fields: {
        username: 'Nom d\'utilisateur',
        password: 'Mot de passe'
      }
    },
    customer: {
      name: 'Client',
      fields: {
        name: 'Nom',
        phone: 'Téléphone'
      }
    },
    menuitem: {
      fields: {
        channel: 'Channel',
        menu_ident: 'Identifiant Menu',
        title: 'Titre',
        sort_order: 'Ordre de tri'
      },
      helper: {
        sort_order: 'Ordre dans lequel le menu apparaîtra',
        menu: 'Groupe de menu dans lequel ce menu sera',
        parent: 'Le menu parent qui contient ce menu'
      }
    },
    order: {
      fields: {
        status: 'Statut',
        channel: 'Channel'
      }
    },
    payment: {
      fields: {
        status: 'Statut du paiement',
        type: 'Type de paiement'
      }
    },
    publication: {
      title: 'Publication',
      show: 'Voir',
      fields: {
        title: 'Titre',
        template: 'Modèle'
      },
      helper: {
        title: 'Titre de la publication',
        ident: 'Identifiant de la publication, utilisé dans l\'url',
        auto_slug: 'Générer le slug automatiquement à partir du titre',
        slug: 'Le slug fait parti de l\'url et permet d\'identifier la page dans un format facile à lire',
        tags: 'Les tags sont nécessaire pour déterminer la catégorie de la publication ou la filtrer',
        channel: 'La publication apparaîtra dans ce channel',
        template: 'Définit les champs, les filtres et les snippets. AVERTISSEMENT : En cas de modification, veuillez recharger la page du navigateur !'
      },
      menu: {
        fields: 'Champs'
      },
      snippet: {
        add: {
          after: 'Ajouter un nouveau snippet apres %{type}',
          before: 'Ajouter un nouveau snippet avant %{type}',
          container: 'Ajouter un nouveau snippet dans le container'
        },
        remove: 'Supprimer ce snippet'
      }
    },
    redirect: {
      fields: {
        target: 'Cible',
        updated_at: 'Mis à jour'
      },
      helper: {
        source: 'Source de la redirection',
        target: 'Cible de la redirection',
        updated_at: 'La dernière mise à jour',
        channel: 'Channel de la redirection'
      }
    },
    servicebase: {
      title: 'Services',
      fields: {
        technical_name: 'Nom technique',
        suppliers_name: 'Supplier\'s name' // TODO translate
      },
      menu: {
        summary: 'Sommaire',
        text_information: 'Informations textuelles'
      }
    },
    serviceofferingconfig: {
      title: 'Prix'
    },
    serviceofferingtemplate: {
      title: 'Attributs'
    },
    serviceoffering: {
      fields: {
        start: 'Début',
        end: 'Fin'
      },
      menu: {
        attributes: 'Attributs',
        pricing: 'Prix',
        today: 'Aujourd\'hui et plus'
      }
    },
    supplier: {
      fields: {
        supplier: 'Fournisseur'
      }
    },
    suppliermessage: {
      fields: {
        status: 'Statut du message',
        type: 'Type de message'
      }
    }
  }
}

export default { ...frenchMessages, ...raTreeLanguageFrench, ...raRelationshipsLanguageFrench, ...customFrenchMessages }
