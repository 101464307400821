import * as React from 'react'
import {
  FileBrowser,
  FileContextMenu,
  FileList,
  FileNavbar,
  FileToolbar,
  setChonkyDefaults,
  ChonkyActions
} from 'chonky'

import { ChonkyIconFA } from 'chonky-icon-fontawesome'
import { useDispatch } from 'react-redux'
import { useNotify } from 'ra-core'

import { getTree, useFileActionHandler, useFolderChain, useFiles, deleteFile, uploadFile, getTransformedFile } from './FileMangementUtils'

import FileUploader from './FileUploader'

import { PropTypes } from 'prop-types'
import { useDidMount } from '../Utils'

import { Grid } from '@material-ui/core'

setChonkyDefaults({ iconComponent: ChonkyIconFA })

const MancubFileBrowser = props => {
  const path = props.path || ''
  const didMount = useDidMount()
  const dispatch = useDispatch()
  const notify = useNotify()
  const hooks = { dispatch: dispatch, notify: notify }
  const [fileMap, setFileMap] = React.useState()
  const [files, setFiles] = React.useState()
  const [folderChain, setFolderChain] = React.useState()
  const [currentPath, setCurrentPath] = React.useState(path || 'media')

  const handleOpen = (folder) => {
    setFiles(useFiles(folder, fileMap))
    setFolderChain(useFolderChain(folder, fileMap))
    setCurrentPath(fileMap[folder].id)
  }

  const createFolder = (folderPath) => {
    const transformedFile = getTransformedFile(null, '.empty_file') // Needed for Django Storage to create a new folder
    uploadFile(transformedFile, folderPath).then(() => {
      handleDelete(folderPath + '.empty_file')
    })
  }

  const handleCreateFolder = (folderName) => {
    if (folderName && folderName.length) {
      const folderPath = currentPath + '/' + folderName + '/'
      createFolder(folderPath)
    }
  }

  const handleDelete = (filePath) => {
    deleteFile(hooks, filePath).then(response => {
      if (response.status !== 200) hooks.notify('Error with api', 'error')
      else {
        getFullTree()
      }
    })
  }

  const getFullTree = () => {
    getTree(hooks, path).then(tree => {
      setFileMap(tree)
      setFiles(useFiles(currentPath, tree))
      setFolderChain(useFolderChain(currentPath, tree))
    })
  }

  const fileActions = React.useMemo(
    () => [ChonkyActions.CreateFolder, ChonkyActions.DeleteFiles],
    []
  )

  const handleFileAction = useFileActionHandler(handleOpen, handleDelete, handleCreateFolder)

  const handleUploadCallBack = () => getFullTree()

  React.useEffect(() => {
    if (didMount) {
      if (props.initSafe) {
        createFolder(path + '/')
      } else {
        getFullTree()
      }
    }
  })
  return (
    <Grid container>
      <Grid item xs={12} style={{ height: props.height || '500px' }}>
        <FileBrowser
        files={files}
        folderChain={folderChain}
        fileActions={fileActions}
        onFileAction={handleFileAction}
        >
          <FileToolbar />
          <FileNavbar />
          <FileList />
          <FileContextMenu />
        </FileBrowser>
      </Grid>
      <Grid item xs={12}>
      {props.children
        ? <FileUploader onUpload={handleUploadCallBack} path={currentPath === 'media' ? '' : currentPath + '/'}>
       {props.children}
      </FileUploader>
        : null
      }
      </Grid>
    </Grid>
  )
}

MancubFileBrowser.propTypes = {
  path: PropTypes.string,
  children: PropTypes.node,
  height: PropTypes.string,
  initSafe: PropTypes.bool
}

export default MancubFileBrowser
