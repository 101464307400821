import { serviceBaseTours } from './Tours/index'
const tours = {
  'servicebase-create': serviceBaseTours.create,
  'servicebase-edit': serviceBaseTours.edit,
  'publication-list': {
    steps: [
      {
        target: "[data-tour-id='publication-list-line'] > tbody > tr:first-child",
        content: 'test'
      }
    ]
  },
  'publication-edit': {
    steps: [
      {
        target: '[role="tablist"] > a:nth-child(1)',
        disableBeacon: true,
        content: 'General edit',
        joyrideProps: {
          scrollOffset: 1000
        }
      },
      {
        target: '[role="tablist"] a:nth-child(2)',
        content: 'Content edit',
        joyrideProps: {
          scrollOffset: 1000
        },
        after: ({ target }) => {
          target.click()
        }
      },
      {
        target: '[id="input-0"]',
        content: 'This is a simple input for content',
        joyrideProps: {
          scrollOffset: 200
        }
      },
      {
        target: '[role="tablist"] a:nth-child(3)',
        content: 'This part is for editing snippets',
        joyrideProps: {
          scrollOffset: 1000
        },
        after: ({ target }) => {
          target.click()
        }
      },
      {
        target: '[id="panel1a-header"]',
        content: 'Each snippet can be expanded to edit its content',
        after: ({ target }) => {
          target.click()
        }
      },
      {
        target: '[id="snippetaccordion"]',
        content: 'Here are all the fields you can edit for this snippet'
      },
      {
        target: '[id="removesnippet_button"]',
        content: 'You can also remove the snippet with this button'
      },
      {
        target: '[id="panel1a-header"]',
        content: 'The snippet accordion can also be collapsed to make things clearer',
        after: ({ target }) => {
          target.click()
        },
        joyrideProps: {
          scrollOffset: 100
        }
      },
      {
        target: '[role="tablist"] a:nth-child(4)',
        content: 'Photos on the publication edit',
        joyrideProps: {
          scrollOffset: 1000
        }
      }
    ]
  }
}

export default tours
