import * as React from 'react'
import { Fragment, memo } from 'react'
import { useMediaQuery } from '@material-ui/core'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  SearchInput,
  SimpleList,
  TextField,
  // DateInput,
  ReferenceInput,
  AutocompleteInput,
  FunctionField
} from 'react-admin' // eslint-disable-line import/no-unresolved
import PropTypes from 'prop-types'
import { ResetViewsButton } from '../../Components'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'
import DateField from '../../Components/Datefield'

import OrderItemDownloadVoucherPDF from './Components/DownloadVoucherPDF'
import SupplierStatusField from '../../Suppliers/SupplierMessage/SupplierStatusField'

const ServiceBaseFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="order" reference="order">
      <AutocompleteInput optionText="code" />
    </ReferenceInput>
    {/* <DateInput
      label="Start date"
      source="start_date"
      defaultValue=""
    /> */}
  </Filter>
)

// eslint-disable-next-line react/display-name
const OrderItemListBulkActions = memo(props => (
  <Fragment>
    <ResetViewsButton {...props} />
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const rowClick = (id, basePath, record) => {
  return 'edit'
}

const OrderItemPanel = ({ record }) => (
  <ul>
    <p>Tickets:</p>
    {record.tickets.map(ticket => (
      <li key={ticket}>{ticket}</li>
    ))}
  </ul>
)

const OrderItemList = (props) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('order.orderitem')
  return (
    <>
      <List
        {...props}
        bulkActionButtons={<OrderItemListBulkActions />}
        filters={<ServiceBaseFilter />}
        sort={{ field: 'id', order: 'DESC' }}
        exporter={(data) => exporter(data, 'OrderItem')}
        perPage={25}
      >
        {isSmall
          ? (
          <SimpleList
            primaryText={record => {
              const serviceoffering = record.serviceoffering
              let _str = serviceoffering.translations.en.name + ' - ' + serviceoffering.start_date
              Object.prototype.hasOwnProperty.call(serviceoffering, 'variant_name')
                ? _str = _str + ' - ' + serviceoffering.variant_name
                : _str = _str + ' - ' + serviceoffering.start_time
              return _str
            }}
            secondaryText={record => (
              <ReferenceField basePath="order" record={record} label="Order" source="basket_id" reference="order" link={true}>
                <TextField source="order_number" />
              </ReferenceField>
            )}
          />
            )
          : (
          <Datagrid rowClick={rowClick} optimized>
            <FunctionField label="Ident" source="id" render={(record) => `#OI${record.id}`} />
            <FunctionField label="Summary" render={record => record.item_object.display_summary} />
            <DateField label="Start Date" source="item_object.service_realization_detail.start" showTime/>
            <ReferenceField label="Order" source="order" reference="order" link={true}>
              <TextField source="code" />
            </ReferenceField>
            <SupplierStatusField label="Supplier" {...props} />
            <ListActionToolbar>
              <EditButton />
              <OrderItemDownloadVoucherPDF style={{ marginLeft: '30px' }} {...props}/>
            </ListActionToolbar>
          </Datagrid>
            )}
      </List>
    </>
  )
}

OrderItemPanel.propTypes = {
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.string
}

export default OrderItemList
