import * as React from 'react'

import { PropTypes } from 'prop-types'
import { Typography, IconButton, Card, CardContent, Grid, Select, MenuItem, TextField } from '@material-ui/core'

import EuroIcon from '@material-ui/icons/Euro'

import getConfig from '../core_config'
import getExchangeRates from '../Utils/getExchangeRates'

const ConvertedPriceField = props => {
  const { buyCurrency, sellCurrency, rates, price } = props
  const rate = Object.values(rates).filter(r => r.buy_currency === buyCurrency && r.sell_currency === sellCurrency)

  return (
    <Typography variant="body1">
      {rate.length > 0
        ? (Math.ceil(rate[0].rate * price * 100) / 100).toFixed(2)
        : null}
      </Typography>
  )
}

const CurrencyExchangeToolBar = props => {
  const [buyCurrency, setBuyCurrency] = React.useState('CZK')
  const [sellCurrency, setSellCurrency] = React.useState('EUR')
  const [open, setOpen] = React.useState(false)
  const [priceToConvert, setPriceToConvert] = React.useState(0)
  const config = getConfig()
  const rates = getExchangeRates()
  if (config === 'ERROR' || rates === 'ERROR') return 'ERROR'
  if (config === 'Loading' || rates === 'Loading') return 'Loading'
  return (
    <>
      <IconButton onClick={() => setOpen(!open)}style={{ color: 'white' }}>
        <EuroIcon/>
      </IconButton>
      <Card hidden={!open} style={{ position: 'absolute', top: '48px', right: '50px' }} variant="outlined">
        <CardContent style={{ padding: 20, paddingTop: 10 }}>
          <Grid item xs={12}>
            <Typography variant="h5">Currency exchange</Typography>
          </Grid>
          <br/>
          <Grid item spacing={2} container xs={12}>
            <Grid item xs={4}>
              <Select
                value={buyCurrency}
                onChange={(e) => setBuyCurrency(e.target.value)}
              >
                {config.currencies.map((currency) => (<MenuItem key={currency} value={currency}>{currency}</MenuItem>))}
              </Select>
            </Grid>
            <Grid item xs={8}>
            <TextField value={priceToConvert} onChange={(e) => setPriceToConvert(e.target.value)} type="number"/>
            </Grid>
          </Grid>
          <Grid item spacing={2} container xs={12} alignItems="center">
            <Grid item xs={4}>
              <Select
                value={sellCurrency}
                onChange={(e) => setSellCurrency(e.target.value)}
              >
                {config.currencies.map((currency) => (<MenuItem key={currency} value={currency}>{currency}</MenuItem>))}
              </Select>
            </Grid>
            <Grid item xs={8}>
              <ConvertedPriceField
                buyCurrency={buyCurrency}
                sellCurrency={sellCurrency}
                price={parseFloat(priceToConvert)}
                rates={rates}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  )
}

ConvertedPriceField.propTypes = {
  buyCurrency: PropTypes.string,
  sellCurrency: PropTypes.string,
  price: PropTypes.number,
  rates: PropTypes.object.isRequired
}

export default CurrencyExchangeToolBar
