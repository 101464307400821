import * as React from 'react'

import OrderList from '../Order/Components/OrderList'
import { FilterWithSave } from '@react-admin/ra-preferences'
import getConfig from '../../Components/core_config'

import {
  DateInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { useDefineAppLocation } from '@react-admin/ra-navigation'

const ModifiedRangeFilter = props => {
  return (
    <>
      <DateInput source="modified_at_after" label="Modified from" resource="order"/>
      <DateInput source="modified_at_before" label="Modified to" resource="order" resettable />
    </>
  )
}

const RealizationRangeFilter = props => {
  return (
    <>
      <DateInput source="realization_start_after" label="Realization from" resource="order"/>
      <DateInput source="realization_start_before" label="Realization to" resource="order"/>
    </>
  )
}

const OrderFilter = props => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <FilterWithSave {...props}>
      <TextInput source="q" label="ra.action.search" alwaysOn resettable />
      <SelectInput source="status" choices={config.order_status}/>
      <ReferenceInput source="channel" reference="channel">
        <AutocompleteInput optionText="ident" />
      </ReferenceInput>
      <ReferenceInput source="channel_user" reference="channeluser">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput source="service_base" reference="servicebase" alwaysOn>
        <AutocompleteInput optionText="ident"/>
      </ReferenceInput>
      <ModifiedRangeFilter label="Modified range" alwaysOn/>
      <RealizationRangeFilter label="Realization range" alwaysOn/>
    </FilterWithSave>
  )
}

const OrderBasketList = props => {
  useDefineAppLocation('basket.list')
  return (
    <OrderList
      filter={{ admin_baskets: true }}
      filters={<OrderFilter />}
      sort={{ field: 'modified_at', order: 'DESC' }}
      omitColumns={['id', 'technical_note', 'created_at']}
      columnsPref="basket.list.columns"
      colorFull
      {...props}
    />
  )
}

export default OrderBasketList
