import * as React from 'react'

import { Edit, SimpleForm, TextInput } from 'react-admin'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

import { EditActions } from '../Components'
import { WithProps } from '../Utils'

const ContactEdit = props => {
  useDefineAppLocation('geolocation.edit')
  return (
    <Edit actions={<EditActions />} {...props}>
      <WithProps>{({ record, ...props }) =>
        (
          <SimpleForm record={record} {...props}>
            <TextInput source='name'/>
            <TextInput source='email'/>
            <TextInput source='phone'/>
          </SimpleForm>
        )
      }
      </WithProps>
    </Edit>
  )
}

export default ContactEdit
