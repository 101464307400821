import React from 'react'
import {
  Button
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { PropTypes } from 'prop-types'
import EditIcon from '@material-ui/icons/Edit'
import { useTranslate } from 'ra-core'

const BulkEditDataTagsButton = (props) => {
  const translate = useTranslate()
  return (
      <Button color="primary" component={RouterLink}
      to={{
        pathname: `/${props.resource}/bulkeditdatatags/${props.selectedIds}`
      }}
      startIcon={<EditIcon />}
      >
          {translate('simple.action.datatagsedit')}
      </Button>
  )
}

BulkEditDataTagsButton.propTypes = {
  resource: PropTypes.string.isRequired,
  selectedIds: PropTypes.object.isRequired
}

export default BulkEditDataTagsButton
