import * as React from 'react'

import {
  ReferenceManyField,
  Datagrid,
  TextField
} from 'react-admin'

import {
  Typography,
  Grid
} from '@material-ui/core'

import { PropTypes } from 'prop-types'

import DetailCard from '../../../Components/DetailCard'

const ServiceOfferingTemplateCard = props => {
  return (
    <>
    <DetailCard
      title="Offering Template"
      reference="serviceofferingtemplate"
      {...props}
    >
      <Typography variant="body2">Ident: </Typography><TextField source="ident"/>
      {props.withAttributes
        ? <>
        <br/> <br/>
        <Grid item xs={12}>
          <Typography variant="h6">Service setup attributes</Typography>
          <ReferenceManyField sort={{ field: 'sort_order', order: 'ASC' }} reference="servicesetupattribute" target="service_offering_template">
            <Datagrid
              optimized
            >
              <TextField source="id" />
              <TextField source="ident" />
              <TextField source="field_type" />
              <TextField source="sort_order" />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <br/> <br/>
        <Grid item xs={12}>
          <Typography variant="h6">Ticket attributes</Typography>
          <ReferenceManyField sort={{ field: 'sort_order', order: 'ASC' }} reference="ticketattribute" target="service_offering_template">
            <Datagrid
              optimized
            >
              <TextField source="id" />
              <TextField source="ident" />
              <TextField source="field_type" />
              <TextField source="sort_order" />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        </>
        : null
      }
    </DetailCard>
    </>
  )
}

ServiceOfferingTemplateCard.propTypes = {
  withAttributes: PropTypes.bool,
  record: PropTypes.object
}

export default ServiceOfferingTemplateCard
