import * as React from 'react'
import { PropTypes } from 'prop-types'
import { Typography } from '@material-ui/core'
import { useDidMount, getChannelTransFuncParam } from '../Utils'
import { TranslationWithTooltip } from '../Tooltips'

const ChannelTextField = props => {
  const { source, record, label, ...rest } = props
  if (source === undefined) return 'No source provided'
  const [fieldValue, setFieldValue] = React.useState()
  const [textValue, setTextValue] = React.useState()
  const didMount = useDidMount()
  const [doTranslate, setDoTranslate] = React.useState(false)

  const formatAndSetTextValue = (formatedValue) => {
    if (formatedValue !== undefined && formatedValue !== null) {
      const values = formatedValue.split('{{')
      if (values.length >= 1) {
        formatedValue = ''
        values.forEach(val => {
          if (!val) return
          let translation = getChannelTransFuncParam(val)

          if (translation === undefined) translation = val
          else setDoTranslate(true)
          formatedValue += translation
        })
      }
    }

    setTextValue(formatedValue)
  }

  React.useEffect(() => {
    if (didMount) {
      let value = record

      if (source === undefined || source === null) value = undefined

      if (value !== undefined) {
        source.split('.').forEach(element => {
          value = value[element]
        })
      }
      formatAndSetTextValue(value)

      const translation = getChannelTransFuncParam(value)
      if (translation === undefined) return setFieldValue(value)

      setFieldValue(translation)
    }
  })
  return (
    <>
    {!doTranslate
      ? <Typography variant="body2" display="inline" {...rest}>{textValue}</Typography>
      : <TranslationWithTooltip fieldValue={fieldValue} textValue={textValue}/>
    }
    </>

  )
}

ChannelTextField.propTypes = {
  source: PropTypes.string,
  label: PropTypes.string,
  record: PropTypes.object,
  doTranslate: PropTypes.bool
}

export default ChannelTextField
