import * as React from 'react'
import { Fragment, memo } from 'react'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  Pagination
} from 'react-admin' // eslint-disable-line import/no-unresolved

import BulkEditButton from '../../Components/BulkEditButton'
import BulkCloneButton from './Components/BulkCloneButton'
import BulkBatchEditButton from './Components/BulkBatchEditButton'
import ListActions from '../../Components/Toolbar/ListActions'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { useSelectedColumns } from '@react-admin/ra-preferences'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'
import DateField from '../../Components/Datefield'
import ShortTextField from '../../Components/ShortTextField'
import ServiceOfferingFilters from './Filters'
import dateAdmin from '../../Utils/dateAdmin'
import HoursField from '../../Components/Fields/HoursField'

// eslint-disable-next-line react/display-name
const ServiceOfferingListBulkActions = memo(props => (
  <Fragment>
    <BulkCloneButton {...props} />
    <BulkBatchEditButton {...props} />
    <BulkEditButton {...props} />
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const ServiceOfferingPanel = props => (
  // TODO Show the list of related service setup, with its customer and order number
  <p>Change this when we will have service setup, order and customer available from the api</p>
)

const serviceOfferingColumns = {
  id: <TextField source="id" />,
  ident: <FunctionField label="Ident" render={record => <ShortTextField text={record.ident}/>}/>,
  servicebase: <FunctionField label="Service base" render={record => <ShortTextField text={record.service_base_detail.admin_name}/>}/>,
  start: <DateField source="realization_start_from" showTime />,
  end: <DateField source="realization_start_to" showTime />,
  min_lead_time: <HoursField source="min_lead_time" sortable={false} />,
  valid_from: <DateField source="valid_from" />,
  valid_to: <DateField source="valid_to" />,
  status: <TextField source="status"/>,
  serviceoffering_prices:
  <ReferenceField label="Prices" source="service_offering_config" reference="serviceofferingconfig" link={false}>
    <TextField source="ident" />
  </ReferenceField>,
  serviceoffering_attributes:
  <ReferenceField label="Attributes" source="service_offering_template" reference="serviceofferingtemplate" link={false}>
    <TextField source="ident" />
  </ReferenceField>,
  realization_strategy: <TextField label="Realization strategy" source="service_base_detail.realization_strategy" />
}

const ServiceOfferingList = props => {
  const columns = useSelectedColumns({
    preferences: 'serviceoffering.list.columns',
    columns: serviceOfferingColumns,
    omit: ['id', 'serviceoffering_type', 'realization_strategy', 'valid_from', 'valid_to']
  })
  useDefineAppLocation('catalog.serviceoffering')
  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="serviceoffering.list.columns" columns={serviceOfferingColumns} fromToday='realization_start_from' />}
        bulkActionButtons={<ServiceOfferingListBulkActions />}
        filters={<ServiceOfferingFilters />}
        filterDefaultValues={{ realization_start_from: dateAdmin.parse(new Date(), 'yyyy-mm-dd') }}
        sort={{ field: 'realization_start_from', order: 'ASC' }}
        exporter={(data) => exporter(data, 'ServiceOffering')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
        >
        <Datagrid rowClick="edit" expand={ServiceOfferingPanel} optimized>
          {columns}
          <ListActionToolbar>
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

export default ServiceOfferingList
