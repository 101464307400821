import { useDefineAppLocation } from '@react-admin/ra-navigation'
import * as React from 'react'
import {
  Card,
  CardContent,
  Grid,
  Typography,
  Select,
  MenuItem,
  TextField
} from '@material-ui/core'
import getConfig from '../../Components/core_config'
import { PropTypes } from 'prop-types'
import getExchangeRates from '../../Components/Utils/getExchangeRates'

const ConvertedPriceField = props => {
  const { buyCurrency, sellCurrency, rates, price } = props
  const rate = Object.values(rates).filter(r => r.buy_currency === buyCurrency && r.sell_currency === sellCurrency)

  return (
    <Typography variant="body1">
      {rate.length > 0
        ? (Math.ceil(rate[0].rate * price * 100) / 100).toFixed(2)
        : null}
      </Typography>
  )
}

const CurrencyExchangeList = props => {
  useDefineAppLocation('accounting.currencyexchange.list')

  const [buyCurrency, setBuyCurrency] = React.useState('CZK')
  const [sellCurrency, setSellCurrency] = React.useState('EUR')
  const [priceToConvert, setPriceToConvert] = React.useState(0)
  const config = getConfig()
  const rates = getExchangeRates()
  if (config === 'ERROR' || rates === 'ERROR') return 'ERROR'
  if (config === 'Loading' || rates === 'Loading') return 'Loading'

  return (
    <>
      <Grid container style={{ width: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <Card variant="outlined">
          <CardContent style={{ padding: 40 }}>
            <Grid item xs={12}>
              <Typography variant="h5">Currency exchange</Typography>
            </Grid>
            <br/>
            <Grid item spacing={2} container xs={12}>
              <Grid item xs={4}>
                <Select
                  value={buyCurrency}
                  onChange={(e) => setBuyCurrency(e.target.value)}
                >
                {config.currencies.map((currency) => (<MenuItem key={currency} value={currency}>{currency}</MenuItem>))}
                </Select>
              </Grid>
              <Grid item xs={8}>
                <TextField value={priceToConvert} onChange={(e) => setPriceToConvert(e.target.value)} type="number"/>
              </Grid>
            </Grid>
            <Grid item spacing={2} container xs={12} alignItems="center">
              <Grid item xs={4}>
                <Select
                  value={sellCurrency}
                  onChange={(e) => setSellCurrency(e.target.value)}
                >
                {config.currencies.map((currency) => (<MenuItem key={currency} value={currency}>{currency}</MenuItem>))}
                </Select>
              </Grid>
              <Grid item xs={8}>
                <ConvertedPriceField
                  buyCurrency={buyCurrency}
                  sellCurrency={sellCurrency}
                  price={parseFloat(priceToConvert)}
                  rates={rates}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </>
  )
}

ConvertedPriceField.propTypes = {
  buyCurrency: PropTypes.string,
  sellCurrency: PropTypes.string,
  price: PropTypes.Number,
  rates: PropTypes.array
}

export default CurrencyExchangeList
