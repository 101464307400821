import * as React from 'react'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { Create, SimpleForm, TextInput } from 'react-admin'

const ContactCreate = props => {
  useDefineAppLocation('geolocation.create')
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source='name'/>
        <TextInput source='email'/>
        <TextInput source='phone'/>
      </SimpleForm>
    </Create>
  )
}

export default ContactCreate
