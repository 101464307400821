import * as React from 'react'
import { Children, cloneElement } from 'react'
import { PropTypes } from 'prop-types'
import { makeStyles } from '@material-ui/core'

const useServiceOfferingListActionToolbarStyles = makeStyles({
  toolbar: {
    alignItems: 'center',
    display: 'flex',
    marginTop: -1,
    marginBottom: -1
  }
})

const ListActionToolbar = ({ children, ...props }) => {
  const classes = useServiceOfferingListActionToolbarStyles()
  return (
      <div className={classes.toolbar}>
        {Children.map(children, button => cloneElement(button, props))}
      </div>
  )
}

ListActionToolbar.propTypes = {
  children: PropTypes.node
}

export default ListActionToolbar
