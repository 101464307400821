import dateformat from 'dateformat'
import { isUndefinedOrNull } from '../Utils'

/**
 * It's format for the react admin, but parse for the database
 *
 * @param {string} str
 *
 * @returns {Date} return the Date object corresponding to the string
 */
const format = (str) => {
  if (isUndefinedOrNull(str)) return new Date()

  let t = /([0-9]{2}):([0-9]{2}):([0-9]{2})/.exec(str)
  if (t !== null) t = t[0].split(':')
  else t = [12, 0, 0]

  let d = /([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{2,4})/.exec(str)
  if (d !== null) {
    d = d[0].split('/')
    d = [d[2], d[1], d[0]]
  } else {
    d = /([0-9]{2,4})-([0-9]{1,2})-([0-9]{1,2})/.exec(str)
    if (d !== null) d = d[0].split('-')
    else d = [1970, 1, 1]
  }
  const date = new Date(d[0], d[1] - 1, d[2], t[0], t[1], t[2])
  return date
}

/**
 * It's parse for the react admin, but format for the database
 *
 * @param {date} date
 * @param {string} format
 *
 * @returns {string} Return the string corresponding to the date and format given
 */
const parse = (date, format) => {
  if (isUndefinedOrNull(date)) date = new Date()
  const str = dateformat(date, format)
  return str
}
export default { parse, format }
