import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import moment from 'moment'

if (process.env.NODE_ENV === 'production') {
  if (window.CONFIG) {
    const keys = Object.keys(window.CONFIG)
    if (keys.indexOf('SENTRY_ENV') >= 0 && keys.indexOf('SENTRY_DSN') >= 0) {
      Sentry.init({
        dsn: window.CONFIG.SENTRY_DSN,
        integrations: [new Integrations.BrowserTracing()],
        environment: window.CONFIG.SENTRY_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
      })
    }
  }
}

moment.tz.setDefault('UTC')

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
