import * as React from 'react'

import { Send } from '@material-ui/icons'
import { fetchEnd, fetchStart, useNotify, useRedirect } from 'ra-core'
import { Button } from 'ra-ui-materialui'
import { useDispatch } from 'react-redux'
import { API_URL, httpClient } from '../../dataProvider'
import PropTypes from 'prop-types'

const SendButton = props => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const redirect = useRedirect()
  const hooks = { dispatch: dispatch, notify: notify, redirect: redirect }
  const sendMessage = () => {
    hooks.dispatch(fetchStart())
    httpClient(API_URL + '/suppliermessage/', {
      method: 'POST',
      // headers: { 'Content-type': 'application/json; charset=utf8' },
      body: JSON.stringify(props.record)
    })
      .then((response) => {
        if (response.status !== 201) hooks.notify('Error with api', 'error')
        else {
          hooks.notify('Message sent')
        }
      })
      .catch((e) => {
        hooks.notify('Error', 'error')
      })
      .finally(() => {
        hooks.dispatch(fetchEnd())
        hooks.redirect('/')
        hooks.redirect(props.redirect === undefined ? '/suppliermessage' : props.redirect)
      })
  }
  return (
      <Button
      color="primary"
      label="send"
      onClick={sendMessage}
      disabled={props.invalid}
    >
      <Send />
    </Button>
  )
}

SendButton.propTypes = {
  record: PropTypes.object.isRequired,
  redirect: PropTypes.string,
  invalid: PropTypes.bool
}

export default SendButton
