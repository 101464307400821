import * as React from 'react'
import PropTypes from 'prop-types'
import { TextField } from 'ra-ui-materialui'
import { required, useQuery } from 'ra-core'
import TextInputWithSuggestions from '../TextInputWithSuggestions'
import { ReferenceManyField, TextInput, Pagination } from 'react-admin'
import DateTimeInput from '../DateTimeInput'
import { DatagridWithDialog } from '../Dialogs'
import DateField from '../Datefield'

const DataTagEditForm = props => {
  return (
    <>
    <ReferenceManyField reference="datatag" target="publication" pagination={<Pagination />}>
      <DatagridWithDialog
        defaultValue={{
          publication: props.record.id
        }}
        form={<DataTagForm/>}
        rowClick="edit"
      >
        <TextField source="namespace"/>
        <TextField source="ident"/>
        <TextField source="value"/>
        <TextField source="format"/>
        <DateField source="valid_from" showTime />
        <DateField source="valid_to" showTime />
        <DateField source="updated_at" showTime />
      </DatagridWithDialog>
    </ReferenceManyField>
    </>
  )
}
const dataTagIdents = () => {
  const { data, error, loading } = useQuery({
    type: 'getCustom',
    resource: 'datatag',
    payload: { data: 'idents' }
  })
  if (error || loading) return []
  return Object.values(data).map(sd => {
    const result = {}
    result.id = sd.ident
    return result
  })
}

const DataTagForm = props => {
  const choices = dataTagIdents()
  return (
  <>
    <TextInputWithSuggestions source="ident" choices={choices} validate={required()} fullWidth/>
    <TextInput source="value" validate={required()} initialValue={props.record && props.record.value != null ? props.record.value : 1 } fullWidth/>
    <TextInput source="format" fullWidth/>
    <TextInput source="namespace" fullWidth/>
    <DateTimeInput source="valid_from" fullWidth/>
    <DateTimeInput source="valid_to" fullWidth/>
  </>
  )
}

DataTagForm.propTypes = {
  record: PropTypes.object
}
DataTagEditForm.propTypes = {
  record: PropTypes.object.isRequired
}

export default DataTagEditForm
