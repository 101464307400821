import ServiceOfferingTemplateList from './List'
import ServiceOfferingTemplateCreate from './Create'
import ServiceOfferingTemplateEdit from './Edit'

export default {
  list: ServiceOfferingTemplateList,
  create: ServiceOfferingTemplateCreate,
  edit: ServiceOfferingTemplateEdit
}

export { default as Card } from './Components/Card'
