import {
  useQuery
} from 'react-admin'

const getMenuGroups = () => {
  const { data, error, loading } = useQuery({
    type: 'getCustom',
    resource: 'menuitem',
    payload: { data: 'group' }
  })
  if (error || loading) return []
  return Object.values(data).map((mi) => {
    const result = {}
    result.id = mi.group
    return result
  })
}

export default getMenuGroups
