import * as React from 'react'

import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { Datagrid, EditButton, Filter, List, SearchInput, TextField, Pagination } from 'react-admin'
import ListActions from '../Components/Toolbar/ListActions'
import exporter from '../Components/exporter'
import ListActionToolbar from '../Components/Toolbar/ListActionToolbar'

const ContactFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn/>
  </Filter>
)

const ContactList = props => {
  useDefineAppLocation('contact.list')
  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="contact.list.columns" />}
        sort={{ field: 'name', order: 'DESC' }}
        exporter={(data) => exporter(data, 'Contact')}
        filters={<ContactFilter/>}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
        empty={false}
      >
        <Datagrid
          rowClick='edit'
          optimized
        >
          <TextField source='name'/>
          <TextField source='email'/>
          <TextField source='phone'/>
          <ListActionToolbar>
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

export default ContactList
