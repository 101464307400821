import React, { useState } from 'react'
import { PropTypes } from 'prop-types'
import { DualListInput } from '@react-admin/ra-enterprise/node_modules/@react-admin/ra-relationships'
import AddIcon from '@material-ui/icons/Add'
import { Button } from 'react-admin' // eslint-disable-line import/no-unresolved
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import { useDidMount, getChannelTransFuncParam } from '../../../../Components/Utils'
import ChannelTextTooltip from '../../../../Components/Tooltips/ChannelTextTooltip'

const DialogAddChoice = (props) => {
  const { open, handleClose, handleAddClick } = props

  const [choiceValue, setChoiceValue] = useState()

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <TextField
          label="choice"
          variant="filled"
          onChange={(e) => setChoiceValue(e.target.value)}
        />
      </DialogContent>

      <DialogActions>
        <Button label="add" onClick={() => handleAddClick(choiceValue)}>
          <AddIcon />
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const ChoiceInput = (props) => {
  const { name, placeholder, choices } = props

  const didMount = useDidMount()
  const [open, setOpen] = useState()
  const [formattedChoices, setFormattedChoices] = useState([])

  React.useEffect(() => {
    if (didMount && choices !== undefined) {
      const formattedChoicesTemp = formattedChoices
      for (const choice of choices) {
        formattedChoicesTemp.push({ id: choice, name: choice })
      }
      setFormattedChoices(formattedChoicesTemp)
      setOpen(false) // this is for refresh the DualList component
    }
  })

  const handleClose = () => {
    setOpen(false)
  }

  const handleCreateOpen = () => {
    setOpen(true)
  }

  const handleAddClick = (choiceValue) => {
    setFormattedChoices((formattedChoices) => [
      ...formattedChoices,
      { id: choiceValue, name: choiceValue }
    ])
    handleClose()
  }

  return (
    <>
      <Button label={`create ${name}`} onClick={handleCreateOpen}>
        <AddIcon />
      </Button>
      {formattedChoices.length > 0
        ? (
        <DualListInput
          label={placeholder}
          source={name}
          choices={formattedChoices}
          optionText={(record) => record !== undefined
            ? <>
            <span style={{ marginRight: 8 }}>{record.name}</span> <ChannelTextTooltip fieldValue={getChannelTransFuncParam(record.name)} editable/>
            </>

            : null}
        />
          )
        : null}
      <DialogAddChoice
        open={open}
        handleClose={handleClose}
        handleAddClick={handleAddClick}
      />
    </>
  )
}

DialogAddChoice.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  handleAddClick: PropTypes.func
}

ChoiceInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  choices: PropTypes.array,
  record: PropTypes.object
}

export default ChoiceInput
