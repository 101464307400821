import * as React from 'react'

import {
  TextInput,
  required,
  ReferenceManyField,
  TextField,
  BooleanField,
  BooleanInput,
  NumberInput,
  NumberField
} from 'react-admin'

import PropTypes from 'prop-types'
import PriceInput from '../../../Components/PriceInput'
import PriceField from '../../../Components/PriceField'
import { DatagridWithDialog } from '../../../Components/Dialogs'
import { ChannelTextInput } from '../../../Components/Inputs'

const SellPriceItemForm = (props) => {
  return (
    <>
      <TextInput source="ident" validate={required()} fullWidth />
      <PriceInput
        validate={required()}
        priceSource="price"
        defaultCurrency="CZK"
        {...props}
      />
      <NumberInput source="original_price" fullWidth />
      <NumberInput source="units" fullWidth />
      <NumberInput source="sort_order" fullWidth />
      <BooleanInput source="is_main" fullWidth />
      <BooleanInput source="is_buyable_alone" fullWidth />
      <ChannelTextInput label="title" source="content.title" editable {...props}/>
      <ChannelTextInput label="Price quantifier" source="content.price_quantifier" editable {...props}/>
      <ChannelTextInput label="subtitle" source="content.subtitle" editable {...props}/>
      <ChannelTextInput label="description_button" source="content.description_button" editable {...props}/>
      <ChannelTextInput label="description" source="content.description" editable {...props}/>
      <ChannelTextInput label="discount" source="content.discount" editable {...props}/>
      <TextInput label="filter_group" source="content.filter_group" fullWidth />
      <TextInput source="ticket_attribute" fullWidth />
      <TextInput source="capacity_pool_tag" fullWidth />
    </>
  )
}

const SellPriceItemsEdit = (props) => {
  return (
    <ReferenceManyField
      label=""
      sort={{ field: 'sort_order', order: 'ASC' }}
      reference="sellpriceitem"
      target="service_offering_config"
      fullWidth
    >
      <DatagridWithDialog
        defaultValue={{
          currency: 'CZK',
          service_offering_config: props.record.id
        }}
        form={<SellPriceItemForm />}
      >
        <TextField source="ident" />
        <TextField source="sort_order" />
        <TextField source="ticket_attribute" />
        <TextField source="capacity_pool_tag" />
        <BooleanField source="is_main" />
        <BooleanField source="is_buyable_alone" />
        <PriceField label="Price" priceSource="price" />
        <NumberField label="Original price" source="original_price" />
      </DatagridWithDialog>
    </ReferenceManyField>
  )
}

SellPriceItemsEdit.propTypes = {
  record: PropTypes.object
}

SellPriceItemForm.propTypes = {
  initialValues: PropTypes.object
}

export default SellPriceItemsEdit
