import * as React from 'react'
import { useGetList } from 'ra-core'
import { Card, CardContent, Divider, makeStyles } from '@material-ui/core'
import { Loading, ReferenceField } from 'ra-ui-materialui'
import { PropTypes } from 'prop-types'
import DateField from '../../../Components/Datefield'
import { JsonField } from 'react-admin-json-view'

const useStyles = makeStyles((theme) => ({
  loading: {
    maxHeight: '500px'
  }
}))

const TicketInfos = props => {
  const classes = useStyles()
  const { data, ids, error, loading } = useGetList(
    'ticket',
    { page: 1, perPage: 1000000 },
    { field: 'id', order: 'ASC' },
    { service_setup: props.record.id }
  )
  if (error) return 'ERROR'
  if (loading) return (<Loading className={classes.loading}/>)

  const firstTicket = data[ids[0]]
  if (firstTicket === undefined) {
    return (
    <>
    {props.children}
    <span>NO TICKET</span>
    </>
    )
  }
  return (
    ids.map(id => {
      const ticket = data[id]
      return (
        <>
        {id === ids[0] ? props.children : <><Divider /><br/></>}
        <span>Summary: {ticket.display_summary}</span><br/><br/>
        <span>Ticket attributes:</span><br/>
        <ul>
        {ticket.content.attributes.map(ta => (
          <li key={ta.ident}>
            <span>{ta.ident}: {ta.value}</span><br/>
            <span>{ta.type}: {ta.number}</span><br/>
          </li>
        ))}
        </ul>
        </>
      )
    })
  )
}

const OrderItemInfoPanel = props => {
  const classes = useStyles()
  if (props.record === undefined) return null
  const { data, error, loading } = useGetList(
    'servicesetup',
    { page: 1, perPage: 1 },
    { field: 'id', order: 'ASC' },
    { item_ident: props.record.item_ident }
  )
  if (error) return ('ERROR')
  if (loading) {
    return (
      <>
        <Card style={{ marginBottom: 5, marginTop: 50 }} variant="outlined">
          <CardContent style={{ padding: 16 }}>
              <Loading className={classes.loading}/>
          </CardContent>
          </Card>
      </>)
  }
  const setup = data[Object.keys(data)[0]]
  return (
      <>
        <Card style={{ marginBottom: 5 }} variant="outlined">
          <CardContent style={{ padding: 16 }}>
            {setup === undefined
              ? <span>NO SETUP</span>
              : <TicketInfos record={setup}>
              <span>Start date: </span>
              <ReferenceField record={setup} source="service_offering" reference="serviceoffering" link={false}>
                <DateField source="realization_start_from" showTime/>
              </ReferenceField><br/>
              <span>End date: </span>
              <ReferenceField record={setup} source="service_offering" reference="serviceoffering" link={false}>
                <DateField source="realization_start_to" showTime/>
              </ReferenceField><br/> <br/>
              {props.record.item_object.service_setup_attibutes.map(ssa =>
                <div key={ssa.id}>
                  <span>{ssa.ident}: {ssa.field_type}</span>
                  <JsonField record={ssa} source="content"/>
                </div>
              )}<br/>
            </TicketInfos>}
          </CardContent>
        </Card>
      </>
  )
}

TicketInfos.propTypes = {
  record: PropTypes.object.isRequired,
  children: PropTypes.object
}

OrderItemInfoPanel.propTypes = {
  record: PropTypes.object.isRequired
}

export default OrderItemInfoPanel
