import React from 'react'
import {
  Create,
  TextInput,
  FormWithRedirect,
  Toolbar,
  SaveButton,
  ReferenceArrayInput,
  CheckboxGroupInput,
  useTranslate,
  SelectInput,
  FormDataConsumer
} from 'react-admin'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import RichTextInput from 'ra-input-rich-text'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

import getConfig from '../../Components/core_config'
import { EditActions } from '../../Components'

const onDemandChoice = 'datepicker_customtime'

const ServiceBaseCreateForm = (props) => {
  useDefineAppLocation('catalog.servicebase.create')
  const translate = useTranslate()
  const config = getConfig()

  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <FormWithRedirect
    mutators = {{
      setRealizationStartForOnDemand: (args, state, utils) => {
        utils.changeValue(state, 'realization_start_selector', () => onDemandChoice)
      }
    }}
    {...props}
    render={formProps => (
    <form>
      <div style={{ padding: 20 }}>
        <Grid container spacing={4}>
          <Grid container item md={12} lg={6} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{translate('resources.servicebase.menu.summary', { _: 'Summary' })}</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextInput source="ident" fullWidth required/>
              <TextInput source="technical_name" fullWidth />
            </Grid>
            <Grid item xs={6}>
              <SelectInput
              source="realization_strategy"
              choices={config.realization_strategy}
              onChange={event => event.target.value === 'on_demand' && formProps.form.mutators.setRealizationStartForOnDemand()}
              required
              fullWidth />
            </Grid>
            <Grid item xs={6}>
              <FormDataConsumer>
                {({ formData, ...rest }) => (
                        <SelectInput
                            source="realization_start_selector"
                            choices={config.realization_start_selector}
                            disabled={formData.realization_strategy === 'on_demand'}
                            {...rest}
                            required
                            fullWidth
                        />
                )}
              </FormDataConsumer>
            </Grid>
            <Grid item xs={12}>
              <TextInput source="notes" fullWidth />
            </Grid>
            {/* Should be removed in the future when the api doesn't use the channels in service base */}
            <Grid item xs={12}>
              <ReferenceArrayInput source="channels" reference="channel">
                <CheckboxGroupInput optionText="ident"/>
              </ReferenceArrayInput>
            </Grid>
          </Grid>
          <Grid container item md={12} lg={6} spacing={1}>
            <Grid item xs={12}>
              <Typography variant="h5">{translate('resources.servicebase.menu.text_information', { _: 'Text information' })}</Typography> <br/>
              <RichTextInput source="technical_note" />
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
            <SaveButton
                saving={formProps.saving}
                handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
            />
        </Box>
      </Toolbar>
    </form>
    )}
    />
  )
}

const ServiceBaseCreate = ({ permissions, ...props }) => {
  const translate = useTranslate()
  return (
  <>
    <Create
      title={translate('resources.servicebase.title')}
      actions={<EditActions tour="servicebase-create"/>}
      {...props}
    >
      <ServiceBaseCreateForm />
    </Create>
  </>
  )
}

ServiceBaseCreate.propTypes = {
  permissions: PropTypes.arrayOf(String)
}

export default ServiceBaseCreate
