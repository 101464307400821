import * as React from 'react'
import {
  Button,
  Edit,
  LinearProgress,
  ReferenceManyField,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  useUpdate,
  ReferenceField,
  FunctionField,
  useTranslate,
  TextField,
  BooleanField
} from 'react-admin' // eslint-disable-line import/no-unresolved
import { Grid, Typography } from '@material-ui/core'
import { EditActions } from '../../Components'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { WithProps } from '../../Utils'
import RichTextInput from 'ra-input-rich-text'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import { Form } from 'react-final-form'
import { JsonInput } from 'react-admin-json-view'
import { Save } from '@material-ui/icons'
import AttributeForm from '../Attribute/Components/Form'
import getConfig from '../../Components/core_config'
import { DatagridWithDialog } from '../../Components/Dialogs'
import { useSelectedColumns } from '@react-admin/ra-preferences'

const AttributeColumns = {
  id: <TextField source="ident" />,
  field_type: <TextField source="field_type" />,
  required: <BooleanField source="required" />,
  sort_order: <TextField source="sort_order" />,
  realization_only: <BooleanField source="realization_only" />,
  display_options_default: <TextField source="content.display_options.default" label="Web display" />

}

const TAContentEditForm = (props) => {
  const [content, setContent] = React.useState(props.record.content)
  const [update, { error, loading }] = useUpdate()
  if (error) return 'ERROR'
  if (loading) return <LinearProgress />
  const handleSave = () => {
    const newRecord = props.record
    newRecord.content = content
    update('ticketattribute', props.record.id, newRecord)
  }
  return (
    <>
      <Form initialValues={props.record} onSubmit={() => {}}>
        {(formProps) => (
          <form style={{ width: '100%' }} onSubmit={formProps.handleSubmit}>
            <JsonInput source="content" onChange={(v) => setContent(v)} />
          </form>
        )}
      </Form>
      <Button
        style={{ marginTop: Object.keys(content).length > 0 ? 0 : 25 }}
        onClick={handleSave}
        variant="contained"
        label="Save content"
      >
        <Save />
      </Button>
    </>
  )
}

const ServiceOfferingTemplateEditForm = (props) => {
  useDefineAppLocation(
    props.location.state === undefined
      ? 'catalog.serviceofferingtemplate.edit'
      : `${props.location.state.before}.serviceofferingtemplate.edit`
  )

  const attributeColumns = useSelectedColumns({
    preferences: 'template.attribute.columns',
    columns: AttributeColumns
  })

  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <SimpleForm
      warnWhenUnsavedChanges
      record={props.record}
      {...props}
      toolbar={<SaveDeleteToolbar />}
    >
      <Grid container spacing={4} style={{ width: '100%' }} {...props}>
        <Grid item xs={12}>
          <ReferenceField source="service_base" reference="servicebase" link={true}>
              <FunctionField render={(record) => <>ServiceBase {record.ident}</>} />
          </ReferenceField>
          <TextInput source="ident" fullWidth />
          <SelectInput
            source="content.order_form_template"
            label="Order form workflow"
            choices={config.order_form_template}
            validate={required()}
            fullWidth
          />
          <RichTextInput source="technical_note" fullWidth />
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5">Service setup Attributes</Typography>
          </Grid>
          <Grid item xs={12}>
            <ReferenceManyField
              sort={{ field: 'sort_order', order: 'ASC' }}
              reference="servicesetupattribute"
              target="service_offering_template"
            >
              <DatagridWithDialog
                defaultValue={{
                  attribute_type: 'service_setup',
                  service_offering_template: props.record.id
                }}
                form={<AttributeForm />}
              >
                {attributeColumns}
              </DatagridWithDialog>
            </ReferenceManyField>
          </Grid>
        </Grid>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <Typography variant="h5">Ticket Attributes</Typography>
          </Grid>
          <Grid item xs={12}>
            <ReferenceManyField
              sort={{ field: 'sort_order', order: 'ASC' }}
              reference="ticketattribute"
              target="service_offering_template"
            >
              <DatagridWithDialog
                defaultValue={{
                  attribute_type: 'ticket',
                  service_offering_template: props.record.id
                }}
                form={<AttributeForm />}
              >
                {attributeColumns}
              </DatagridWithDialog>
            </ReferenceManyField>
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

const ServiceOfferingTemplateEdit = (props) => {
  const translate = useTranslate()
  return (
  <Edit title={`${translate('resources.serviceofferingtemplate.title')} #${props.id}`} actions={<EditActions />} {...props}>
    <WithProps>
      {({ record, ...formProps }) => (
        <ServiceOfferingTemplateEditForm
          record={record}
          location={props.location}
          {...formProps}
        />
      )}
    </WithProps>
  </Edit>
  )
}

ServiceOfferingTemplateEdit.propTypes = {
  location: PropTypes.object,
  id: PropTypes.number
}

ServiceOfferingTemplateEditForm.propTypes = {
  location: PropTypes.object,
  record: PropTypes.object,
  id: PropTypes.number.isRequired
}

TAContentEditForm.propTypes = {
  record: PropTypes.object.isRequired
}

export default ServiceOfferingTemplateEdit
