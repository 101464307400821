import * as React from 'react'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import {
  Edit,
  SimpleForm,
  TextInput,
  required,
  ReferenceField,
  FunctionField,
  ReferenceInput,
  AutocompleteInput,
  ReferenceManyField,
  TextField,
  BooleanField,
  BooleanInput,
  useTranslate
} from 'react-admin'
import { EditActions } from '../../Components'

import { Grid, Typography, Table, TableBody, TableRow, TableCell } from '@material-ui/core'

import PropTypes from 'prop-types'
import SellPriceItemsEdit from '../SellPriceItem/Components/SellPriceItemsEdit'
import BuyPriceItemsEdit from './Components/BuyPriceItemsEdit'
import CapacityPoolTemplateList from '../CapacityPool/Components/CapacityPoolTemplateList'
import CloneButton from './Components/CloneButton'
import { DatagridWithDialog } from '../../Components/Dialogs'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import HoursInput from '../../Components/Inputs/HoursInput'

const CapacityPoolTemplateField = (props) => {
  return (
    <>
      <Typography variant="h5">Capacity pools templates</Typography>
      <CapacityPoolTemplateList
        columnsPref="capacitypooltemplate.config.columns"
        target="service_offering_config"
        initialValues={{ service_offering_config: props.record.id }}
      />
    </>
  )
}

const ServiceSupplierForm = props => {
  return (
    <>
      {props.record !== undefined
        ? <><TextField record={props.record} variant="body1" source="supplier_detail.name"/><br/><br/></>
        : <ReferenceInput label="Supplier" source="supplier" reference="supplier">
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
      }
      <BooleanInput source="display_on_voucher"/>
      <BooleanInput initialValue={props.record ? props.record.send_booking_order : true} source="send_booking_order"/>
    </>
  )
}

const ServiceOfferingConfigEditForm = (props) => {
  return (
    <SimpleForm
      record={props.record}
      toolbar={<SaveDeleteToolbar/>}
      {...props}
    >

      <Grid container spacing={4} style={{ width: '100%' }}>
        <Grid item xs={12} sm={12} md={6}>
          <ReferenceField source="service_base" reference="servicebase" link={true}>
              <FunctionField render={(record) => <>ServiceBase {record.ident}</>} />
          </ReferenceField>
          <TextInput source="ident" validate={required()} fullWidth/>
          <Grid container>
            <Grid item xs={6}>
              <HoursInput label='Minimal lead time' source='min_lead_time'/>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <Typography variant="h5">Service suppliers</Typography>
          <ReferenceManyField reference="servicesupplier" target="service_offering_config">
            <DatagridWithDialog
              form={<ServiceSupplierForm/>}
              defaultValue={{
                service_offering_config: props.record.id
              }}
            >
              <TextField label="Name" source="supplier_detail.name"/>
              <BooleanField source="display_on_voucher"/>
              <BooleanField source="send_booking_order"/>
            </DatagridWithDialog>
          </ReferenceManyField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Sell price items</Typography>
          <SellPriceItemsEdit record={props.record} />
        </Grid>
        <Grid item xs={12}>
          <Table style={{ width: 'auto' }} size="small" align="right">
            <TableBody>
              <TableRow>
                <TableCell>
                  Main price:
                </TableCell>
                <TableCell>
                  {props.record.main_price
                    ? <> {props.record.main_price} {props.record.main_price_currency}</>
                    : <> &ndash;</>
                  }
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  Main price quantifier:
                </TableCell>
                <TableCell>
                  {props.record.main_price_quantifier
                    ? <> {props.record.main_price_quantifier}</>
                    : <> &ndash;</>
                  }
                </TableCell>
              </TableRow>
              {props.record.original_main_price
                ? <TableRow>
                    <TableCell>
                      Original main price:
                    </TableCell>
                    <TableCell>
                      {props.record.original_main_price} {props.record.main_price_currency}
                    </TableCell>
                  </TableRow>
                : <></>
              }
              {props.record.main_price_discount_text
                ? <TableRow>
                    <TableCell>
                      Main price discount text:
                    </TableCell>
                    <TableCell>
                      {props.record.main_price_discount_text}
                    </TableCell>
                  </TableRow>
                : <></>
              }
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5">Buy price items</Typography>
          <BuyPriceItemsEdit record={props.record} />
        </Grid>
        <Grid item xs={12}>
          <CapacityPoolTemplateField record={props.record} />
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

const ServiceOfferingConfigEdit = (props) => {
  useDefineAppLocation('catalog.serviceofferingconfig.edit')
  const translate = useTranslate()
  return (
    <Edit title={`${translate('resources.serviceofferingconfig.title')} #${props.id}`} mutationMode="pessimistic" actions={<EditActions more={<CloneButton {...props}/>} />} {...props}>
      <ServiceOfferingConfigEditForm record={props.record} {...props} />
    </Edit>
  )
}
ServiceOfferingConfigEdit.propTypes = {
  record: PropTypes.object,
  id: PropTypes.number
}

ServiceOfferingConfigEditForm.propTypes = {
  record: PropTypes.object
}

CapacityPoolTemplateField.propTypes = {
  serviceBase: PropTypes.number,
  record: PropTypes.object
}

ServiceSupplierForm.propTypes = {
  record: PropTypes.object
}

export default ServiceOfferingConfigEdit
