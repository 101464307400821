import * as React from 'react'
import { useSelector } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import classnames from 'classnames'

import { SubMenu } from './Components'

import { PropTypes } from 'prop-types'

import { Build, Payment, Gavel, EventNote, Settings, FormatPaint, CalendarToday, ListAlt, RoomService, Receipt, ShoppingBasket, AccountBalanceWallet, ShoppingCart, Bookmark, Person, Face, Web, AccountTree, Photo, FileCopy, CallMissedOutgoing, Message, LocalShipping, LocationOn, List, Translate, TrendingUp, PermContactCalendar } from '@material-ui/icons'

// import { MainAccountingMenu } from './Accounting/Menu'
// import { AdvancedServicesMenu, MainServicesMenu } from './Services/Menu'
// import { AdvancedOrdersMenu, MainOrdersMenu } from './Orders/Menu'
// import { AdvancedCustomersMenu, MainCustomersMenu } from './Customers/Menu'
// import { AdvancedCmsMenu, MainCmsMenu } from './Cms/Menu'
// import { MainSuppliersMenu } from './Suppliers/Menu'
// import { MainSettingsMenu } from './ChannelManagement/Menu'
// import { MainGeolocationMenu } from './Geolocation/Menu'
// import { MainContactMenu } from './Contacts/Menu'

import { MenuItemLink, useTranslate } from 'react-admin'

const ServicesMenuIcon = () => (<RoomService/>)
// const OrdersMenuIcon = () => (<Receipt/>)
// const CustomersMenuIcon = () => (<AccountCircle/>)
// const CmsMenuIcon = () => (<ChromeReaderMode/>)
// const SettingsMenuIcon = () => (<Settings/>)

const MenuUI = ({ dense = true }) => {
  const open = useSelector((state) => state.admin.ui.sidebarOpen)
  const classes = useStyles()
  const translate = useTranslate()

  return (
    <div
      className={classnames(classes.root, {
        [classes.open]: open,
        [classes.closed]: !open
      })}
      id="AdminMenuRoot"
    >
        <MenuItemLink to='/contact' primaryText={translate('menu.navigation.contacts')} leftIcon={<PermContactCalendar />} dense={dense}/>
        <MenuItemLink to='/customer' primaryText={translate('menu.navigation.customers')} leftIcon={<Face />}dense={dense}/>
        <MenuItemLink to='/supplier' primaryText={translate('menu.navigation.suppliers')} leftIcon={<LocalShipping />} dense={dense}/>
        <MenuItemLink to='/suppliermessage' primaryText={translate('menu.navigation.suppliermessages')} leftIcon={<Message />} dense={dense}/>

        <MenuItemLink to='/order' primaryText={translate('menu.navigation.orders')} leftIcon={<Receipt />} dense={dense}/>
        <MenuItemLink to='/payment' primaryText={translate('menu.navigation.payments')} leftIcon={<Payment />} dense={dense}/>
        <MenuItemLink to='/accountingdocument' primaryText={translate('menu.navigation.accountingdocuments')} leftIcon={<Gavel />} dense={dense}/>

        <MenuItemLink to='/publication' primaryText={translate('menu.navigation.publications')} leftIcon={<Web />} dense={dense}/>

        <MenuItemLink to='/servicebase' primaryText={translate('menu.navigation.services')} leftIcon={<ServicesMenuIcon />} dense={dense}/>
        <MenuItemLink to='/serviceoffering' primaryText={translate('menu.navigation.serviceofferings')} leftIcon={<EventNote />} dense={dense}/>
        <MenuItemLink to='/servicerealization' primaryText={translate('menu.navigation.servicerealizations')} leftIcon={<ListAlt />} dense={dense}/>
        <MenuItemLink to='/currencyexchange' primaryText={translate('menu.navigation.currencyexchange')} leftIcon={<TrendingUp />} dense={dense}/>

        <MenuItemLink to='/images' primaryText={translate('menu.navigation.images')} leftIcon={<Photo />} dense={dense}/>
        <MenuItemLink to='/documents' primaryText={translate('menu.navigation.documents')} leftIcon={<FileCopy />} dense={dense}/>
        <MenuItemLink to='/channeltext' primaryText={translate('menu.navigation.translations')} leftIcon={<Translate />} dense={dense}/>

        <SubMenu
          name="menu.navigation.advanced"
          icon={<Build />}
          dense={dense}
        >
          <>
              <MenuItemLink to='/channel' primaryText={translate('menu.navigation.channels')} leftIcon={<List />}/>
              <MenuItemLink to='/redirect' primaryText={translate('menu.navigation.redirects')} leftIcon={<CallMissedOutgoing />}/>
              <MenuItemLink to='/geolocation' primaryText={translate('menu.navigation.geolocations')} leftIcon={<LocationOn />}/>

              <MenuItemLink to='/menuitem' primaryText={translate('menu.navigation.menuitems')} leftIcon={<AccountTree />}/>
              <MenuItemLink to='/menutree' primaryText={translate('menu.navigation.menutree')} leftIcon={<AccountTree />}/>

              <MenuItemLink to='/expenditure' primaryText={translate('menu.navigation.expenditures')} leftIcon={<AccountBalanceWallet />} />
              <MenuItemLink to='/serviceofferingconfig' primaryText={translate('menu.navigation.serviceofferingconfig')} leftIcon={<Settings />} />
              <MenuItemLink to='/serviceofferingtemplate' primaryText={translate('menu.navigation.serviceofferingtemplates')} leftIcon={<FormatPaint />} />

              <MenuItemLink to='/offeringcalendar' primaryText={translate('menu.navigation.offeringcalendar')} leftIcon={<CalendarToday />} />
              <MenuItemLink to='/realizationcalendar' primaryText={translate('menu.navigation.realizationcalendar')} leftIcon={<CalendarToday />} />

              <MenuItemLink to='/basket' primaryText={translate('menu.navigation.baskets')} leftIcon={<ShoppingBasket />} />
              <MenuItemLink to='/orderitem' primaryText={translate('menu.navigation.orderitems')} leftIcon={<ShoppingCart />} />
              <MenuItemLink to='/booking' primaryText={translate('menu.navigation.bookings')} leftIcon={<Bookmark />} />
              <MenuItemLink to='/channeluser' primaryText={translate('menu.navigation.channelusers')} leftIcon={<Person />} />
          </>
        </SubMenu>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  open: {
    width: 220
  },
  closed: {
    width: 55
  }
}))

MenuUI.propTypes = {
  dense: PropTypes.bool
}

export default MenuUI
