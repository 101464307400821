import * as React from 'react'
import { ENTITY_TITLE } from './Components'

const Logo = () => {
  return (
    <a href='/#/' style={{ color: 'inherit', textDecoration: 'none', display: 'flex' }}>

      <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width="20.470000pt" height="20.470000pt" viewBox="0 0 84.000000 84.000000"
      preserveAspectRatio="xMidYMid meet" style={{ marginRight: 10 }}>

        <g transform="translate(0.000000,90.000000) scale(0.100000,-0.100000)" fill="#FFFFFF" stroke="none">
          <path d="M231 794 c-16 -12 -18 -20 -11 -38 5 -15 5 -27 -2 -34 -11 -11 -2
          -25 70 -122 53 -70 101 -120 117 -120 7 0 21 21 30 48 9 26 32 86 51 134 19
          48 34 94 34 102 0 26 -26 33 -147 39 -96 4 -126 2 -142 -9z m233 -66 c-3 -13
          -13 -38 -20 -57 -8 -18 -14 -46 -14 -61 0 -15 -9 -35 -19 -44 -19 -17 -20 -17
          -34 6 -8 12 -31 49 -51 82 -21 32 -35 64 -32 72 3 9 29 14 78 17 40 1 79 4 86
          5 9 1 11 -5 6 -20z"/>
          <path d="M53 419 c-7 -7 -13 -19 -13 -26 0 -26 42 -35 155 -32 63 2 116 1 119
          -2 3 -3 -14 -68 -39 -144 -44 -133 -52 -187 -21 -154 14 15 63 138 94 238 22
          72 17 115 -14 117 -10 1 -75 4 -144 8 -96 6 -128 5 -137 -5z"/>
          <path d="M463 410 c-13 -5 -23 -15 -23 -21 0 -7 22 -48 48 -93 26 -45 57 -101
          67 -126 22 -52 34 -70 46 -70 5 0 9 6 9 14 0 14 -57 144 -86 199 -8 15 -13 31
          -10 36 3 4 49 11 103 14 111 6 135 13 131 36 -3 14 -22 16 -133 18 -72 1 -140
          -2 -152 -7z"/>
          </g>
      </svg>
      {ENTITY_TITLE}
    </a>
  )
}

export default Logo
