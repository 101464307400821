import * as React from 'react'
import { Tooltip } from '@material-ui/core'
import {
  AccessTimeTwoTone,
  CheckCircleTwoTone,
  CancelTwoTone,
  HelpTwoTone,
  ChatBubble
} from '@material-ui/icons'
import { useGetList, useVersion } from 'ra-core'
import LocalDate from '../../Components/LocalDate'

const SupplierStatusField = props => {
  const version = useVersion()
  if (props.record === undefined || props.record.item_object === undefined) return <></>
  const { data, ids } = useGetList(
    'suppliermessage',
    { page: 1, perPage: 100 },
    { field: 'id', order: 'ASC' },
    { service_setup: props.record.item_object.id },
    { version: version }
  )
  if (ids.length === 0) {
    return (
    <Tooltip arrow title="No supplier contacted">
        <HelpTwoTone style={{ color: 'grey' }} />
    </Tooltip>
    )
  }
  const chips = ids.map((id, i) => {
    if (data[id] === undefined) return null
    let title = 'Status unknown'
    let child = <HelpTwoTone style={{ color: 'grey' }}/>

    // don't show canceled
    if (data[id].status === 'canceled') return null

    title = data[id].supplier_name + '</br>'
    title = title + data[id].status_display + ' ' + data[id].message_type_display + '</br>'
    switch (data[id].status) {
      case 'pending':
        title = title + LocalDate(data[id].sent_at, true) + '<br/>'
        child = <AccessTimeTwoTone style={{ color: 'GoldenRod' }} />
        break
      case 'confirmed':
        title = title + LocalDate(data[id].response_at, true) + '<br/>' + data[id].response_text
        child = <span>
        <CheckCircleTwoTone style={{ color: 'Green' }} />
        {data[id].response_text ? <ChatBubble style={{ color: 'Gold', position: 'relative', left: '-0.75rem', top: '.4rem', fontSize: '1rem' }} /> : null}
        </span>
        break
      case 'refused':
        title = title + LocalDate(data[id].response_at, true) + '<br/>' + data[id].response_text
        child = <span>
        <CancelTwoTone style={{ color: 'Red' }} />
        {data[id].response_text ? <ChatBubble style={{ color: 'Gold', position: 'relative', left: '-0.75rem', top: '.4rem', fontSize: '1rem' }} /> : null}
        </span>
        break
    }

    return <Tooltip key={i} arrow title={<div dangerouslySetInnerHTML={{ __html: title }}/>}>
        {child}
      </Tooltip>
  })
  return chips
}

export default SupplierStatusField
