import * as React from 'react'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { BulkDeleteButton, Datagrid, DeleteButton, EditButton, List, ReferenceField, TextField, useTranslate } from 'react-admin'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'

const ServiceOfferingConfigBulkActions = props => (
  <>
    <BulkDeleteButton />
  </>
)

const ServiceOfferingConfigList = props => {
  useDefineAppLocation('catalog.serviceofferingconfig.list')
  const translate = useTranslate()
  return (
  <>
    <List
      title={translate('resources.serviceofferingconfig.title')}
      bulkActionButtons={<ServiceOfferingConfigBulkActions />}
      empty={false}
      perPage={25}
      {...props}
    >
      <Datagrid optimized rowClick='edit'>
        <ReferenceField source="service_base" reference="servicebase" link={false}>
          <TextField source="ident"/>
        </ReferenceField>
        <TextField source="ident"/>
        <ListActionToolbar>
          <EditButton/>
          <DeleteButton/>
        </ListActionToolbar>
      </Datagrid>
    </List>
  </>
  )
}

export default ServiceOfferingConfigList
