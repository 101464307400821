import CustomerList from './List'
import CustomerEdit from './Edit'
import CustomerCreate from './Create'
import FaceIcon from '@material-ui/icons/Face'
export default {
  list: CustomerList,
  edit: CustomerEdit,
  create: CustomerCreate,
  icon: FaceIcon
}
