
import PropTypes from 'prop-types'

import React, { useState } from 'react'
import {
  Button,
  fetchStart,
  fetchEnd,
  useRefresh,
  useNotify
} from 'react-admin'
import moment from 'moment-timezone'
import MomentUtils from '@date-io/moment'
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import { API_URL, httpClient } from '../../../dataProvider'
import { useDispatch } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Switch from '@material-ui/core/Switch'
import MenuItem from '@material-ui/core/MenuItem'
import Chip from '@material-ui/core/Chip'
import IconCancel from '@material-ui/icons/Cancel'
import IconClone from '@material-ui/icons/DoneAll'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const days = [
  'Monday', 'Tuesday',
  'Wednesday', 'Thursday',
  'Friday', 'Saturday',
  'Sunday'
]

const daysToWeekDay = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6
}

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '90%'
  },
  chip: {
    margin: 2
  },
  noLabel: {
    marginTop: theme.spacing(3)
  }
}))

const BulkDialog = props => {
  const { open, handleClose, selectedIds, resource } = props
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const [singleDayClone, setSingleDayClone] = useState(false)
  const [singleDayDate, setsingleDayDate] = useState(null)
  const [ident, setIdent] = useState(null)
  const [daySelection, setDateSelection] = useState(days)
  const classes = useStyles()
  const dispatch = useDispatch()
  const notify = useNotify()
  const refresh = useRefresh()
  const hooks = { dispatch: dispatch, notify: notify }

  const cloneData = () => {
    hooks.dispatch(fetchStart())
    const data = {
      ids: selectedIds,
      ident: ident,
      singleDayClone: singleDayClone,
      singleDayDate: singleDayDate,
      startDate: startDate,
      endDate: endDate,
      daySelection: daySelection.map(d => daysToWeekDay[d])
    }
    httpClient(API_URL + '/' + resource + '/bulkclone', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status !== 200) hooks.notify('Error with the API (' + response.status + ')', 'error')
        else {
          refresh()
          hooks.notify('Clone of ' + resource + ' finished')
        }
      })
      .catch((e) => {
        hooks.notify('Error: can\'t clone', 'error')
      })
      .finally(() => {
        hooks.dispatch(fetchEnd()) // stop the global loading indicator
        handleClose()
      })
  }
  return (
    <Dialog
        open={open}
        onClose={handleClose}
        aria-label="Clone service offering"
      >
        <DialogTitle>Clone Service Offering</DialogTitle>
        <DialogContent>
          { (selectedIds.length > 1) ? <p>WARNING clonning from more than one item (currently selected {selectedIds.length} items)<br />Use this only for different times in one day!</p> : ''}
          <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
            <TextField label="New ident" variant="filled" onChange={e => setIdent(e.target.value)}/> <br/><br/>
            <FormControlLabel control={<Switch defaultChecked={singleDayClone} />} label="Clone for a single day" onChange={(e => setSingleDayClone(e.target.checked))}/><br/><br/>
            {singleDayClone
              ? <>
              <DatePicker value={singleDayDate} variant="static" label="Target Date" onChange={v => setsingleDayDate(v._d.toISOString().slice(0, 10))}/>
              </>
              : <>
            <KeyboardDatePicker value={startDate} autoOk format="DD.MM.yyyy" placeholder="dd.mm.yyyy" label="From" onChange={(v) => setStartDate(v._d.toISOString().slice(0, 10))} />
            <br/>
            <br/>
            <KeyboardDatePicker value={endDate} autoOk format="DD.MM.yyyy" placeholder="dd.mm.yyyy" label="To" onChange={(v) => setEndDate(v._d.toISOString().slice(0, 10))} />
            <br/>
              <p>Enabled week days within the period selected</p>
              <Select
                labelId="day-mutiple-chip-label"
                id="day-mutiple-chip"
                multiple
                value={daySelection}
                onChange={days => setDateSelection(days.target.value)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) =>
                  <div className={classes.chips}>
                    {selected.map(value => (
                      <Chip fullWidth key={value} label={value} className={classes.chip} />)
                    )}
                  </div>
                }
              >
                {days.map((day) => (
                  <MenuItem key={day} value={day}>
                    {day}
                  </MenuItem>
                ))}

              </Select>
            </>}
          </MuiPickersUtilsProvider>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleClose}
          >
            <IconCancel />
          </Button>
          <Button
            label="ra.action.clone"
            onClick={() => cloneData()}
          >
            <IconClone />
          </Button>
        </DialogActions>
      </Dialog>
  )
}

BulkDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedIds: PropTypes.array,
  resource: PropTypes.string
}

export default BulkDialog
