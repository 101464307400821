import * as React from 'react'
import {
  LinearProgress,
  required,
  TextInput,
  useGetList,
  useTranslate,
  useVersion
} from 'react-admin'

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  Grid,
  makeStyles,
  Popover,
  TextField,
  Typography
} from '@material-ui/core'
import * as icons from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useField, useForm } from 'react-final-form'
import ContentInput from './ContentInput'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
  },
  accordion: {
    backgroundColor: theme.palette.action.focus
  },
  accordionError: {
    backgroundColor: theme.palette.action.focus,
    borderBottom: '2px solid red'
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    flexBasis: '20%',
    flexShrink: 0
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '20%',
    flexShrink: 0
  },
  detail: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
    flexBasis: '60%',
    flexShrink: 0
  },
  snippetDiv: {
    paddingLeft: 20,
    borderLeft: '2px solid lightgrey',
    transition: '0,3s',
    '&:hover': {
      borderLeft: '2px solid grey'
    }
  },
  addDiv: {
    cursor: 'pointer',
    height: '10px',
    transition: '0.3s',
    fontSize: '0px',
    display: 'flex',
    alignItems: 'center',
    '&:hover, &:focus, &:active': {
      '& span': {
        fontSize: '15px'
      },
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: '25px',
      position: 'relative',
      height: '30px'
    }
  },
  addDivActive: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    '& span': {
      fontSize: '15px'
    },
    paddingTop: 5,
    paddingBottom: 5,
    fontSize: '25px',
    position: 'relative',
    height: '30px'
  },
  span: {
    fontSize: '0px',
    transition: '0.3s'
  },
  snippetChoiceGrid: {
    margin: 'auto',
    padding: 10
  },
  snippetChoiceContent: {
    cursor: 'pointer',
    textAlign: 'center',
    border: '2px solid lightgrey',
    padding: 5,
    borderRadius: 10,
    '&:focus': {
      backgroundColor: 'lightgrey'
    }
  },
  snippetChoiceTypo: {
    paddingTop: 5,
    wordWrap: 'break-word'
  }
}))

const PublicationSnippetEdit = props => {
  const content = props.record.content
  if (content.snippets) {
    return (
      <div id="snippets_div">
        {/* <Typography variant="h6">Snippets :</Typography> */}
        {content.snippets.length === 0 ? <AddSnippetDiv {...props} placeValue={0} source={'content.snippets[0]'} label={'Add a new snippet'} big={true}/> : null }
        <SnippetContentEdit first data={content} {...props}/>
      </div>
    )
  } else {
    return (
      <div id="snippets_div">
        <AddSnippetDiv {...props} placeValue={0} source={'content.snippets[0]'} label={'Add a new snippet'} big={true}/>
      </div>
    )
  }
}

const SnippetContentEdit = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const content = props.data
  const placeValueRegex = /(\d+)(?!.*\d)/
  if (content === undefined || content === null) { return null }
  let source
  props.source === undefined ? source = 'content' : source = props.source
  const contentEditForm = []
  let itemCounter = 0
  for (const key of Object.keys(content)) {
    if (Array.isArray(content[key])) {
      if (content[key][0] && content[key][0].content_type) {
        contentEditForm.push(
          <div className={props.first ? null : classes.snippetDiv}>
            <AddSnippetDiv {...props} placeValue={0} source={`${source}.snippets[0]`} key={`list-item-${itemCounter}`} label={translate('resources.publication.snippet.add.before', { _: 'Add a new snippet before %{type}', type: content[key][0].content_type })} />
          </div>
        )
        itemCounter++
      }
      for (const arKey of Object.keys(content[key])) {
        if (content[key][arKey] && content[key][arKey].content_type) {
          contentEditForm.push(<SnippetContentEdit source={`${source}.${key}[${arKey}]`} key={`list-item-${itemCounter}`} first={props.first} data={content[key][arKey]}/>)
          itemCounter++
        }
      }
    } else if (key === 'content_type') {
      const isContainerEmpty = content.content_type === 'container' && (content.context === null || content.context.snippets === undefined)
      contentEditForm.push(
        <div className={props.first ? null : classes.snippetDiv} key={`list-item-${itemCounter}`}>
          <SnippetAccordion {...props} />
          <SnippetContentEdit source={`${source}.context`} data={content.context} />
          {isContainerEmpty ? <div className={classes.snippetDiv}><AddSnippetDiv {...props} source={`${source}.context.snippets[0]`} placeValue={0} label={translate('resources.publication.snippet.add.container', { _: 'Add a new snippet inside the container' })} /></div> : null}
          <AddSnippetDiv {...props} placeValue={props.source === undefined ? null : parseInt(placeValueRegex.exec(props.source)[0]) + 1} label={translate('resources.publication.snippet.add.after', { _: 'Add a new snippet after %{type}', type: content.ident })} />
        </div>
      )
      itemCounter++
    }
  }
  return contentEditForm
}

const UseIcons = ({ iconSearch }) => {
  const ResultIcon = icons[iconSearch]
  if (ResultIcon === undefined) return null
  return (<ResultIcon style={{ verticalAlign: 'middle' }} color="primary" />)
}

const getChannelSnippet = () => {
  const version = useVersion()
  const { data, ids, loading, error } = useGetList(
    'channelsnippet',
    { page: 1, perPage: 100000 },
    { field: 'ident', order: 'ASC' },
    { version: version }
  )
  return { data, ids, loading, error }
}

const AddSnippetDiv = props => {
  const classes = useStyles()
  const form = useForm()
  const myRegex = /\[[0-9]\](?!.*\[[0-9]\])(?=)(.*$)/
  const biggerSource = props.source === undefined ? null : props.source.replace(myRegex, '')

  const {
    input: { onChange }
  } = useField(biggerSource)

  const { data, ids, loading, error } = getChannelSnippet()

  const [anchorEl, setAnchorEl] = React.useState(null)
  const [filterSnippet, setFilterSnippet] = React.useState('')

  if (loading) return (<div className={classes.addDiv}></div>)
  if (error) return ('ERROR')

  const getSnippetsList = get(form.getState().values, biggerSource, '')
  let snippetsList
  Array.isArray(getSnippetsList) ? snippetsList = getSnippetsList : snippetsList = []

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  const open = Boolean(anchorEl)

  const handleFilterChange = (event) => {
    setFilterSnippet(event.target.value)
  }

  const setSnippetChoice = value => {
    handleClose()
    const dataToMove = []
    for (let index = 0; index < snippetsList.length; index++) {
      if (index < props.placeValue) {
        dataToMove[index] = snippetsList[index]
      } else {
        dataToMove[index + 1] = snippetsList[index]
      }
    }
    dataToMove[props.placeValue] = { ident: value.ident, content_type: value.ident, context: null }
    onChange(dataToMove)
  }

  const handleSnippetChoice = value => () => {
    setSnippetChoice(value)
  }

  const handleSnippetEnter = value => e => {
    const enterOrSpace =
      e.key === 'Enter' ||
      e.key === ' ' ||
      e.key === 'Spacebar' ||
      e.which === 13 ||
      e.which === 32
    if (enterOrSpace) {
      e.preventDefault()
      setSnippetChoice(value)
    }
  }

  return (
    <>
    <div id="addsnippet_div" className={open || props.big ? classes.addDivActive : classes.addDiv} onClick={handleClick}>
      <AddIcon color="primary" style={{ fontSize: 'inherit' }}/>
      <span className={classes.span}>{props.label}</span>
    </div>
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      elevation={12}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      PaperProps={{
        style: {
          maxHeight: '50%',
          width: '30%'
        }
      }}
    >
      <Grid container spacing={2} className={classes.snippetChoiceGrid}>
        <Grid item xs={12}>
          <TextField autoFocus={true} value={filterSnippet} onChange={handleFilterChange} label="Snippet type" variant="filled" fullWidth />
        </Grid>
        {ids.filter(id => filterSnippet === '' ? true : String(data[id].ident).includes(filterSnippet.replace(/ /g, '_'))).map(id => (
          <Grid key={id} item xs={4} >
            <div tabIndex={0} onKeyPress={handleSnippetEnter(data[id])} onClick={handleSnippetChoice(data[id])} className={classes.snippetChoiceContent}>
              <UseIcons iconSearch={data[id].admin_icon} />
              <Typography className={classes.snippetChoiceTypo} >{String(data[id].ident).replace(/_/g, ' ')}</Typography>
            </div>
          </Grid>
        ))}
      </Grid>
    </Popover>
    </>
  )
}

const snippetHasError = (source) => {
  const form = useForm()
  const errors = get(form.getState().errors, source, null)
  if (errors !== null) {
    for (const key of Object.keys(errors)) {
      if (key !== 'snippets' && key !== 'context') {
        return true
      }
    }
    for (const key of Object.keys(errors.context)) {
      if (key !== 'snippets') {
        return true
      }
    }
  }
  return false
}

const SnippetAccordion = props => {
  const classes = useStyles()
  const form = useForm()
  const translate = useTranslate()
  const hasError = snippetHasError(props.source)
  const [expanded, setExpanded] = React.useState(false)
  const myRegex = /\[[0-9]\](?!.*\[[0-9]\])(?=)(.*$)/
  const biggerSource = props.source.replace(myRegex, '')
  const {
    input: { onChange }
  } = useField(biggerSource)
  const handleRemove = () => {
    const snippetNumberRegex = /(\d+)(?!.*\d)/
    const snippetNumberMatch = snippetNumberRegex.exec(props.source)
    const dataAfterDelete = []
    const snippetsList = get(form.getState().values, biggerSource, [])
    for (let index = 0; index < snippetsList.length - 1; index++) {
      if (index < snippetNumberMatch[0]) {
        dataAfterDelete[index] = snippetsList[index]
      } else {
        dataAfterDelete[index] = snippetsList[index + 1]
      }
    }
    setExpanded(false)
    onChange(dataAfterDelete)
  }
  const context = () => {
    const getContext = get(props.data, 'context', '')
    let contextString = JSON.stringify(getContext)
    if (contextString.length > 120) {
      contextString = contextString.substring(0, 120) + '...'
    }
    return contextString
  }
  const handleChange = (event) => {
    setExpanded(!expanded)
  }
  const title = get(props.data, 'ident', 'No title')
  const contentType = get(props.data, 'content_type', '')
  return (
    <div className={classes.root} >
      <Accordion id="snippetaccordion" expanded={expanded} onChange={handleChange} defaultExpanded={false} className={hasError ? classes.accordionError : classes.accordion} TransitionProps={{ unmountOnExit: false }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.heading}>{title}</Typography>
          <Typography className={classes.secondaryHeading}>Type: {contentType.replace(/_/g, ' ')}</Typography>
          <Typography className={classes.detail}>{context()}</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ display: 'block' }} >
          <Grid container>
            <Grid item xs={8}>
              <TextInput source={`${props.source}.ident`} label="ident" validate={required()}/>
            </Grid>
            <Grid item xs={4}>
              <Button
                onClick={handleRemove}
                startIcon={<RemoveCircleOutlineIcon />}
                id="removesnippet_button"
                color="secondary"
              >
                {translate('resources.publication.snippet.remove', { _: 'Remove snippet' })}
              </Button>
            </Grid>
          </Grid>
          <ChannelSnippetForm {...props} />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

const ChannelSnippetForm = props => {
  const { data, ids, loading, error } = getChannelSnippet()
  if (loading) return (<LinearProgress />)
  if (error) return ('ERROR')
  let snippet
  ids.forEach(id => {
    if (data[id].ident === props.data.content_type) {
      snippet = data[id]
    }
  })
  if (snippet === undefined) {
    return <p>no channel snippet</p>
  }
  const returnForm = [] // push to this the react-admin fields depending on field_type
  let itemCounter = 0
  if (snippet.config.fields !== undefined) {
    snippet.config.fields.forEach(field => {
      const display = get(props.data.context, 'display', '')
      // TODO improve key
      returnForm.push(<ContentInput field={field} source={`${props.source}.context.${field.field_name}`} key={`${props.data.ident}-field-${itemCounter}`} label={field.field_name} fieldType={field.field_type} display={display}/>)
      itemCounter++
    })
  }
  return returnForm
}

PublicationSnippetEdit.propTypes = {
  label: PropTypes.string,
  data: PropTypes.object,
  record: PropTypes.object
}

PublicationSnippetEdit.defaultProps = {
  label: ''
}

ChannelSnippetForm.propTypes = {
  data: PropTypes.object
}

SnippetAccordion.propTypes = {
  data: PropTypes.object,
  source: PropTypes.string
}

AddSnippetDiv.propTypes = {
  source: PropTypes.string,
  placeValue: PropTypes.number,
  big: PropTypes.bool,
  label: PropTypes.string
}

UseIcons.propTypes = {
  iconSearch: PropTypes.string.isRequired
}

export default PublicationSnippetEdit
