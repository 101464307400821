import PropTypes from 'prop-types'

import React, { useState } from 'react'
import {
  Button
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/EditAttributes'
import BulkBatchEditDialog from './BulkBatchEditDialog'

function BulkBatchEditButton ({ resource, resourceToRedirect, selectedIds }) {
  resourceToRedirect = resourceToRedirect === undefined ? resource : resourceToRedirect
  const [showDialog, setShowDialog] = useState(false)

  const handleOpen = () => {
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  return (
    <>
      <Button onClick={handleOpen} label="Batch edit">
        <IconContentAdd />
      </Button>
      <BulkBatchEditDialog
        open={showDialog}
        handleClose={handleClose}
        resource={resource}
        resourceToRedirect={resourceToRedirect}
        selectedIds={selectedIds}
      />
    </>
  )
}

BulkBatchEditButton.propTypes = { resource: PropTypes.string, resourceToRedirect: PropTypes.string, selectedIds: PropTypes.arrayOf(PropTypes.string) }

export default BulkBatchEditButton
