import * as React from 'react'
import { Fragment } from 'react'
import { useMediaQuery } from '@material-ui/core'
import {
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  NumberField,
  List,
  Pagination,
  SearchInput,
  SimpleList,
  TextField,
  TextInput,
  useTranslate,
  BooleanField
} from 'react-admin' // eslint-disable-line import/no-unresolved
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActions from '../../Components/Toolbar/ListActions'
import { useSelectedColumns } from '@react-admin/ra-preferences'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'
import ShortTextField from '../../Components/ShortTextField'

const ServiceBaseFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput
      source="ident"
      defaultValue=""
    />
  </Filter>
)

const rowClick = (id, basePath, record) => {
  return 'edit'
}

const serviceBaseColumns = {
  ident: <FunctionField render={record => <ShortTextField text={record.ident}/>}/>,
  enabled: <BooleanField source="enabled" />,
  technical_name: <TextField source="technical_name" />,
  suppliers_name: <TextField source="suppliers_name" />,
  future_service_offerings: <NumberField source="future_offerings" />,
  total_service_offerings: <NumberField source="total_offerings" />,
  realization_strategy: <TextField source="realization_strategy" />,
  realization_start_selector: <TextField source="realization_start_selector" />

}

const ServiceBaseList = props => {
  const columns = useSelectedColumns({
    preferences: 'servicebase.list.columns',
    columns: serviceBaseColumns,
    omit: ['future_service_offerings', 'total_service_offerings', 'realization_start_selector']
  })
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('catalog.servicebase')
  const translate = useTranslate()
  return (
    <>
      <List
        {...props}
        title={translate('menu.navigation.services')}
        actions={<ListActions columnPreference="servicebase.list.columns" columns={serviceBaseColumns} />}
        bulkActionButtons={false}
        filters={<ServiceBaseFilter />}
        sort={{ field: 'ident', order: 'ASC' }}
        exporter={(data) => exporter(data, 'ServiceBase')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      >
        {isSmall
          ? (
          <SimpleList
            primaryText={record => record.ident}
            secondaryText={record => record.product_code}
          />
            )
          : (
          <Datagrid rowClick={rowClick} optimized>
            {columns}
            <ListActionToolbar>
              <EditButton />
            </ListActionToolbar>
          </Datagrid>
            )}
      </List>
    </>
  )
}

export default ServiceBaseList
