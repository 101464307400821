
import * as React from 'react'
import { Button, ReferenceField, TextField, TextInput, useCreate, useGetList } from 'react-admin'
import { PropTypes } from 'prop-types'
import { Typography, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { withStyles } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import AddIcon from '@material-ui/icons/Add'
import { DialogForm } from '../Dialogs'

const styles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    border: '1px solid #dadde9',
    padding: 10
  }
}

const getChannelTexts = (key, excludedChannel) => {
  if (key === undefined) return
  const { data, ids, loading, error } = useGetList(
    'channeltext',
    { page: 1, perPage: 100 },
    { field: 'id', order: 'ASC' },
    { key: key, excluded_channel: excludedChannel }
  )
  return { data, ids, loading, error }
}

const AddMissingChannelTextButton = props => {
  const { channelTextKey, onSuccess } = props
  const [create] = useCreate('channeltext/generate_missing')
  const handleClick = () => {
    create(
      {
        payload: {
          data: {
            key: channelTextKey
          }
        }
      },
      {
        onSuccess: ({ data }) => {
          onSuccess()
        }
      }
    )
  }
  return (
    <Button
      onClick={() => handleClick()}
      startIcon={<AddIcon/>}
      label="Generate missing ones"
    />
  )
}

const TooltipTitle = props => {
  const { fieldValue, editable, handleEditClick, excludedChannel } = props
  const { data, ids, loading, error } = getChannelTexts(fieldValue, excludedChannel)

  if (loading) return 'Loading'
  if (error) return 'Error'
  return (
    <>
      {ids.map(id => {
        return (
        <Typography key={id} variant="body1">
          {data[id].channel_ident}: {data[id].value}
          {editable
            ? <Button
                onClick={() => handleEditClick(data[id])}
                startIcon={<EditIcon/>}
              />
            : null
          }
        </Typography>
        )
      })}
      <AddMissingChannelTextButton channelTextKey={fieldValue} excludedChannel={excludedChannel}/>
    </>
  )
}

const ChannelTextTooltip = props => {
  const { fieldValue, placement = 'right-start', tooltipSource = <Typography variant='body2'><InfoIcon color="primary"/></Typography>, ...rest } = props
  if (fieldValue === undefined | fieldValue === null | fieldValue === '') return ''
  const [open, setOpen] = React.useState(false)
  const [formRecord, setFormRecord] = React.useState()
  const handleEditClick = (record) => {
    setFormRecord(record)
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <Tooltip {...rest}
        title={<TooltipTitle
          fieldValue={fieldValue}
          handleEditClick={handleEditClick}
          {...rest}
        />}
        placement={placement}
        interactive
      >
        {tooltipSource}
      </Tooltip>
      <DialogForm
        open={open}
        formRecord={formRecord}
        handleClose={handleClose}
        resource="channeltext"
        noRefresh
      >
        <>
        <ReferenceField source="channel" reference="channel" link={false}>
          <TextField variant="h5" source="ident"/>
        </ReferenceField>
        <br/>
        <br/>
        <TextInput source="value" multiline fullWidth/>
        </>
      </DialogForm>
    </>
  )
}

ChannelTextTooltip.propTypes = {
  fieldValue: PropTypes.string,
  placement: PropTypes.string,
  tooltipSource: PropTypes.object
}

TooltipTitle.propTypes = {
  fieldValue: PropTypes.string,
  editable: PropTypes.bool,
  handleEditClick: PropTypes.func,
  excludedChannel: PropTypes.number
}

AddMissingChannelTextButton.propTypes = {
  channelTextKey: PropTypes.string,
  onSuccess: PropTypes.func
}

export default withStyles(styles)(ChannelTextTooltip)
