import * as React from 'react'
import { NumberInput, TextInput, SelectInput, BooleanInput } from 'react-admin'
import getConfig from '../../../Components/core_config'
import { ChannelTextInput } from '../../../Components/Inputs'
import { PropTypes } from 'prop-types'
import ChoiceInput from './Inputs/ChoiceInput'
import { Grid } from '@material-ui/core'
import { JsonInput } from 'react-admin-json-view'

const AdditionalInputs = (props) => {
  const { record } = props
  return record !== undefined
    ? (
    <>
    {record.field_type === 'place_choices'
      ? <>
      <Grid xs={12}>
        <ChannelTextInput
          label="Place address"
          record={record}
          source="content.title_place_address"
          editable
        />
      </Grid>
      <Grid xs={12}>
        <ChannelTextInput
          label="Place name"
          record={record}
          source="content.title_place_name"
          editable
        />
      </Grid>
    </>
      : null}
    </>
      )
    : null
}

const AttributeForm = (props) => {
  const { record } = props
  const config = getConfig()
  const [configFields, setConfigFields] = React.useState()

  const handleChange = (event) => {
    const configElementsList = config.ticket_field_config[event.target.value]
    setConfigFields(configElementsList)
  }

  React.useEffect(() => {
    if (
      config !== 'Loading' &&
      configFields === undefined &&
      record !== undefined
    ) {
      setConfigFields(config.ticket_field_config[record.field_type])
    }
  })

  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <>
      <Grid container xs={12} spacing={2} style={{ width: '100%' }}>
        <Grid xs={6}>
          <TextInput source="ident" />
        </Grid>
        <Grid xs={6}>
          <SelectInput
            choices={config.ticket_field_types}
            source="field_type"
            onChange={(e) => handleChange(e)}
          />
        </Grid>
        <Grid xs={12}>
          <ChannelTextInput
            label="Title"
            record={record}
            source="content.title"
            editable
          />
        </Grid>
        <Grid xs={12}>
          <ChannelTextInput
            label="Subtitle"
            record={record}
            source="content.subtitle"
            editable
          />
        </Grid>
        <Grid xs={12}>
          <ChannelTextInput
            label="Description"
            record={record}
            source="content.description"
            editable
          />
        </Grid>
        <Grid xs={12}>
          <ChannelTextInput
            label="Secondary field title"
            record={record}
            source="content.secondary_field_title"
            helperText="Only for CPSPI_choices and SPICP_choices"
            editable
          />
        </Grid>
        <AdditionalInputs record={record}/>
        <Grid xs={6}>
          <BooleanInput source="required" />
        </Grid>
        <Grid xs={6}>
         <NumberInput source="sort_order" />
        </Grid>
        <Grid xs={12}>
        {configFields !== undefined &&
          configFields.map((value) => {
            switch (value.type) {
              case 'string':
                return (
                  <ChannelTextInput
                    record={record}
                    label={value.field_type}
                    key={value.field_type}
                    source={`config.${value.field_type}`}
                    editable
                  />
                )
              case 'list':
                return (
                  <ChoiceInput
                    name={`config.${value.field_type}`}
                    placeholder={value.field_type}
                    choices={
                      record === undefined ? [] : record.config[value.field_type]
                    }
                    record={record}
                  />
                )

              case 'bool':
                return (
                  <BooleanInput
                    label={value.field_type}
                    key={value.field_type}
                    defaultValue={false}
                    source={`config.${value.field_type}`}
                  />
                )

              default:
                return null
            }
          })}
        </Grid>
        <Grid sm={12}>
          <BooleanInput
            source="realization_only"
            helperText="Realization only will hide field from web order form"
           />
        </Grid>
        <Grid sm={6}>
          <SelectInput
            label="Web display state"
            choices={[
              { id: 'show', name: 'show' },
              { id: 'hidden', name: 'hidden' }
            ]}
            source="content.display_options.default"
            helperText="When order form is loaded"
          />
        </Grid>
        <Grid sm={6}>
          <JsonInput
              label="Web display conditions"
              source="content.display_options.condition" />
        </Grid>
      </Grid>
    </>
  )
}

AttributeForm.propTypes = {
  attributeType: PropTypes.string,
  initialValues: PropTypes.object,
  record: PropTypes.object
}

AdditionalInputs.propTypes = {
  record: PropTypes.object
}

export default AttributeForm
