import * as React from 'react'
import { PropTypes } from 'prop-types'
import { API_URL, httpClient } from '../../../dataProvider'
import { useDidMount } from '../../../Components/Utils'
import {
  Button,
  Datagrid,
  FunctionField,
  ListContextProvider,
  ReferenceField,
  TextField,
  useCreate,
  useGetMany
} from 'react-admin'
import lodashkeyBy from 'lodash/keyBy'
import ListActionToolbar from '../../../Components/Toolbar/ListActionToolbar'
import ActionDelete from '@material-ui/icons/Delete'
import AddIcon from '@material-ui/icons/Add'
import { TextField as MUITextField, Grid, Typography } from '@material-ui/core'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

const getDataTags = async (publicationChoices) => {
  const data = await httpClient(`${API_URL}/datatag?publications=${publicationChoices}&page=1&page_size=1000&ordering=ident`, { method: 'GET' })
    .then((response, data) => {
      return response.json.results
    })
  return data.reduce((res, itm) => {
    const result = res.find(item => item.ident === itm.ident)
    if (!result) {
      itm.ids = [itm.id]
      itm.publications = [itm.publication]
      return res.concat(itm)
    }
    result.ids = result.ids.concat(itm.id)
    result.publications = result.publications.concat(itm.publication)
    return res
  }, [])
}

const deleteDataTag = async (id) => {
  await httpClient(`${API_URL}/datatag/${id}`, { method: 'DELETE' })
}

const DeleteButton = props => {
  const handleClick = async () => {
    for (const id of props.record.ids) {
      await deleteDataTag(id)
    }
    props.refresh()
  }
  return (
    <Button onClick={handleClick} className='ra-delete-button'>
      <ActionDelete color="error"/>
    </Button>
  )
}

const AddDataTagButton = props => {
  const [create] = useCreate()
  const handleClick = () => {
    for (const publication of props.publications) {
      create('datatag', { publication: publication, value: 1, ident: props.datatagIdent }, {
        onSuccess: ({ data }) => {
          props.refresh()
        },
        onFailure: () => {
          props.refresh()
        }
      })
    }
  }
  return (
    <Button onClick={handleClick} label='ra.action.add'>
      <AddIcon/>
    </Button>
  )
}

const PublicationsSummary = props => {
  const { data, loading, error } = useGetMany('publication', props.ids)
  if (loading) return 'Loading'
  if (error) return 'Error'
  return (
    <ListContextProvider value={{
      data: lodashkeyBy(data, 'id'),
      ids: data.map(({ id }) => id),
      total: data.length,
      page: 1,
      perPage: 1000,
      setPage: 1,
      currentSort: { field: 'id', order: 'ASC' },
      basePath: '/publication',
      resource: '/publication'
    }}>
      <Datagrid>
        <TextField source="id"/>
        <TextField source="ident"/>
        <ReferenceField source="channel" reference="channel">
          <TextField source="ident"/>
        </ReferenceField>
      </Datagrid>
    </ListContextProvider>
  )
}

const BulkEditDataTags = props => {
  useDefineAppLocation('content.publication.bulkeditdatatags')
  const ids = String(props.match.params.ids).split(',')
  const didMount = useDidMount()
  const [datatags, setDatatags] = React.useState([])
  const [datatagIdent, setDatatagIdent] = React.useState('')

  React.useEffect(() => {
    if (didMount) {
      getDataTags(String(props.match.params.ids)).then(r => setDatatags(r))
    }
  })

  return (
    <Grid spacing={2} container>
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Selected publications</Typography>
        <PublicationsSummary ids={ids}/>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h5">Datatags</Typography>
      </Grid>
      <Grid item xs={4}>
        <MUITextField onChange={(e) => setDatatagIdent(e.target.value)} label="new datatag" variant='filled' fullWidth/>
      </Grid>
      <Grid container item xs={8}>
        <AddDataTagButton
          publications={String(props.match.params.ids).split(',')}
          datatagIdent={datatagIdent}
          refresh={() => getDataTags(String(props.match.params.ids)).then(r => setDatatags(r))}
        />
      </Grid>
      <Grid item xs={12}>
        <ListContextProvider value={{
          data: lodashkeyBy(datatags, 'id'),
          ids: datatags.map(({ id }) => id),
          resource: 'datatag',
          total: datatags.length,
          page: 1,
          perPage: 1000,
          setPage: 1,
          currentSort: { field: 'ident', order: 'ASC' },
          basePath: '/datatag',
          selectedIds: []
        }}>
          <Datagrid>
            <TextField source="ident"/>
            <FunctionField label="Publications" render={record => record.publications.map(p => p + '; ')}/>
            <ListActionToolbar>
              <DeleteButton refresh={() => getDataTags(String(props.match.params.ids)).then(r => setDatatags(r))}/>
            </ListActionToolbar>
          </Datagrid>
        </ListContextProvider>
      </Grid>
    </Grid>
  )
}

export default BulkEditDataTags

PublicationsSummary.propTypes = {
  ids: PropTypes.array
}

AddDataTagButton.propTypes = {
  publications: PropTypes.array,
  datatagIdent: PropTypes.string,
  refresh: PropTypes.func
}

DeleteButton.propTypes = {
  record: PropTypes.object,
  refresh: PropTypes.func
}

BulkEditDataTags.propTypes = {
  match: PropTypes.object.isRequired
}
