import { SaveButton, Toolbar } from 'ra-ui-materialui'
import React from 'react'

const CreateToolbar = props => (
    <Toolbar {...props} >
      <SaveButton label="ra.action.save" />
    </Toolbar>
)

export default CreateToolbar
