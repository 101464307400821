import React, { useState } from 'react'
import {
  Button,
  FormWithRedirect,
  SaveButton,
  useDelete,
  useNotify,
  useRefresh
} from 'react-admin'
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline'
import IconCancel from '@material-ui/icons/Cancel'
import red from '@material-ui/core/colors/red'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import PropTypes from 'prop-types'

function RemoveButton ({ resourceLabel, ...props }) {
  const resource = props.resource ? props.resource : ''
  const id = props.record ? props.record.id : 0
  const [showDialog, setShowDialog] = useState(false)
  const [deleteResource, { loading }] = useDelete(resource, id)
  const notify = useNotify()
  const refresh = useRefresh()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSubmit = async () => {
    deleteResource(
      { payload: { id: id } },
      {
        onSuccess: ({ data }) => {
          setShowDialog(false)
          refresh()
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        }
      }
    )
  }

  return (
    <>
      <Button onClick={handleClick} label="ra.action.remove">
        <RemoveCircleOutlineIcon />
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleCloseClick}
        aria-label={'Remove ' + resourceLabel + '?'}
      >
        <DialogTitle>Delete {resourceLabel}</DialogTitle>
        <FormWithRedirect
          resource={resource}
          save={handleSubmit}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                <h3>Are you sure to delete this element?</h3>
              </DialogContent>
              <DialogActions>
                <Button
                  label="ra.action.cancel"
                  onClick={handleCloseClick}
                  disabled={loading}
                >
                  <IconCancel />
                </Button>
                <SaveButton
                  label="ra.action.delete"
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  // pristine={pristine}
                  // saving={saving}
                  // disabled={loading}
                  icon={<RemoveCircleOutlineIcon/>}
                  color={red[500]}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}

RemoveButton.propTypes = {
  resource: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resourceLabel: PropTypes.string.isRequired
}

export default RemoveButton
