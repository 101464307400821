import * as React from 'react'
import { Card, CardHeader, Grid } from '@material-ui/core'
import { Datagrid, FunctionField, ListContextProvider, Loading, ReferenceField, TextField, useGetList } from 'react-admin'
import lodashkeyBy from 'lodash/keyBy'

import DateField from '../Components/Datefield'
// import { ServiceRealizationCalendar } from './Components'
import { orderStatusColor, supplierStatusColor } from '../Utils/StatusColors'

const Dashboard = () => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Mancub admin interface dashboard" />
        </Card>
      {/* <Grid item xs={12}>
        <ServiceRealizationCalendar/>
      </Grid> */}
      </Grid>
      <Grid item md={12} xl={6}>
        <LastOrders />
      </Grid>
      <Grid item md={12} xl={6}>
        <Card>
          <LastSupplierResponses />
        </Card>
      </Grid>
    </Grid>
  )
}

const LastOrders = props => {
  const { data, ids, error, loading } = useGetList(
    'order',
    { page: 1, perPage: 10 },
    { field: 'checkout_at', order: 'DESC' },
    { admin_orders: 'true' }
  )
  if (error) return 'ERROR'
  if (loading) return <Loading />
  const dataArray = ids.map(id => data[id])
  return (
    <Card>
      <CardHeader title="Last 10 orders" />
      <ListContextProvider value={{
        data: lodashkeyBy(dataArray, 'id'),
        ids: dataArray.map(({ id }) => id),
        resource: 'order',
        total: dataArray.length,
        page: 1,
        perPage: 10,
        currentSort: { field: 'checkout_at', order: 'DESC' },
        basePath: '/order',
        selectedIds: []
      }}>
        <Datagrid rowClick="edit" isRowSelectable={() => false}>
          <ReferenceField source="channel" reference="channel" link={false} >
            <TextField source="ident"/>
          </ReferenceField>
          <TextField source="code" />
          <DateField source="checkout_at" showTime />
          <ReferenceField label="Customer" source="customer" reference="customer" link={false}>
            <TextField source="name"/>
          </ReferenceField>
          <FunctionField label="Price" render={record => <span>{record.total} {record.currency}</span>}/>
          <FunctionField source="status" render={record => <span style={{ color: orderStatusColor(record.status) }}>{record.status}</span>} />
        </Datagrid>
      </ListContextProvider>
    </Card>
  )
}

const LastSupplierResponses = props => {
  const { data, ids, error, loading } = useGetList(
    'suppliermessage',
    { page: 1, perPage: 10 },
    { field: 'sent_at', order: 'DESC' }
  )
  if (error) return 'ERROR'
  if (loading) return <Loading />
  const dataArray = ids.map(id => data[id])
  return (
    <Card>
      <CardHeader title="Last 10 supplier responses" />
      <ListContextProvider value={{
        data: lodashkeyBy(dataArray, 'id'),
        ids: dataArray.map(({ id }) => id),
        resource: 'suppliermessage',
        total: dataArray.length,
        page: 1,
        perPage: 10,
        currentSort: { field: 'response_at', order: 'DESC' },
        basePath: '/suppliermessage',
        selectedIds: []
      }}>
        <Datagrid rowClick="edit" isRowSelectable={() => false}>
          <ReferenceField source="supplier" reference="supplier" link={false}>
            <TextField source="name"/>
          </ReferenceField>
          <ReferenceField source="order_item" reference="orderitem" link={false}>
              <FunctionField label="Ident" source="id" render={(record) => <span>#OI{record.id}</span>} />
          </ReferenceField>
          <FunctionField source="message" render={record => record.message === null ? null : record.message.replace(/<\/?[^>]+(>|$)/g, ' ').length > 30 ? record.message.replace(/<\/?[^>]+(>|$)/g, ' ').substring(0, 30) + '...' : record.message.replace(/<\/?[^>]+(>|$)/g, ' ') }/>
          <DateField source="sent_at" />
          <DateField source="response_at" />
          <FunctionField source="status" render={record => <span style={{ color: supplierStatusColor(record.status) }}>{record.status}</span>} />
        </Datagrid>
      </ListContextProvider>
    </Card>
  )
}

export default Dashboard
