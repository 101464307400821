import * as React from 'react'
import MancubFileBrowser from '../../Components/MancubFileBrowser'
import {
  ImageField,
  ImageInput
} from 'react-admin'

const FileBrowser = () => {
  return (
    <MancubFileBrowser path="images">
      <ImageInput source="file" label="Images" multiple>
        <ImageField source="src" title="title" />
      </ImageInput>
    </MancubFileBrowser>
  )
}

export default FileBrowser
