import * as React from 'react'
import {
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin'
import getConfig from '../../Components/core_config'
import { JsonInput } from 'react-admin-json-view'

const CustomerBaseForm = () => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <>
      <SelectInput fullWidth source="customer_type" choices={config.customer_type} validate={required()} />
      <TextInput fullWidth source="name" validate={required()} resettable />
      <TextInput fullWidth source="email" type="email" resettable />
      <TextInput fullWidth source="phone" resettable />
      <TextInput fullWidth source="note" resettable />
      <ReferenceInput fullWidth label="Parent" source="parent" reference="customer" resettable>
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput fullWidth label="User account" source="user_account" reference="channeluser" resettable>
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput fullWidth source="source" />
      <BooleanInput fullWidth source="newsletter" />
      <JsonInput source="billing_address" />
      <TextInput fullWidth source="note" resettable />
    </>
  )
}

export default CustomerBaseForm
