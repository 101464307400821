
import * as React from 'react'
import {
  DeleteButton,
  Toolbar,
  SaveButton,
  useNotify
} from 'react-admin'
import { PropTypes } from 'prop-types'

const SaveContinueToolbar = (props) => {
  const notify = useNotify()
  return (
    <Toolbar {...props}>
      <SaveButton label="menu.save_close" disabled={props.pristine} />
      {/* TODO might be buggy from previous experience */}
      <SaveButton
        onSuccess={() => {
          notify('ra.notification.updated', 'info', { smart_count: 1 }, false)
        }}
        style={{ marginLeft: 10 }}
        label="menu.save_continue"
        redirect={false}
        disabled={props.pristine}
      />
      { props.disableDelete
        ? null
        : <DeleteButton
        undoable={false}
        style={{ marginRight: 0, marginLeft: 'auto' }}
      />}
    </Toolbar>
  )
}

SaveContinueToolbar.propTypes = {
  pristine: PropTypes.bool,
  resource: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  deleteLabel: PropTypes.string,
  disableDelete: PropTypes.bool
}

export default SaveContinueToolbar
