import * as React from 'react'
import {
  ReferenceManyField
} from 'react-admin'
import PropTypes from 'prop-types'

const SetupCount = props => { return props.total === 0 ? ' ' : props.total }
SetupCount.propTypes = { total: PropTypes.number }
SetupCount.defaultProps = { total: 0 }

const ConnectedSetups = (props, label) => (
  <ReferenceManyField label={label} reference="servicesetup" target="serviceoffering.id" {...props}>
    <SetupCount />
  </ReferenceManyField>
)
ConnectedSetups.propTypes = { label: PropTypes.string }
ConnectedSetups.defaultProps = { label: 'Connected setups' }

export default ConnectedSetups
