
import green from '@material-ui/core/colors/green'
import orange from '@material-ui/core/colors/orange'
import red from '@material-ui/core/colors/red'
import grey from '@material-ui/core/colors/grey'
import blue from '@material-ui/core/colors/blue'
import purple from '@material-ui/core/colors/purple'

export const paymentStatusColor = (status) => {
  switch (status) {
    case 'UNPAID':
    case 'CANCELLED':
      return red[500]
    case 'PENDING_CAPTURE':
    case 'PENDING_AUTHORIZATION':
    case 'PENDING':
      return orange[500]
    case 'OK':
    case 'CAPTURED':
    case 'AUTHORIZED':
    case 'PAID':
      return green[500]
    default:
      return ''
  }
}

export const supplierStatusColor = (status) => {
  switch (status) {
    case 'refused':
      return red[500]
    case 'pending':
      return orange[500]
    case 'confirmed':
      return green[500]
    case 'canceled':
      return grey[500]
    default:
      return ''
  }
}

export const orderStatusColor = (status) => {
  switch (status) {
    case 'cancelled':
      return red[500]
    case 'basket':
      return orange[500]
    case 'merged':
      return grey[500]
    case 'checkout':
      return purple[500]
    case 'placed':
      return blue[500]
    case 'accepted':
      return green[500]
    default:
      return ''
  }
}
