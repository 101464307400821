import * as React from 'react'
import { PropTypes } from 'prop-types'
import { useGetList, useLocale } from 'react-admin'
import { channelMapping } from '..'
import { Typography, Grid } from '@material-ui/core'
import ChannelTextTooltip from './ChannelTextTooltip'
import TranslateIcon from '@material-ui/icons/Translate'

const getTranslation = (key, channel) => {
  if (key === undefined) return key
  const { data, ids, loading, error } = useGetList(
    'channeltext',
    { page: 1, perPage: 10 },
    { field: 'id', order: 'ASC' },
    { key: key, channel__ident: channel }
  )
  return { data, ids, loading, error }
}

const TranslationWithTooltip = props => {
  const { fieldValue, textValue } = props
  if (fieldValue === undefined) return ''
  const locale = useLocale()
  const { data, ids, loading, error } = getTranslation(fieldValue, channelMapping[locale])
  if (loading) return 'Loading'
  if (error) return 'Error'

  return (
    <Grid container spacing={1} xs={12}>
      <Grid item>
        <Typography variant="body2" display="inline">{ids.length === 0
          ? <>{textValue}</>
          : <> {!data[ids[0]] || !data[ids[0]].value || data[ids[0]].value.length === 0 ? textValue : data[ids[0]].value}</>
        }</Typography>
      </Grid>
      <Grid item>
        <ChannelTextTooltip
          placement="right-start"
          tooltipSource={<Typography variant='body2'><TranslateIcon color="primary"/></Typography>}
          fieldValue={fieldValue}
          editable
        />
      </Grid>
    </Grid>
  )
}

TranslationWithTooltip.propTypes = {
  fieldValue: PropTypes.string,
  textValue: PropTypes.string
}

export default TranslationWithTooltip
