import * as React from 'react'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { Create, ReferenceInput, SelectInput, SimpleForm, required } from 'react-admin'
import {
  WithProps
} from '../../Utils'
import {
  Grid,
  Typography
} from '@material-ui/core'
import getConfig from '../../Components/core_config'

const OrderItemCreate = props => {
  useDefineAppLocation('catalog.booking.create')
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <>
      <Create {...props}>
        <WithProps>{({ record, ...props }) =>
          <SimpleForm warnWhenUnsavedChanges record={record} {...props}>
                <Grid container spacing={4} style={{ width: '100%' }} {...props}>
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12}>
                      <Typography variant="h5">General</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SelectInput source="booking_from" choices={config.booking_from} fullWidth validate={required()} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ReferenceInput source="channel" reference="channel">
                        <SelectInput optionText="ident" fullWidth/>
                      </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SelectInput source="status" choices={config.booking_status} fullWidth validate={required()} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ReferenceInput source="ticket" reference="ticket">
                        <SelectInput optionText={(record) => `${record.id}: ${record.display_summary}`} fullWidth />
                      </ReferenceInput>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ReferenceInput source="capacity_pool" reference="capacitypool">
                        <SelectInput optionText={(record) => `${record.id}: ${record.ident}`} fullWidth validate={required()}/>
                      </ReferenceInput>
                    </Grid>
                  </Grid>
                </Grid>
              </SimpleForm>}
            </WithProps>
      </Create>
    </>
  )
}

export default OrderItemCreate
