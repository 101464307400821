import * as React from 'react'
import {
  Edit,
  SimpleForm
} from 'react-admin'

import { EditActions } from '../../Components'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import MenuItemForm from './Form'

const MenuItemEdit = ({ permissions, ...props }) => {
  useDefineAppLocation('content.menuitem.edit')
  return (
    <>
      <Edit mutationMode="pessimistic" actions={<EditActions />} {...props}>
      <SimpleForm
        warnWhenUnsavedChanges
        sanitizeEmptyValues={false}
        toolbar={<SaveDeleteToolbar deleteLabel="title" />}
        {...props}
      >
        <MenuItemForm/>
      </SimpleForm>
      </Edit>
    </>
  )
}

MenuItemEdit.propTypes = {
  permissions: PropTypes.arrayOf(String).isRequired
}

export default MenuItemEdit
