import * as React from 'react'
import PropTypes from 'prop-types'

import { FunctionField, TextField } from 'react-admin'

import { paymentStatusColor } from '../../Utils/StatusColors'

import { PriceInfoField } from '../../Components'

const PaymentInfoPanel = props => {
  return (
    <>
      <h3>Payment (REF: {props.record.id})</h3>
      <p>Type: <TextField source="payment_type"/></p>
      <p>Status: <FunctionField source="status" render={record => <span style={{ color: paymentStatusColor(record.status) }}>{record.status}</span>} /></p>
      <p>Amount: <PriceInfoField {...props}/></p>
    </>
  )
}

PaymentInfoPanel.propTypes = {
  record: PropTypes.object
}

export default PaymentInfoPanel
