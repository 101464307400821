import PublicationList from './List'
import PublicationEdit from './Edit'
import PublicationCreate from './Create'
import BookIcon from '@material-ui/icons/Book'

export default {
  list: PublicationList,
  edit: PublicationEdit,
  create: PublicationCreate,
  icon: BookIcon
}
