import * as React from 'react'
import { Fragment, memo } from 'react'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  Pagination
} from 'react-admin' // eslint-disable-line import/no-unresolved

import ListActions from '../../Components/Toolbar/ListActions'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { useSelectedColumns } from '@react-admin/ra-preferences'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'
import DateField from '../../Components/Datefield'
import ShortTextField from '../../Components/ShortTextField'

import ServiceRealizationFilters from './Filters'
import dateAdmin from '../../Utils/dateAdmin'

// eslint-disable-next-line react/display-name
const ServiceRealizationListBulkActions = memo(props => (
  <Fragment>
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const serviceRealizationColumns = {
  id: <TextField source="id" />,
  servicebase:
  <ReferenceField source="service_base" reference="servicebase" link={false}>
     <FunctionField render={record => <ShortTextField text={record.ident}/>}/>
  </ReferenceField>,
  start: <DateField source="start" showTime />,
  status: <TextField source="status"/>,
  number_of_servicesetups: <FunctionField label="Number ServiceSetups" render={record => !record.service_setups_info ? null : record.service_setups_info.total }/>,
  number_of_tickets: <FunctionField label="Number Tickets" render={record => !record.service_setups_info ? null : record.service_setups_info.tickets }/>,
  capacity_dualdisplay: <FunctionField label="Occupied/total" render={record => <>{record.capacity_pool_info === undefined ? null : <>{record.capacity_pool_info.occupied}/{record.capacity_pool_info.total}</>}</>}/>,
  empty: <TextField label="Empty" source="capacity_pool_info.empty"/>,
  technical_note: <TextField source="technical_note"/>,
  realization_strategy: <TextField label="Realization strategy" source="service_base_detail.realization_strategy" />
}

const ServiceRealizationList = props => {
  const columns = useSelectedColumns({
    preferences: 'servicerealization.list.columns',
    columns: serviceRealizationColumns,
    omit: ['id', 'technical_note', 'number_of_servicesetups', 'realization_strategy']
  })
  useDefineAppLocation('order.servicerealization')

  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="servicerealization.list.columns" columns={serviceRealizationColumns} fromToday='start' />}
        bulkActionButtons={<ServiceRealizationListBulkActions />}
        filters={<ServiceRealizationFilters />}
        filterDefaultValues={{ start: dateAdmin.parse(new Date(), 'yyyy-mm-dd') }}
        sort={{ field: 'start', order: 'ASC' }}
        exporter={(data) => exporter(data, 'ServiceRealization')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
        >
        <Datagrid rowClick="edit" optimized>
          {columns}
          <ListActionToolbar>
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

export default ServiceRealizationList
