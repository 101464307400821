import * as React from 'react'
import { PropTypes } from 'prop-types'
import { Tooltip } from '@material-ui/core'

const ShortTextField = props => {
  const textLength = props.textLength ? props.textLength : 30
  let text = props.text
  if (typeof text !== 'string') text = JSON.stringify(text)
  if (text && text.length > textLength) {
    let t = text
    if (t.includes('::')) t = t.split('::').slice(1).join('::')
    if (t.length <= textLength) {
      return (
        <Tooltip title={text}>
          <span>{t}</span>
        </Tooltip>
      )
    }
    return (
      <Tooltip title={text}>
        <span>{'…' + text.substring(text.length - textLength, text.length)}</span>
      </Tooltip>
    )
  }
  return <span>{text}</span>
}

ShortTextField.propTypes = {
  textLength: PropTypes.number,
  text: PropTypes.string
}

export default ShortTextField
