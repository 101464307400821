import React from 'react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-admin'
import { PropTypes } from 'prop-types'

const styles = {
  button: {
    marginTop: '1em'
  }
}

const CreateSOButton = ({ classes, record }) => (
  <Button
    color="primary"
    className={classes.button}
    component={Link}
    to={`/serviceoffering/create?servicebase_id=${record.id}`}
    label="simple.action.create"
    title="Create Service Offering"
  >
    <AddIcon />
  </Button>
)

CreateSOButton.propTypes = {
  classes: PropTypes.object.isRequired,
  record: PropTypes.object.isRequired
}

export default withStyles(styles)(CreateSOButton)
