import * as React from 'react'
import DateField from '../../../Components/Datefield'
import PropTypes from 'prop-types'
import { SelectInput, EditButton, ReferenceInput } from 'react-admin'

import { DateTimeInput } from '../../../Components'

import { DialogForm } from '../../../Components/Dialogs'
import moment from 'moment'

const EditRealizationStartDate = props => {
  const realizationStrategy = props.record.item_object.service_realization_detail ? props.record.item_object.service_realization_detail.service_base_detail.realization_strategy : null
  const [open, setOpen] = React.useState(false)

  const handleOpen = () => {
    setOpen(true)
  }
  console.log(props.record.item_object.service_realization_detail)
  const handleClose = () => {
    setOpen(false)
  }
  return (
    <>
      <EditButton style={{ marginLeft: 5 }} onClick={handleOpen}/>
      <DialogForm
        open={open}
        formRecord={realizationStrategy === 'on_demand' ? props.record.item_object.service_realization_detail : props.record.item_object}
        handleClose={handleClose}
        resource={realizationStrategy === 'on_demand' ? 'servicerealizationpicker' : 'servicesetup'}
      >
        {realizationStrategy
          ? realizationStrategy === 'on_demand'
            ? <DateTimeInput label="Start date" source="start" fullWidth/>
            : <ReferenceInput
          label="Service realization"
          source="service_realization"
          reference="servicerealizationpicker"
          sort={{ field: 'start', order: 'ASC' }}
          perPage={1000}
          filter={{ service_base: props.record.item_object.service_realization_detail.service_base }}
          filterToQuery={() => {
            const startRaw = props.record.item_object.service_realization_detail.start

            const start = moment(startRaw).subtract(33, 'days').toISOString()
            const end = moment(startRaw).add(33, 'days').toISOString()

            return { start_from: start.substring(0, start.length - 5), start_to: end.substring(0, start.length - 5) }
          }}
        >
          {/* <AutocompleteInput suggestionLimit={30} optionText={(record) => (record.start === undefined ? '' : `${moment(record.start).format('DD. MM. YYYY, HH:mm')}`)} resettable fullWidth/> */}
          <SelectInput source='start' optionText={(record) => (record.start === undefined ? '' : `${moment(record.start).format('DD. MM. YYYY, HH:mm')}`)} fullWidth/>
        </ReferenceInput>
          : null
        }

      </DialogForm>
    </>
  )
}

const RealizationStartDate = props => {
  return (
    <>
      <DateField variant="body1" source="item_object.service_realization_detail.start" showTime/>
      <EditRealizationStartDate {...props}/>
    </>
  )
}

RealizationStartDate.propTypes = {
  record: PropTypes.object
}

EditRealizationStartDate.propTypes = {
  record: PropTypes.object
}

export default RealizationStartDate
