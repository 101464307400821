import ServiceRealizationList from './List'
import ServiceRealizationEdit from './Edit'
import ServiceRealizationCreate from './Create'

export default {
  list: ServiceRealizationList,
  edit: ServiceRealizationEdit,
  create: ServiceRealizationCreate
}

export { default as Calendar } from './Calendar'
