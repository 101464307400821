import * as React from 'react'
import { Fragment, memo } from 'react'
import { PropTypes } from 'prop-types'

import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { CreateDialog } from '@react-admin/ra-form-layout'
import { List, ReferenceField, NumberField, TextField, Datagrid, EditButton, BulkDeleteButton, BulkExportButton, SearchInput, SelectInput, Pagination } from 'react-admin'

import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import DateField from '../../Components/Datefield'
import { PriceInfoField } from '../../Components'
import getConfig from '../../Components/core_config'

import OrderInfoPanel from '../../Orders/Order/Components/OrderInfoPanel'

import PaymentInfoPanel from '../Payment/PaymentInfoPanel'
import AccountingDownloadPDF from './DownloadAsPDF'

import { FilterWithSave } from '@react-admin/ra-preferences'

const AccountingDocumentExpandPanel = props => {
  return (
    <>
      <p>Due date: <DateField source="due_date" dontShowTime/></p>
      <ReferenceField source="payment" reference="payment" link={false}>
        <PaymentInfoPanel />
      </ReferenceField>
      {props.record.order
        ? <ReferenceField source="order" reference="order" link={false}>
        <OrderInfoPanel />
      </ReferenceField>
        : <></>}
    </>
  )
}

const AccountingDocumentFilter = props => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <FilterWithSave {...props}>
      <SearchInput source="q" alwaysOn />
      <SelectInput source="document_type" choices={config.document_type}/>
    </FilterWithSave>
  )
}

// eslint-disable-next-line react/display-name
const AccountingDocumentListBulkActions = memo(props => (
  <Fragment>
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const AccountingDocumentList = props => {
  useDefineAppLocation('accounting.accountingdocument.list')
  return (
        <>
          <List
          {...props}
          sort={{ field: 'issue_date', order: 'DESC' }}
          perPage={50}
          pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
          bulkActionButtons={<AccountingDocumentListBulkActions />}
          filters={<AccountingDocumentFilter/>}
          empty={false}
          >
            <Datagrid
              isRowExpandable={() => true}
              expand={<AccountingDocumentExpandPanel />}
              optimized
            >
              <DateField source="issue_date"/>
              <DateField source="vat_date" showTime={false}/>
              <TextField source="document_type"/>
              <TextField source="document_number"/>
              <PriceInfoField label="Paid" {...props} />
              <NumberField source="normalized_price" options={{ style: 'currency', currency: 'CZK', maximumFractionDigits: 0 }} textAlign='left'/>
              <ListActionToolbar>
                <AccountingDownloadPDF {...props}/>
                <EditButton />
              </ListActionToolbar>
            </Datagrid>
          </List>
          <CreateDialog {...props}>
            <></>
          </CreateDialog>
        </>
  )
}

AccountingDocumentExpandPanel.propTypes = {
  record: PropTypes.object
}

export default AccountingDocumentList
