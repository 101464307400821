import React from 'react'
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  required,
  SelectInput
} from 'react-admin'

import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import CreateToolbar from '../../Components/CreateToolbar'
import getConfig from '../../Components/core_config'

const OrderCreate = ({ permissions, ...props }) => {
  if (props.resource === 'basket') {
    useDefineAppLocation('order.basket.edit')
  } else {
    useDefineAppLocation('order.list.edit')
  }
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <>
      <Create {...props} >
        <SimpleForm initialValues={props.location.filterValues} toolbar={<CreateToolbar />} >
          <ReferenceInput label="Channel" source="channel" reference="channel" validate={required()} >
            <AutocompleteInput optionText={record => record.ident }/>
          </ReferenceInput>
          <ReferenceInput label="Customer" source="customer" reference="customer" >
            <AutocompleteInput resettable optionText="name" />
          </ReferenceInput>
          <ReferenceInput label="Channel User" source="channel_user" reference="channeluser" validate={required()}>
            <AutocompleteInput resettable optionText="username"/>
          </ReferenceInput>
            {props.resource === 'basket'
              ? <SelectInput source="status" choices={config.order_status} initialValue="basket" disabled/>
              : <SelectInput source="status" choices={config.order_status} />
            }

        </SimpleForm>
      </Create>
    </>
  )
}

OrderCreate.propTypes = {
  location: PropTypes.object,
  permissions: PropTypes.arrayOf(String),
  resource: PropTypes.string
}

export default OrderCreate
