import * as React from 'react'
import {
  TextField
} from 'react-admin'

import get from 'lodash/get'
import PropTypes from 'prop-types'
import { JsonField } from 'react-admin-json-view'

const PublicationContentDisplay = ({ source, record = {} }) => {
  const jsonElement = get(record, source)
  const jsonField = []
  for (const [key, value] of Object.entries(jsonElement)) {
    jsonField.push(<div>{key}</div>)
    if (Array.isArray(value) || typeof value === 'object') {
      console.log(`${source}.${key}`)
      jsonField.push(
        <JsonField
          source={`${source}.${key}`}
          record={record}
        />
      )
    } else {
      jsonField.push(
        <TextField
          label={key}
          source={`${source}.${key}`}
          record={record}
        />
      )
    }
    jsonField.push(<><br></br><br></br></>)
  }
  return jsonField
}

PublicationContentDisplay.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  source: PropTypes.string.isRequired
}

PublicationContentDisplay.defaultProps = {
  addLabel: true
}

export default PublicationContentDisplay
