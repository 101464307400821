import * as React from 'react'

import { useMediaQuery } from '@material-ui/core'
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleList,
  TextField,
  TextInput,
  Pagination
} from 'react-admin'
import ListActions from '../../Components/Toolbar/ListActions'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'

const ChannelUserFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput
      source="username"
      defaultValue=""
    />
    <ReferenceInput source="channel" reference="channel">
      <SelectInput optionText="ident" />
    </ReferenceInput>
  </Filter>
)

const rowClick = (id, basePath, record) => {
  return 'edit'
}

const ChannelUserList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('customer.channeluser')
  return (
    <>
      <List
        {...props}
        actions={<ListActions />}
        bulkActionButtons={false}
        filters={<ChannelUserFilter />}
        sort={{ field: 'username', order: 'ASC' }}
        exporter={(data) => exporter(data, 'ChannelUser')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      >
        {isSmall
          ? (
            <SimpleList
              primaryText={record => record.username}
              secondaryText={record =>
                <ReferenceField source="channel" reference="channel" basePath="channel" record={record} link={true}>
                  <TextField source="ident" />
                </ReferenceField>
              }
            />
            )
          : (
          <Datagrid rowClick={rowClick} optimized>
            <TextField source="username" />
            <TextField source="email" />
            <ReferenceField source="channel" reference="channel" link={true}>
              <TextField source="ident"/>
            </ReferenceField>
            <ListActionToolbar>
              <EditButton />
            </ListActionToolbar>
          </Datagrid>
            )}
      </List>
    </>
  )
}

export default ChannelUserList
