import { usePreferences, useSetLocaleAndPreference } from '@react-admin/ra-preferences'
import { Logout } from 'ra-ui-materialui'
import { Menu, MenuItem, ListItemIcon, makeStyles } from '@material-ui/core'
import * as React from 'react'
import { Brightness4, DateRange, Translate } from '@material-ui/icons'
import { useLocale, useTranslate } from 'ra-core'
import moment from 'moment'

const useStyles = makeStyles((theme) => ({
  menuItem: {
    color: theme.palette.text.secondary
  },
  icon: {
    minWidth: theme.spacing(5)
  }
}))

const FirstDayToggle = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const [firstDay, setFirstDay] = usePreferences(
    'calendar.firstday',
    1
  )
  const locale = useLocale()
  React.useEffect(() => moment.updateLocale(locale, { week: { dow: firstDay } }), [firstDay, locale])

  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleFirstDay = (newFirstDay) => {
    setFirstDay(newFirstDay)
    handleClick(null)
  }
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null)
  }
  return (
    <div>
    <MenuItem onClick={handleClick} className={classes.menuItem}>
      <ListItemIcon className={classes.icon}>
        <DateRange color="text" />
      </ListItemIcon>
      {translate('menu.first_day', { _: 'First day of week' })}
    </MenuItem>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClick}
    >
      <MenuItem
        className={classes.menuItem}
        selected={firstDay === 0}
        onClick={() => handleFirstDay(0)}
      >
        {translate('menu.sunday', { _: 'Sunday' })}
      </MenuItem>
      <MenuItem
        className={classes.menuItem}
        selected={firstDay === 1}
        onClick={() => handleFirstDay(1)}
      >
        {translate('menu.monday', { _: 'Monday' })}
      </MenuItem>
    </Menu>
    </div>
  )
}

const CustomLanguageSwitcher = props => {
  const classes = useStyles()
  const languages = [
    { locale: 'en', language: 'English' },
    { locale: 'fr', language: 'French' },
    { locale: 'cs', language: 'Czech' }
  ]
  const locale = useLocale()
  const setLocale = useSetLocaleAndPreference()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const handleClick = (event) => {
    setAnchorEl(anchorEl === null ? event.currentTarget : null)
  }
  const handleLanguage = (lanLocale) => {
    setLocale(lanLocale)
    setAnchorEl(null)
  }
  return (
    <div>
    <MenuItem onClick={handleClick} className={classes.menuItem}>
      <ListItemIcon className={classes.icon}>
        <Translate color="text" />
      </ListItemIcon>
      {languages.find(element => element.locale === locale).language}
    </MenuItem>
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClick}
    >
      {languages.map(l =>
      <MenuItem
        className={classes.menuItem}
        key={l.locale}
        selected={locale === l.locale}
        onClick={() => handleLanguage(l.locale)}
      >
        {l.language}
      </MenuItem>
      )}
    </Menu>
    </div>
  )
}

const CustomThemeToggle = props => {
  const classes = useStyles()
  const translate = useTranslate()
  const [theme, setTheme] = usePreferences(
    'theme',
    'light'
  )
  const handleClick = () => {
    setTheme(theme === 'light' ? 'dark' : 'light')
  }
  return (
    <MenuItem onClick={handleClick} className={classes.menuItem}>
      <ListItemIcon className={classes.icon}>
        <Brightness4 color="text" />
      </ListItemIcon>
      {translate('menu.theme', { _: 'Change theme' })}
    </MenuItem>
  )
}

const MyLogoutButton = props => {
  return (
    <>
    <CustomLanguageSwitcher/>
    <FirstDayToggle />
    <CustomThemeToggle />
    <Logout {...props} />
    </>
  )
}

export default MyLogoutButton
