import * as React from 'react'

import {
  TextInput,
  required,
  ReferenceManyField,
  TextField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  AutocompleteInput
} from 'react-admin'

import PropTypes from 'prop-types'
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid'
import getConfig from '../../../Components/core_config'
import PriceInput from '../../../Components/PriceInput'
import PriceField from '../../../Components/PriceField'
import { EditableDatagridCustomActions } from '../../../Components/Actions'

const BuyPriceItemForm = props => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
  <RowForm {...props}>
    <TextInput source="ident" validate={required()}/>
    <ReferenceInput source="supplier" reference="supplier" >
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
    <SelectInput choices={config.expenditure_types} source="expenditure_type" validate={required()}/>
    <PriceInput validate={required()} priceSource="amount" withCurrency/>
  </RowForm>
  )
}

const BuyPriceItemsEdit = props => {
  return (
    <ReferenceManyField label="" sort={{ field: 'ident', order: 'ASC' }} reference="buypriceitem" target="service_offering_config" fullWidth>
      <EditableDatagrid
        actions={<EditableDatagridCustomActions/>}
        createForm={<BuyPriceItemForm initialValues={{ service_offering_config: props.record.id }} />}
        editForm={<BuyPriceItemForm />}
      >
        <TextField source="ident"/>
        <TextField source="expenditure_type"/>
        <PriceField label="Amount" priceSource="amount"/>
        <ReferenceField source="supplier" reference="supplier" >
          <TextField source="name"/>
        </ReferenceField>
      </EditableDatagrid>
    </ReferenceManyField>
  )
}

BuyPriceItemsEdit.propTypes = {
  record: PropTypes.object
}

export default BuyPriceItemsEdit
