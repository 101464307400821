import ChannelUserList from './List'
import ChannelUserCreate from './Create'
import ChannelUserEdit from './Edit'
import AccountCircle from '@material-ui/icons/AccountCircle'

export default {
  list: ChannelUserList,
  edit: ChannelUserEdit,
  create: ChannelUserCreate,
  icon: AccountCircle
}
