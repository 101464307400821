const cacheDataProviderProxy = (dataProvider, duration = 5 * 60 * 1000) =>
  new Proxy(dataProvider, {
    get: (target, name) => (resource, params) => {
      if (resource === 'mancubconfig' && (name === 'getOne' || name === 'getMany' || name === 'getList')) {
        return dataProvider[name](resource, params).then(response => {
          const validUntil = new Date()
          validUntil.setTime(validUntil.getTime() + duration)
          response.validUntil = validUntil
          return response
        })
      }
      return dataProvider[name](resource, params)
    }
  })

export default cacheDataProviderProxy
