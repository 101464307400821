import * as React from 'react'

import { ExitToApp } from '@material-ui/icons'
import { Button } from 'ra-ui-materialui'
import { BASE_URL } from '../../dataProvider'
import { PropTypes } from 'prop-types'

const GoToButton = props => {
  return (
    <Button
      color="primary"
      disabled={props.record.secret === undefined || props.record.secret.length <= 0 }
      href={`${BASE_URL}/suppliermessage/${props.record.secret}`}
      label='Open'
    >
      <ExitToApp />
    </Button>
  )
}

GoToButton.propTypes = {
  record: PropTypes.object.isRequired
}

export default GoToButton
