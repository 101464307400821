import * as React from 'react'
import MancubFileBrowser from '../../Components/MancubFileBrowser'
import {
  FileField,
  FileInput
} from 'react-admin'

const FileBrowser = () => {
  return (
    <MancubFileBrowser path="documents">
      <FileInput source="file" label="Documents" multiple>
        <FileField source="src" title="title" />
      </FileInput>
    </MancubFileBrowser>
  )
}

export default FileBrowser
