import * as React from 'react'

import { PropTypes } from 'prop-types'

import {
  ReferenceManyField,
  Datagrid,
  EditButton,
  Button,
  FunctionField,
  useRecordContext
} from 'react-admin'

import {
  Typography, Accordion, AccordionDetails, AccordionSummary
} from '@material-ui/core'

import AddIcon from '@material-ui/icons/Add'

import { Link } from 'react-router-dom'

const ReferenceDatagrid = props => {
  const { reference, target, children, noEdit, expand, data } = props
  const record = useRecordContext()
  return (
    <>
    {Object.values(data).length === 0
      ? <Button
        startIcon={<AddIcon/>}
        label="simple.action.create"
        component={Link}
        to={`/${reference}/create?${target}_id=${record.id}`}
      />
      : <Datagrid expand={expand}>
      {children}
      <FunctionField
        label={
          <Button
            startIcon={<AddIcon/>}
            label="simple.action.create"
            component={Link}
            to={`/${reference}/create?${target}_id=${record.id}`}
          />
        }
        render={record => noEdit ? null : <EditButton record={record}/>}
      />
      </Datagrid>
    }
  </>
  )
}

const ReferenceManyFieldAccordion = props => {
  const { title, reference, target, children, noEdit, expand, defaultExpanded, ...rest } = props
  return (
    <Accordion defaultExpanded={defaultExpanded}>
      <AccordionSummary>
        <Typography variant="h5">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <ReferenceManyField reference={reference} target={target} label={title} {...rest}>
          <ReferenceDatagrid
            reference={reference}
            target={target}
            noEdit={noEdit}
            expand={expand}
          >
            {children}
          </ReferenceDatagrid>
        </ReferenceManyField>
      </AccordionDetails>
    </Accordion>
  )
}

ReferenceManyFieldAccordion.propTypes = {
  children: PropTypes.array,
  expand: PropTypes.element,
  title: PropTypes.string,
  reference: PropTypes.string,
  target: PropTypes.string,
  record: PropTypes.object,
  defaultExpanded: PropTypes.bool,
  noEdit: PropTypes.bool
}

ReferenceDatagrid.propTypes = {
  children: PropTypes.element,
  expand: PropTypes.element,
  reference: PropTypes.string,
  target: PropTypes.string,
  noEdit: PropTypes.bool,
  data: PropTypes.object
}

export default ReferenceManyFieldAccordion
