import * as React from 'react'
import RichTextInput from 'ra-input-rich-text'
import {
  Edit,
  ReferenceField,
  SimpleForm,
  TextField,
  EditButton,
  Button
} from 'react-admin'
import PropTypes from 'prop-types'
import {
  WithProps
} from '../../Utils'
import {
  Card,
  CardContent,
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ExitToApp from '@material-ui/icons/ExitToApp'

import { EditActions } from '../../Components'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import SupplierMessagePanel from '../../Components/SupplierMessagePanel'
import DateField from '../../Components/Datefield'
import GoToPublication from '../../Components/PublicationContent/GoToPublication'
import RealizationStartDate from './Components/RealizationStartDate'
import OrderItemInfoInput from './Components/OrderItemInfoInput'
import { Link } from 'react-router-dom'
import getConfig from '../../Components/core_config'

const OrderItemEdit = ({ record, permissions, ...props }) => {
  useDefineAppLocation(props.location.state === undefined ? 'order.orderitem.edit' : `${props.location.state.before}.orderitem.edit`)
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <>
      <Edit actions={<EditActions />} {...props}>
        <WithProps>{({ record, ...props }) =>
          <SimpleForm warnWhenUnsavedChanges record={record} {...props} toolbar={<SaveDeleteToolbar />} >
            <Grid container xs={12} style={{ width: '100%' }} spacing={4}>
              <Grid container item xs={12} sm={8}>
                <Grid item xs={12} sm={7}>
                  <Card variant="outlined">
                    <CardContent>
                      <Grid container item xs={12}>
                        <Grid item xs={8}>
                          <Typography variant="h5">Publication</Typography>
                        </Grid>
                        <Grid container style={{ justifyContent: 'right' }} item xs={4}>
                          <ReferenceField source="publication.id" reference="publication" link={true}>
                            <EditButton />
                          </ReferenceField>
                        </Grid>
                      </Grid>
                      <br/>
                      <Grid xs={12}>
                        <Typography variant="body1">Channel</Typography>
                        <ReferenceField source="publication.channel" reference="channel" link={true}>
                          <TextField variant="body1" source="ident" fullWidth/>
                        </ReferenceField> <br/><br/>
                        <Typography variant="body1">Ident: </Typography><TextField variant="body1" source="publication.ident"/> <br/> <br/>
                        <Typography variant="body1">Title: </Typography><TextField variant="body1" source="publication.title"/> <br/> <br/>
                      </Grid>
                      <Grid container item style={{ justifyContent: 'center' }} xs={12}>
                        <Grid>
                          <GoToPublication label="See on website" type="slug_link" record={record.publication} />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
              <Grid container item xs={12} sm={4} alignContent="flex-start" spacing={1}>
                <Grid item xs={12}>
                  <Typography variant="h5">General</Typography>
                  <Grid item xs={12}>
                    <Typography variant="body1">Created at: <DateField variant="body1" source="created_at" showTime/></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">Modified at: <DateField variant="body1" source="modified_at" showTime/></Typography>
                  </Grid>
                  <br/>
                  <Grid item xs={12}>
                    <Typography variant="body1">Order: <ReferenceField reference="order" source="order"><TextField variant="body1" source="code"/></ReferenceField></Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body1">Price: {record.price_info.amount} {record.price_info.currency}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <RichTextInput source="technical_note" fullWidth />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h5">Realization</Typography>
                    <Typography variant="body1">Start time: <RealizationStartDate record={record}/></Typography>
                    <Button
                      label="See realization"
                      alignIcon="left"
                      component={Link}
                      to={`/servicerealization/${record.item_object.service_realization}`}
                    >
                      <ExitToApp />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item container spacing={2} xs={4}>

              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid container item md={6} alignContent="flex-start" spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="h5">Supplier messages:</Typography>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: 0 }}>
                  <SupplierMessagePanel record={record} redirect={`/orderitem/${record.id}`} noDelete/>
                </Grid>
              </Grid>

              <Grid item md={6} style={{ height: '100%' }}>
                <OrderItemInfoInput record={record}/>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              {/* <Grid item xs={6} style={{ height: '100%' }}>
                <ServiceOfferingInfo record={record} />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid> */}
            </Grid>
          </SimpleForm>}
        </WithProps>
      </Edit>
    </>
  )
}

OrderItemEdit.propTypes = {
  record: PropTypes.object.isRequired,
  location: PropTypes.object,
  permissions: PropTypes.arrayOf(String)
}

export default OrderItemEdit
