
import * as React from 'react'
import { PropTypes } from 'prop-types'
import { Typography, Tooltip } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import { withStyles } from '@material-ui/core/styles'
import getExchangeRates from '../Utils/getExchangeRates'

const styles = {
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    border: '1px solid #dadde9',
    padding: 10
  }
}

const TooltipTitle = props => {
  const { fieldValue, currency } = props
  const exchangeRates = getExchangeRates()
  if (exchangeRates === 'ERROR' || exchangeRates === 'Loading') return exchangeRates

  return (
    <>
    {Object.values(exchangeRates).filter((d) => d.buy_currency === currency && d.sell_currency !== currency).map(d => {
      return (
      <Typography key={d.id} variant="body1">
        {(Math.ceil(d.rate * parseFloat(fieldValue) * 100) / 100).toFixed(2)} {d.sell_currency}
      </Typography>
      )
    })}
    </>
  )
}

const ConvertedPricesTooltip = props => {
  const { fieldValue, placement = 'right-start', tooltipSource = <Typography variant='body2'><InfoIcon color="primary"/></Typography>, ...rest } = props
  if (fieldValue === undefined) return ''
  return (
    <>
      <Tooltip {...rest}
        title={<TooltipTitle
          fieldValue={fieldValue}
          {...rest}
        />}
        placement={placement}
        interactive
      >
        {tooltipSource}
      </Tooltip>
    </>
  )
}

ConvertedPricesTooltip.propTypes = {
  fieldValue: PropTypes.string,
  placement: PropTypes.string,
  tooltipSource: PropTypes.object
}

TooltipTitle.propTypes = {
  fieldValue: PropTypes.string,
  currency: PropTypes.string
}

export default withStyles(styles)(ConvertedPricesTooltip)
