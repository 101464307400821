import * as React from 'react'
import { useGetList } from 'ra-core'
import {
  Card,
  CardContent,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography
} from '@material-ui/core'
import { Loading } from 'ra-ui-materialui'
import PropTypes from 'prop-types'
import { Form } from 'react-final-form'
import PriceInfoInput from '../../../Components/PriceInfoInput'
import {
  AutocompleteInput,
  Button,
  ReferenceManyField,
  useRefresh,
  useUpdate,
  TextField as RaTextField,
  ReferenceField,
  SelectInput,
  required,
  NumberInput,
  ReferenceInput
} from 'react-admin'
import { Save } from '@material-ui/icons'
import { PriceInfoField } from '../../../Components'
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid'
import DateField from '../../../Components/Datefield'
import getConfig from '../../../Components/core_config'
import { ChannelTextField } from '../../../Components/Fields'
import { EditableDatagridCustomActions } from '../../../Components/Actions'

const useStyles = makeStyles((theme) => ({
  loading: {
    maxHeight: '500px'
  }
}))

const getSPI = (soId) => {
  const { data, error, loading } = useGetList(
    'sellpriceitem',
    { page: 1, perPage: 1000 },
    { field: 'ident', order: 'ASC' },
    { service_offering: soId }
  )
  if (error || loading) return []
  return Object.values(data).map(spi => {
    const result = {}
    result.id = spi.ident
    return result
  })
}

const getCP = (soId) => {
  const { data, error, loading } = useGetList(
    'capacitypool',
    { page: 1, perPage: 1000 },
    { field: 'ident', order: 'ASC' },
    { service_offering: soId }
  )
  if (error || loading) return []
  return Object.values(data).map(spi => {
    const result = {}
    result.id = spi.ident
    return result
  })
}

const TicketAttributeField = props => {
  switch (props.ticketAttribute.type) {
    case 'choice':
      return <TicketAttributeChoice onChange={props.onChange} ticketAttribute={props.ticketAttribute} serviceOfferingId={props.serviceOfferingId} />
    case 'integer':
      return <TicketAttributeInteger onChange={props.onChange} ticketAttribute={props.ticketAttribute} serviceOfferingId={props.serviceOfferingId} />
    case 'bool':
      return <TicketAttributeBool onChange={props.onChange} ticketAttribute={props.ticketAttribute} serviceOfferingId={props.serviceOfferingId} />
    case 'SPI_choice':
      return <TicketAttributeSPIchoice onChange={props.onChange} ticketAttribute={props.ticketAttribute} serviceOfferingId={props.serviceOfferingId} />
    case 'CP_choice':
      return <TicketAttributeCPchoice onChange={props.onChange} ticketAttribute={props.ticketAttribute} serviceOfferingId={props.serviceOfferingId} />
    default:
      return `${props.ticketAttribute.type} not implemented |  ${props.ticketAttribute.ident}: ${props.ticketAttribute.value}`
  }
}

const TicketAttributeSPIchoice = props => {
  if (props.ticketAttribute.ident.match(/\$\d$/) === null) {
    return <TicketAttributeChoice {...props} />
  }
  return <CPselect {...props} />
}

const TicketAttributeCPchoice = props => {
  if (props.ticketAttribute.ident.match(/\$\d$/) === null) {
    return <CPselect {...props} />
  }
  return <TicketAttributeChoice {...props} />
}

const CPselect = props => {
  const CPchoices = getCP(props.serviceOfferingId)
  const [value, setValue] = React.useState(props.ticketAttribute.value.toUpperCase())
  const handleChange = (e) => {
    setValue(e.target.value)
    props.onChange(e.target.value)
  }
  return (
    <FormControl variant="filled">
      <InputLabel>{props.ticketAttribute.ident}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
      >
        {CPchoices.map(cp => <MenuItem key={cp.id} value={cp.id}>{cp.id}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

const TicketAttributeBool = props => {
  return (
    <FormControlLabel
    control={<Switch color="primary" checked={props.ticketAttribute.value === 'on'} onChange={(event) => props.onChange(event.target.checked ? 'on' : 'off') } />}
    label={`${props.ticketAttribute.ident}`}
    labelPlacement="start"
  />
  )
}

const TicketAttributeInteger = props => {
  const [value, setValue] = React.useState(props.ticketAttribute.value)
  const handleChange = (e) => {
    setValue(e.target.value)
    props.onChange(e.target.value)
  }
  return (
    <TextField
      label={props.ticketAttribute.ident}
      variant="filled"
      type="number"
      value={value}
      onChange={handleChange}
    />
  )
}

const TicketAttributeChoice = props => {
  const SPIchoices = getSPI(props.serviceOfferingId)
  const [value, setValue] = React.useState(props.ticketAttribute.value.toUpperCase())
  const handleChange = (e) => {
    setValue(e.target.value)
    props.onChange(e.target.value)
  }
  return (
    <FormControl variant="filled">
      <InputLabel>{props.ticketAttribute.ident}</InputLabel>
      <Select
        value={value}
        onChange={handleChange}
      >
        {SPIchoices.map(spi => <MenuItem key={spi.id} value={spi.id}>{spi.id}</MenuItem>)}
      </Select>
    </FormControl>
  )
}

const ExpenditureRowForm = props => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
  <RowForm {...props}>
    <SelectInput source="status" choices={config.expenditure_status} validate={required()} />
    <DateField source="created_at" />
    <DateField source="modified_at" />
    <ReferenceInput source="supplier" reference="supplier" >
      <AutocompleteInput optionText="name" />
    </ReferenceInput>
    <NumberInput source="price" validate={required()}/>
    <SelectInput source="currency" choices={config.currencies_choices} validate={required()} />
  </RowForm>
  )
}

const TicketInfos = props => {
  const classes = useStyles()
  const refresh = useRefresh()
  const [key, setKey] = React.useState(null)
  const { data, ids, error, loading } = useGetList(
    'ticket',
    { page: 1, perPage: 1000000 },
    { field: 'id', order: 'ASC' },
    { service_setup: props.record.id }
  )
  const a = useUpdate()
  const update = a[0]
  const errorUpdate = a[1].error
  const loadingUpdate = a[1].loading
  if (error || errorUpdate) return 'ERROR'
  if (loading) return (<Loading className={classes.loading}/>)
  const ticketsContent = []
  ids.forEach(id => { ticketsContent[id] = data[id].content })

  const handleUpdateTicket = (id) => {
    const newRecord = data[id]
    newRecord.content = ticketsContent[id]
    update('ticket', id, newRecord, data[id], {
      onSuccess: () => setTimeout(refresh, 500)
    })
  }

  const handlePriceChange = (f, v, id, spi) => {
    const spiKey = Object.keys(ticketsContent[id].sell_price_items).find(key => ticketsContent[id].sell_price_items[key].ident === spi.ident)
    ticketsContent[id].sell_price_items[spiKey][f] = v
  }

  const handleTicketChange = (f, v, id) => {
    const taKey = Object.keys(ticketsContent[id].attributes).find(key => ticketsContent[id].attributes[key].ident === f)
    const taOldValue = ticketsContent[id].attributes[taKey].value
    const spiKey = Object.keys(ticketsContent[id].sell_price_items).find(key => ticketsContent[id].sell_price_items[key].ident === taOldValue)
    ticketsContent[id].attributes[taKey].value = v
    if (ticketsContent[id].attributes[taKey].type === 'choice' || (ticketsContent[id].attributes[taKey].type === 'CP_choice' && f.match(/\$\d$/) !== null)) {
      ticketsContent[id].sell_price_items[spiKey].ident = v
      ticketsContent[id].sell_price_items[spiKey].value = v
    } else if (ticketsContent[id].attributes[taKey].type === 'integer') {
      ticketsContent[id].sell_price_items[taKey].value = v
      ticketsContent[id].sell_price_items[taKey].number = parseInt(v)
    } else if (ticketsContent[id].attributes[taKey].type === 'SPI_choice' && f.match(/\$\d$/) === null) {
      ticketsContent[id].sell_price_items[taKey].value = v
      ticketsContent[id].sell_price_items[taKey].ident = v
    }
    setKey(Math.random())
  }

  const firstTicket = data[ids[0]]
  if (firstTicket === undefined) {
    return (
    <>
    {props.children}
    {/* <Loading className={classes.loading}/> */}
      <span>NO TICKET</span>
    </>
    )
  }
  return (
    ids.map(id => {
      const ticket = data[id]
      return (
        <>
        {id === ids[0] ? props.children : <><Divider /><br/></>}
        <Typography>Summary: {ticket.display_summary}</Typography><br/>
        <Grid container xs={12}>
          <Grid item xs={4}>
            <Typography>Ticket attributes:</Typography>
            <ul>
            {ticketsContent[ticket.id] === undefined
              ? null
              : ticketsContent[ticket.id].attributes.map(ta => (
              <li key={ta.ident}>
                <TicketAttributeField onChange={(v) => handleTicketChange(ta.ident, v, ticket.id)} ticketAttribute={ta} serviceOfferingId={props.record.service_offering} />
              </li>
              ))}
            </ul><br/>
          </Grid>
          <Grid item xs={8}>
            <SPIContent key={key} ticketsContent={ticketsContent} ticket={ticket} handlePriceChange={handlePriceChange} />
          </Grid>
          <Grid item xs={12}>
            <Typography>Expenditures: </Typography>
          </Grid>
          <Grid item xs={12}>
            <ReferenceManyField record={ticket} reference="expenditure" target="ticket">
                <EditableDatagrid
                  actions={<EditableDatagridCustomActions/>}
                  createForm={<ExpenditureRowForm initialValues={{ ticket: ticket.id }} />}
                  editForm={<ExpenditureRowForm />}
                >
                  <RaTextField source="status" />
                  <DateField source="created_at" />
                  <DateField source="modified_at" />
                  <ReferenceField source="supplier" reference="supplier" link={false} >
                    <RaTextField source="email" />
                  </ReferenceField>
                  <PriceInfoField label="Price"/>
                  <div></div>
                </EditableDatagrid>
            </ReferenceManyField>
          </Grid>
        </Grid>
        <Button
          style={{ marginBottom: 20, marginTop: 20 }}
          onClick={() => handleUpdateTicket(ticket.id)}
          variant="contained"
          label="Save ticket"
          disabled={loadingUpdate}
        >
          <Save />
        </Button>
        </>
      )
    })
  )
}

const SPIContent = props => (
  <>
  <Typography>Sell price item:</Typography>
  <ul>
  {props.ticketsContent[props.ticket.id] === undefined
    ? null
    : props.ticketsContent[props.ticket.id].sell_price_items.map(spi => (
  <><Form key={spi.ident} initialValues={spi} onSubmit={() => {} } >
    {formProps => (
      <form id="spiForm" style={{ width: '100%' }} onSubmit={formProps.handleSubmit}>
          <li>
            <span>{spi.ident}: {spi.value}</span><br/>
            {spi.value === '0' || spi.value === 'off'
              ? <br/>
              : <><span>Price: </span><br/>
            <PriceInfoInput onChange={(f, v) => props.handlePriceChange(f, v, props.ticket.id, spi)} source="" record={spi} /></>}
          </li>
      </form>
    )}
  </Form></>
    ))}
</ul></>
)

const OrderItemInfoInput = props => {
  const classes = useStyles()
  if (props.record === undefined || props.record.item_object === undefined || props.record.item_object.servicesetup_content === undefined) return <></>
  const { data, error, loading } = useGetList(
    'servicesetup',
    { page: 1, perPage: 1 },
    { field: 'id', order: 'ASC' },
    { order_item: props.record.id }
  )
  if (error) return ('ERROR')
  if (loading) {
    return (
      <>
        <Card style={{ marginBottom: 5, marginTop: 50 }} variant="outlined">
          <CardContent style={{ padding: 16 }}>
              <Loading className={classes.loading}/>
          </CardContent>
          </Card>
      </>)
  }
  const setup = data[Object.keys(data)[0]]
  return (
      <>
      <Typography variant="h5" style={{ margin: 9 }}>Setup attributes:</Typography>
      <ul>
        {props.record.item_object.servicesetup_content.attributes.map(attribute =>
          attribute.value !== undefined && attribute.value.length > 0
            ? <li key={attribute.title}>
            <ChannelTextField record={attribute} placement="top" source="title" editable/>
            <ChannelTextField record={attribute} placement="top" source="value" editable/>
          </li>
            : null
        )}
      </ul>

      <Typography variant="h5" style={{ margin: 9 }}>Tickets:</Typography>
      <Card style={{ marginBottom: 5 }} variant="outlined">
        <CardContent style={{ padding: 16 }}>
          {setup === undefined
            ? <span>NO SETUP</span>
            : <TicketInfos order={props.record.order} record={setup} />}
        </CardContent>
      </Card>
      </>
  )
}

TicketInfos.propTypes = {
  record: PropTypes.object.isRequired,
  children: PropTypes.object
}

OrderItemInfoInput.propTypes = {
  record: PropTypes.object.isRequired
}

TicketAttributeChoice.propTypes = {
  serviceOfferingId: PropTypes.number.isRequired,
  ticketAttribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

TicketAttributeInteger.propTypes = {
  serviceOfferingId: PropTypes.number.isRequired,
  ticketAttribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

TicketAttributeBool.propTypes = {
  serviceOfferingId: PropTypes.number.isRequired,
  ticketAttribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

TicketAttributeSPIchoice.propTypes = {
  serviceOfferingId: PropTypes.number.isRequired,
  ticketAttribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

TicketAttributeCPchoice.propTypes = {
  serviceOfferingId: PropTypes.number.isRequired,
  ticketAttribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

CPselect.propTypes = {
  serviceOfferingId: PropTypes.number.isRequired,
  ticketAttribute: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired
}

SPIContent.propTypes = {
  ticketsContent: PropTypes.array.isRequired,
  ticket: PropTypes.object.isRequired,
  handlePriceChange: PropTypes.func.isRequired
}

export default OrderItemInfoInput
