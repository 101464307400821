import * as React from 'react'
import {
  // DeleteWithConfirmIconButton,
  EditRowButton
} from '@react-admin/ra-editable-datagrid'
import { Confirm, useResourceContext, useDelete, useRecordContext, useNotify, useRefresh } from 'react-admin'

import { IconButton } from '@material-ui/core'
import ActionDelete from '@material-ui/icons/Delete'

const DeleteConfirmButton = props => {
  const resource = useResourceContext()
  const record = useRecordContext()
  const [open, setOpen] = React.useState(false)
  const [deleteOne, { loading }] = useDelete(resource, record.id)
  const notify = useNotify()
  const refresh = useRefresh()

  const handleDialogOpen = () => {
    setOpen(true)
  }
  const handleDialogClose = () => {
    setOpen(false)
  }

  const handleDelete = (event) => {
    event.stopPropagation()
    deleteOne(
      { payload: { id: record.id } },
      {
        onSuccess: ({ data }) => {
          setOpen(false)
          notify('ra.notification.deleted', 'info', { smart_count: 1 })
          refresh()
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        }
      }
    )
  }

  return (
    <>
      <IconButton
        onClick={handleDialogOpen}
        className='ra-delete-button'
        key="button"
        size="small"
      >
        <ActionDelete color="error" />
      </IconButton>
      <Confirm
        loading={loading}
        isOpen={open}
        title='Delete'
        content='ra.message.delete_content'
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  )
}

const EditableDatagridCustomActions = props => {
  return (
  <>
    <EditRowButton/>
    <DeleteConfirmButton {...props}/>
  </>
  )
}

export default EditableDatagridCustomActions
