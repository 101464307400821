import * as React from 'react'
import get from 'lodash/get'
import { useRecordContext } from 'ra-core'
import { TextField } from 'react-admin'
import { Typography } from '@material-ui/core'

// this component is used to display `min_lead_time` by converting value from seconds to hours
const HoursField = props => {
  const { className, source, emptyText, ...rest } = props

  const record = useRecordContext(props)
  const value = get(record, source)

  return (
    <Typography
      {...rest}
    >
        { value / 3600 }&nbsp;h
    </Typography>

  )
}

HoursField.propTypes = {
  ...Typography.propTypes,
  ...TextField.propTypes
}

export default HoursField
