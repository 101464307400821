import * as React from 'react'
import PropTypes from 'prop-types'
import {
  AutocompleteInput,
  DateInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'
import { JsonInput } from 'react-admin-json-view'
import { Typography } from '@material-ui/core'

import getConfig from '../../Components/core_config'
import DateField from '../../Components/Datefield'

const AccoutingDocumentEditForm = (props) => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <SimpleForm record={props.record} {...props}>
      <Typography variant="h6">Issue Date:  <DateField variant="h6" source="issue_date"/></Typography>
      <DateInput source="vat_date" validate={required()}/>
      <DateInput source="due_date" validate={required()}/>
      <SelectInput
        choices={config.document_type}
        source="document_type"
        validate={required()}
      />
      <ReferenceInput label="Order" source="order" reference="order">
        <AutocompleteInput
          optionText={(record) => `${record.code}: ${record.status}`}
        />
      </ReferenceInput>
      <ReferenceInput label="Customer" source="customer" reference="customer">
        <AutocompleteInput
          optionText={(record) => `${record.id}: ${record.name}`}
        />
      </ReferenceInput>
      <ReferenceInput label="Payment" source="payment" reference="payment">
        <AutocompleteInput
          optionText={(record) =>
            `${record.reference_number} : ${record.status}`
          }
        />
      </ReferenceInput>
      <NumberInput source="amount" />
      <SelectInput source="currency" choices={config.currencies_choices} />
      <RichTextInput source="technical_note" />
      <JsonInput source="content" />
    </SimpleForm>
  )
}

AccoutingDocumentEditForm.propTypes = {
  record: PropTypes.object
}

export default AccoutingDocumentEditForm
