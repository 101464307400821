
import PropTypes from 'prop-types'

import React from 'react'
import {
  SelectInput,
  Button,
  fetchStart,
  fetchEnd,
  useNotify,
  useRefresh,
  FormWithRedirect,
  SaveButton
} from 'react-admin'
import { API_URL, httpClient } from '../../../dataProvider'
import { useDispatch } from 'react-redux'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const getServiceBase = async (socIds) => {
  if (socIds.length === 0) return []
  // use first selected item and resolve it's list of available price configs (ServiceOfferingConfig)
  const data = await httpClient(`${API_URL}/serviceoffering/${socIds[0]}`, { method: 'GET' })
    .then((response, data) => {
      return httpClient(`${API_URL}/servicebase/${response.json.service_base}`, { method: 'GET' })
    })
    .then((response, data) => {
      return response.json
    })
  return data
}

const BulkBatchEditDialog = props => {
  const { open, handleClose, selectedIds, resource } = props
  const dispatch = useDispatch()
  const refresh = useRefresh()
  const notify = useNotify()
  const hooks = { dispatch: dispatch, notify: notify }

  const [serviceBase, setServiceBase] = React.useState({ service_configs: [], service_templates: [] })
  const [soc, setSOC] = React.useState(null)
  const [sot, setSOT] = React.useState(null)
  const [status, setStatus] = React.useState(null)

  React.useEffect(() => {
    getServiceBase(selectedIds)
      .then(r => setServiceBase(r))
  }, [selectedIds])

  const submitData = () => {
    hooks.dispatch(fetchStart())
    const data = {
      ids: selectedIds,
      serviceOfferingTemplate: sot,
      serviceOfferingConfig: soc,
      status: status
    }
    httpClient(API_URL + '/' + resource + '/batchedit', {
      method: 'POST',
      body: JSON.stringify(data)
    })
      .then((response) => {
        if (response.status !== 200) hooks.notify('Error with the API (' + response.status + ')', 'error')
        else {
          refresh()
          hooks.notify('Batch edit of ' + resource + ' finished')
        }
      })
      .catch((e) => {
        hooks.notify('Error: can\'t batch edit', 'error')
      })
      .finally(() => {
        hooks.dispatch(fetchEnd()) // stop the global loading indicator
        handleClose()
      })
  }

  return (
    <FormWithRedirect
      save={submitData}
      render={({
        handleSubmitWithRedirect,
        saving
      }) => (
        <Dialog
          fullWidth
          open={open}
          onClose={handleClose}
          aria-label="Batch edit"
        >
          <DialogTitle>Batch edit</DialogTitle>
          <DialogContent>
            <p>Will change {selectedIds.length} items, leave empty for no change</p>
            {/* TODO load status choices from core services.models.SERVICE_OFFERING_STATUSES */}
            <SelectInput label="Status" optionText="ident" resettable fullWidth choices={[{ id: '', ident: '-' }, { id: 'enabled', ident: 'enabled' }, { id: 'disabled', ident: 'disabled' }, { id: 'archived', ident: 'archived' }, { id: 'cancelled', ident: 'cancelled' }]} source="status" onChange={e => e ? setStatus(e.target.value) : null} />
            <SelectInput label="Prices" optionText="ident" resettable fullWidth choices={serviceBase.service_configs} source="soc" onChange={e => e ? setSOC(e.target.value) : null} /> <br />
            <SelectInput label="Attributes" optionText="ident" resettable fullWidth choices={serviceBase.service_templates} source="sot" onChange={e => e ? setSOT(e.target.value) : null} />
          </DialogContent>
          <DialogActions>
            <Button
              label="ra.action.cancel"
              onClick={handleClose}
            >
              <IconCancel />
            </Button>
            <SaveButton
                label="ra.action.save"
                handleSubmitWithRedirect={
                    handleSubmitWithRedirect
                }
                saving={saving}
            />
          </DialogActions>
        </Dialog>
      )}
    />
  )
}

BulkBatchEditDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  resource: PropTypes.string,
  resourceToRedirect: PropTypes.string
}

export default BulkBatchEditDialog
