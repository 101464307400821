import React from 'react'
import {
  BooleanInput,
  Create,
  FormDataConsumer,
  ReferenceInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useTranslate
} from 'react-admin'
import { TextField } from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { toKebabCase } from '../../Utils/Format'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { templateInitialFieldValues } from './utils'
import validateSlug from './validateSlug'

const PublicationCreate = ({ permissions, ...props }) => {
  const translate = useTranslate()
  const transform = data => ({
    ...data,
    slug: data.auto_slug ? toKebabCase(data.title) : data.slug
  })
  useDefineAppLocation('content.publication.create')
  return (
    <>
      <Create {...props} transform={transform}>
        <SimpleForm initialValues={props.location.filterValues} >
          <TextInput source="ident" helperText="resources.publication.helper.ident" validate={required()} fullWidth/>
          <TextInput source="technical_name" fullWidth/>
          <TextInput source='publication_template_ident' helperText="resources.publication.helper.publication_template_ident" fullWidth />
          <TextInput source="title" helperText="resources.publication.helper.title" validate={required()} fullWidth />
          <ReferenceInput source="channel" reference="channel" validate={required()} fullWidth>
            <SelectInput optionText="ident" helperText="resources.publication.helper.channel"/>
          </ReferenceInput>
          <BooleanInput source="auto_slug" helperText="resources.publication.helper.auto_slug" initialValue={false} />
          <FormDataConsumer>
            {({ formData }) => {
              templateInitialFieldValues(formData)
            }}
          </FormDataConsumer>
          <FormDataConsumer>
            {({ formData, ...rest }) => {
              const autoValue = toKebabCase(formData.title)
              const [value, setValue] = React.useState('')
              const [error, setError] = React.useState(false)
              const [errorMessage, setErrorMessage] = React.useState('')
              const validateSlugHook = (v) => {
                validateSlug(v, formData).then((res) => {
                  if (res !== undefined) {
                    setError(true)
                    setErrorMessage(res)
                  } else {
                    setError(false)
                    setErrorMessage('')
                  }
                })
              }
              const handlChange = (e) => {
                setValue(e.target.value)
                validateSlugHook(e.target.value)
              }
              validateSlugHook(autoValue)
              return <TextField
                label="Slug"
                onChange={handlChange}
                disabled={formData.auto_slug}
                variant="filled"
                margin="dense"
                value={formData.auto_slug ? autoValue : value}
                helperText={errorMessage.length > 0 ? errorMessage : translate('resources.publication.helper.slug')}
                error={error}
                fullWidth
              />
            }}
          </FormDataConsumer>
        </SimpleForm>
      </Create>
    </>
  )
}

PublicationCreate.propTypes = {
  location: PropTypes.object,
  record: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(String).isRequired
}

export default PublicationCreate
