import * as React from 'react'
import { Fragment, memo } from 'react'

import { useMediaQuery } from '@material-ui/core'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  EditButton,
  Filter,
  List,
  SearchInput,
  SimpleList,
  TextField,
  TextInput,
  EmailField,
  ReferenceManyField,
  Pagination
} from 'react-admin'

import ListActions from '../../Components/Toolbar/ListActions'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'

const CustomerFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput
      source="name"
      defaultValue=""
    />
    <TextInput
      source="email"
      defaultValue=""
    />
    <TextInput
      source="phone"
      defaultValue=""
    />
  </Filter>
)

// eslint-disable-next-line react/display-name
const CustomerListBulkActions = memo(props => (
  <Fragment>
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const CustomerPanel = props => (
  <>
    <TextField source="note" {...props} />
    <ReferenceManyField target="parent" reference="customer" {...props}>
      <Datagrid rowClick="edit">
        <TextField source="customer_type"/>
        <TextField source="name"/>
        <EmailField source="email"/>
        <TextField source="phone" />
      </Datagrid>
    </ReferenceManyField>
  </>
)

const CustomerList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('customer.list')
  return (
    <>
      <List
        {...props}
        actions={<ListActions />}
        bulkActionButtons={<CustomerListBulkActions />}
        filters={<CustomerFilter />}
        sort={{ field: 'name', order: 'ASC' }}
        exporter={(data) => exporter(data, 'Customer')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      >
        {isSmall
          ? (
          <SimpleList
            primaryText={record => record.name + ' ' }
            secondaryText={record => record.email}
          />
            )
          : (
          <Datagrid rowClick="edit" expand={<CustomerPanel />} optimized>
            <TextField source="id" />
            <TextField source="customer_type" />
            <TextField source="name" />
            <EmailField source="email"/>
            <TextField source="phone" />
            <ListActionToolbar>
              <EditButton />
            </ListActionToolbar>
          </Datagrid>
            )}
      </List>
    </>
  )
}

CustomerPanel.propTypes = {
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  resource: PropTypes.object
}

export default CustomerList
