import * as React from 'react'

import { NumberField } from 'react-admin'

import AbstractCapacityPoolList from './AbstractCapacityPoolList'

const capacityPoolColumns = {
  capacity: <NumberField source="capacity" textAlign="left" />
}

const CapacityPoolList = (props) => {
  return (
    <AbstractCapacityPoolList
      reference="capacitypool"
      columns={capacityPoolColumns}
      {...props}
    />
  )
}

export default CapacityPoolList
