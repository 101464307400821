import * as React from 'react'
import {
  Edit,
  SimpleForm,
  ReferenceManyField,
  Datagrid
} from 'react-admin'

import { EditActions } from '../../Components'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import { getOrderColumns } from '../../Orders'

import {
  Grid,
  Typography
} from '@material-ui/core'
import CustomerBaseForm from './Forms'

const CustomerEdit = ({ permissions, ...props }) => {
  useDefineAppLocation('customer.list.edit')
  const orderColumns = getOrderColumns(
    'customer.detail.orders.column',
    ['id', 'technical_note', 'modified_at', 'created_at', 'user', 'customer']
  )
  const basketColumns = getOrderColumns(
    'customer.detail.baskets.column',
    ['id', 'technical_note', 'modified_at', 'checkout_at', 'user', 'customer']
  )
  return (
  <>
    <Edit actions={<EditActions />} {...props}>
      <SimpleForm warnWhenUnsavedChanges toolbar={<SaveDeleteToolbar deleteLabel="name" />}>
      <Grid container style={{ width: '100%' }} spacing={4} xs={12}>
          <Grid item md={4}>
            <CustomerBaseForm/>
          </Grid>
          <Grid item md={8}>
            <Typography variant="h5">Orders</Typography>
            <ReferenceManyField reference="order" target="customer" filter={{ admin_orders: true }}>
              <Datagrid rowClick="edit">
                {orderColumns}
              </Datagrid>
            </ReferenceManyField>
            <br/>
            <Typography variant="h5">Basket</Typography>
            <ReferenceManyField reference="order" target="customer" filter={{ admin_baskets: true }}>
              <Datagrid rowClick="edit">
                {basketColumns}
              </Datagrid>
            </ReferenceManyField>
          </Grid>
        </Grid>
      </SimpleForm>
    </Edit>
  </>
  )
}

CustomerEdit.propTypes = {
  permissions: PropTypes.arrayOf(String).isRequired
}

export default CustomerEdit
