import * as React from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  SearchInput,
  SelectInput,
  TextInput,
  Pagination
} from 'react-admin'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActions from '../../Components/Toolbar/ListActions'
import exporter from '../../Components/exporter'
import { ChannelTextDatagrid } from './Components'

const translatedStatuses = [
  { id: 'ALL', name: 'ALL' },
  { id: 'NON-TRANSLATED', name: 'NON-TRANSLATED' },
  { id: 'TRANSLATED', name: 'TRANSLATED' }
]

const ChannelTextFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput
      source="value"
      defaultValue=""
    />
    <TextInput
      source="search_key"
      defaultValue=""
    />
    <SelectInput choices={translatedStatuses} source="translated_status" alwaysOn/>
    <ReferenceInput source="channel" reference="channel" alwaysOn>
      <SelectInput optionText="ident"/>
    </ReferenceInput>
  </Filter>
)

const ChannelTextForm = props => {
  return (
  <>
    <ReferenceInput source="channel" reference="channel">
      <SelectInput optionText="ident" helperText="resources.channeltext.helper.channel" fullWidth/>
    </ReferenceInput>
    <TextInput source="language" helperText="resources.channeltext.helper.language" fullWidth />
    <TextInput source="key" helperText="resources.channeltext.helper.key" fullWidth />
    <TextInput source="value" defaultValue="" helperText="resources.channeltext.helper.value" multiline fullWidth />
  </>
  )
}

const ChannelTextList = props => {
  useDefineAppLocation('settings.channeltext')
  return (
    <>
      <List
        {...props}
        actions={<ListActions noCreate/>}
        bulkActionButtons={false}
        filters={<ChannelTextFilter />}
        sort={{ field: 'key', order: 'ASC' }}
        exporter={(data) => exporter(data, 'ChannelText')}
        empty={false}
        hasCreate
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      >
        <ChannelTextDatagrid
          defaultValues={props.location.filterValues}
          form={<ChannelTextForm />}
          showChannel
        />
      </List>
      <div style={{ height: '200px' }}>

      </div>
    </>
  )
}

ChannelTextList.propTypes = {
  location: PropTypes.object.isRequired
}

export default ChannelTextList
