import * as React from 'react'
import {
  ReferenceField,
  FunctionField,
  TextField
} from 'react-admin'
import { ChannelTextTooltip } from '../../../Components/Tooltips'
import { PropTypes } from 'prop-types'
import { DatagridWithDialog } from '../../../Components/Dialogs'

const Datagrid = props => {
  const { showChannel, ...rest } = props
  return (
    <DatagridWithDialog
      noRefresh
      {...rest}
    >
      {showChannel
        ? <ReferenceField source="channel" reference="channel" link={false}>
        <TextField source="ident" />
      </ReferenceField>
        : null}
      <TextField source="language" />
      <TextField source="key" />
      <TextField source="value" />
      <FunctionField label="Others" render={record =>
        <ChannelTextTooltip
          placement="left-start"
          fieldValue={record.key}
          excludedChannel={record.channel}
          editable
        />}
      />
    </DatagridWithDialog>
  )
}

Datagrid.propTypes = {
  record: PropTypes.object,
  showChannel: PropTypes.bool
}

export default Datagrid
