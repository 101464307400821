import React from 'react'
import {
  Create,
  SimpleForm
} from 'react-admin'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import MenuItemForm from './Form'

const MenuItemCreate = ({ permissions, ...props }) => {
  useDefineAppLocation('content.menuitem.create')
  return (
  <>
    <Create {...props}>
      <SimpleForm initialValues={props.location.filterValues}>
        <MenuItemForm/>
      </SimpleForm>
    </Create>
  </>
  )
}

MenuItemCreate.propTypes = {
  location: PropTypes.object,
  permissions: PropTypes.arrayOf(String).isRequired
}

export default MenuItemCreate
