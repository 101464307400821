import * as React from 'react'

import { Layout } from '@react-admin/ra-enterprise'
import { AppLocationContext } from '@react-admin/ra-navigation'
import { PropTypes } from 'prop-types'
import { Box, Typography } from '@material-ui/core'
import tours from './tours'
import * as Sentry from '@sentry/react'
import {
  Error,
  History
} from '@material-ui/icons'
import { AppBar, Button, Title } from 'ra-ui-materialui'
import BreadcrumbFromLocation from './Components/BreadcrumbFromLocation'
import { CurrencyExchangeToolBar } from './Components/Toolbar'
import { useTranslate } from 'ra-core'
import { useSetLocale } from 'react-admin'
import { usePreferences } from '@react-admin/ra-preferences'
import { makeStyles } from '@material-ui/core/styles'

import MenuUI from './Menu'
import Logo from './Logo'

const useStyles = makeStyles({
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  }
})

const MyAppBar = props => {
  const classes = useStyles()
  return (
    <AppBar {...props}>
      <Box flex="1" style={{ display: 'flex' }}>
        <Typography variant="h6" className={classes.title}>
          <Logo/>
        </Typography>
        <CurrencyExchangeToolBar/>
      </Box>
    </AppBar>
  )
}

const MyError = ({
  error,
  errorInfo,
  ...rest
}) => {
  const translate = useTranslate()
  Sentry.captureException(error, errorInfo)
  return (
    <div>
      <Title title="Error" />
      <h1><Error /> Something Went Wrong </h1>
      <div>A client error occurred and your request couldnt be completed.</div>
      {process.env.NODE_ENV !== 'production' && (
        <details>
          <h2>{translate(error.toString())}</h2>
          {errorInfo.componentStack}
        </details>
      )}
      <div>
        <Button
          variant="contained"
          startIcon={<History />}
          onClick={() => history.go(-1)}
          label="Back"
        />
      </div>
    </div>
  )
}

const customLayout = ({ children, ...props }) => {
  const setLocale = useSetLocale()
  const locale = usePreferences('locale')[0]
  React.useEffect(() => {
    if (locale) setLocale(locale)
  }, [setLocale])
  // TODO fix Typography bellow - broken on custom title in Publication edit - PublicationEditTitle
  return (
    <AppLocationContext>
      <Layout {...props} menu={MenuUI} appBar={MyAppBar} tours={tours} error={MyError}>
        <BreadcrumbFromLocation />
        <Typography color="primary" variant="h4" id="react-admin-title" />
        {children}
      </Layout>
    </AppLocationContext>
  )
}

MyError.propTypes = {
  error: PropTypes.object,
  errorInfo: PropTypes.object
}

export default customLayout
