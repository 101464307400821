import OrderItemList from './List'
import OrderItemEdit from './Edit'
import OrderItemCreate from './Create'

import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket'

export default {
  list: OrderItemList,
  edit: OrderItemEdit,
  create: OrderItemCreate,
  icon: ShoppingBasketIcon
}

export { default as InfoPanel } from './Components/OrderItemInfoPanel'
