import ServiceBaseList from './List'
import ServiceBaseEdit from './Edit'
import ServiceBaseCreate from './Create'

import ImportContactsIcon from '@material-ui/icons/ImportContacts'

export default {
  list: ServiceBaseList,
  edit: ServiceBaseEdit,
  create: ServiceBaseCreate,
  icon: ImportContactsIcon
}

export { default as Card } from './Components/Card'
