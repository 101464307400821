import * as React from 'react'

import { FunctionField } from 'react-admin'

import PropTypes from 'prop-types'

const PriceField = props => {
  return (
    <FunctionField label={props.label} render={record => <span>{record[props.priceSource]} {record.currency} <br/>vat: {record.vat_rate}%</span>}/>
  )
}

PriceField.propTypes = {
  label: PropTypes.string,
  priceSource: PropTypes.string
}

export default PriceField
