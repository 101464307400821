import * as React from 'react'
import {
  Button,
  SimpleForm,
  ReferenceInput,
  useGetList,
  AutocompleteInput,
  required,
  TextInput
} from 'react-admin'
import { Link } from 'react-router-dom'
import { CompleteCalendar } from '@react-admin/ra-calendar'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import BulkCloneButton from './Components/BulkCloneButton'
import { FilterWithSave, usePreferences } from '@react-admin/ra-preferences'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import ListActions from '../../Components/Toolbar/ListActions'
import { makeStyles } from '@material-ui/core'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import { CALENDAR_DATE_OPTIONS, DateTimeInput } from '../../Components'
import { useGenColor } from '../../Components/Utils'

const useStyles = makeStyles((theme) => ({
  root: {
    '& td.fc-day-sat, td.fc-day-sun ': {
      backgroundColor: theme.palette.action.selected
    }
  }
}))

const ServiceOfferingFilter = props => (
  <FilterWithSave {...props}>
    <ReferenceInput source="service_base" reference="servicebase" alwaysOn>
      <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable/>
    </ReferenceInput>
  </FilterWithSave>
)

const getTitles = () => {
  const { data, ids } = useGetList(
    'servicebase',
    { page: 1, perPage: 10 },
    {}
  )
  const rMap = new Map()
  ids.forEach(id => {
    rMap.set(data[id].id, data[id].ident)
  })
  return rMap
}

const ServiceOfferingCalendar = props => {
  const titles = getTitles()
  const classes = useStyles()
  useDefineAppLocation('catalog.calendar')
  const strWoResource = String(props.location.pathname).replace('/offeringcalendar/', '')
  const firstDay = usePreferences('calendar.firstday', 1)[0]
  return (
  <CompleteCalendar {...props}
    ListProps={{
      title: 'Offering calendar',
      actions: <ListActions noReset {...props} />,
      filters: <ServiceOfferingFilter/>
    }}
    CalendarProps={{
      classes: classes,
      convertToEvent: (event) => ({
        id: String(event.id),
        title: titles.get(event.service_base),
        start: event.realization_start_from,
        end: event.realization_start_to,
        backgroundColor: useGenColor(titles.get(event.service_base))
      }),
      eventTimeFormat: CALENDAR_DATE_OPTIONS,
      firstDay: firstDay
    }}
    CreateDialogProps={{
      fullWidth: true,
      title: 'Create service offering'
    }}
    EditDialogProps={{
      fullWidth: true,
      title: 'Edit service offering'
    }}
  >
    <SimpleForm toolbar={<SaveDeleteToolbar />}>
      <TextInput source="ident" />
      <ReferenceInput label="Service base" fullWidth source="service_base" reference="servicebase">
        <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable validate={required()}/>
      </ReferenceInput>
      <DateTimeInput
        source="realization_start_from"
        validate={required()}
      />
      <DateTimeInput
      source="realization_start_to"
      />
      <Button startIcon={<ExitToAppIcon />} label="Go to service offerings" component={Link} to={`/serviceoffering/${strWoResource}`}/>
      {isNaN(parseInt(strWoResource)) ? null : <BulkCloneButton resource="serviceoffering" resourceToRedirect="calendar" selectedIds={[parseInt(strWoResource)]} />}
    </SimpleForm>
  </CompleteCalendar>
  )
}

ServiceOfferingCalendar.propTypes = {
  location: PropTypes.object.isRequired,
  pathname: PropTypes.node.isRequired
}

export default ServiceOfferingCalendar
