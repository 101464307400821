import React from 'react'
import { PropTypes } from 'prop-types'
import { useInput, useTranslate } from 'react-admin'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { InputHelperText } from 'ra-ui-materialui'

const TextInputWithSuggestions = props => {
  const {
    input: { defaultValue, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props)
  const translate = useTranslate()
  return (
      <Autocomplete
        defaultValue={rest.value}
        freeSolo
        options={props.choices.map((option) => option.id)}
        onChange={(event, newValue) => onChange(newValue)}
        renderInput={({ fullWidth, ...params }) => (
          <TextField
            {...params}
            {...rest}
            label={props.label ? props.label : translate(`resources.${props.resource}.fields.${props.source}`, { _: String(props.source).charAt(0).toUpperCase() + String(props.source).slice(1) })}
            onChange={onChange}
            error={!!(touched && error)}
            helperText={
              <InputHelperText
                  touched={touched}
                  error={error}
                  helperText={props.helperText}
              />
            }
            variant="filled"
            margin="dense"
            required={isRequired}
            fullWidth={props.fullWidth}
          />
        )}
      />
  )
}

TextInputWithSuggestions.propTypes = {
  choices: PropTypes.array.isRequired,
  helperText: PropTypes.string.isRequired,
  resource: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool
}

export default TextInputWithSuggestions
