import * as React from 'react'

import {
  LinearProgress,
  useGetList
} from 'react-admin'

export const getChannelTemplate = template => {
  const { data, loading, error } = useGetList(
    'channeltemplate',
    { page: 1, perPage: 1 },
    { field: 'id', order: 'ASC' },
    { ident: template }
  )
  if (error) return 'ERROR'
  if (loading) return (<LinearProgress />)
  const templateRecord = data[Object.keys(data)[0]]
  return templateRecord
}

const getTemplates = () => {
  const { data, ids, loading, error } = useGetList(
    'channeltemplate',
    { page: 1, perPage: 100000 },
    { field: 'ident', order: 'ASC' }
  )
  return { data, ids, loading, error }
}

export const templateInitialFieldValues = (formData) => {
  const { data, ids, loading, error } = getTemplates()
  formData.content = {}
  if (loading) return ([])
  if (error) return ('ERROR')
  if (formData.template !== undefined) {
    const selectedTemplate = ids.filter(id => data[id].ident === formData.template).map(id => data[id])
    if (selectedTemplate.length) {
      for (const key of Object.keys(selectedTemplate[0].config)) {
        if (!Array.isArray(selectedTemplate[0].config[key])) {
          formData.content[key] = selectedTemplate[0].config[key].default_value
        } else {
          for (const arKey of Object.keys(selectedTemplate[0].config[key])) {
            formData.content[selectedTemplate[0].config[key][arKey].field_name] = ''
          }
        }
      }
    }
  }
}
