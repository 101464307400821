import * as React from 'react'
import { useMediaQuery } from '@material-ui/core'
import {
  DateTimeInput,
  Filter,
  List,
  ReferenceField,
  ReferenceInput,
  SearchInput,
  SelectInput,
  SimpleList,
  TextField,
  TextInput,
  Pagination
} from 'react-admin'
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid'

import PropTypes from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import exporter from '../../Components/exporter'
import DateField from '../../Components/Datefield'
import { EditableDatagridCustomActions } from '../../Components/Actions'

const RedirectFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <TextInput
      source="source"
      defaultValue=""
    />
    <TextInput
      source="target"
      defaultValue=""
    />
    <ReferenceInput source="channel" reference="channel">
      <SelectInput optionText="ident" />
    </ReferenceInput>
  </Filter>
)

const RedirectForm = props => (
  <RowForm {...props}>
    <TextInput source="source" helperText="resources.redirect.helper.source" fullWidth/>
    <TextInput source="target" helperText="resources.redirect.helper.target" fullWidth/>
    <DateTimeInput disabled source="updated_at" helperText="resources.redirect.helper.updated_at" showTime fullWidth/>
    <ReferenceInput source="channel" reference="channel">
      <SelectInput optionText="ident" helperText="resources.redirect.helper.channel" fullWidth/>
    </ReferenceInput>
  </RowForm>
)

const RedirectList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('content.redirect')
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        filters={<RedirectFilter />}
        sort={{ field: 'updated_at', order: 'DESC' }}
        exporter={(data) => exporter(data, 'Redirect')}
        hasCreate
        empty={false}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      >
        {isSmall
          ? (
          <SimpleList
            primaryText={record => record.source}
            secondaryText={record => record.target}
            tertiaryText={record =>
              <ReferenceField source="channel" reference="channel" basePath="channel" record={record} link={true}>
                <TextField source="ident"/>
              </ReferenceField>
            }
          />
            )
          : (
          <EditableDatagrid
            actions={<EditableDatagridCustomActions/>}
            createForm={<RedirectForm />}
            editForm={<RedirectForm />}
          >
            <TextField source="source"/>
            <TextField source="target"/>
            <TextField source="usage_counter" />
            <DateField source="updated_at" />
            <ReferenceField source="channel" reference="channel">
              <TextField source="ident"/>
            </ReferenceField>
          </EditableDatagrid>
            )}
      </List>
    </>
  )
}

RedirectList.propTypes = {
  record: PropTypes.node.isRequired
}

export default RedirectList
