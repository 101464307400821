
import * as React from 'react'
import { PropTypes } from 'prop-types'
import { useDispatch } from 'react-redux'

import { API_URL, httpClient } from '../../dataProvider'
import { useNotify, fetchEnd, fetchStart } from 'ra-core'

import { useRefresh, Button } from 'react-admin'

import SendIcon from '@material-ui/icons/Send'
import CancelIcon from '@material-ui/icons/Cancel'

import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'

const ResendMessage = props => {
  const { record } = props
  const [open, setOpen] = React.useState(false)
  const [messageContent, setMessageContent] = React.useState(record.message)
  const dispatch = useDispatch()
  const notify = useNotify()
  const refresh = useRefresh()
  const hooks = { dispatch: dispatch, notify: notify }
  const url = API_URL + '/resendsuppliermessage'
  const resendSupplierMessage = (e) => {
    e.stopPropagation()
    hooks.dispatch(fetchStart())
    httpClient(url, {
      method: 'POST',
      body: JSON.stringify({
        messageId: record.id,
        messageContent: messageContent
      })
    }).then((response, data) => {
      response.status !== 201 ? hooks.notify('Error in API', 'error') : hooks.notify('New meesage sent', 'info')
      hooks.dispatch(fetchEnd())
      refresh()
    })
  }

  const handleOpen = () => {
    setMessageContent(record.message)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button label="Resend" onClick={handleOpen} alignIcon="left">
        <SendIcon/>
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth
      >
        <DialogTitle>Resend Supplier Message</DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Supplier message"
            value={messageContent}
            onChange={(e) => setMessageContent(e.target.value)}
            multiline
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button label="ra.action.cancel" onClick={handleClose} alignIcon="left">
            <CancelIcon/>
          </Button>
          <Button label="Resend" onClick={resendSupplierMessage} alignIcon="left">
            <SendIcon/>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

ResendMessage.propTypes = {
  record: PropTypes.object
}

export default ResendMessage
