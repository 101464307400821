import React from 'react'

export const serviceBaseTours = {
  create: {
    steps: [
      {
        target: 'input[id=ident]',
        content: 'Fill in the ident to reference your new ServiceBase (e.g. Grand Tour de Prague)'
      },
      {
        target: '[id=realization_start_selector]',
        content: 'Choose a selector to display to the customers when they will choose service\'s time'
      },
      {
        target: '[id=realization_strategy]',
        content: (
          <div style={{ textAlign: 'left' }}>
            <ul>
              <li>pre_created stands for services that occurs at a specific time such as touristic tour.</li>
              <li>create for every setup stands for services that occurs at the time asked by the customer. For example a transfer from airport on Thursday 16 of June at 9pm.</li>
            </ul>
          </div>
        )
      },
      {
        target: '[id=channels]',
        content: 'You can finnally pick on wich channels you want your serviceBase to be used'
      },
      {
        target: '[role=toolbar]',
        content: 'After saving you will be redirected on edit page of the created ServiceBase. Check for the next tour there!'
      }
    ]
  },
  edit: {
    steps: [
      {
        target: '[id=servicebase-intrasec-data]',
        content: 'You will find in this section the data of the ServiceBase itself'
      },
      {
        target: '[id=servicebase-connected-soc-sot]',
        content: 'This section shows the connected SOC and SOT. In future it will also provide form to edit and create them directly from this page'
      },
      {
        target: '[id=serviceoffering-list-view-component]',
        content: 'There are all the ServiceOffering connected. It can either display it in calendar view or in list.'
      }
    ]
  }

}
