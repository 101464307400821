import * as React from 'react'
import { Fragment, memo } from 'react'

import { useMediaQuery, Chip } from '@material-ui/core'
import lodashkeyBy from 'lodash/keyBy'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  EditButton,
  Filter,
  FunctionField,
  List,
  ListContextProvider,
  Pagination,
  ReferenceField,
  SearchInput,
  SimpleList,
  TextField,
  useGetList,
  ReferenceInput,
  AutocompleteInput
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { ResetViewsButton } from '../../Components'
import { PropTypes } from 'prop-types'
import ListActions from '../../Components/Toolbar/ListActions'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import exporter from '../../Components/exporter'

const MenuItemFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
    <ReferenceInput source="channel" reference="channel">
      <AutocompleteInput optionText="ident" />
    </ReferenceInput>
  </Filter>
)
// eslint-disable-next-line react/display-name
const MenuItemListBulkActions = memo(props => (
    <Fragment>
      <ResetViewsButton {...props} />
      <BulkDeleteButton {...props} />
      <BulkExportButton {...props} />
    </Fragment>
))

const MenuItemPanel = (menuItems, props) => {
  const data = Object.values(menuItems.menuItems.data).filter(item => parseInt(item.parent) === parseInt(menuItems.id))
  if (data.length > 0) {
    return (
      <ListContextProvider value={{
        data: lodashkeyBy(data, 'id'),
        ids: data.map(({ id }) => id),
        resource: 'menuitem',
        total: data.length,
        page: 1,
        perPage: 10000,
        setPage: 1,
        currentSort: { field: 'sort_order', order: 'ASC' },
        basePath: '/menuitem',
        selectedIds: []
      }}>
          <Datagrid rowClick="edit" isRowExpandable={(record) => parseInt(record.has_children) > 0} expand={<MenuItemPanel {...menuItems} {...props}/>}>
              {/* <TextField source="title" /> */}
                <TextField source="sort_order" />
                <TextField source="title"/>
                <FunctionField source="has_children" render={(record) => parseInt(record.has_children) > 0 ? <Chip label={record.has_children} /> : null} />
                <TextField source="target_url"/>
                <ReferenceField source="publication" reference="publication" link={false}>
                  <FunctionField sortBy="ident" render={record => record.ident} />
                </ReferenceField>
              <ListActionToolbar>
                <EditButton />
              </ListActionToolbar>
          </Datagrid>
      </ListContextProvider>
    )
  }
  return <></>
}

const rowClick = (id, basePath, record) => {
  return 'edit'
}

const MenuItemList = props => {
  const menuItems = useGetList(
    'menuitem',
    { page: 1, perPage: 10000 }, // pagination
    { field: 'sort_order', order: 'ASC' }, // sort
    {} // filter
  )
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('content.menuitem')
  return (
      <>
          {isSmall
            ? (
            <List
              {...props}
              bulkActionButtons={<MenuItemListBulkActions />}
              sort={{ field: 'sort_order', order: 'ASC' }}
              exporter={(data) => exporter(data, 'MenuItem')}
              perPage={50}
              pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
            >
              <SimpleList
                primaryText={record => record.title}
                secondaryText={record => (
                  <ReferenceField basePath="menu" record={record} source="menu" reference="menu">
                    <TextField source="ident"/>
                  </ReferenceField>
                )}
              />
            </List>
              )
            : (
            <List
              {...props}
              actions={<ListActions />}
              filters={<MenuItemFilter />}
              bulkActionButtons={<MenuItemListBulkActions />}
              sort={{ field: 'sort_order', order: 'ASC' }}
              exporter={(data) => exporter(data, 'MenuItem')}
              perPage={50}
              pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
            >
              <Datagrid rowClick={rowClick} isRowExpandable={(record) => parseInt(record.has_children) > 0} expand={<MenuItemPanel menuItems={menuItems} { ...props } />} optimized>
                <ReferenceField source="channel" reference="channel" link={false}>
                  <TextField source="ident"/>
                </ReferenceField>
                <TextField source="group"/>
                <TextField source="sort_order" />
                <TextField source="title"/>
                <FunctionField source="has_children" render={(record) => parseInt(record.has_children) > 0 ? <Chip label={record.has_children} /> : null} />
                <TextField source="target_url"/>
                <ReferenceField source="publication" reference="publication" link={false}>
                  <FunctionField sortBy="ident" render={record => record.ident} />
                </ReferenceField>
                <ListActionToolbar>
                  <EditButton />
                </ListActionToolbar>
              </Datagrid>
              </List>
              )}
      </>
  )
}

MenuItemPanel.propTypes = {
  menuItems: PropTypes.object
}

export default MenuItemList
