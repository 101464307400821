import { isUndefinedOrNull } from '../Utils'

/**
 * Convert a string to a more human readable format.
 * Works for camelCase and snake_case ; capitalize every word first letter
 * @param {string} string: the string you want to convert
 * @return {string}: the formatted string
 */
function toHumanReadable (string) {
  if (isUndefinedOrNull(string)) {
    return ''
  } else {
    const hrString = string.match(/[A-Za-z0-9][a-z]*/g) || []
    return hrString.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    ).join(' ')
  }
}

function toKebabCase (string) {
  if (isUndefinedOrNull(string)) {
    return ''
  } else {
    string = string.toLowerCase().normalize('NFD').replace(/\p{Diacritic}/gu, '')
    const kcString = string.match(/[A-Za-z0-9][a-z]*/g) || []
    return kcString.join('-')
  }
}

/**
 * ### IMPORTANT: May be replaced easily by the lodash/get method
 *
 * Get an object attribute from a path as string notation
 * Code is adapted from stackoverflow:
 *  https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arays-by-string-path
 * @param {} object: the object to get the attribute from
 * @param {*} path: the path of the nested element
 * @returns the search attribute value, or undefined if the path does not exist in the object
 * @example
 *  object = {foo: [[bar: baz]]}
 *  path = "foo[0][0].bar"
 *  ------------
 *  returns: baz
 */
function getPropertyByString (object, path) {
  path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  path = path.replace(/^\./, '') // strip a leading dot
  const arr = path.split('.')
  for (let i = 0; i < arr.length; ++i) {
    const key = arr[i]
    if (key in object) {
      object = object[key]
    } else {
      return
    }
  }
  return object
}

/**
 * ### IMPORTANT: May be replaced easily with the lodash/set method
 *
 * Update a nested element in an object from a path as string notation
 * If the path does not exists, the function will create it on the way
 * Code is adapted from stackoverflow:
 *  https://stackoverflow.com/questions/6491463/accessing-nested-javascript-objects-and-arays-by-string-path
 * @param {Object} object: the object to update
 * @param {String} path: the path of the nested element
 * @param {} value: the value to set at the specified path
 * @example
 *  object = {foo: [undefined]}
 *  str = "foo[0][0].name"
 *  value = "bar"
 *  ------------
 *  object = {foo: [[name: "bar"]]}
 */
function updateObjectByString (object, path, value) {
  path = path.replace(/\[(\w+)\]/g, '.$1') // convert indexes to properties
  path = path.replace(/^\./, '') // strip a leading dot
  const arr = path.split('.')
  if (object === undefined) {
    object = {}
  }
  for (let i = 0; i < arr.length; ++i) {
    const key = arr[i]
    if (i === arr.length - 1) {
      object[key] = value
    } else {
      if (!Object.prototype.hasOwnProperty.call(object, key) || object[key] === undefined) {
        const nextElement = arr[i + 1]
        isNaN(nextElement)
          ? object[key] = {}
          : object[key] = []
      }
      object = object[key]
    }
  }
}

/**
 * Tag that can be used to access to React-admin 'record' inside it
 * Way to use it:
 * <WithProps>{({ record, ...props }) => {
 *    return(
 *      Put your components here...
 *    )
 *  }}</WithProps>
 * @returns {React.Component}
 */
const WithProps = ({ children, ...props }) => children(props)

/**
 * Return a source as string for an array element
 * Replace the function getSource of FormDataConsumer when the element is array
 * @param {String} currentSource: the source where the array comes from
 * @param {String} index: the index of the array
 * @example
 *  currentSource = 'tickets[0]'
 *  index = 0
 *  return: 'tickets[0][0]'
 */
const getSourceArray = (currentSource, index) => {
  return currentSource + '[' + index + ']'
}

export { toHumanReadable }
export { toKebabCase }
export { getPropertyByString }
export { updateObjectByString }
export { WithProps }
export { getSourceArray }
