import MenuItemList from './List'
import MenuItemCreate from './Create'
import MenuItemEdit from './Edit'
import Menu from '@material-ui/icons/Menu'

export default {
  list: MenuItemList,
  create: MenuItemCreate,
  edit: MenuItemEdit,
  icon: Menu
}

export { default as Tree } from './Tree'
