import { useDefineAppLocation } from '@react-admin/ra-navigation'
import * as React from 'react'
import { Create, SimpleForm } from 'react-admin'
import CreateToolbar from '../../Components/CreateToolbar'
import SupplierBaseForm from './Forms'

const SupplierCreate = props => {
  useDefineAppLocation('supplier.list.create')
  return (
    <>
      <Create {...props}>
        <SimpleForm toolbar={<CreateToolbar/>}>
          <SupplierBaseForm/>
        </SimpleForm>
      </Create>
    </>
  )
}

export default SupplierCreate
