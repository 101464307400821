import jsonExport from 'jsonexport/dist'
import { downloadCSV } from 'react-admin'

const exporter = (data, name, omitColumns) => {
  const dataForExport = data.map(item => {
    const { [omitColumns]: _, ...itemForExport } = item
    return itemForExport
  })
  jsonExport(dataForExport, (_err, csv) => downloadCSV(csv, name))
}

export default exporter
