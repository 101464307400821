import * as React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { PropTypes } from 'prop-types'
import {
  Button,
  SaveButton,
  LinearProgress,
  FormWithRedirect,
  useUpdate,
  useCreate,
  useNotify,
  useRefresh
} from 'react-admin'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'

const DialogForm = ({ children, ...props }) => {
  const { open, handleClose, formRecord, resource, defaultValue, noRefresh } = props

  const isCreate = formRecord === undefined
  const notify = useNotify()
  const refresh = useRefresh()

  const [handleSubmit, { error, loading }] = isCreate
    ? useCreate()
    : useUpdate()

  if (error) return 'ERROR'
  if (loading) return <LinearProgress />

  const handleSave = (newRecord) => {
    handleClose()
    isCreate
      ? handleSubmit(resource, newRecord, {
        onSuccess: ({ data }) => {
          !noRefresh && refresh()
        },
        onFailure: ({ error }) => {
          !noRefresh && refresh()
          notify(error.message, 'error')
        }
      })
      : handleSubmit(resource, formRecord.id, newRecord, formRecord, {
        onSuccess: ({ data }) => {
          !noRefresh && refresh()
        },
        onFailure: ({ error }) => {
          !noRefresh && refresh()
          notify(error.message, 'error')
        }
      })
  }

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth>
        <FormWithRedirect
          defaultValue={defaultValue}
          save={handleSave}
          record={formRecord}
          render={({ handleSubmitWithRedirect, pristine, saving }) => (
            <>
              <DialogContent>
                {React.Children.map(children, (child) => {
                  return React.cloneElement(child, { record: formRecord })
                })}
              </DialogContent>

              <DialogActions>
                <Button label="ra.action.cancel" onClick={handleClose}>
                  <IconCancel />
                </Button>
                <SaveButton
                  handleSubmitWithRedirect={handleSubmitWithRedirect}
                  pristine={pristine}
                  saving={saving}
                />
              </DialogActions>
            </>
          )}
        />
      </Dialog>
    </>
  )
}
DialogForm.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  formRecord: PropTypes.object,
  children: PropTypes.element,
  resource: PropTypes.string,
  defaultValue: PropTypes.object,
  noRefresh: PropTypes.bool
}

export default DialogForm
