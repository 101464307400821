import * as React from 'react'
import { PropTypes } from 'prop-types'
import { useInput, useTranslate } from 'ra-core'
import {
  DateTimePicker,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import moment from 'moment-timezone'
import { DATE_FORMAT } from '.'

const DateTimeInput = props => {
  const {
    input: { defaultValue, onChange, ...rest },
    meta: { touched, error },
    isRequired
  } = useInput(props)
  const translate = useTranslate()
  return (
    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment}>
      <DateTimePicker
        ampm={false}
        format={DATE_FORMAT}
        disabled={props.disabled}
        label={props.label ? translate(props.label) : translate(`resources.${props.resource}.fields.${props.source}`, { _: String(props.source).charAt(0).toUpperCase() + String(props.source).slice(1) }).replace('_', ' ')}
        value={rest.value === undefined ? null : rest.value.length === 0 ? null : rest.value}
        inputVariant="filled"
        clearable
        style={{ minWidth: '256px' }}
        onChange={(v) => onChange(v)}
        error={!!(touched && error)}
        helperText={touched && error ? translate(error) : props.helperText ? translate(props.helperText) : ' '}
        required={isRequired}
        fullWidth={props.fullWidth}
        margin="dense"
      />
    </MuiPickersUtilsProvider>
  )
}

DateTimeInput.propTypes = {
  helperText: PropTypes.string,
  label: PropTypes.string,
  resource: PropTypes.string,
  source: PropTypes.string.isRequired,
  disabled: PropTypes.func,
  fullWidth: PropTypes.bool
}

export default DateTimeInput
