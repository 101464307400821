import * as React from 'react'
import { FilterWithSave } from '@react-admin/ra-preferences'
import { SearchInput, ReferenceInput, AutocompleteInput, TextInput, SelectInput, DateInput } from 'react-admin'

import getConfig from '../../Components/core_config'

const SearchFilters = props => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <FilterWithSave {...props}>
      <SearchInput source="q" alwaysOn />
      <ReferenceInput source="order" reference="order" alwaysOn>
        <AutocompleteInput optionText="code" resettable/>
      </ReferenceInput>
      <SelectInput source="payment_type" choices={config.payment_type}/>
      <TextInput source="status" />
      <ReferenceInput source="customer" reference="customer" alwaysOn>
        <AutocompleteInput optionText="email" resettable/>
      </ReferenceInput>
      <DateInput
        label="Created at"
        source="created_at"
      />
    </FilterWithSave>
  )
}

export default SearchFilters
