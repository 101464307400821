import * as React from 'react'
import { PropTypes } from 'prop-types'
import lodashGet from 'lodash/get'

const PriceInfoField = props => {
  let priceInfoArray = []
  if (props.priceInfo !== undefined) {
    priceInfoArray[0] = props.priceInfo
  } else if (props.record !== undefined) {
    priceInfoArray = lodashGet(props.record, 'price_info', [])
    if (priceInfoArray[0] === undefined) {
      if (props.record.currency === undefined) return null
      priceInfoArray[0] = {}
      if (props.record.price === undefined) priceInfoArray[0].amount = props.record.amount
      else priceInfoArray[0].amount = props.record.price
      priceInfoArray[0].currency = props.record.currency
      if (props.record.vat_rate !== undefined)priceInfoArray[0].vat_rate = props.record.vat_rate
    }
  } else {
    return null
  }
  const compare = (a, b) => {
    if (a.currency > b.currency) return 1
    if (b.currency > a.currency) return -1

    return 0
  }
  priceInfoArray.sort((a, b) => compare(a, b))
  const priceString = currString(priceInfoArray)
  return (
    <span>{priceString}</span>
  )
}

const currString = (priceInfoArray) => {
  let resultString = ''
  for (const key of Object.keys(priceInfoArray)) {
    const priceInfo = priceInfoArray[key]
    if (resultString.length > 0) resultString += ' + '
    resultString += (Math.ceil(priceInfo.amount * 100) / 100).toFixed(2) + ' ' + priceInfo.currency + `${priceInfo.vat_rate === undefined ? '' : ` vat: ${priceInfo.vat_rate}%`}`
  }
  return resultString
}

PriceInfoField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object,
  priceInfo: PropTypes.object
}

export default PriceInfoField
