import * as React from 'react'
import {
  Edit,
  FormTab,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput,
  useGetOne,
  AutocompleteInput,
  Button,
  BooleanInput,
  useTranslate
} from 'react-admin'
import { PropTypes } from 'prop-types'
import { useForm } from 'react-final-form'
import { Grid } from '@material-ui/core'

import { EditActions } from '../../Components'
import { toKebabCase } from '../../Utils/Format'
import {
  useDefineAppLocation
} from '@react-admin/ra-navigation'
import GoToPublication from '../../Components/PublicationContent/GoToPublication'
import OtherChannelLinks from '../../Components/PublicationContent/OtherChannelLinks'
import DataTagEditForm from '../../Components/PublicationContent/DataTagEditForm'
import PublicationSnippetDataEditForm from '../../Components/PublicationContent/PublicationSnippetDataEditForm'
import ExitToApp from '@material-ui/icons/ExitToApp'
import { Link } from 'react-router-dom'
import validateSlug from './validateSlug'
import { JsonInput } from 'react-admin-json-view'

import getConfig from '../../Components/core_config'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import CloneButton from './Components/CloneButton'

const CommoditySelection = (props) => {
  const form = useForm()
  const [commodity, setCommodity] = React.useState(props.record.commodity_ident)
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  const handleComChange = (e) => {
    if (e.length === 0) {
      form.change('commodity_type', null)
      form.change('commodity_ident', null)
      setCommodity(null)
    } else {
      form.change('commodity_type', config.commodity_types[0].id) // service base is hardcoded until new type of commodity for the selectinput of commodity
      setCommodity(e)
    }
  }
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={8}>
        <ReferenceInput
          label="Service"
          source="commodity_ident"
          reference="servicebase"
          onChange={handleComChange}
          {...props}
        >
          <AutocompleteInput optionText="ident" resettable fullWidth/>
        </ReferenceInput>
      </Grid>
      <Grid item xs={4}>
        <Button
          startIcon={<ExitToApp />}
          label="See service"
          component={Link}
          to={`/servicebase/${commodity}`}
          disabled={commodity === null}
        />
      </Grid>
    </Grid>
  )
}

const PublicationEditForm = (props) => {
  useDefineAppLocation('content.publication.edit')
  return (
    <TabbedForm
      warnWhenUnsavedChanges
      sanitizeEmptyValues={false}
      toolbar={<SaveDeleteToolbar />}
      {...props}
    >
      <FormTab label="General">
        <Grid container spacing={4} fullWidth>
          <Grid item container xs={12} sm={6} alignContent="flex-start">
            <TextInput
              source="technical_name"
              fullWidth
            />
            <TextInput
              source="ident"
              helperText="resources.publication.helper.ident"
              fullWidth
            />
            <TextInput
              source="title"
              helperText="resources.publication.helper.title"
              fullWidth
            />
            <TextInput
              source="slug"
              helperText="resources.publication.helper.slug"
              fullWidth
              validate={(value) => validateSlug(value, props.record)}
            />
            <CommoditySelection {...props} />
          </Grid>
          <Grid item container xs={12} sm={6} alignContent="flex-start">
            <BooleanInput source="enabled" fullWidth />
            <BooleanInput source="sitemap_enabled" fullWidth />
            <ReferenceInput source="channel" reference="channel">
              <SelectInput
                optionText="ident"
                helperText="resources.publication.helper.channel"
                fullWidth
                />
            </ReferenceInput>
            <TextInput
              source="publication_template_ident"
              helperText="resources.publication.helper.publication_template_ident"
              fullWidth
            />
            <JsonInput source="properties" />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Snippets">
        <Grid item xs={12} style={{ width: '100%' }}>
          <PublicationSnippetDataEditForm {...props} />
        </Grid>
      </FormTab>
      <FormTab label="Data Tags">
        <Grid item xs={12} style={{ width: '100%' }}>
          <DataTagEditForm {...props} />
        </Grid>
      </FormTab>
    </TabbedForm>
  )
}

const getChannel = (id) => {
  const { data, error, loading } = useGetOne('channel', id)
  if (error) return '---'
  if (loading) return ''
  return data.ident
}

const PublicationEditTitle = (props) => {
  const translate = useTranslate()
  let title
  title = `${translate('resources.publication.title')}`
  title += ` ${props.id}: `
  title += ` ${getChannel(props.record.channel)}: `
  title += props.record.ident
  return title
}

const PublicationEdit = ({ permissions, ...props }) => {
  const transform = (data) => ({
    ...data,
    slug: data.auto_slug ? toKebabCase(data.title) : data.slug
  })
  return (
    <Edit
      mutationMode="pessimistic"
      title={<PublicationEditTitle {...props} />}
      actions={
        <EditActions
          tour="publication-edit"
          more={
            <>
              <OtherChannelLinks record={{ id: props.id }} />
              <GoToPublication
                type="slug_link"
                record={{ id: props.id }}
              />
              <CloneButton {...props}/>
            </>
          }
        />
      }
      {...props}
      transform={transform}
    >
      <PublicationEditForm />
    </Edit>
  )
}

PublicationEditTitle.propTypes = {
  record: PropTypes.object.isRequired
}

PublicationEdit.propTypes = {
  permissions: PropTypes.arrayOf(String).isRequired,
  id: PropTypes.string
}

PublicationEditForm.propTypes = {
  record: PropTypes.object.isRequired
}

CommoditySelection.propTypes = {
  record: PropTypes.object.isRequired
}

export default PublicationEdit
