import * as React from 'react'

import { Edit, SimpleForm, TextInput, required, SelectInput, NumberInput } from 'react-admin'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

import { EditActions } from '../Components'
import { WithProps } from '../Utils'

import { JsonInput } from 'react-admin-json-view'
import ChannelTextInput from '../Components/Inputs/ChannelTextInput'

const GeolocationEdit = props => {
  useDefineAppLocation('geolocation.edit')
  return (
    <Edit actions={<EditActions />} {...props}>
      <WithProps>{({ record, ...props }) =>
        (
          <SimpleForm record={record} {...props}>
            <TextInput source='ident' validate={required()} fullWidth/>
            <SelectInput source='enabled' choices={[{ id: 'true', name: 'true' }, { id: 'false', name: 'false' }]} validate={required()} fullWidth/>
            <TextInput source='address' validate={required()} fullWidth/>
            <TextInput source='latitude' validate={required()} fullWidth/>
            <TextInput source='longitude' validate={required()} fullWidth/>
            <ChannelTextInput editable placement="left-start" source="content.title"/>
            <ChannelTextInput editable placement="left-start" source="content.description"/>
            <NumberInput source='content.google_map_zoom' fullWidth/>
            <JsonInput source='content' />
          </SimpleForm>
        )
      }
      </WithProps>
    </Edit>
  )
}

export default GeolocationEdit
