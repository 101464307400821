import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, fetchEnd, fetchStart, useNotify, useGetOne, useRedirect, useRefresh } from 'react-admin'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
import { useDispatch } from 'react-redux'
import { API_URL, httpClient } from '../../../dataProvider'

import { TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const cloneSOC = (id, hooks, resource, ident) => {
  const data = { id: id, ident: ident }
  hooks.dispatch(fetchStart())
  httpClient(`${API_URL}/${resource}/clone`, {
    method: 'POST',
    body: JSON.stringify(data)
  }).then((response) => {
    if (response.status !== 201) hooks.notify('Error with the API')
    else {
      hooks.dispatch(fetchEnd())
      hooks.redirect(`/${resource}/${response.json.id}`)
    }
  }).catch(() => {
    hooks.notify('Error cannot clone')
  })
}

const getIdent = (id) => {
  const { data, loading, error } = useGetOne('serviceofferingconfig', id)
  if (loading) { return 'loading' }
  if (error) { return 'error' }
  return data
}

const CloneButton = (props) => {
  const { id, resource } = props
  const [showDialog, setShowDialog] = React.useState(false)
  const [ident, setIdent] = React.useState('loading')
  const SOCident = getIdent(id)
  const dispatch = useDispatch()
  const notify = useNotify()
  const redirect = useRedirect()
  const refresh = useRefresh()
  const hooks = { dispatch: dispatch, notify: notify, redirect: redirect, refresh: refresh }

  React.useEffect(() => {
    if (ident === 'loading' && SOCident.ident !== undefined) {
      setIdent(SOCident.ident)
    }
  })

  const handleClick = () => {
    setShowDialog(true)
  }
  const handleClose = () => {
    setShowDialog(false)
  }
  const handleIdentChange = (e) => {
    setIdent(e.target.value)
  }
  const clone = () => {
    handleClose()
    cloneSOC(id, hooks, resource, ident)
  }

  return (
    <>
      <Button onClick={handleClick} label="Clone">
        <AddBoxIcon/>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleClose}
        aria-label="clone-SOC"
      >
        <DialogTitle>Clone configuration</DialogTitle>
        <DialogContent>
          <TextField label="Ident" defaultValue={ident} onChange={handleIdentChange} fullWidth/>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleClose}
          >
            <CancelIcon/>
          </Button>
          <Button
            label="ra.action.clone"
            onClick={clone}
          >
            <AddBoxIcon/>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CloneButton.propTypes = {
  id: PropTypes.number,
  resource: PropTypes.string
}

export default CloneButton
