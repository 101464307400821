
import * as React from 'react'
import { Fragment, memo } from 'react'

import {
  Datagrid,
  BulkDeleteButton,
  BulkExportButton,
  EditButton,
  FunctionField,
  List,
  TextField,
  ReferenceField,
  ReferenceManyField,
  Pagination
} from 'react-admin' // eslint-disable-line import/no-unresolved
import ShortTextField from '../../../Components/ShortTextField'

import ListActions from '../../../Components/Toolbar/ListActions'

import ListActionToolbar from '../../../Components/Toolbar/ListActionToolbar'

import statusRowStyle from '../../../Components/statusRowStyle'
import exporter from '../../../Components/exporter'

import { orderStatusColor } from '../../../Utils/StatusColors'

import DateField from '../../../Components/Datefield'

import { PropTypes } from 'prop-types'
import SupplierStatusField from '../../../Suppliers/SupplierMessage/SupplierStatusField'
import { useSelectedColumns } from '@react-admin/ra-preferences'

const OrderPanel = props => (
  <ReferenceManyField
    reference="orderitem"
    target="order"
    sort={{ field: 'servicesetup_at', order: 'ASC' }}
    filter={{ notEmpty: 'True' }}
    {...props}
  >
    <Datagrid rowClick={`/order/${props.record.id}`}>
      <DateField label="Start Date" source="item_object.service_realization_detail.start" showTime/>
      <ReferenceField label="Service Base" source="item_object.service_realization_detail.service_base" reference="servicebase" link={false}>
        <FunctionField render={record => <ShortTextField textLength={50} text={record.admin_name}/>}/>
      </ReferenceField>
      {/* <FunctionField label="Summary" render={record => record.display_summary} /> */}
      <SupplierStatusField label="Supplier Status" />
    </Datagrid>
  </ReferenceManyField>
)

// eslint-disable-next-line react/display-name
const OrderListBulkActions = memo(props => (
  <Fragment>
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const orderColumns = {
  id: <TextField source="id" />,
  code: <TextField source="code" />,
  customer:
  <ReferenceField label="Customer" source="customer" reference="customer" link={false}>
    <TextField source="name"/>
  </ReferenceField>,
  user: <FunctionField source="channel_user" render={record => <span>{record.channel_user.username}<br/>{record.channel_user.email}</span>} />,
  beneficier_name: <TextField source="beneficier_name" />,
  beneficier_phone: <TextField source="beneficier_phone" />,
  checkout_at: <DateField source="checkout_at" showTime />,
  created_at: <DateField source="created_at" showTime />,
  modified_at: <DateField source="modified_at" showTime />,
  first_servicesetup_at: <DateField source="first_servicesetup_at" showTime />,
  price_info: <FunctionField label="Price" render={record => <span>{(Math.ceil(record.total * 100) / 100).toFixed(2)} {record.currency}</span>}/>,
  status: <FunctionField source="status" render={record => <span style={{ color: orderStatusColor(record.status) }}>{record.status}</span>} />,
  order_type: <TextField source="order_type" />,
  technical_note: <TextField source="technical_note" />,
  customer_note: <TextField source="customer_note" />,
  channel:
  <ReferenceField source="channel" reference="channel" link={false} >
    <TextField source="ident"/>
  </ReferenceField>
}

export const getColumns = (columnsPref, omitColumns) => {
  return useSelectedColumns({
    preferences: columnsPref,
    columns: orderColumns,
    omit: omitColumns
  })
}

const OrderList = props => {
  const { columnsPref, filters, filter, omitColumns, sort, colorFull } = props
  const columns = getColumns(columnsPref, omitColumns)

  // from https://stackoverflow.com/questions/66897430/react-admin-datagrid-expand-all-rows-by-default
  // const useExpandDefaultForAll = (resource) => {
  //   const [ids, expanded] = useSelector(
  //     (state) => ([state.admin.resources[resource].list.ids, state.admin.resources[resource].list.expanded])
  //   )
  //   const dispatch = useDispatch()
  //   useEffect(() => {
  //     for (let i = 0; i < ids.length; i++) {
  //       if (!expanded.includes(ids[i])) {
  //         dispatch(toggleListItemExpand(resource, ids[i]))
  //       }
  //     }
  //   }, [ids])
  // }
  // useExpandDefaultForAll(props.resource)

  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference={columnsPref} columns={orderColumns} />}
        bulkActionButtons={<OrderListBulkActions />}
        filters={filters}
        filter={filter}
        sort={sort}
        exporter={(data) => exporter(data, 'Order')}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      >
        <Datagrid
          isRowExpandable={(record) => record ? record.order_items_count > 0 : null}
          rowClick="edit"
          expand={<OrderPanel />}
          optimized
          rowStyle={colorFull ? (record) => record ? statusRowStyle(orderStatusColor(record.status)) : null : null }
        >
          {columns}
          <ListActionToolbar>
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

OrderPanel.propTypes = {
  record: PropTypes.object
}

OrderList.propTypes = {
  filter: PropTypes.object,
  filters: PropTypes.element,
  columnsPref: PropTypes.string,
  omitColumns: PropTypes.array,
  colorFull: PropTypes.bool,
  sort: PropTypes.object,
  resource: PropTypes.string
}

export default OrderList
