import * as React from 'react'
import {
  Pagination,
  ReferenceManyField,
  TextInput
} from 'react-admin'

import { Button, TextField as TextFieldMUI } from '@material-ui/core'

import { PropTypes } from 'prop-types'
import { ChannelTextDatagrid } from '../../ChannelText/Components'

const TranslationFilterButton = props => {
  const { setFilter, filterValue, filterName } = props
  return (
    <Button
      color="primary"
      variant={filterValue === filterName ? 'disabled' : ''}
      onClick={() => setFilter(filterName)}
    >
      {filterName}
    </Button>
  )
}

const ChannelTextRowForm = props => (
  <>
    <TextInput source="key" fullWidth/>
    <TextInput source="value" multiline fullWidth/>
  </>
)

const ChannelExpand = props => {
  const [filter, setFilter] = React.useState('ALL')
  const [searchKey, setSearchKey] = React.useState('')
  const [searchValue, setSearchValue] = React.useState('')
  return (
    <>
    <div style={{ display: 'flex', justifyContent: 'right', alignItems: 'center' }}>
      <TextFieldMUI style={{ marginRight: 15 }}label="Key" variant="filled" onChange={e => setSearchKey(e.target.value)}/>
      <TextFieldMUI label="Value" variant="filled" onChange={e => setSearchValue(e.target.value)}/>
      <TranslationFilterButton filterName="NON-TRANSLATED" filterValue={filter} setFilter={setFilter}/>
      <TranslationFilterButton filterName="TRANSLATED" filterValue={filter} setFilter={setFilter}/>
      <TranslationFilterButton filterName="ALL" filterValue={filter} setFilter={setFilter}/>
    </div>
    <ReferenceManyField
      reference="channeltext"
      target="channel"
      sort={{ field: 'key', order: 'ASC' }}
      pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      filter={{ translated_status: filter, search_key: searchKey, value: searchValue }}
      fullWidth
      {...props}
    >
      <ChannelTextDatagrid
        form={<ChannelTextRowForm/>}
        defaultValue={{ channel: props.record.id }}
      />
    </ReferenceManyField>
    </>
  )
}

ChannelExpand.propTypes = {
  record: PropTypes.object
}

TranslationFilterButton.propTypes = {
  setFilter: PropTypes.func,
  filterValue: PropTypes.string,
  filterName: PropTypes.string
}

ChannelTextRowForm.propTypes = {
  record: PropTypes.object,
  defaultKey: PropTypes.string
}

export default ChannelExpand
