import * as React from 'react'
import {
  Edit,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin' // eslint-disable-line import/no-unresolved
import PropTypes from 'prop-types'
import {
  Grid,
  Typography
} from '@material-ui/core'
import { EditActions, DateTimeInput } from '../../Components'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import getConfig from '../../Components/core_config'

const BookingEdit = ({ record, permissions, ...props }) => {
  useDefineAppLocation('catalog.booking.edit')
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <Edit actions={<EditActions />} {...props}>
    <SimpleForm warnWhenUnsavedChanges record={record} toolbar={<SaveDeleteToolbar deleteLabel="id" />} {...props}>
      <Grid container spacing={4} style={{ width: '100%' }} {...props}>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5">General</Typography>
          </Grid>
          <Grid item xs={12} sm={2}>
            <TextInput disabled source="id" fullWidth/>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectInput source="booking_from" choices={config.booking_from} fullWidth/>
          </Grid>
          <Grid item xs={12} sm={4}>
            <ReferenceInput source="channel" reference="channel">
              <SelectInput optionText="ident" fullWidth/>
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={3}>
            <SelectInput source="status" choices={config.booking_status} fullWidth/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <DateTimeInput disabled source="created_at" fullWidth/>
            <DateTimeInput disabled source="modified_at" fullWidth/>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceInput source="ticket" reference="ticket">
              <SelectInput optionText={(record) => `${record.id}: ${record.display_summary}`} fullWidth/>
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ReferenceInput source="capacity_pool" reference="capacitypool">
              <SelectInput optionText={(record) => `${record.id}: ${record.ident}`} fullWidth/>
            </ReferenceInput>
          </Grid>
        </Grid>
      </Grid>
    </SimpleForm>
    </Edit>
  )
}

// const BookingEdit = props => (
//   <Edit actions={<EditActions />} {...props}>
//     <WithProps>{({ record, formProps }) =>
//       <BookingEditForm record={record} {...formProps} {...props}/>
//     }</WithProps>
// )

BookingEdit.propTypes = {
  record: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(String)
}

export default BookingEdit
