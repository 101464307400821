import { useGetOne } from 'react-admin'

const getConfig = (type) => {
  const { data, error, loading } = useGetOne('mancubconfig', 'all')
  if (error || !data) return 'ERROR'
  if (loading) return 'Loading'
  return data.config
}

export default getConfig
