import englishMessages from 'ra-language-english'
import { raTreeLanguageEnglish } from '@react-admin/ra-tree'
import { raRelationshipsLanguageEnglish } from '@react-admin/ra-relationships'

const customEnglishMessages = {
  simple: {
    action: {
      enable: 'Enable all',
      disable: 'Disable all',
      datatagsedit: 'Edit datatags',
      create: 'Create',
      refresh_payment_status: 'Update payment status',
      refresh_payment_status_bulk: 'Update states of selected'
    },
    ui_help: 'Help'
  },
  notifications: {
    dateupdated: 'Date updated',
    apierror: 'Error with API',
    payment_state_fetch: {
      bulk: {
        failed: 'Unable update all selected',
        success: 'Statuses updated sucessfully'
      },
      single: {
        failed: 'Unable to update payment status',
        success: 'Payment state successfully updated'
      }
    }
  },
  menu: {
    save_close: 'Save and close',
    save_continue: 'Save and continue',
    filters: {
      from_today: 'Start Today',
      reset: 'Reset filters',
      ident: 'Ident',
      start_after: 'Start after',
      end_before: 'End before'
    },
    navigation: {
      accounting: 'Accounting',
      advanced: 'Advanced',
      accountingdocuments: 'Invoices',
      payments: 'Payments',
      publications: 'Publications',
      channels: 'Channels',
      translations: 'Translations',
      images: 'Images',
      documents: 'Documents',
      geolocations: 'Geolocations',
      contacts: 'Contacts',
      redirects: 'Redirects',
      menuitems: 'Menu Items',
      menutree: 'Menu tree',
      baskets: 'Baskets',
      channelusers: 'Users',
      expenditures: 'Expenditures',
      orderitems: 'Order Items',
      realizationcalendar: 'Realization Calendar',
      servicerealizations: 'Service Realizations',
      bookings: 'Bookings',
      customers: 'Customers',
      orders: 'Orders',
      settings: 'Settings',
      services: 'Services',
      serviceofferings: 'Service Offerings',
      offeringcalendar: 'Offering Calendar',
      serviceofferingtemplates: 'Attributes',
      serviceofferingconfig: 'Prices',
      suppliers: 'Suppliers',
      suppliermessages: 'Supplier Messages',
      currencyexchange: 'Currency Converter'
    }
  },
  resources: {
    accountingdocument: {
      fields: {
        type: 'Document type'
      }
    },
    channel: {
      fields: {
        technical_name: 'Technical name'
      },
      helper: {
        ident: 'Ident of the channel',
        technical_name: 'Name used in admin',
        config: 'Config of the channel'
      }
    },
    channeltoken: {
      helper: {
        token: 'Auto generated',
        expiration: 'If empty, will be 4 weeks ahead',
        label: 'An optional label'
      }
    },
    menuitem: {
      fields: {
        channel: 'Channel',
        menu_ident: 'Menu identifier',
        title: 'title',
        sort_order: 'Sort Order'
      },
      helper: {
        sort_order: 'Order in which the menu will appear',
        menu: 'Group of menu where this menu item will be',
        parent: 'The menu which will contain the current menu'
      }
    },
    order: {
      fields: {
        status: 'Status',
        channel: 'Channel'
      }
    },
    payment: {
      fields: {
        status: 'Payment status',
        type: 'Payment type'
      }
    },
    publication: {
      title: 'Publication',
      show: 'Show',
      helper: {
        title: 'Title of the publication',
        ident: 'Identifier of the publication, used in the url',
        auto_slug: 'Auto generates the slug',
        slug: 'The slug is part of the url which allows to easily identify a publication in an easy to read format',
        tags: 'Tags are necessary to determine in which category the publication should be and how to filter it',
        channel: 'The publication will be in this channel',
        template: 'Defines fields, filters and snippets. WARNING: If changed please reload browser page!'
      },
      menu: {
        fields: 'Fields'
      }
    },
    redirect: {
      helper: {
        source: 'Source of the redirect',
        target: 'Target of the redirect',
        updated_at: 'Last update',
        channel: 'Channel of the redirect'
      }
    },
    servicebase: {
      title: 'Service',
      fields: {
        technical_name: 'Technical name',
        suppliers_name: 'Supplier\'s name'
      }
    },
    serviceofferingconfig: {
      title: 'Offering Price Config'
    },
    serviceofferingtemplate: {
      title: 'Offering Attribute Template'
    },
    serviceoffering: {
      menu: {
        attributes: 'Attributes',
        pricing: 'Pricing',
        today: 'From Today'
      }
    }
  }
}

export default { ...englishMessages, ...raTreeLanguageEnglish, ...raRelationshipsLanguageEnglish, ...customEnglishMessages }
