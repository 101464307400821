import * as React from 'react'
import {
  AutocompleteInput,
  Edit,
  ReferenceInput,
  ReferenceManyField,
  TextField,
  SelectInput,
  TextInput,
  SimpleForm,
  Datagrid,
  useGetOne,
  FunctionField,
  ReferenceField,
  Button,
  ListContextProvider,
  EditButton,
  Toolbar,
  useGetList
} from 'react-admin'
import { Link as ReactLink } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import {
  Card,
  CardContent,
  Grid,
  Link,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core'

import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'

import { EditActions, PriceInfoField } from '../../Components'
import { PropTypes, any, bool, string } from 'prop-types'
// import RichTextInput from 'ra-input-rich-text'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import { WithProps } from '../../Utils'
import SupplierStatusField from '../../Suppliers/SupplierMessage/SupplierStatusField'

import statusRowStyle from '../../Components/statusRowStyle'
import DateField from '../../Components/Datefield'
import LocalDate from '../../Components/LocalDate'

import AccountingDownloadPDF from '../../Accounting/AccountingDocument/DownloadAsPDF'
import OrderItemDownloadVoucherPDF from '../OrderItem/Components/DownloadVoucherPDF'
import getConfig from '../../Components/core_config'
// import SupplierMessageSummary from '../../Suppliers/SupplierMessage/SupplierMessageSummary'
import ResendDocumentsButton from './Components/ResendDocumentsButton'
import ExitToApp from '@material-ui/icons/ExitToApp'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { ChannelTextField } from '../../Components/Fields'
import lodashkeyBy from 'lodash/keyBy'
import ShortTextField from '../../Components/ShortTextField'
import { paymentStatusColor, supplierStatusColor } from '../../Utils/StatusColors'
import SupplierMessagePanel from '../../Components/SupplierMessagePanel'
import SaveContinueToolbar from '../../Components/Toolbar/SaveContinueToolbar'

const OrderItemPanel = props => {
  return (
    <Grid container item xs={12} spacing={2}>
      <Grid container item xs={12}>
        <Toolbar style={{ display: 'flex', width: '100%' }}>
          <OrderItemDownloadVoucherPDF style={{ marginLeft: 'auto' }} {...props}/>
          <EditButton record={props.record}/>
        </Toolbar>
      </Grid>
      <PanelAccordion title={'Supplier Messages'}>
        <Grid>
          <SupplierMessagePanel noDelete={true} {...props} redirect={`/order/${props.record.order}`} />
        </Grid>
      </PanelAccordion>
      <SellPriceItemsPanel {...props}/>
      <TicketPanel {...props}/>
      <SetupAttributesPanel {...props}/>
      <VoucherAttributesPanel {...props}/>
      <ExpenditurePanel {...props}/>
    </Grid>
  )
}

const PanelAccordion = props => (
  <Accordion style={{ width: '100%' }} defaultExpanded={props.defaultExpanded}>
    <AccordionSummary expandIcon={<ExpandMore />} >
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="h5">{props.title}</Typography>
        </Grid>
      </Grid>
    </AccordionSummary>
    <AccordionDetails>
      {props.children}
    </AccordionDetails>
  </Accordion>
)

const SellPriceItemsPanel = props => {
  const sellPriceItems = props.record.item_object.servicesetup_content.sell_price_items
  return !!props.record.item_object && sellPriceItems.length > 0 &&
      <PanelAccordion title={'Sell price items'} defaultExpanded>
        <Table>
          <TableBody>
            {sellPriceItems.map((spi, i) => !!spi.ident && <TableRow key={i}>
              <TableCell>{spi.ident}</TableCell>
              <TableCell>{spi.amount} {spi.currency}</TableCell>
              <TableCell>{spi.vat_rate}</TableCell>
            </TableRow>
            )}
          </TableBody>
        </Table>
      </PanelAccordion>
}

const AttributesPanel = ({ attributes, title, defaultExpanded }) => {
  const context = {
    data: attributes.reduce((o, val, index) => {
      o[index] = { ...val, key: index }
      return o
    }, {}),
    ids: Object.keys([...Array(attributes.length)]),
    total: attributes.length,
    page: 1,
    perPage: 10000,
    setPage: 1,
    currentSort: { field: 'key', order: 'ASC' },
    resource: 'attribute',
    basePath: 'attribute'
  }

  return <PanelAccordion title={title} defaultExpanded={defaultExpanded}>
  {/* <Table>
    <TableBody>
      {attributes.map((attribute, i) => <TableRow key={i}>
        <TableCell width={'35%'}>
          <ChannelTextField record={attribute} placement="top" source="title"/>
        </TableCell>
        <TableCell>
          <ChannelTextField record={attribute} placement="top" source="value_display"/>
        </TableCell>
      </TableRow>
      )}
    </TableBody>
  </Table> */}
  <ListContextProvider value={context}>
      <Datagrid>
      <ChannelTextField placement="top" source="title"/>
      <ChannelTextField placement="top" source="value_display"/>
      </Datagrid>
  </ListContextProvider>
</PanelAccordion>
}

const SetupAttributesPanel = props => {
  const attributes = props.record.item_object.servicesetup_content.attributes.filter(e => {
    return !e.realization_only
  })
  return !!props.record.item_object && attributes.length > 0 &&
      <AttributesPanel defaultExpanded attributes={attributes} title={'Service Attributes'} />
}

const VoucherAttributesPanel = props => {
  const attributes = props.record.item_object.servicesetup_content.attributes.filter(e => {
    return !!e.realization_only
  })
  return !!props.record.item_object && attributes.length > 0 &&
      <AttributesPanel attributes={attributes} title={'Voucher Attributes'} />
}

const TicketPanel = props => {
  const { record, ...rest } = props
  const context = {
    data: lodashkeyBy(record.item_object.tickets, 'id'),
    ids: record.item_object.tickets.map(({ id }) => id),
    total: record.item_object.tickets.length,
    page: 1,
    perPage: 10000,
    setPage: 1,
    currentSort: { field: 'id', order: 'ASC' },
    resource: 'ticket',
    basePath: 'ticket'
  }
  if (record.item_object === undefined || record.item_object.tickets.length === 0) return null
  return (
    <PanelAccordion title={'Tickets'} defaultExpanded>
      <ListContextProvider value={context}>
        <Datagrid {...rest}>
          <FunctionField label="Attributes" render={(record) =>
            record.content.attributes.map((attribute, i) => attribute.value.length > 0
              ? <Grid container key={i}>
                  <Grid item xs={4}>{attribute.ident}:&nbsp;</Grid>
                  <Grid item xs={8}>
                    <ChannelTextField record={attribute} source={'value_display'}/>
                  </Grid>
              </Grid>
              : null)
          }/>
          <FunctionField label="Sell price items" render={(record) =>
            record.content.sell_price_items.map(spi => <div key={spi.ident}>{spi.ident}: {spi.amount} {spi.currency}</div>)
          }/>
        </Datagrid>
      </ListContextProvider>
    </PanelAccordion>
  )
}

const ExpenditurePanel = props => {
  if (!props.record.item_object) return null
  const { data, ids, error, loading } = useGetList(
    'expenditure',
    { page: 1, perPage: 10000 },
    { field: 'id', order: 'ASC' },
    { service_setup: props.record.item_object.id }
  )
  if (error) return 'ERROR'
  if (loading) return 'Loading'
  return ids.length > 0 &&
    <PanelAccordion title={'Expenditures'}>
      <ListContextProvider value={{
        data: lodashkeyBy(data, 'id'),
        ids: ids,
        total: ids.length,
        page: 1,
        perPage: 10000,
        setPage: 1,
        currentSort: { field: 'id', order: 'ASC' },
        resource: 'expenditure',
        basePath: 'expenditure'
      }}>
        <Datagrid>
          <ReferenceField source="buy_price_item" reference="buypriceitem" link={false}>
            <TextField source="ident"/>
          </ReferenceField>
          <ReferenceField source="supplier" reference="supplier" link={true} >
            <TextField source="name" />
          </ReferenceField>
          <PriceInfoField label="Price"/>
        </Datagrid>
      </ListContextProvider>
    </PanelAccordion>
}

const OrderItemList = props => {
  return (
    <ReferenceManyField
      addLabel={false}
      reference="orderitem"
      target="order"
      sort={{ field: 'servicesetup_at', order: 'ASC' }}
      filter={{ notEmpty: 'True' }}
      fullWidth
    >
      <Datagrid
        expand={<OrderItemPanel {...props}/>}
        rowStyle={(record) => record ? statusRowStyle(supplierStatusColor(record.item_object.supplier_status)) : null}
        rowClick="expand"
      >
        <DateField label="Start Date" source="item_object.service_realization_detail.start" showTime/>
        <ReferenceField label="Service Base" source="item_object.service_realization_detail.service_base" reference="servicebase" link={false}>
          <FunctionField label="Ident" render={record => <ShortTextField textLength={50} text={record.ident}/>}/>
        </ReferenceField>
        {/* <FunctionField label="Summary" render={record => record.item_object.display_summary} /> */}
        <SupplierStatusField label="Supplier" {...props} />
      </Datagrid>
    </ReferenceManyField>
  )
}

const OrderToolbar = props => {
  return <SaveContinueToolbar disableDelete={true} {...props}/>
}

const OrderEditForm = ({ record, ...props }) => {
  if (props.resource === 'basket') {
    useDefineAppLocation('order.basket.edit')
  } else {
    useDefineAppLocation('order.list.edit')
  }
  const [customer, setCustomer] = React.useState(record.customer)
  const config = getConfig()

  if (config === 'ERROR') return 'error'
  if (config === 'Loading') return 'loading'

  return (
    <SimpleForm warnWhenUnsavedChanges record={record} {...props} toolbar={<OrderToolbar/>}>
      <Grid container spacing={4} style={{ width: '100%' }} {...props}>
      <Grid container item xs={12} sm={9} spacing={2} alignContent="flex-start" >
          {/* Accordion AccountingDocs */}
          <Grid item xs={12}>
            <Accordion>
              <AccordionSummary>
                <Typography variant="h5">Accounting Documents</Typography>
              </AccordionSummary>
              <AccordionDetails>
              <Grid item xs={12}>
                <Card variant="outlined">
                  <CardContent style={{ padding: 16 }}>
                    <ReferenceManyField reference="accountingdocument" target="order">
                      <Datagrid
                        resource="accountingdocument"
                        rowClick="edit"
                      >
                        <TextField source="document_type"/>
                        <DateField source="created_at"/>
                        <PriceInfoField label="Paid" {...props} />
                        <DateField source="vat_date"/>
                        <ListActionToolbar>
                          <AccountingDownloadPDF {...props}/>
                        </ListActionToolbar>
                      </Datagrid>
                    </ReferenceManyField>
                  </CardContent>
                </Card>
              </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Accordion OrderItems */}
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant="h5">Order items</Typography>
                  </Grid>
                </Grid>

              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent style={{ padding: 16 }}>
                      <OrderItemList {...props} />
                    </CardContent>
                  </Card>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
          {/* Accordion Payments */}
          <Grid item xs={12}>
            <Accordion defaultExpanded>
              <AccordionSummary>
                <Typography variant="h5">Payments</Typography>
                <Button
                  color="primary"
                  // class="ml-auto"
                  style={{ marginLeft: 'auto' }}
                  component={ReactLink}
                  to={`/payment/create?order_id=${record.id}`}
                  label="simple.action.create"
                  title="Create Payment"
                >
                  <AddIcon />
                </Button>
              </AccordionSummary>
              <AccordionDetails>
                <Grid item xs={12}>
                  <Card variant="outlined">
                    <CardContent style={{ padding: 16 }}>
                      <ReferenceManyField reference="payment" target="order">
                        <Datagrid
                          resource="payment"
                          rowStyle={(record) => record ? statusRowStyle(paymentStatusColor(record.status)) : null}
                        >
                          <DateField source="created_at" showTime/>
                          <TextField source="payment_type" />
                          <FunctionField source="status" render={record => <span style={{ color: paymentStatusColor(record.status) }}>{record.status}</span>} />
                          <PriceInfoField label="Amount" />
                          <TextField source="reference_number" />
                        </Datagrid>
                      </ReferenceManyField>
                    </CardContent>
                  </Card>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={3} alignContent="flex-start" spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h5">General</Typography>
          </Grid>
          <Grid item xs={12}>
              <Link href={`${record.order_url}`}>See order page</Link>
          </Grid>
          <Grid item xs={12}>
            <span >Total price: </span>
            <FunctionField render={record => <span>{(Math.ceil(record.total * 100) / 100).toFixed(2)} {record.currency}</span>}/>
          </Grid>
          <Grid item xs={12}>
            Created at: <FunctionField render={record => <DateField record={record} source="created_at" showTime/>}/>
          </Grid>
          <Grid item xs={12}>
            Checkout at: <FunctionField render={record => <DateField record={record} source="checkout_at" showTime/>}/>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput label="Customer" source="customer" reference="customer" onChange={(e) => setCustomer(e)} resettable={true}>
              <AutocompleteInput optionText="email" fullWidth />
            </ReferenceInput>
            <Button
              startIcon={<ExitToApp />}
              label="See customer"
              component={ReactLink}
              to={`/customer/${customer}`}
              disabled={customer === undefined}
            />
          </Grid>
          <Grid item xs={12}>
            <TextInput source="beneficier_name" multiline fullWidth/>
          </Grid>
          <Grid item xs={12}>
            <TextInput source="beneficier_phone" multiline fullWidth/>
          </Grid>
          <Grid item xs={12}>
            {record.customer_note
              ? <Typography variant="body1">
                Customer note: {
                  record.customer_note.split('\n').map(function (item, idx) {
                    return (
                          <>
                              {item}
                              <br/>
                          </>
                    )
                  })
                }
                </Typography>
              : <></>}
          </Grid>
          <Grid item xs={12}>
            <TextInput source="technical_note" multiline fullWidth/>
          </Grid>
          <Grid item xs={12}>
            <SelectInput source="status" choices={config.order_status} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <SelectInput source="order_type" choices={config.order_type} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput label="Channel" source="channel" reference="channel">
              <AutocompleteInput optionText={record => record.ident } fullWidth/>
            </ReferenceInput>
          </Grid>
          {/* <Grid item xs={12}>
            <ReferenceInput label="Channel User" source="channel_user" reference="channeluser" resettable={true}>
              <AutocompleteInput optionText={(record) => record.mail + ' (' + record.username + ')'} fullWidth/>
            </ReferenceInput>
          </Grid> */}
        </Grid>
      </Grid>
    </SimpleForm>
  )
}

const OrderEditTitle = props => {
  return `${props.resource.charAt(0).toUpperCase()}${props.resource.slice(1)} ${props.record.code}: ${props.record.channel_user.email} on ${LocalDate(props.record.modified_at)} via ${getChannel(props.record.channel)}`
}

const getChannel = (id) => {
  const { data, error, loading } = useGetOne('channel', id)
  if (error) return 'No channel found'
  if (loading) return ''
  return data.ident
}

const OrderEdit = props => (
  <Edit mutationMode="pessimistic" title={<OrderEditTitle {...props} />} actions={<EditActions more={<ResendDocumentsButton {...props}/>}/>} {...props}>
    <WithProps>{({ record, ...formProps }) =>
      <OrderEditForm record={record} {...formProps} />
    }</WithProps>
  </Edit>
)

OrderItemPanel.propTypes = {
  record: PropTypes.object
}

PanelAccordion.propTypes = {
  title: PropTypes.string,
  children: React.Children,
  defaultExpanded: bool | undefined
}

ExpenditurePanel.propTypes = {
  record: PropTypes.object
}

SellPriceItemsPanel.propTypes = {
  record: PropTypes.object
}

AttributesPanel.propTypes = {
  attributes: PropTypes.arrayOf(any),
  title: string,
  defaultExpanded: bool
}

SetupAttributesPanel.propTypes = {
  record: PropTypes.object
}

VoucherAttributesPanel.propTypes = {
  record: PropTypes.object
}

TicketPanel.propTypes = {
  record: PropTypes.object
}

OrderEditTitle.propTypes = {
  record: PropTypes.object.isRequired
}

OrderEditForm.propTypes = {
  id: PropTypes.string.isRequired,
  record: PropTypes.object.isRequired,
  permissions: PropTypes.arrayOf(String),
  resource: PropTypes.string
}

export default OrderEdit
