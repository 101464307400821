import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {
    '& td.fc-day-sat, td.fc-day-sun ': {
      backgroundColor: theme.palette.action.selected
    }
  }
}))

export default useStyles
