import * as React from 'react'

import { ArrayInput, NumberInput, SimpleFormIterator, TextInput, required } from 'react-admin'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { PropTypes } from 'prop-types'
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core'
import * as icons from '@material-ui/icons'
import get from 'lodash/get'
import { useForm } from 'react-final-form'

/**
 * A dynamic input used to display one of the field
 * of a publication's content
 *
 * @param {string} label
 * @param {string} source The field source from the publication
 *   /!\ The source path must be base from the publication
 *   /!\ and so, start with content
 *   /!\ eg: content.h1
 * @param {string} fieldType The kind of field to display
 * @returns {React.Component}
 */
const ContentInput = ({ source, fieldType, label, ...props }) => {
  const optional = props.field === undefined ? true : props.field.optional
  switch (fieldType) {
    case 'TextInput':
      return <TextInput source={source} label={label} validate={optional ? [] : [required()]} parse={v => v === '' ? '' : v} {...props} fullWidth/> // initialValue not working if snippet is totally new on a root level
    case 'MarkdownInput':
      return <MarkdownInput source={source} label={label} parse={v => v === '' ? '' : v} {...props} />
    case 'ArrayInput':
      return (
        <ArrayInput label={label} source={source} validate={optional ? [] : [required()]} >
          <SimpleFormIterator>
            <ArrayForm field={props.field} />
          </SimpleFormIterator>
        </ArrayInput>
      )
    case 'SelectInput':
      return <CustomSelect source={source} {...props}/>
    case 'NumberInput':
      return <NumberInput source={source} label={label} {...props} validate={optional ? [] : [required()]} fullWidth />
    case 'Dict':
      return <DictInput source={source} {...props} />
    default:
      return null
  }
}

const UseIcons = ({ iconSearch }) => {
  const ResultIcon = icons[iconSearch]
  if (ResultIcon === undefined) return null
  return (<ResultIcon style={{ verticalAlign: 'middle' }} color="primary" />)
}

const DictInput = props => {
  const dictForm = []
  dictForm.push(
    <div style={{ paddingLeft: 20, borderLeft: '2px solid lightgrey' }}>
      <p>{props.field.field_name}</p>
      {props.field.fields.map(field => <ContentInput key={field.field_name} field={field} source={`${props.source}.${field.field_name}`} label={field.field_name} fieldType={field.field_type} />)}
    </div>
  )
  return dictForm
}

const CustomSelect = props => {
  const form = useForm()
  let selectedOption
  const existingOptions = props[props.field.field_name] === undefined || props[props.field.field_name] === null ? [] : Object.keys(props[props.field.field_name])
  for (const key of Object.keys(existingOptions)) {
    if (get(props[props.field.field_name], existingOptions[key], '') !== null) { selectedOption = existingOptions[key] }
  }
  const [fieldValue, setFieldValue] = React.useState(selectedOption)
  const handleChange = (event) => {
    if (selectedOption !== event.target.value) {
      form.change(props.source, null)
    }
    setFieldValue(event.target.value)
  }
  const selectForm = []
  let fields
  props.field.fields === undefined ? fields = null : fields = props.field.fields
  selectForm.push(
    <FormControl variant="filled" fullWidth>
      <InputLabel>{props.field.field_name}</InputLabel>
      <Select
        value={fieldValue}
        onChange={handleChange}
        style={{ marginBottom: 20, borderBottom: '2px solid grey' }}
        >
        {fields.map(field => (
          <MenuItem key={field.field_name} value={field.field_name}>{field.field_name}</MenuItem>
        ))}
      </Select>
    </FormControl>
  )
  selectForm.push(<DynamicFields {...props} fieldValue={fieldValue}/>)
  return selectForm
}

const DynamicFields = props => {
  let fields
  const fieldsForm = []
  props.field.fields === undefined ? fields = null : fields = props.field.fields
  for (const key of Object.keys(fields)) {
    if (fields[key].field_name === props.fieldValue) {
      fields[key].fields.forEach(field => {
        fieldsForm.push(<ContentInput field={field} source={`${props.source}.${props.fieldValue}.${field.field_name}`} label={field.field_name} fieldType={field.field_type} />)
      })
    }
  }
  return fieldsForm
}

const ArrayForm = props => {
  const arrayForm = []
  if (props.field === undefined) return null
  props.field.fields.forEach(arrayField => {
    arrayForm.push(<ContentInput field={arrayField} source={`${props.source}.${arrayField.field_name}`} label={arrayField.field_name} fieldType={arrayField.field_type} />)
  })
  return arrayForm
}

ContentInput.propTypes = {
  label: PropTypes.string.isRequired,
  source: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  data: PropTypes.object,
  field: PropTypes.object,
  props: PropTypes.object
}

UseIcons.propTypes = {
  iconSearch: PropTypes.string.isRequired
}

export default ContentInput
