
import * as React from 'react'

import { Button } from 'react-admin'

import { Form } from 'react-final-form'

import {
  Grid
} from '@material-ui/core'

import { Save } from '@material-ui/icons'

import { PropTypes } from 'prop-types'

import { getTransformedFile, uploadFile } from './FileMangementUtils'

const FileUploader = props => {
  const [files, setFiles] = React.useState([])
  const handleFileChange = (e) => {
    const newFiles = e
    for (let index = 0; index < newFiles.length; index++) {
      const fileName = newFiles[index].name || ''
      newFiles[index] = getTransformedFile(newFiles[index], fileName)
    }
    setFiles(newFiles)
  }
  const handleSubmit = async () => {
    if (files.length) {
      await Promise.all(files.map(async file => {
        await uploadFile(file, props.path)
      }))
      props.onUpload()
    }
  }
  return (
    <Grid item xs={12}>
      <Grid container item xs={12} spacing={0}>
        <Form onSubmit={handleSubmit}>
        {formProps => (
          <form onSubmit={formProps.handleSubmit}>
            {props.children
              ? React.cloneElement(props.children, { onChange: handleFileChange })
              : null
            }
          </form>
        )}
        </Form>
      </Grid>
      <Grid item xs={12}>
        <Button
        onClick={handleSubmit}
        variant="contained"
        label="Save"
        disabled={!files.length}
        >
          <Save />
        </Button>
      </Grid>
    </Grid>
  )
}

FileUploader.propTypes = {
  path: PropTypes.string,
  onUpload: PropTypes.func,
  children: PropTypes.node
}

export default FileUploader
