import React, { useState } from 'react'
import {
  Button
} from 'react-admin'
import IconContentAdd from '@material-ui/icons/Add'
import BulkDialog from './BulkDialog'

import PropTypes from 'prop-types'

const BulkCloneButton = ({ resource, selectedIds }) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleOpen = () => {
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  return (
    <>
      <Button onClick={handleOpen} label="ra.action.clone">
        <IconContentAdd />
      </Button>
      <BulkDialog
        open={showDialog}
        handleClose={handleClose}
        resource={resource}
        selectedIds={selectedIds}
      />
    </>
  )
}

BulkCloneButton.propTypes = {
  resource: PropTypes.string,
  resourceToRedirect: PropTypes.string,
  selectedIds: PropTypes.arrayOf(PropTypes.string)
}

export default BulkCloneButton
