import React from 'react'
import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  required,
  TextInput,
  SelectInput,
  useGetOne,
  useGetList,
  useCreate,
  useCreateSuggestionContext
} from 'react-admin'
import PropTypes from 'prop-types'
import { parse } from 'query-string'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { DateTimeInput } from '../../Components'
import getConfig from '../../Components/core_config'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Typography
} from '@material-ui/core'

import moment from 'moment'

const ServiceRealizationField = props => {
  const { data } = useGetOne('servicebase', props.serviceBase)
  if (data === undefined) return 'No service base'

  return (
    <>
      <Typography variant="body2" >{data.realization_strategy}</Typography>
      {data.realization_strategy === 'pre_created'
        ? <ServiceRealizationInput serviceBase={props.serviceBase}/>
        : null}
    </>
  )
}

const fetchServiceRealizationChoices = (serviceBase) => {
  const { data } = useGetList(
    'servicerealization',
    { page: 1, perPage: 100 },
    { field: 'start', order: 'ASC' },
    { service_base: serviceBase }
  )
  const realChoices = Object.values(data).map(real => {
    return {
      id: real.id,
      name: moment(real.start).format('DD MMM. YYYY HH:mm')
    }
  })
  // Jarda help
  // console.log(realChoices)
  return realChoices
}

const ServiceRealizationInput = props => {
  const retrievedChoices = fetchServiceRealizationChoices(props.serviceBase)
  const [choices, setChoices] = React.useState()
  const postCreate = data => {
    const newChoice = {
      id: data.id,
      name: moment(data.start).format('DD MMM. YYYY HH:mm')
    }
    const newChoices = choices
    newChoices.push(newChoice)
    setChoices(newChoices)
  }
  React.useEffect(async () => {
    if (!choices || (!choices.length && retrievedChoices.length)) {
      setChoices(retrievedChoices)
    }
  }
  // Jarda help
  //, [setChoices]
  )
  return (
    <SelectInput create={<ServiceRealizationCreate postCreate={postCreate} serviceBase={props.serviceBase}/>} choices={choices} source="service_realization" fullWidth/>
  )
}

const ServiceRealizationCreate = props => {
  const { filter, onCancel, onCreate } = useCreateSuggestionContext()
  const [start, setStart] = React.useState(filter || '')
  const [status, setStatus] = React.useState(filter || '')
  const [create] = useCreate('servicerealization')

  const handleSubmit = (event) => {
    event.preventDefault()
    create(
      {
        payload: {
          data: {
            start: start,
            status: status,
            service_base: props.serviceBase
          }
        }
      },
      {
        onSuccess: ({ data }) => {
          setStart('')
          setStatus('')
          onCreate(data)
          props.postCreate(data)
        }
      }
    )
  }

  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <Dialog open onClose={onCancel}>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <SelectInput onChange={e => setStatus(e.target.value)} choices={config.service_realization_status} label="status" source="service_realization.status"/> <br/>
          <DateTimeInput onChange={e => setStart(e.toJSON())} label="start" source="service_realization.start" /> <br/>
        </DialogContent>
        <DialogActions>
          <Button type="submit">Save</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </form>
  </Dialog>
  )
}

// eslint-disable-next-line react/prop-types
const ServiceOfferingCreate = ({ permissions, ...props }) => {
  const { servicebase_id: strServicebaseId } = parse(props.location.search)
  const servicebaseId = strServicebaseId ? parseInt(strServicebaseId, 10) : ''
  const [serviceBase, setServiceBase] = React.useState(servicebaseId)

  const handleServiceBaseChange = (event) => {
    setServiceBase(event)
  }
  useDefineAppLocation('catalog.serviceoffering.create')

  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <>
      <Create {...props}>
        <SimpleForm defaultValue={{ service_base: servicebaseId, ...props.location.filterValues } }>
          <ReferenceInput onChange={handleServiceBaseChange} label="Service base" source="service_base" reference="servicebase" validate={required()} >
            <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable />
          </ReferenceInput>
          <TextInput source="ident" validate={required()}/>
          {serviceBase !== null ? <ServiceRealizationField serviceBase={serviceBase}/> : null}
          <DateTimeInput
            source="realization_start_from"
            validate={required()}
          />
          <DateTimeInput
            source="realization_start_to"
          />
          <ReferenceInput
            source="service_offering_template"
            reference="serviceofferingtemplate"
            filter={{ service_base: serviceBase }}
            validate={required()}
          >
            <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable />
          </ReferenceInput>
          <ReferenceInput
            label="Offering config"
            source="service_offering_config"
            reference="serviceofferingconfig"
            filter={{ service_base: serviceBase }}
            validate={required()}
          >
            <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable />
          </ReferenceInput>
          <SelectInput choices={config.service_offering_status} source="status" validate={required()}/>
        </SimpleForm>
      </Create>
    </>
  )
}

ServiceOfferingCreate.propTypes = {
  location: PropTypes.object.isRequired
}

ServiceRealizationField.propTypes = {
  serviceBase: PropTypes.number
}

ServiceRealizationInput.propTypes = {
  serviceBase: PropTypes.number
}

ServiceRealizationCreate.propTypes = {
  serviceBase: PropTypes.number,
  postCreate: PropTypes.func
}

export default ServiceOfferingCreate
