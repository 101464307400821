import * as React from 'react'
import { SelectInput, ReferenceInput, TextField, required } from 'react-admin'
import { RowForm } from '@react-admin/ra-editable-datagrid'

import DateField from '../../Components/Datefield'
import PriceInfoInput from '../../Components/PriceInfoInput'
import getConfig from '../../Components/core_config'

const PaymentForm = props => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
  <RowForm {...props}>
    <DateField source="created_at"/>
    <SelectInput source="payment_type" choices={config.payment_type} validate={required()} />
    <TextField source="status" />
    <PriceInfoInput noVat/>
    <TextField source="reference_number" />
    <ReferenceInput source="order" reference="order">
      <SelectInput optionText={(record) => `${record.code}: ${record.price_info !== undefined ? record.price_info.amount : ''} ${record.price_info !== undefined ? record.price_info.currency : ''}`} validate={required()} fullWidth/>
    </ReferenceInput>
    <span>User from selected order</span>
  </RowForm>
  )
}

export default PaymentForm
