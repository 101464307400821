import * as React from 'react'

import {
  TextField,
  ReferenceField
} from 'react-admin'

import {
  Typography
} from '@material-ui/core'

import DetailCard from '../../../Components/DetailCard'

const ServiceBaseCard = props => {
  return (
    <>
    <DetailCard
      title="Service Base"
      reference="servicebase"
      {...props}
    >
      <>
      <Typography variant="body2">
        <ReferenceField source="id" reference="servicebase" link={true}>
          <TextField source="ident"/>
        </ReferenceField>
      </Typography>
      <br/>
      <Typography variant="body2">Realization start selector: </Typography><TextField source="realization_start_selector"/>
      <br/><br/>
      <Typography variant="body2">Realization strategy: </Typography><TextField source="realization_strategy"/>
      </>
    </DetailCard>
    </>
  )
}

export default ServiceBaseCard
