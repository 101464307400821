import React from 'react'
import {
  Create,
  TextInput,
  PasswordInput,
  SimpleForm,
  required
} from 'react-admin'
import { PropTypes } from 'prop-types'
import { generalTitle, ViewTitle } from '../Utils/titles'

const AdminUserCreate = ({ permissions, ...props }) => (
  <>
    <ViewTitle title="Create new admin user" />
    <Create title={generalTitle} {...props}>
      <SimpleForm>
        <TextInput fullWidth source={'name'} validate={required()} resettable />
        <TextInput fullWidth source={'email'} validate={required()} resettable />
        <PasswordInput fullWidth source={'password'} validate={required()} resettable />
      </SimpleForm>
    </Create>
  </>
)

AdminUserCreate.propTypes = {
  permissions: PropTypes.arrayOf(String).isRequired
}

export default AdminUserCreate
