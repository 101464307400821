import * as React from 'react'

import {
  BooleanInput,
  NumberInput,
  required,
  SelectInput,
  TextInput
} from 'react-admin'

import { PropTypes } from 'prop-types'
import DateTimeInput from './DateTimeInput'
/**
 * This is used to show an input of a dynamic type
 *
 * @param {string} label The indication text to show
 * @param {string} source The field to show
 * @param {string} fieldType The field type of the input (choice, bool, int, date, time, datetime, string)
 * @param {Array} choices: A list of object used in SelectInput component if fieldType is equal to 'choice'
 * @returns {React.Component} Return the react component generated from the parameters
 */
const DynamicInput = ({ label, source, fieldType, disabled, choices, props }) => {
  switch (fieldType) {
    case 'choice':
      return <SelectInput label={label} source={source} choices={choices} validate={required()} disabled={disabled} {...props} />
    case 'bool':
      return <BooleanInput label={label} source={source} validate={required()} disabled={disabled} {...props}/>
    case 'int':
      return <NumberInput label={label} source={source} validate={required()} disabled={disabled} {...props} />
    // case 'date':
    //   return <DateInput label={label || 'Date'} source={source} parse={t => dateAdmin.parse(t, 'yyyy-mm-dd') } format={t => dateAdmin.format(t)} validate={required()} disabled={disabled} {...props}/>
    // case 'time':
    //   return <TimeInput label={label || 'Time'} source={source} options={{ format: 'HH:mm:ss' }} parse={t => dateAdmin.parse(t, 'HH:MM:ss') } format={t => dateAdmin.format(t)} validate={required()} disabled={disabled} {...props}/>
    case 'datetime':
      // return <DateTimeInput label={label || 'Date'} source={source} parse={t => dateAdmin.parse(t, 'yyyy-mm-dd') + 'T' + dateAdmin.parse(t, 'HH:MM:ss')} format={t => dateAdmin.format(t)} validate={required()} disabled={disabled} {...props}/>
      return <DateTimeInput label={label || 'Date'} source={source} validate={required()} disabled={disabled} />
    default:
      return <TextInput label={label || 'Text'} source={source} validate={required()} disabled={disabled} {...props} />
  }
}
DynamicInput.propTypes = {
  label: PropTypes.string,
  source: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  choices: PropTypes.array,
  props: PropTypes.object
}
DynamicInput.defaultProps = {
  source: 'value',
  fieldType: '',
  disabled: false,
  choices: {}
}

export default DynamicInput
