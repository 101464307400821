import * as React from 'react'
import { FilterWithSave } from '@react-admin/ra-preferences'
import { TextInput, ReferenceInput, AutocompleteInput } from 'react-admin'

import { DateTimeInput } from '../../Components'
import getConfig from '../../Components/core_config'

const ServiceOfferingFilters = props => {
  const config = getConfig()

  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <FilterWithSave {...props}>
      <ReferenceInput source="service_base" reference="servicebase" alwaysOn>
        <AutocompleteInput optionText="ident" resettable/>
      </ReferenceInput>
      <TextInput
        label="menu.filters.ident"
        source="ident"
        defaultValue=""
      />
      <DateTimeInput
        label="menu.filters.start_after"
        parse={(v) => v ? v._d : v}
        source="realization_start_from"
        resource="serviceoffering"
        alwaysOn
      />
      <DateTimeInput
        label="menu.filters.end_before"
        parse={(v) => v ? v._d : v}
        source="realization_start_to"
        resource="serviceoffering"
        alwaysOn
      />
    </FilterWithSave>
  )
}

export default ServiceOfferingFilters
