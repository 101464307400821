import React from 'react'
import {
  Create,
  FormWithRedirect,
  Toolbar,
  SaveButton,
  useTranslate,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
  required
} from 'react-admin'
import {
  Box,
  Grid,
  Typography
} from '@material-ui/core'
import { Send } from '@material-ui/icons'
import RichTextInput from 'ra-input-rich-text'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { DateTimeInput } from '../../Components'
import getConfig from '../../Components/core_config'

const SupplierMessageCreateForm = (props) => {
  useDefineAppLocation('supplier.suppliermessage.create')
  const translate = useTranslate()
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <FormWithRedirect
    {...props}
    render={formProps => (
    <form>
      <div style={{ padding: 20 }}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography variant="h5">{translate('resources.suppliermessage.menu.summary', { _: 'Summary' })}</Typography>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput source="supplier" reference="supplier" validate={required()}>
              <AutocompleteInput optionText={(record) => `${record.name}: ${record.email}`} fullWidth/>
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <SelectInput choices={config.supplier_message_type} source="message_type" validate={required()}/>
          </Grid>
          <Grid item xs={12}>
            <ReferenceInput source="order_item" reference="orderitem" validate={required()}>
              <AutocompleteInput optionText={(record) =>
                record ? `OI${record.id}: ${record.price_info === undefined ? '' : `${record.price_info.amount} ${record.price_info.currency}`}` : null}
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12}>
            <RichTextInput source="message" fullWidth />
          </Grid>
          <Grid item>
            <DateTimeInput source="response_valid_until" label="Response valid until" />
          </Grid>
        </Grid>
      </div>
      <Toolbar>
        <Box display="flex" justifyContent="space-between" width="100%">
          <SaveButton
            icon={<Send />}
            label="Send"
            saving={formProps.saving}
            handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
          />
        </Box>
      </Toolbar>
    </form>
    )}
    />
  )
}

const SupplierMessageCreate = ({ permissions, ...props }) => (
  <>
    <Create {...props}>
      <SupplierMessageCreateForm />
    </Create>
  </>
)

SupplierMessageCreate.propTypes = {
  permissions: PropTypes.arrayOf(String)
}

export default SupplierMessageCreate
