import * as React from 'react'
import { Fragment, memo } from 'react'
import {
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  Filter,
  List,
  SearchInput,
  TextField
} from 'react-admin' // eslint-disable-line import/no-unresolved
import ListActions from '../../Components/Toolbar/ListActions'
import { ResetViewsButton } from '../../Components'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { useSelectedColumns } from '@react-admin/ra-preferences'
import exporter from '../../Components/exporter'
import DateField from '../../Components/Datefield'

const ServiceBaseFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn />
  </Filter>
)

// eslint-disable-next-line react/display-name
const BookingListBulkActions = memo(props => (
  <Fragment>
    <ResetViewsButton {...props} />
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const bookingColumns = {
  id: <TextField source="id" />,
  from: <TextField source="booking_from"/>,
  status: <TextField source="status" />,
  ticket: <TextField source="ticket" />,
  capacity_pool: <TextField source="capacity_pool" />,
  created_at: <DateField source="created_at" />,
  modified_at: <DateField source="modified_at" />,
  valid_until: <DateField source="valid_until" />
}

const BookingList = (props) => {
  const columns = useSelectedColumns({
    preferences: 'booking.list.columns',
    columns: bookingColumns,
    omit: ['id']
  })
  useDefineAppLocation('catalog.booking')
  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="booking.list.columns" columns={bookingColumns} />}
        bulkActionButtons={<BookingListBulkActions />}
        filters={<ServiceBaseFilter />}
        sort={{ field: 'technicalName', order: 'DESC' }}
        exporter={(data) => exporter(data, 'Booking')}
        perPage={25}
      >
        <Datagrid rowClick="edit" optimized>
          {columns}
        </Datagrid>
      </List>
    </>
  )
}

export default BookingList
