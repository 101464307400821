import AdminUserList from './List'
import AdminUserEdit from './Edit'
import AdminUserCreate from './Create'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
export default {
  list: AdminUserList,
  edit: AdminUserEdit,
  create: AdminUserCreate,
  icon: AccountBoxIcon
}
