import * as React from 'react'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { AutocompleteInput, Create, ReferenceInput, SimpleForm, required } from 'react-admin'

const OrderItemCreate = props => {
  useDefineAppLocation('order.orderitem.create')
  return (
    <>
      <Create {...props}>
        <SimpleForm>
          <ReferenceInput source="order" reference="order" validate={required()}>
            <AutocompleteInput optionText={(record) =>
              record ? `${record.code}: ${record.status}` : null}
              />
          </ReferenceInput>
          <ReferenceInput source="publication" reference="publication" validate={required()}>
            <AutocompleteInput optionText={(record) =>
              record ? `P${record.id} CH${record.channel}: ${record.ident}` : null}
              />
          </ReferenceInput>
          <ReferenceInput source="servicerealization" reference="servicerealization" validate={required()}>
            <AutocompleteInput optionText={(record) =>
              record ? `SR${record.id}: ${record.service_base} ${record.start}` : null}
              />
          </ReferenceInput>
        </SimpleForm>
      </Create>
    </>
  )
}

export default OrderItemCreate
