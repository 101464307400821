import { useDefineAppLocation } from '@react-admin/ra-navigation'
import * as React from 'react'
import { AutocompleteInput, Create, TextInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm } from 'react-admin'
// import RichTextInput from 'ra-input-rich-text'
import { parse } from 'query-string'
import PropTypes from 'prop-types'

import getConfig from '../../Components/core_config'
const PaymentCreate = props => {
  useDefineAppLocation('accounting.payment.create')
  const { order_id: strOrderId } = parse(props.location.search)
  const orderId = strOrderId ? parseInt(strOrderId, 10) : ''

  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ order: orderId, ...props.location.filterValues }}>
        <ReferenceInput label="Order" source="order" reference="order">
          <AutocompleteInput optionText={(record) => `${record.code}: ${record.status}`}/>
        </ReferenceInput>
        <SelectInput
          choices={config.payment_type}
          source="payment_type"
          validate={required()}
        />
        <SelectInput
          choices={config.payment_status}
          source="status"
          validate={required()}
        />
        <TextInput source="reference_number"/>
        <NumberInput source="amount"/>
        <SelectInput source="currency" choices={config.currencies_choices}/>
      </SimpleForm>
    </Create>
  )
}

PaymentCreate.propTypes = {
  location: PropTypes.object
}

export default PaymentCreate
