import * as React from 'react'
import {
  DateField,
  Edit,
  FormWithRedirect,
  ReferenceManyField,
  TextField,
  TextInput
} from 'react-admin'
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid'
import {
  Grid,
  Typography
} from '@material-ui/core'

import { EditActions, DateTimeInput } from '../../Components'
import { JsonInput } from 'react-admin-json-view'

import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'

const ChannelTokenForm = props => (
  <RowForm {...props}>
    <TextInput disabled source="token" helperText="resources.channeltoken.helper.token" fullWidth/>
    <DateTimeInput source="expiration" label="Expiration" helperText="resources.channeltoken.helper.expiration" fullWidth />
    <TextInput source="label" helperText="resources.channeltoken.helper.label" fullWidth />
  </RowForm>
)

const ChannelEditForm = (props) => {
  useDefineAppLocation('settings.channel.edit')
  return (
    <FormWithRedirect
    {...props}
    render={formProps => (
      // custom form
      <form>
        <div style={{ padding: 20 }}>
          <Grid container spacing={4}>
            <Grid container item sm={12} md={6} spacing={2} alignContent="flex-start">
              <Grid item xs={12}>
                <Typography variant="h7">General</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextInput disabled source="id" fullWidth/>
              </Grid>
              <Grid item xs={6}>
                <TextInput source="ident" helperText="resources.channel.helper.ident" fullWidth/>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6}>
                <Grid item xs={12}>
                  <Typography variant="h7">Config</Typography>
                </Grid>
                <Grid item xs={12}>
                  <JsonInput
                    source="config"
                    helperText="resources.channel.helper.config"
                  />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={8}>
                <Typography variant="h7">Channel Tokens</Typography>
              </Grid>
              <Grid item xs={12}>
                <ReferenceManyField reference="channeltoken" target="channel">
                <EditableDatagrid
                  createForm={<ChannelTokenForm initialValues={{ channel: formProps.record.id }} />}
                  editForm={<ChannelTokenForm />}
                  noDelete
                >
                  <TextField source="token"/>
                  <DateField source="expiration" showTime={true} />
                  <TextField source="label" />
                </EditableDatagrid>
                </ReferenceManyField>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <SaveDeleteToolbar {...formProps} />
      </form>
    )}
  />
  )
}

const ChannelEdit = ({ permissions, ...props }) => (
  <>
    <Edit
      actions={<EditActions />}
      {...props}
    >
      <ChannelEditForm />
    </Edit>
  </>
)

ChannelEdit.propTypes = {
  permissions: PropTypes.arrayOf(String)
}

export default ChannelEdit
