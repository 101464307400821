import ServiceOfferingConfigCreate from './Create'
import ServiceOfferingConfigEdit from './Edit'
import ServiceOfferingConfigList from './List'

export default {
  list: ServiceOfferingConfigList,
  create: ServiceOfferingConfigCreate,
  edit: ServiceOfferingConfigEdit
}

export { default as Card } from './Components/Card'
