import czechMessages from 'ra-language-czech'

const customCzechMessages = {
  simple: {
    action: {
      enable: 'Zapnout vše',
      disable: 'Vypnout vše',
      datatagsedit: 'Editovat datatagy',
      create: 'Vytvořit',
      refresh_payment_status: 'Aktualizovat stav platby',
      refresh_payment_status_bulk: 'Aktualizovat stav vybraných'
    },
    ui_help: 'Nápověda'
  },
  notifications: {
    dateupdated: 'Datum aktualizováno',
    apierror: 'Nastala chyba s API',
    payment_state_fetch: {
      bulk: {
        failed: 'Nebylo možné aktualizovat všechny vybrané',
        success: 'Stavy úspěšně aktualizovány'
      },
      single: {
        failed: 'Nebylo možné aktualizovat stav platby',
        success: 'Stav platby úspšeně aktualizován'
      }
    }
  },
  menu: {
    save_close: 'Uložit a zavřít',
    save_continue: 'Uložit a pokračovat',
    filters: {
      from_today: 'Od dneška',
      reset: 'Resetovat filtry',
      ident: 'Ident',
      start_after: 'Začíná po',
      end_before: 'Končí před'
    },
    navigation: {
      accounting: 'Účetnictví',
      advanced: 'Rozšířené',
      accountingdocuments: 'Faktury',
      payments: 'Platby',
      publications: 'Publikace',
      channels: 'Kanály',
      translations: 'Překlady',
      images: 'Obrázky',
      documents: 'Dokumenty',
      geolocations: 'Geolokace',
      contacts: 'Kontakty',
      redirects: 'Přesměrování',
      menuitems: 'Položky menu',
      menutree: 'Strom menu',
      baskets: 'Košíky',
      channelusers: 'Uživatelé',
      expenditures: 'Výdaje',
      orderitems: 'Položky objednávky',
      realizationcalendar: 'Kalendář realizací',
      servicerealizations: 'Realizace služeb',
      bookings: 'Rezervace',
      customers: 'Zákazníci',
      orders: 'Objednávky',
      settings: 'Nastavení',
      services: 'Služby',
      serviceofferings: 'Nabídka služeb',
      offeringcalendar: 'Kalendář nabídek',
      serviceofferingtemplates: 'Atributy služeb',
      serviceofferingconfig: 'Ceny služeb',
      suppliers: 'Dodavatelé',
      suppliermessages: 'Komunikace s dodavateli',
      currencyexchange: 'Převodník měn'
    }
  },
  resources: {
    accountingdocument: {
      fields: {
        type: 'Typ dokumentu'
      }
    },
    channel: {
      fields: {
        technical_name: 'Technický název'
      },
      helper: {
        ident: 'Identifikátor kanálu',
        technical_name: 'Název použitý v administrátorském rozhraní',
        config: 'Konfigurace kanálu'
      }
    },
    channeltoken: {
      helper: {
        token: 'Automaticky generované',
        expiration: 'Pokud toto pole necháte prázdné, token expiruje za 4 týdny',
        label: 'Volitelný název'
      }
    },
    menuitem: {
      fields: {
        channel: 'Kanál',
        menu_ident: 'Identifikátor menu',
        title: 'Název',
        sort_order: 'Pořadí'
      },
      helper: {
        sort_order: 'Hodnota pořadí dle které jsou položky řazeny (od nejmenší po největší)',
        menu: 'Skupina menu kde tato položka bude',
        parent: 'Menu, které bude obsahovat aktuální menu'
      }
    },
    order: {
      fields: {
        status: 'Stav',
        channel: 'Kanál'
      }
    },
    payment: {
      fields: {
        status: 'Stav platby',
        type: 'Typ platby'
      }
    },
    publication: {
      title: 'Publikace',
      show: 'Zobrazit',
      fields: {
        enabled: 'Zapnuto'
      },
      helper: {
        title: 'Název publikace',
        ident: 'Identifikátor publikace. Bude použito v URL',
        auto_slug: 'Automaticky generuje slug',
        slug: 'Slug je část URL, která umožňuje snadno identifikovat publikaci a snadno se čte',
        tags: 'Tagy jsou nezbytné pro zařazení publikace do kategorií aby je bylo možné filtrovat',
        channel: 'Publikace bude v tomto kanále',
        template: 'Definuje pole, filtry a snippety. POZOR: Při změně prosím proveďte refresh stránky prohlížeče!'
      },
      menu: {
        fields: 'Pole'
      }
    },
    redirect: {
      helper: {
        source: 'Zdroj přesměrování',
        target: 'Cíl přesměrování',
        updated_at: 'Poslední změna',
        channel: 'Kanál přesměrování'
      }
    },
    servicebase: {
      title: 'Služba',
      fields: {
        technical_name: 'Technický název',
        suppliers_name: 'Dodavatelův název'
      }
    },
    serviceofferingconfig: {
      title: 'Nastavení cen nabídek'
    },
    serviceofferingtemplate: {
      title: 'Šablony atributů nabídek'
    },
    serviceoffering: {
      menu: {
        attributes: 'Šablona',
        pricing: 'Ceník',
        today: 'Od dneška'
      }
    }
  }
}

export default { ...czechMessages, ...customCzechMessages }
