import OrderFullList from './List'
import OrderEdit from './Edit'
import OrderCreate from './Create'

import ReceiptIcon from '@material-ui/icons/Receipt'

export default {
  list: OrderFullList,
  edit: OrderEdit,
  create: OrderCreate,
  icon: ReceiptIcon
}

export { getColumns } from './Components/OrderList'
