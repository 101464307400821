import * as React from 'react'
import PropTypes from 'prop-types'
import { ReferenceManyField, TextField, TextInput, BooleanInput } from 'ra-ui-materialui'
import {
  required
  // useRecordContext
} from 'ra-core'
import { Pagination, BooleanField, useGetList, FunctionField } from 'react-admin'
import { JsonInput } from 'react-admin-json-view'
import ShortTextField from '../../Components/ShortTextField'

import { DatagridWithDialog } from '../Dialogs'
import TextInputWithSuggestions from '../TextInputWithSuggestions'
// import SnippetDataContext from './SnippetDataContext'

const PublicationSnippetDataEditForm = props => {
  const { record } = props
  return (
    <ReferenceManyField reference="publicationsnippetdata" target="publication" sort={{ field: 'sort_order', order: 'ASC' }} pagination={<Pagination />} fullWidth>
      <DatagridWithDialog
        defaultValue={{
          publication: record.id
        }}
        form={<PublicationSnippetDataRow publicationTemplateIdent={record.publication_template_ident}/>}
        rowClick="edit"
      >
        <TextField source="section"/>
        <TextField source="sort_order"/>
        <TextField source="snippet_ident"/>
        <FunctionField label="Context" render={record => <ShortTextField textLength={40} text={record.context}/>}/>
        <FunctionField label="Query" render={record => <ShortTextField textLength={20} text={record.query}/>}/>
        <TextField source="master_snippet"/>
        <BooleanField source="enabled"/>
      </DatagridWithDialog>
    </ReferenceManyField>
  )
}

const getSectionConfig = (publicationTemplateIdent) => {
  const { data, loading, error } = useGetList(
    'publicationtemplate',
    { page: 1, perPage: 1 },
    { field: 'id', order: 'ASC' },
    { ident: publicationTemplateIdent }
  )
  if (loading) return 'loading'
  if (error) return 'error'
  const sections = data[Object.keys(data)[0]].config.sections
  return sections
}

const PublicationSnippetDataRow = props => {
  // const record = useRecordContext(props)
  const sectionConfig = getSectionConfig(props.publicationTemplateIdent)
  const [sectionChoices, setSectionChoices] = React.useState([])
  const [snippetIdentChoices, setSnippetIdentChoices] = React.useState([])

  const updateSnippetIdentChoices = (allowedSnippets) => {
    const choices = []
    allowedSnippets.forEach((snippet) => choices.push({ id: snippet, name: snippet }))
    setSnippetIdentChoices(choices)
  }

  const handleSectionChange = (e) => {
    if (sectionConfig !== 'error' && sectionConfig !== 'loading') {
      if (sectionConfig[e] !== undefined) {
        updateSnippetIdentChoices(sectionConfig[e].allowed_snippets)
      }
    }
  }

  React.useEffect(() => {
    if (sectionConfig !== 'error' && sectionConfig !== 'loading' && sectionChoices.length <= 0) {
      const choices = []
      Object.keys(sectionConfig).forEach((section) => choices.push({ id: section, name: section }))
      setSectionChoices(choices)
      if (props.record) {
        if (sectionConfig[props.record.section] !== undefined) {
          updateSnippetIdentChoices(sectionConfig[props.record.section].allowed_snippets)
        }
      }
    }
  })
  return (
  <>
    <TextInputWithSuggestions source="section" choices={sectionChoices} validate={required()} onChange={handleSectionChange} fullWidth/><br/>
    <TextInputWithSuggestions source="snippet_ident" choices={snippetIdentChoices} validate={required()} fullWidth/>
    <TextInput source="master_snippet" fullWidth/>
    <TextInput source="sort_order" validate={required()} fullWidth/>
    <BooleanInput initialValue={props.record && props.record.enabled != null ? props.record.enabled : true } source="enabled"/>
    {/* <SnippetDataContext record={record}/> <br/><br/> */}
    <JsonInput source="context" {...props}/>
    <JsonInput source="query" {...props}/>
  </>
  )
}

PublicationSnippetDataEditForm.propTypes = {
  record: PropTypes.object.isRequired
}

PublicationSnippetDataRow.propTypes = {
  record: PropTypes.object,
  publicationTemplateIdent: PropTypes.string
}

export default PublicationSnippetDataEditForm
