
import * as React from 'react'

import {
  ChonkyActions,
  FileHelper
} from 'chonky'

import { MEDIA_URL, API_URL, httpClient, blobToData } from '../../dataProvider'
import { fetchEnd, fetchStart } from 'ra-core'

const getCorrectedPath = path => {
  return path.startsWith('/') ? path : '/' + path
}

export const useFiles = (currentFolderId, fileMap) => {
  const currentFolder = fileMap[currentFolderId]
  const files = currentFolder.childrenIds
    ? currentFolder.childrenIds.map((fileId) => fileMap[fileId] ? fileMap[fileId] : null)
    : []
  return files
}

export const useFolderChain = (currentFolderId, fileMap) => {
  const currentFolder = fileMap[currentFolderId]
  const folderChain = [currentFolder]

  let parentId = currentFolder.parentId
  while (parentId) {
    const parentFile = fileMap[parentId]
    if (parentFile) {
      folderChain.unshift(parentFile)
      parentId = parentFile.parentId
    } else {
      parentId = null
    }
  }

  return folderChain
}

export const useFileActionHandler = (handleOpen, handleDelete, handleCreateFolder) => {
  return React.useCallback(
    (data) => {
      if (data.id === ChonkyActions.OpenFiles.id) {
        const { targetFile, files } = data.payload
        const fileToOpen = targetFile || files[0]
        if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
          handleOpen(fileToOpen.id)
        } else {
          const downloadLink = document.createElement('a')
          downloadLink.href = MEDIA_URL + '/' + fileToOpen.id
          downloadLink.target = '_blank'
          downloadLink.download = fileToOpen.name
          downloadLink.click()
        }
      } else if (data.id === ChonkyActions.CreateFolder.id) {
        const folderName = prompt('Provide the name for your new folder:')
        handleCreateFolder(folderName)
      } else if (data.id === ChonkyActions.DeleteFiles.id) {
        data.state.selectedFilesForAction.map(file => handleDelete(file.id))
      }
    }
  )
}

export const deleteFile = async (hooks, path) => {
  if (path.startsWith('.')) {
    path = path.slice(1)
  }
  const correctedPath = getCorrectedPath(path)
  hooks.dispatch(fetchStart())
  return httpClient(API_URL + '/files' + correctedPath, {
    method: 'DELETE'
  })
    .then((response) => {
      hooks.dispatch(fetchEnd())
      return response
    })
}

export const getTransformedFile = (file, fileName) => {
  let preview = ''
  const rawFile = file || ''
  if (file) {
    if (!file.path) return file
    preview = URL.createObjectURL(file)
  }
  return {
    rawFile: rawFile,
    src: preview,
    title: fileName
  }
}

export const uploadFile = async (transformedFile, path) => {
  const base64file = await fileToBase64(transformedFile)
  const data = {
    file: {
      data: base64file,
      file_path: path + transformedFile.title
    }
  }
  await httpClient(API_URL + '/files/upload', {
    method: 'POST',
    body: JSON.stringify(data)
  })
}

export const getTree = async (hooks, path = '') => {
  const correctedPath = getCorrectedPath(path)
  hooks.dispatch(fetchStart())
  return httpClient(API_URL + '/files' + correctedPath + '/', {
    method: 'GET'
  }, false)
    .then((response) => {
      if (response.status !== 200) hooks.notify('Error with api', 'error')
      else {
        hooks.dispatch(fetchEnd())
        return response.json
      }
    })
}

export const fileToBase64 = async (transformedFile) => {
  return new Promise((resolve) => {
    fetch(transformedFile.src).then(r => {
      r.blob().then((blob) => {
        const filename = transformedFile.title
        blobToData(blob).then((filedata) => {
          const base64file = `filename:${filename};${filedata}`
          resolve(base64file)
        })
      })
    })
  })
}
