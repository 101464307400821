import * as React from 'react'

import {
  ReferenceManyField,
  Datagrid,
  TextField,
  FunctionField,
  ReferenceField
} from 'react-admin'

import {
  Typography,
  Grid
} from '@material-ui/core'

import { PropTypes } from 'prop-types'

import HoursField from '../../../Components/Fields/HoursField'
import DetailCard from '../../../Components/DetailCard'
import ShortTextField from '../../../Components/ShortTextField'
import PriceField from '../../../Components/PriceField'
import CapacityPoolTemplateList from '../../CapacityPool/Components/CapacityPoolTemplateList'

const ServiceOfferingConfigCard = props => {
  return (
    <>
    <DetailCard
    title="Offering Config"
    reference="serviceofferingconfig"
    {...props}
    >
    <Grid container spacing={2} style={{ width: '100%' }}>
      <Grid container item xs={12}>
        <Grid item xs={6}>
          <Typography variant="body2">Ident: </Typography>
          <TextField source="ident"/>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="body2">Min. Lead Time: </Typography>
          <HoursField source="min_lead_time"/>
        </Grid>
      </Grid>
      {props.withAttributes
        ? <>
        <br/> <br/>
        <Grid item xs={12}>
          <Typography variant="h6">Sell price items</Typography>
          <ReferenceManyField sort={{ field: 'sort_order', order: 'ASC' }} reference="sellpriceitem" target="service_offering_config">
            <Datagrid optimized>
              <FunctionField label="Ident" render={record => <ShortTextField textLength={10} text={record.ident}/>}/>
              <TextField source="capacity_pool_tag" />
              <PriceField label="Price" priceSource="price" />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <br/> <br/>
        <Grid item xs={12}>
          <Typography variant="h6">Buy price items</Typography>
          <ReferenceManyField sort={{ field: 'ident', order: 'ASC' }} reference="buypriceitem" target="service_offering_config">
            <Datagrid optimized>
              <TextField source="ident" />
              <ReferenceField source="supplier" reference="supplier">
                <TextField source="name"/>
              </ReferenceField>
              <PriceField label="Amount" priceSource="amount" />
            </Datagrid>
          </ReferenceManyField>
        </Grid>
        <br/> <br/>
        <Grid item xs={12}>
          <Typography variant="h6">Capacity pool templates</Typography>
          <CapacityPoolTemplateList
           columnsPref="capacitypooltemplate.configcard.columns"
           target="service_offering_config"
           omitColumns={['status']}
           noEdit
          />
        </Grid>
        </>
        : null
      }
        </Grid>
    </DetailCard>
    </>
  )
}

ServiceOfferingConfigCard.propTypes = {
  withAttributes: PropTypes.bool,
  record: PropTypes.object
}

export default ServiceOfferingConfigCard
