import * as React from 'react'
import {
  AutocompleteInput,
  BooleanInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  Button,
  useRecordContext
} from 'react-admin'

import { Link } from 'react-router-dom'

import TextInputWithSuggestions from '../../Components/TextInputWithSuggestions'

import { API_URL, httpClient } from '../../dataProvider'
import { getMenuGroups, useDidMount } from '../../Components/Utils'

import ExitToApp from '@material-ui/icons/ExitToApp'

const getChannels = async () => {
  const data = await httpClient(API_URL + '/channel', { method: 'GET' })
    .then((response, data) => {
      return response.json
    })
  const choices = {}
  data.results.forEach(d => {
    choices[d.id] = d.ident
  })
  return choices
}

const MenuItemForm = props => {
  const choices = getMenuGroups()
  const record = useRecordContext()
  const [publication, setPublication] = React.useState(record.publication)
  const [channels, setChannels] = React.useState(undefined)
  const [channel, setChannel] = React.useState(record.channel)

  const didMount = useDidMount()
  React.useEffect(() => {
    if (didMount) {
      getChannels().then(r => {
        setChannels(r)
      })
    }
  })

  const channelIdent = channelId => {
    if (channels === undefined) return ''
    return channels[channelId]
  }

  return (
    <>
      <ReferenceInput
        source="channel"
        reference="channel"
        validate={required()}
        onChange={(v) => setChannel(v.target.value)}
      >
        <SelectInput optionText="ident" />
      </ReferenceInput>
      <TextInput source="title" validate={required()} fullWidth />
      <BooleanInput source="enabled" />
      <NumberInput
        source="sort_order"
        helperText="resources.menuitem.helper.sort_order"
        validate={required()}
      />
      <TextInputWithSuggestions
        source="group"
        choices={choices}
        helperText="resources.menuitem.helper.menu"
        validate={required()}
      />
      <ReferenceInput
        label="Parent menu"
        source="parent"
        reference="menuitem"
        filter={{ channel: channel }}
      >
        <AutocompleteInput
          optionText={(record) =>
            record === undefined
              ? ''
              : `${channelIdent(record.channel)} — ${record.group} — ${record.title}`
          }
          helperText="resources.menuitem.helper.parent"
          resettable
        />
      </ReferenceInput>
      <TextInput source="target_url" />
      <ReferenceInput
        label="Publication"
        fullWidth
        source="publication"
        reference="publication"
        filter={{ channel: channel }}
        onChange={(e) => setPublication(e)}
      >
        <AutocompleteInput
          optionText={(record) =>
            record === undefined
              ? ''
              : `${channelIdent(record.channel)} — ${record.ident} — ${record.title}`
          }
          suggestionLimit={50}
          resettable
        />
      </ReferenceInput>
      <Button
        startIcon={<ExitToApp />}
        label="See Publication"
        component={Link}
        to={`/publication/${publication}`}
        disabled={publication === undefined}
      />
    </>
  )
}

export default MenuItemForm
