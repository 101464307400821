import { useDefineAppLocation } from '@react-admin/ra-navigation'
import * as React from 'react'
import { Create, SimpleForm, TextInput, required, ReferenceInput, AutocompleteInput, SelectInput } from 'react-admin'
import getConfig from '../../Components/core_config'
import { parse } from 'query-string'
import PropTypes from 'prop-types'

const ServiceOfferingTemplateCreate = props => {
  useDefineAppLocation('catalog.serviceofferingtemlate.create')
  const { service_base_id: strServicebaseId } = parse(props.location.search)
  const servicebaseId = strServicebaseId ? parseInt(strServicebaseId, 10) : ''
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <Create {...props}>
      <SimpleForm defaultValue={{ service_base: servicebaseId, ...props.location.filterValues }}>
        <TextInput source="ident" validate={required()}/>
        <ReferenceInput label="Service Base" source="service_base" reference="servicebase">
          <AutocompleteInput
            optionText={(record) => `${record.id}: ${record.ident}`}
          />
        </ReferenceInput>
        <SelectInput
          source="content.order_form_template"
          label="Order form workflow"
          choices={config.order_form_template}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}

ServiceOfferingTemplateCreate.propTypes = {
  location: PropTypes.object
}

export default ServiceOfferingTemplateCreate
