import ServiceOfferingList from './List'
import ServiceOfferingEdit from './Edit'
import ServiceOfferingCreate from './Create'

import EventNoteIcon from '@material-ui/icons/EventNote'

export default {
  list: ServiceOfferingList,
  edit: ServiceOfferingEdit,
  create: ServiceOfferingCreate,
  icon: EventNoteIcon
}

export { default as Calendar } from './Calendar'
