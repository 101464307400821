import * as React from 'react'
import {
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItem,
  Grid,
  Typography
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import {
  ReferenceInput,
  useGetMany,
  AutocompleteInput,
  TextInput,
  Toolbar,
  useUpdate
} from 'react-admin'
import { Form } from 'react-final-form'
import { DateTimeInput } from '../../../Components'

const SaveAllButton = (props) => {
  // eslint-disable-next-line no-unused-vars
  const updatesCall = []
  const updatesError = []
  const updatesLoading = []
  for (let index = 0; index < props.data.length; index++) {
    const [update, { error, loading }] = useUpdate()
    updatesError.push(error)
    updatesLoading.push(loading)
    updatesCall.push(update)
  }
  const handleClick = () => {
    for (let index = 0; index < props.data.length; index++) {
      updatesCall[index]({ resource: 'serviceoffering', payload: { id: props.data[index].id, data: props.data[index], previousData: props.previousData[index] } })
    }
    if (updatesError.includes(true)) { return <p>ERROR</p> }
  }
  return (
    <Button
      color="primary"
      disabled={updatesLoading.includes(true)}
      onClick={handleClick}
    >
      Save all
    </Button>
  )
}

const CloneOfferings = (props) => {
  const ids = String(props.match.params.ids).split(',')
  const { data, loading, error } = useGetMany('serviceoffering', ids)
  const dataToSave = data
  const previousData = data

  const handleChange = (formProps) => {
    // formProps === undefined ? console.log('undefined') : console.log(formProps.form)
    if (formProps !== undefined) {
      const value = formProps.form.getState().values
      for (const key of Object.keys(dataToSave)) {
        if (dataToSave[key].id === value.id) {
          dataToSave[key] = value
        }
      }
    }
  }

  return (
    <Card>
    <Grid container spacing={4} xs={12} alignContent="flex-start">
      <Grid item xs={12} style={{ textAlign: 'center' }}>
        <Typography variant="h4">Cloning: {props.match.params.ids}</Typography>
      </Grid>
      <Grid item xs={12}>
      {loading
        ? (<p>loading</p>)
        : (error
            ? (<p>error</p>)
            : (
        <List>
          {data.map(offering => (
            <>
            <Divider />
            <ListItem key={offering.id}>
              <Form onSubmit={() => {}} initialValues={offering}>
                {formProps => (
                  <form style={{ width: '100%' }} onSubmit={formProps.handleSubmit}>
                    <Grid container xs={12} spacing={2}>
                      <Grid item xs={3}>
                        <TextInput source="ident" fullWidth onChange={() => handleChange(formProps)} />
                      </Grid>
                      <Grid item xs={3}>
                        <ReferenceInput label="Service base" fullWidth source="service_base" reference="servicebase" >
                          <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable onInputValueChange={() => handleChange(formProps)} />
                        </ReferenceInput>
                      </Grid>
                      <Grid item xs={3}>
                        <DateTimeInput
                          source="realization_start_from"
                          onChange={() => handleChange(formProps)}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <DateTimeInput
                          source="realization_start_to"
                          onChange={() => handleChange(formProps)}
                        />
                      </Grid>
                    </Grid>
                  </form>
                )}
              </Form>
            </ListItem>
            </>
          ))}
        </List>
              )
          )
      }
      </Grid>
    </Grid>
    <Toolbar>
      <Box display="flex" justifyContent="space-between" width="100%">
        <SaveAllButton data={loading || error ? [] : dataToSave} previousData={previousData}/>
      </Box>
    </Toolbar>
    </Card>
  )
}

CloneOfferings.propTypes = {
  match: PropTypes.object.isRequired
}

SaveAllButton.propTypes = {
  data: PropTypes.object.isRequired,
  previousData: PropTypes.object.isRequired
}

export default CloneOfferings
