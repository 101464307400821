import * as React from 'react'

import {
  BooleanField,
  NumberField,
  TextField
} from 'react-admin'

import { isUndefinedOrNull } from '../Utils'
import { PropTypes } from 'prop-types'
import DateField from './Datefield'

/**
 * This is used to show a field of a dynamic type
 *
 * @param {string} label The indication text to show
 * @param {Object} record This is the current record, it should be provided by React Admin, but it should have a field_type attribute
 * @param {string} source The field to show
 * @param {string} fieldType The field type of the input (bool, int, date, time, datetime, string)
 * @returns {React.Component} Return the react component generated from the parameters
 */
const DynamicField = ({ label, record, source, fieldType, props }) => {
  if (isUndefinedOrNull(record)) return null
  if (fieldType === '') fieldType = record.field_type
  switch (fieldType) {
    case 'choice':
      return <div>no Field available for choice field type</div>
    case 'bool':
      return <BooleanField label={label || 'Include the item'} source={source} record={record} {...props}/>
    case 'int':
      return <NumberField label={label || 'Number of item'} source={source} record={record} {...props}/>
    case 'date':
      return <DateField label={label || 'Date'} source={source} record={record} {...props}/>
    case 'time':
      return <TextField label={label || 'Time'} source={source} record={record} {...props}/>
    case 'datetime':
      return <DateField label={label || 'Date'} source={source} record={record} showTime {...props}/>
    default:
      return <TextField label={label || 'Item text'} source={source} record={record} {...props}/>
  }
}
DynamicField.propTypes = {
  label: PropTypes.string,
  record: PropTypes.object.isRequired,
  source: PropTypes.string.isRequired,
  fieldType: PropTypes.string.isRequired,
  props: PropTypes.object
}
DynamicField.defaultProps = {
  record: {},
  source: 'value',
  fieldType: ''
}

export default DynamicField
