import * as React from 'react'

import {
  addField,
  useTranslate,
  FormField
} from 'react-admin' // eslint-disable-line import/no-unresolved

import {
  InputAdornment,
  IconButton,
  TextField as MuiTextField,
  Grid
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// this component is used for `min_lead_time` and saves hours as seconds
const HoursInput = ({
  input,
  meta,
  variant = 'filled',
  margin = 'dense',
  ...rest
}) => {
  const handleChangeValue = e => {
    if (e.target.value !== '' && isNaN(parseInt(e.target.value))) return
    setDisplayedValue(e.target.value)
  }
  const translate = useTranslate()

  const [displayedValue, setDisplayedValue] = React.useState(!input.value ? '' : `${input.value / 3600}`)

  const getRealValue = () => Math.ceil(parseFloat(displayedValue) * 3600)

  React.useEffect(() => {
    input.onChange(isNaN(parseFloat(displayedValue)) ? null : getRealValue())
  }, [displayedValue])

  const getEndAdornment = () => (
      <InputAdornment position="end">
        <span style={{ marginTop: '1rem' }}>
          &nbsp;hour(s)
        </span>
        <IconButton
            aria-label={translate('ra.action.clear_input_value')}
            title={translate('ra.action.clear_input_value')}
            disableRipple
            onClick={() => setDisplayedValue('')}
            style={{
              height: 24,
              width: 24,
              padding: 0
            }}
        >
          <ClearIcon
            style={{
              height: 16,
              width: 16
            }}
          />
        </IconButton>
      </InputAdornment>
  )

  return <Grid
      container
      direction="row"
      justifyContent="center"
      spacing={0}
    >
      <Grid item xs={6}>
        <MuiTextField
          value={displayedValue}
          variant={variant}
          margin={margin}
          align='right'

          onChange={handleChangeValue}
          onFocus={input.onFocus}
          onBlur={input.onBlur}

          InputProps={{ endAdornment: getEndAdornment() }}
          {...rest}
        />
      </Grid>
    </Grid>
}

HoursInput.propTypes = MuiTextField.propTypes | FormField.propTypes

export default addField(HoursInput)
