import React from 'react'
import {
  FormWithRedirect,
  Create,
  CreateActions,
  TextInput,
  SaveButton,
  DeleteButton,
  Toolbar,
  ReferenceInput,
  SelectInput,
  PasswordInput
} from 'react-admin'
import {
  Box,
  Typography,
  Grid
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { JsonInput } from 'react-admin-json-view'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { SimpleForm } from '@react-admin/ra-tree'
import { WithProps } from '../../Utils'

const ChannelUserCreateForm = (props) => {
  useDefineAppLocation('customer.channeluser.create')
  return (
  <FormWithRedirect
    {...props}
    render={formProps => {
      console.log(props)
      formProps.initialValues = props.location.filterValues
      return (
      // custom form
      <form>
        <div style={{ padding: 20 }}>
          <Grid container spacing={4}>
            <Grid container item sm={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h7">General</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="username" label="resources.channeluser.fields.username" fullWidth/>
              </Grid>
              <Grid item xs={12}>
                <PasswordInput source="password" label="resources.channeluser.fields.password" fullWidth/>
              </Grid>
              <Grid item xs={9}>
                <ReferenceInput source="channel" reference="channel">
                  <SelectInput optionText="ident" fullWidth/>
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6}>
                <Grid item xs={12}>
                  <Typography variant="h7">Content</Typography>
                </Grid>
                <Grid item xs={12}>
                  <JsonInput
                    source="content"
                  />
                </Grid>
            </Grid>
          </Grid>
        </div>

        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
                <DeleteButton
                  record={formProps.record}
                  redirect={formProps.basePath}
                />
            </Box>
        </Toolbar>
      </form>
      )
    }}
  />
  )
}

const ChannelUserCreate = ({ permissions, location, ...props }) => {
  const filterValues = location.filterValues
  return (
  <>
    <Create
      actions={<CreateActions />}
      {...props}
      >
      <WithProps>{({ ...props }) =>
        <SimpleForm {...props} initialValues={filterValues}>
          <Grid container spacing={4} style={{ width: '100%' }}>
            <Grid container item sm={12} md={6} spacing={2} >
              <Grid item xs={12}>
                <Typography variant="h7">General</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="username" label="resources.channeluser.fields.username" fullWidth/>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="mail" fullWidth/>
              </Grid>
              <Grid item xs={12}>
                <PasswordInput source="password" label="resources.channeluser.fields.password" fullWidth/>
              </Grid>
              <Grid item xs={9}>
                <ReferenceInput source="channel" reference="channel">
                  <SelectInput optionText="ident" fullWidth/>
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6}>
                <Grid item xs={12}>
                  <Typography variant="h7">Content</Typography>
                </Grid>
                <Grid item xs={12}>
                  <JsonInput
                    source="content"
                  />
                </Grid>
            </Grid>
          </Grid>
        </SimpleForm>
      }
      </WithProps>
    </Create>
  </>
  )
}

ChannelUserCreate.propTypes = {
  location: PropTypes.object,
  permissions: PropTypes.arrayOf(String).isRequired
}

ChannelUserCreateForm.propTypes = {
  location: PropTypes.object
}

export default ChannelUserCreate
