import React from 'react'
import {
  FormWithRedirect,
  Create,
  CreateActions,
  TextInput,
  SaveButton,
  Toolbar,
  required
} from 'react-admin'
import {
  Box,
  Typography,
  Grid
} from '@material-ui/core'
import { PropTypes } from 'prop-types'
import { JsonInput } from 'react-admin-json-view'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

const ChannelCreateForm = (props) => {
  useDefineAppLocation('settings.channel.create')
  return (
  <FormWithRedirect
    {...props}
    render={formProps => (
      // custom form
      <form>
        <div style={{ padding: 20 }}>
          <Grid container spacing={4}>
            <Grid container item sm={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h7">General</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="ident" helperText="resources.channel.helper.ident" validate={required()} fullWidth/>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6}>
                <Grid item xs={12}>
                  <Typography variant="h7">Config</Typography>
                </Grid>
                <Grid item xs={12}>
                  <JsonInput
                    source="config"
                    helperText="resources.channel.helper.config"
                  />
                </Grid>
            </Grid>
          </Grid>
        </div>

        <Toolbar>
            <Box display="flex" justifyContent="space-between" width="100%">
                <SaveButton
                    saving={formProps.saving}
                    handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                />
            </Box>
        </Toolbar>
      </form>
    )}
  />
  )
}

const ChannelCreate = ({ permissions, ...props }) => (
  <>
    <Create
      actions={<CreateActions />}
      {...props}
    >
      <ChannelCreateForm />
    </Create>
  </>
)

ChannelCreate.propTypes = {
  permissions: PropTypes.arrayOf(String).isRequired
}

export default ChannelCreate
