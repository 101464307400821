import * as React from 'react'
import { Fragment, memo } from 'react'
import { useMediaQuery } from '@material-ui/core'

import {
  AutocompleteArrayInput,
  BooleanField,
  BulkDeleteButton,
  BulkExportButton,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  Pagination,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleList,
  TextField,
  TextInput,
  useTranslate
} from 'react-admin'

import { BulkEnableButton, BulkDisableButton } from '../../Components'
import ListActions from '../../Components/Toolbar/ListActions'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { useSelectedColumns, FilterWithSave } from '@react-admin/ra-preferences'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import GoToPublication from '../../Components/PublicationContent/GoToPublication'
import exporter from '../../Components/exporter'
import { API_URL, httpClient } from '../../dataProvider'
import { useDidMount } from '../../Components/Utils'
import BulkEditDataTagsButton from './Components/BulkEditDataTagsButton'

const getDataTags = async () => {
  const data = await httpClient(API_URL + '/datatag/idents', { method: 'GET' })
    .then((response, data) => {
      return response.json
    })
  const choices = []
  data.forEach(d => {
    choices.push({ id: d.ident, name: d.ident })
  })
  return choices
}

const PublicationFilter = props => {
  const [choices, setChoices] = React.useState([])
  const didMount = useDidMount()
  React.useEffect(() => {
    if (didMount) {
      getDataTags().then(r => {
        setChoices(r)
      })
    }
  })
  return (
    <FilterWithSave {...props}>
      <TextInput source="q" label="ra.action.search" alwaysOn margin="dense" />
      <ReferenceInput source="channel" reference="channel">
        <SelectInput optionText="ident" />
      </ReferenceInput>
      <TextInput source="ident"/>
      <AutocompleteArrayInput choices={choices.length > 0 ? choices : []} source="tags"/>
    </FilterWithSave>
  )
}

// eslint-disable-next-line react/display-name
const PublicationListBulkActions = memo(props => (
  <Fragment>
    <BulkEditDataTagsButton {...props} />
    <BulkEnableButton {...props} />
    <BulkDisableButton {...props} />
    <BulkDeleteButton {...props} />
    <BulkExportButton {...props} />
  </Fragment>
))

const publicationColumns = {
  id: <TextField source="id" />,
  ident: <TextField source="ident" />,
  channel:
  <ReferenceField label="Channel" source="channel" reference="channel" link={false}>
    <TextField source="ident" />
  </ReferenceField>,
  // technical_name: <TextField source="technical_name" />,
  title: <FunctionField sortBy="title" source="title" render={record => record.title.length > 50 ? String(record.title).substring(0, 50) + '...' : record.title} />,
  slug: <TextField source="slug" />,
  template: <TextField source="publication_template_ident" />,
  enabled: <BooleanField source="enabled" />,
  sitemap_enabled: <BooleanField source="sitemap_enabled" />,
  sort_order: <TextField label="Sort order" source="datatags.SORT_ORDER" />,
  rating: <TextField label="Rating" source="datatags.RATING" />,
  agp_selection: <TextField label="AGP Selection" source="datatags.AGP_SELECTION" />,
  ribbon_new: <TextField label="Ribbon new" source="datatags.RIBBON_NEW" />,
  ribbon_special: <TextField label="Ribbon special" source="datatags.RIBBON_SPECIAL" />,
  ribbon_coupon: <TextField label="Ribbon coupon" source="datatags.RIBBON_COUPON" />
}

const PublicationList = props => {
  const columns = useSelectedColumns({
    preferences: 'publication.list.columns',
    columns: publicationColumns,
    omit: ['id', 'template', 'sort_order', 'agp_selection', 'rating', 'ribbon_new', 'ribbon_special', 'ribbon_coupon']
  })
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('content.publication')
  const translate = useTranslate()
  return (
    <>
      <List
        {...props}
        title={translate('menu.navigation.publications')}
        actions={<ListActions tour="publication-list" columnPreference="publication.list.columns" columns={publicationColumns} maxResults="10000" />}
        bulkActionButtons={<PublicationListBulkActions />}
        filters={<PublicationFilter />}
        sort={{ field: 'ident', order: 'ASC' }}
        exporter={(data) => exporter(data, 'Publication', ['other_publications'])}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
      >
        {isSmall
          ? (
          <SimpleList
            data-tour-id="publication-list-line"
            primaryText={record => record.title}
            secondaryText={record => (
              <ReferenceField basePath="channel" record={record} source="channel" reference="channel" link={true}>
                <TextField source="ident"/>
              </ReferenceField>
            )}
          />
            )
          : (
        <Datagrid rowClick="edit" optimized data-tour-id="publication-list-line">
          {columns}
          <ListActionToolbar>
            <GoToPublication type="slug_link" />
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
            )}

      </List>
    </>
  )
}

export default PublicationList
