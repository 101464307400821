import * as React from 'react'
import { NumberInput, required, SelectInput } from 'react-admin'
import PropTypes from 'prop-types'
import lodashGet from 'lodash/get'
import getConfig from './core_config'

const PriceInfoInput = props => {
  const source = props.source === undefined ? '' : props.source
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  const configDecimals = config.currencies_decimals
  const recordCurrency = lodashGet(props.record, `${source === '' ? '' : '.' + source}currency`, config.default_currency)
  let decimals = configDecimals[recordCurrency]
  return (
    <>
    <NumberInput label="Price amount" onChange={props.onChange !== undefined ? (e) => props.onChange('amount', Math.trunc(parseFloat(e.target.value) * Math.pow(10, decimals))) : null} source={`${source}.${props.amount ? props.amount : 'amount'}`} format={v => v / Math.pow(10, decimals)} parse={v => Math.trunc(parseFloat(v) * Math.pow(10, decimals))} style={{ maxWidth: 200 }} validate={required()}/>
    <SelectInput label="Currency" source={`${source}.currency`} choices={config.currencies_choices} defaultValue={config.default_currency} onChange={(e) => { decimals = configDecimals[e.target.value]; if (props.onChange !== undefined) props.onChange('currency', e.target.value) }} style={{ maxWidth: 100 }} validate={required()}/>
    {props.noVat ? null : <NumberInput onChange={props.onChange !== undefined ? (e) => props.onChange('vat', e.target.value) : null} label="Vat rate" source={`${source}.vat_rate`} style={{ maxWidth: 100 }} validate={required()}/>}
    </>
  )
}

PriceInfoInput.propTypes = {
  source: PropTypes.string.isRequired,
  amount: PropTypes.string,
  noVat: PropTypes.bool,
  record: PropTypes.object.isRequired,
  onChange: PropTypes.object
}

export default PriceInfoInput
