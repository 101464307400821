import { API_URL, httpClient } from '../../dataProvider'

const validateSlug = async (value, data) => {
  if (!data.channel) {
    return undefined
  }
  const isSlugUnique = await httpClient(API_URL + '/publication/slug_uniqueness?slug=' + value + '&channel=' + data.channel + (data.id !== undefined ? '&id=' + data.id : ''), {
    method: 'GET'
  })
    .then((response, data) => {
      if (response.status !== 200) {
        return false
      } else {
        return true
      }
    })
    .catch(() => {
      return false
    })
  if (!isSlugUnique) {
    return 'Slug is not unique'
  }
  return undefined
}

export default validateSlug
