import * as React from 'react'
import PropTypes from 'prop-types'

import {
  Edit,
  AutocompleteInput,
  // DateInput,
  TextInput,
  NumberInput,
  ReferenceInput,
  ReferenceField,
  TextField,
  required,
  SelectInput,
  SimpleForm
} from 'react-admin'
import { useDefineAppLocation } from '@react-admin/ra-navigation'

import { EditActions } from '../../Components'

// import RichTextInput from 'ra-input-rich-text'
// import { JsonInput } from 'react-admin-json-view'
import { Typography } from '@material-ui/core'

import getConfig from '../../Components/core_config'
import DateField from '../../Components/Datefield'

const PaymentEdit = props => {
  useDefineAppLocation('accounting.payment.edit')

  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <Edit actions={<EditActions />} {...props}>
      <SimpleForm record={props.record} {...props}>
        <Typography variant="h6">Created Date:  <DateField variant="h6" source="created_at"/></Typography>
        <Typography variant="body1">Order: <ReferenceField reference="order" source="order"><TextField variant="body1" source="code"/></ReferenceField></Typography>
        <ReferenceInput label="Order" source="order" reference="order">
          <AutocompleteInput
            optionText={(record) => `${record.code}: ${record.status}`}
          />
        </ReferenceInput>
        <SelectInput
          choices={config.payment_type}
          source="payment_type"
          validate={required()}
        />
        <SelectInput
          choices={config.payment_status}
          source="status"
          validate={required()}
        />
        <TextInput source="reference_number" />
        <NumberInput source="amount" />
        <SelectInput source="currency" choices={config.currencies_choices} />
      </SimpleForm>
    </Edit>
  )
}

PaymentEdit.propTypes = {
  record: PropTypes.object
}

export default PaymentEdit
