import { useDefineAppLocation } from '@react-admin/ra-navigation'
import * as React from 'react'
import { AutocompleteInput, Create, DateInput, NumberInput, ReferenceInput, required, SelectInput, SimpleForm } from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import getConfig from '../../Components/core_config'

const AccoutingDocumentCreate = props => {
  useDefineAppLocation('accounting.accountingdocument.create')

  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'

  return (
    <Create {...props}>
      <SimpleForm>
        <DateInput
          source="issue_date"
          validate={required()}
        />
        <DateInput
          source="vat_date"
          validate={required()}
        />
        <DateInput
          source="due_date"
          validate={required()}
        />
        <SelectInput
          choices={config.document_type}
          source="document_type"
          validate={required()}
        />
        <ReferenceInput label="Order" source="order" reference="order">
          <AutocompleteInput optionText={(record) => `${record.code}: ${record.status}`}/>
        </ReferenceInput>
        <ReferenceInput label="Customer" source="customer" reference="customer">
          <AutocompleteInput optionText={(record) => `${record.id}: ${record.name}`}/>
        </ReferenceInput>
        <ReferenceInput label="Payment" source="payment" reference="payment">
          <AutocompleteInput optionText={(record) => `${record.reference_number} : ${record.status}`}/>
        </ReferenceInput>
        <NumberInput source="amount"/>
        <SelectInput source="currency" choices={config.currencies_choices}/>
        <RichTextInput source="technical_note"/>
      </SimpleForm>
    </Create>
  )
}

export default AccoutingDocumentCreate
