import * as React from 'react'
import {
  AutocompleteInput,
  Create,
  required,
  SelectInput,
  SimpleForm,
  ReferenceInput,
  ReferenceArrayInput, // eslint-disable-line no-unused-vars
  SimpleShowLayout
} from 'react-admin' // eslint-disable-line import/no-unresolved
import { Checkbox, FormControlLabel, Grid, Typography } from '@material-ui/core' // eslint-disable-line no-unused-vars
import { DateTimeInput } from '../../Components'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { WithProps } from '../../Utils'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import { JsonInput } from 'react-admin-json-view'
import RichTextInput from 'ra-input-rich-text'
import { DualListInput } from '@react-admin/ra-enterprise/node_modules/@react-admin/ra-relationships' // eslint-disable-line no-unused-vars
import LocalDate from '../../Components/LocalDate' // eslint-disable-line no-unused-vars
import getConfig from '../../Components/core_config'

const ServiceRealizationCreateForm = props => {
  useDefineAppLocation('order.servicerealization.create')
  const [servicebase, setServicebase] = React.useState(props.record.service_base) // eslint-disable-line no-unused-vars
  const [soChecked, setSoChecked] = React.useState(false) // eslint-disable-line no-unused-vars
  const [date, setDate] = React.useState(new Date().toJSON()) // eslint-disable-line no-unused-vars
  const handleServiceBaseChange = (event) => {
    setServicebase(event)
  }
  // const handleChecked = (e) => { // eslint-disable-line no-unused-vars
  //   setSoChecked(e.target.checked)
  //   if (e.target.checked) {
  //     setDate(
  //       null
  //     )
  //   } else {
  //     setDate(
  //       new Date().toJSON()
  //     )
  //   }
  // }
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <SimpleForm record={props.record} {...props} toolbar={<SaveDeleteToolbar />} >
      <Grid container spacing={4} style={{ width: '100%' }} {...props}>
        <Grid item xs={12}>
          <Typography variant="h5" >General</Typography>
        </Grid>
        <Grid item xs={6}>
          <SimpleShowLayout>
            <ReferenceInput source="service_base" reference="servicebase" onChange={handleServiceBaseChange} validate={required()}>
              <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}`)} resettable />
            </ReferenceInput>
            <SelectInput choices={config.service_realization_status} source="status"/>
            <DateTimeInput source="start" validate={required()}/>
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={6}>
          <RichTextInput source="technical_note" />
          <JsonInput source="content" />
        </Grid>
        {/* <Grid item xs={12}>
          <Typography variant="h5" >Service offerings</Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={soChecked}
                onChange={handleChecked}
              />
            }
            label="Show service offerings before today"
          />
        </Grid>
        <Grid item xs={12}>
          <ReferenceArrayInput perPage={500} source="service_offerings" reference="serviceoffering" sort={{ field: 'realization_start_from', order: 'DESC' }} filter={{ service_base: servicebase, realization_start_from: date }}>
            <DualListInput optionText={(record) => `${record.ident}: ${LocalDate(record.realization_start_from)}`}/>
          </ReferenceArrayInput>
        </Grid> */}
      </Grid>
    </SimpleForm>
  )
}

const ServiceRealizationCreate = props => (
  <Create {...props}>
    <WithProps>{({ record, ...props }) =>
      <ServiceRealizationCreateForm record={record} {...props} />
    }</WithProps>
  </Create>
)

ServiceRealizationCreateForm.propTypes = {
  record: PropTypes.object,
  id: PropTypes.number.isRequired
}

export default ServiceRealizationCreate
