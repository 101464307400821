import * as React from 'react'

import { Edit } from '@material-ui/icons'
import { Button } from 'ra-ui-materialui'
import { useGetOne } from 'react-admin'
import { PropTypes } from 'prop-types'

const OtherChannelLinks = props => {
  const { data, error, loading } = useGetOne('publication', props.record.id)
  if (error) return 'N/A'
  if (loading) return ''
  const output = []
  data.other_publications.forEach(pub => {
    const link = '#/publication/' + pub.id
    output.push(
      <a href={link}>
        <Button
          color="primary"
          label={pub.channel}
        >
          <Edit />
        </Button>
      </a>
    )
  })

  return (
    <>
      {output}
    </>
  )
}

OtherChannelLinks.propTypes = {
  record: PropTypes.object.isRequired
}

export default OtherChannelLinks
