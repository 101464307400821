import * as React from 'react'

import {
  ReferenceField,
  EditButton
} from 'react-admin'

import {
  Grid,
  Typography,
  Card,
  CardContent
} from '@material-ui/core'

import { PropTypes } from 'prop-types'

/**
 * Component to display data from the record in a Card
 * Children give the detail of the card
 */

const DetailCard = props => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Grid container item xs={12}>
          <Grid xs={8}>
            <Typography variant="h5">{props.title}</Typography>
          </Grid>
          <Grid container style={{ justifyContent: 'flex-end' }} xs={4}>
            <ReferenceField source="id" reference={props.reference} link={true}>
              <EditButton />
            </ReferenceField>
          </Grid>
        </Grid>
        <br/>
        {props.children}
      </CardContent>
    </Card>
  )
}

DetailCard.propTypes = {
  children: PropTypes.element,
  title: PropTypes.string,
  reference: PropTypes.string
}

export default DetailCard
