import { useDefineAppLocation } from '@react-admin/ra-navigation'
import * as React from 'react'
import {
  AutocompleteInput,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  TextInput,
  useQuery
} from 'react-admin'
import { CreateNode, EditNode, SimpleForm, TreeWithDetails } from '@react-admin/ra-tree'
import TextInputWithSuggestions from '../../Components/TextInputWithSuggestions'

const menuIdents = () => {
  const { data, error, loading } = useQuery({
    type: 'getCustom',
    resource: 'menuitem',
    payload: { data: 'group' }
  })
  if (error || loading) return []
  return Object.values(data).map(mi => {
    const result = {}
    result.id = mi.group
    return result
  })
}

const MenuItemCreate = props => {
  const choices = menuIdents()
  return (
  <CreateNode {...props}>
  <SimpleForm>
    <TextInput source="title" validate={required()}/>
    <ReferenceInput source="channel" reference="channel" validate={required()}>
      <SelectInput optionText="ident" />
    </ReferenceInput>
    <NumberInput source="sort_order" helperText="resources.menuitem.helper.sort_order" validate={required()} />
    <TextInputWithSuggestions source="group" choices={choices} helperText="resources.menuitem.helper.menu" validate={required()}/>
    <ReferenceInput label="Parent menu" source="parent" reference="menuitem" resettable>
      <AutocompleteInput optionText="title" helperText="resources.menuitem.helper.parent" />
    </ReferenceInput>
    <TextInput source="target_url"/>
    <ReferenceInput label="Publication" fullWidth source="publication" reference="publication" resettable>
      <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}: ${record.title}`)} resettable/>
    </ReferenceInput>
  </SimpleForm>
  </CreateNode>
  )
}

const MenuItemEdit = props => {
  const choices = menuIdents()
  return (
  <EditNode mutationMode="pessimistic" {...props}>
    <SimpleForm>
      <TextInput source="title" validate={required()}/>
      <ReferenceInput source="channel" reference="channel" validate={required()}>
        <SelectInput optionText="ident" />
      </ReferenceInput>
      <NumberInput source="sort_order" helperText="resources.menuitem.helper.sort_order" validate={required()} />
      <TextInputWithSuggestions source="group" choices={choices} helperText="resources.menuitem.helper.menu" validate={required()}/>
      <ReferenceInput label="Parent menu" source="parent" reference="menuitem" resettable>
        <AutocompleteInput optionText="title" helperText="resources.menuitem.helper.parent" />
      </ReferenceInput>
      <TextInput source="target_url"/>
      <ReferenceInput label="Publication" fullWidth source="publication" reference="publication" resettable>
        <AutocompleteInput optionText={(record) => (record.ident === undefined ? '' : `${record.ident}: ${record.title}`)} resettable/>
      </ReferenceInput>
    </SimpleForm>
  </EditNode>
  )
}

const MenuItemTree = props => {
  useDefineAppLocation('content.menutree')
  return (
    <>
      <TreeWithDetails
        draggable
        allowMultipleRoots
        create={MenuItemCreate}
        edit={MenuItemEdit}
        {...props}
      />
    </>
  )
}

export default MenuItemTree
