import * as React from 'react'
import { AccessTimeTwoTone, CancelTwoTone, CheckCircleTwoTone, HelpTwoTone } from '@material-ui/icons'
import { PropTypes } from 'prop-types'

const SupplierMessageStatusBubble = props => {
  const status = props.status
  switch (status) {
    case 'pending':
      return (
          <AccessTimeTwoTone style={{ color: 'GoldenRod' }}/>
      )
    case 'confirmed':
      return (
          <CheckCircleTwoTone style={{ color: 'Green' }}/>
      )
    case 'refused':
      return (
          <CancelTwoTone style={{ color: 'Red' }} />
      )
    case 'canceled':
      return (
          <CancelTwoTone style={{ color: 'Gray' }} />
      )
    default:
      return (
        <HelpTwoTone style={{ color: 'Grey' }}/>
      )
  }
}

SupplierMessageStatusBubble.propTypes = {
  status: PropTypes.string
}

export default SupplierMessageStatusBubble
