import * as React from 'react'
import { IconButton, Typography } from '@material-ui/core'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import { API_URL } from '../../../dataProvider'
import { useDispatch } from 'react-redux'
import { useNotify } from 'ra-core'

import downloadPDF from '../../../Utils/downloadPDF'
import { PropTypes } from 'prop-types'

const OrderItemDownloadVoucherPDF = props => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const hooks = { dispatch: dispatch, notify: notify }
  const getPDF = (e) => {
    e.stopPropagation()
    const url = API_URL + `/orderitemvoucher/${props.record.id}`
    const documentName = `voucher_${props.record.order}-${props.record.id}.pdf`
    downloadPDF(url, documentName, hooks)
  }
  return (
    <IconButton color="primary" onClick={getPDF} {...props} >
      <PictureAsPdfIcon/>
      <Typography>Voucher</Typography>
    </IconButton>
  )
}

OrderItemDownloadVoucherPDF.propTypes = {
  record: PropTypes.object.isRequired
}

export default OrderItemDownloadVoucherPDF
