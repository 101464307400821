
import React, { useState } from 'react'
import {
  Button, useCreate, useNotify, useRefresh
} from 'react-admin'

import PropTypes from 'prop-types'

import TextField from '@material-ui/core/TextField'
import IconCancel from '@material-ui/icons/Cancel'
import IconClone from '@material-ui/icons/DoneAll'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

const BulkDialog = props => {
  const { open, handleClose, selectedIds, resource } = props
  const [ident, setIdent] = useState(null)
  const notify = useNotify()
  const refresh = useRefresh()
  const [create] = useCreate(resource + '/bulkclone')

  const cloneData = () => {
    const cloneData = {
      ids: selectedIds,
      ident: ident
    }
    create(
      { payload: { data: cloneData } },
      {
        onSuccess: ({ data }) => {
          handleClose()
          refresh()
        },
        onFailure: ({ error }) => {
          notify(error.message, 'error')
        }
      }
    )
  }

  return (
    <Dialog
        fullWidth
        open={open}
        onClose={handleClose}
        aria-label="Clone offering"
      >
        <DialogTitle>Clone offering template</DialogTitle>
        <DialogContent>
          <TextField label="New ident" variant="filled" value={ident} onChange={e => setIdent(e.target.value)}/>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleClose}
          >
            <IconCancel />
          </Button>
          <Button
            label="ra.action.clone"
            onClick={() => cloneData()}
          >
            <IconClone />
          </Button>
        </DialogActions>
    </Dialog>
  )
}

BulkDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  selectedIds: PropTypes.array,
  resource: PropTypes.string
}

export default BulkDialog
