import * as React from 'react'
import { useMediaQuery } from '@material-ui/core'
import {
  List,
  SimpleList,
  TextField,
  Filter,
  SearchInput,
  Datagrid,
  EditButton,
  Pagination,
  ReferenceField,
  AutocompleteArrayInput
} from 'react-admin'

import PropTypes from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import exporter from '../../Components/exporter'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import { API_URL, httpClient } from '../../dataProvider'
import { useDidMount } from '../../Components/Utils'

const getTags = async () => {
  const data = await httpClient(API_URL + '/supplier/tags', { method: 'GET' })
    .then((response, data) => {
      return response.json
    })
  const choices = []
  data.forEach(d => {
    choices.push({ id: d.tags__name, name: d.tags__name })
  })
  return choices
}

const SupplierFilter = props => {
  const [choices, setChoices] = React.useState([])
  const didMount = useDidMount()
  React.useEffect(() => {
    if (didMount) {
      getTags().then(r => { setChoices(r) })
    }
  })
  return (
    <Filter {...props}>
      <SearchInput source="q" alwaysOn/>
      <AutocompleteArrayInput choices={choices} source="tags_inclusive"/>
      <AutocompleteArrayInput choices={choices} source="tags_exclusive"/>
    </Filter>
  )
}

// const SupplierForm = props => (
//   <RowForm {...props}>
//     <TextInput source="name" validate={required()} fullWidth/>
//     <TextInput source="email" validate={required()} fullWidth/>
//     <TextInput source="mancub_URL" fullWidth/>
//     <TextInput source="mancub_channel_key" fullWidth/>
//     <TextInput source="config.title" fullWidth/>
//     <TextInput source="config.logo" fullWidth/>
//     <TextInput source="config.hotline" fullWidth/>
//     <TextInput source="config.web" fullWidth/>
//   </RowForm>
// )

const SupplierList = props => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  useDefineAppLocation('supplier.list')
  return (
    <>
      <List
        {...props}
        bulkActionButtons={false}
        sort={{ field: 'updated_at', order: 'DESC' }}
        exporter={(data) => exporter(data, 'Supplier')}
        filters={<SupplierFilter/>}
        hasCreate
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
        empty={false}
      >
        {isSmall
          ? (
          <SimpleList
            primaryText={record => record.name}
            secondaryText={record => record.email}
          />
            )
          : (
          <Datagrid rowClick="edit" optimized>
            <TextField source="name"/>
            <TextField source="email"/>
            <ReferenceField source="parent" reference="supplier">
              <TextField source="name"/>
            </ReferenceField>
            <ListActionToolbar>
              <EditButton/>
            </ListActionToolbar>
          </Datagrid>
            )}
      </List>
    </>
  )
}

SupplierList.propTypes = {
  record: PropTypes.node.isRequired
}

export default SupplierList
