import * as React from 'react'
import PropTypes from 'prop-types'
import { Button, useGetOne, useNotify, useRedirect, fetchEnd, fetchStart } from 'react-admin'
import AddBoxIcon from '@material-ui/icons/AddBox'
import CancelIcon from '@material-ui/icons/Cancel'
import { TextField } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { useDispatch } from 'react-redux'
import { API_URL, httpClient } from '../../../dataProvider'

const getPublication = (id, resource) => {
  const { data, loading, error } = useGetOne(resource, id)
  if (loading) { return 'loading' }
  if (error) { return 'error' }
  return data
}

const clonePublication = (id, resource, ident, slug, hooks) => {
  const data = { id: id, ident: ident, slug: slug }
  hooks.dispatch(fetchStart())
  httpClient(`${API_URL}/${resource}/${id}/clone`, {
    method: 'POST',
    body: JSON.stringify(data)
  }).then((response) => {
    if (response.status !== 201) hooks.notify('Error with the API', 'warning')
    else {
      hooks.dispatch(fetchEnd())
      hooks.redirect(`/${resource}/${response.json.id}`)
    }
  }).catch(() => {
    hooks.notify('Error cannot clone', 'warning')
  })
}

const CloneButton = props => {
  const { id, resource } = props
  const [showDialog, setShowDialog] = React.useState(false)
  const [ident, setIdent] = React.useState('loading')
  const [slug, setSlug] = React.useState('loading')
  const publication = getPublication(id, resource)
  const dispatch = useDispatch()
  const notify = useNotify()
  const redirect = useRedirect()
  const hooks = { dispatch: dispatch, notify: notify, redirect: redirect }

  React.useEffect(() => {
    if (ident === 'loading' && publication.ident !== undefined) {
      setIdent(publication.ident)
      setSlug(publication.slug)
    }
  })

  const handleClick = () => {
    if (publication !== undefined) setIdent(publication.ident)
    if (publication !== undefined) setSlug(publication.slug)
    setShowDialog(true)
  }

  const handleClose = () => {
    setShowDialog(false)
  }

  const clone = () => {
    handleClose()
    clonePublication(id, resource, ident, slug, hooks)
  }

  return (
    <>
      <Button onClick={handleClick} label="Clone">
        <AddBoxIcon/>
      </Button>
      <Dialog
        fullWidth
        open={showDialog}
        onClose={handleClose}
        aria-label="clone-SOC"
      >
        <DialogTitle>Clone {publication.ident}</DialogTitle>
        <DialogContent>
          <TextField label="Ident" defaultValue={ident} onChange={(e) => setIdent(e.target.value)} fullWidth/>
          <br/><br/>
          <TextField label="Slug" defaultValue={slug} onChange={(e) => setSlug(e.target.value)} fullWidth/>
        </DialogContent>
        <DialogActions>
          <Button
            label="ra.action.cancel"
            onClick={handleClose}
          >
            <CancelIcon/>
          </Button>
          <Button
            label="ra.action.clone"
            disabled={(ident === '' || ident === publication.ident) || (slug === '' || slug === publication.slug)}
            onClick={clone}
          >
            <AddBoxIcon/>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

CloneButton.propTypes = {
  id: PropTypes.number,
  resource: PropTypes.string
}

export default CloneButton
