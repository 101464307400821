import * as React from 'react'

import {
  useTranslate
} from 'react-admin'

import LanguageIcon from '@material-ui/icons/Language'
import { fetchEnd, fetchStart, useNotify } from 'ra-core'
import { Button } from 'ra-ui-materialui'
import { useDispatch } from 'react-redux'
import { API_URL, httpClient } from '../../dataProvider'
import { PropTypes } from 'prop-types'

const ShowLinkLabel = (props) => {
  const translate = useTranslate()
  let title
  title = `${translate('resources.publication.show')}`
  // TODO
  // title += ` ${getChannel(props.record.channel)}`
  title += ' '
  return title
}

ShowLinkLabel.propTypes = {
  record: PropTypes.object.isRequired
}

const GoToPublication = props => {
  const dispatch = useDispatch()
  const notify = useNotify()
  const hooks = { dispatch: dispatch, notify: notify }
  const goToPub = (e) => {
    e.stopPropagation()
    hooks.dispatch(fetchStart())
    httpClient(API_URL + '/publication/' + props.record.id + '/' + props.type, {
      method: 'GET'
    })
      .then((response, data) => {
        if (response.status !== 200) hooks.notify('Error with api', 'error')
        else {
          hooks.dispatch(fetchEnd())
          window.open(`${response.json.link}`, '_blank')
        }
      })
  }
  return (
    <Button
    color="primary"
    label={props.label ? props.label : <ShowLinkLabel {...props} />}
    onClick={goToPub}
    >
      <LanguageIcon />
    </Button>
  )
}

GoToPublication.propTypes = {
  record: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  label: PropTypes.string
}

export default GoToPublication
