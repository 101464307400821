import * as React from 'react'
import PropTypes from 'prop-types'
import { ExportButton, sanitizeListRestProps, TopToolbar, useListContext, Button } from 'react-admin'
import { Link as RouterLink } from 'react-router-dom'
import { Grid } from '@material-ui/core'
import Help from '@material-ui/icons/Help'
import RotateLeft from '@material-ui/icons/RotateLeft'
import TodayIcon from '@material-ui/icons/Today'
import Add from '@material-ui/icons/Add'
import { useTour } from '@react-admin/ra-tour'
import { SelectColumnsButton } from '@react-admin/ra-preferences'

import dateAdmin from '../../Utils/dateAdmin'

const FromTodayFilterButton = props => {
  const context = useListContext()
  const { field, ...rest } = props
  return (
    <Button
    onClick={() => {
      const filterValues = { ...context.filterValues }
      filterValues[field] = dateAdmin.parse(new Date(), 'yyyy-mm-dd')
      context.setFilters(filterValues)
    }}
    {...rest}
    label='menu.filters.from_today'
    >
      <TodayIcon/>
    </Button>
  )
}

const ListActions = ({ tour, columnPreference, columns, ...props }) => {
  const [{ running }, { start }] = useTour()
  const {
    filters,
    maxResults,
    setFilters,
    noCreate,
    fromToday,
    ...rest
  } = props
  const {
    total,
    resource,
    displayedFilters,
    filterValues,
    showFilter
  } = useListContext()

  const filtersProps = {
    resource,
    showFilter,
    displayedFilters,
    filterValues,
    context: 'button'
  }

  return (
    <TopToolbar style={{
      display: 'block',
      width: '100%'
    }} {...sanitizeListRestProps(rest)}>
      <Grid style={{ textAlign: 'right' }}>
        {fromToday && <FromTodayFilterButton field={fromToday} />}
        {props.noReset
          ? null
          : <Button
              onClick={() => setFilters({})}
              disabled={Object.keys(filterValues).length === 0}
              label='menu.filters.reset'
          >
              <RotateLeft />
          </Button>
          }
        {!!filters && React.cloneElement(filters, filtersProps)}
        {(columnPreference === undefined || columns === undefined)
          ? null
          : <SelectColumnsButton
            preference={columnPreference}
            columns={columns}
          />
        }
        <ExportButton disabled={total === 0} maxResults={maxResults} />
        {!noCreate
          ? <Button color="primary" component={RouterLink}
          to={{
            pathname: `/${props.resource}/create`,
            filterValues: filterValues
          }}
          label='ra.action.create'
          >
            <Add />
          </Button>
          : null
        }
        {tour === undefined
          ? null
          : <Button
          label='simple.ui_help'
          color="primary"
          onClick={() => start(tour)}
          disabled={running}
        >
          <Help />
        </Button>
        }
      </Grid>
      {!!props.more && <Grid item xs={12}>{props.more}</Grid>}
    </TopToolbar>
  )
}

FromTodayFilterButton.propTypes = {
  field: PropTypes.string
}

ListActions.propTypes = {
  resource: PropTypes.string,
  maxResults: PropTypes.number,
  columns: PropTypes.object,
  columnPreference: PropTypes.string,
  setFilters: PropTypes.func,
  filters: PropTypes.object,
  tour: PropTypes.string,
  noReset: PropTypes.func,
  more: PropTypes.object,
  noCreate: PropTypes.bool,
  fromToday: PropTypes.string
}

export default ListActions
