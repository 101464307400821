import * as React from 'react'
import {
  Datagrid,
  Edit,
  FormWithRedirect,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useGetList
} from 'react-admin' // eslint-disable-line import/no-unresolved
import {
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
  useMediaQuery
} from '@material-ui/core'

import { EditActions } from '../../Components'
import { JsonInput } from 'react-admin-json-view'

import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import DateField from '../../Components/Datefield'
import LocalDate from '../../Components/LocalDate'

const ChannelUserTokens = (channeluser) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'))
  const { data, ids, loading, error, total, loaded } = useGetList(
    'channelusertoken',
    { page: 1, perPage: 100 },
    { field: 'expiration', order: 'ASC' },
    { user_account: channeluser.id }
  )
  if (loading) { return 'Loading' }
  if (error) { return 'ERROR' }
  // if (total === 0) { return <p>Empty</p> }
  if (isSmall) {
    return (
      <div style={{ width: '80vw', padding: '1vw', overflowX: 'auto' }}>
        <List>
          {ids.map(id =>
            <ListItem key={id} >
              <ListItemText
                primary={data[id].token}
                secondary={LocalDate(data[id].expiration)}
                />
            </ListItem>
          )}
        </List>

    </div>
    )
  }
  return (
    <Datagrid
      basePath=""
      currentSort={{ field: 'expiration', order: 'DESC' }}
      data={data}
      ids={ids}
      selectedIds={[]}
      loaded={loaded}
      total={total}
    >
      <TextField source="token" />
      <DateField source="expiration" />
    </Datagrid>
  )
}

const ChannelUserEditForm = (props) => {
  useDefineAppLocation('customer.channeluser.edit')
  return (
    <FormWithRedirect
    {...props}
    render={formProps => (
      // custom form
      <form>
        <div style={{ padding: 20 }}>
          <Grid container spacing={4}>
            <Grid container item sm={12} md={6} spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h7">General</Typography>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="username" label="resources.channeluser.fields.username" fullWidth/>
              </Grid>
              <Grid item xs={12}>
                <TextInput source="mail" fullWidth/>
              </Grid>
              <Grid item xs={3}>
                <TextInput disabled source="id" fullWidth/>
              </Grid>
              <Grid item xs={9}>
                <ReferenceInput source="channel" reference="channel">
                  <SelectInput optionText="ident" />
                </ReferenceInput>
              </Grid>
            </Grid>
            <Grid container item sm={12} md={6}>
                <Grid item xs={12}>
                  <Typography variant="h7">Content</Typography>
                </Grid>
                <Grid item xs={12}>
                  <JsonInput
                    source="content"
                  />
                </Grid>
            </Grid>
            <Grid container item xs={12}>
              <Grid item xs={8}>
                <Typography variant="h7">Channel user tokens</Typography>
              </Grid>
              <Grid item xs={12}>
                  <ChannelUserTokens id={formProps.record.id} />
              </Grid>
            </Grid>
          </Grid>
        </div>
        <SaveDeleteToolbar {...formProps} deleteLabel="username" />
      </form>
    )}
  />
  )
}

const ChannelUserEdit = ({ permissions, ...props }) => (
  <>
    <Edit
      actions={<EditActions />}
      {...props}
    >
      <ChannelUserEditForm />
    </Edit>
  </>
)

ChannelUserEdit.propTypes = {
  permissions: PropTypes.arrayOf(String).isRequired
}

export default ChannelUserEdit
