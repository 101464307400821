import * as React from 'react'
import PropTypes from 'prop-types'
import { TopToolbar } from 'react-admin'
import { Button } from '@material-ui/core'
import Help from '@material-ui/icons/Help'
import { useTour } from '@react-admin/ra-tour'

const EditActions = ({ basePath, data, tour, more }) => {
  const [{ running }, { start }] = useTour()
  return (
  <TopToolbar>
    {/*
    <CloneButton className="button-clone" basePath={basePath} record={data} />
    <ShowButton basePath={basePath} record={data} />
    */}
    {tour === undefined
      ? null
      : <Button
      startIcon={<Help />}
      color="primary"
      onClick={() => start(tour)}
      disabled={running}
    >
      Help
    </Button>
    }
    {more === undefined ? null : more}
  </TopToolbar>
  )
}

EditActions.propTypes = {
  basePath: PropTypes.string,
  data: PropTypes.object,
  tour: PropTypes.string,
  more: PropTypes.object
}

export default EditActions
