import * as React from 'react'
import {
  Datagrid,
  EditButton,
  List,
  TextField,
  useTranslate,
  ReferenceField,
  DeleteButton,
  BulkDeleteButton
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { useDefineAppLocation } from '@react-admin/ra-navigation'
import ListActionToolbar from '../../Components/Toolbar/ListActionToolbar'
import BulkCloneButton from './Components/BulkCloneButton'

const ServiceOfferingTemplateBulkActions = props => (
  <>
    <BulkCloneButton {...props}/>
    <BulkDeleteButton {...props}/>
  </>
)

const ServiceOfferingTemplateList = props => {
  useDefineAppLocation('catalog.serviceofferingtemplate')
  const translate = useTranslate()
  return (
    <>
      <List
        title={translate('resources.serviceofferingtemplate.title')}
        bulkActionButtons={<ServiceOfferingTemplateBulkActions/>}
        empty={false}
        {...props}
      >
        <Datagrid rowClick="edit" optimized>
          <ReferenceField source="service_base" reference="servicebase" link={false}>
            <TextField source="ident"/>
          </ReferenceField>
          <TextField source="ident"/>
          <ListActionToolbar>
            <EditButton/>
            <DeleteButton/>
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

export default ServiceOfferingTemplateList
