import * as React from 'react'

import PropTypes from 'prop-types'

import { ReferenceManyField, TextField, NumberField, Datagrid } from 'react-admin'

// import { EditableDatagrid } from '@react-admin/ra-editable-datagrid'
import { useSelectedColumns } from '@react-admin/ra-preferences'
import CapacityPoolForm from './CapacityPoolForm'
import { DatagridWithDialog } from '../../../Components/Dialogs'

const abstractCapacityPoolColumns = {
  ident: <TextField source="ident" />,
  tag: <TextField source="tag" />,
  status: <TextField source="status_display" />,
  sort_order: <TextField source="sort_order" />,
  capacity_full: <NumberField source="capacity_full" textAlign="left" />
}

const AbstractCapacityPoolList = (props) => {
  const {
    columnsPref,
    columns,
    omitColumns,
    reference,
    target,
    noEdit,
    initialValues
  } = props

  const gridColumns = useSelectedColumns({
    preferences: columnsPref,
    columns: Object.assign({}, abstractCapacityPoolColumns, columns),
    omit: omitColumns
  })
  const gridType = noEdit ? Datagrid : DatagridWithDialog
  const gridProps = noEdit
    ? { optimized: true }
    : {
        // rowClick: 'edit',
        form: <CapacityPoolForm/>,
        defaultValue: initialValues
      }

  return (
    <ReferenceManyField
      label=""
      sort={{ field: 'sort_order', order: 'ASC' }}
      reference={reference}
      target={target}
      fullWidth
      perPage={1000}
    >
      {React.createElement(gridType, gridProps, gridColumns)}
    </ReferenceManyField>
  )
}

AbstractCapacityPoolList.propTypes = {
  columnsPref: PropTypes.string,
  columns: PropTypes.object,
  omitColumns: PropTypes.array,
  record: PropTypes.object,
  reference: PropTypes.string,
  target: PropTypes.string,
  initialValues: PropTypes.string,
  noEdit: PropTypes.bool
}

export default AbstractCapacityPoolList
