import * as React from 'react'

import { TextInput, NumberInput, SelectInput, required } from 'react-admin'
// import { RowForm } from '@react-admin/ra-editable-datagrid'
import getConfig from '../../../Components/core_config'
import { ChannelTextInput } from '../../../Components/Inputs'

const CapacityPoolForm = (props) => {
  const config = getConfig()
  if (config === 'Loading' || config === 'ERROR') return config
  return (
    <>
      <TextInput source="ident" validate={required()} fullWidth/>
      <ChannelTextInput source="content.title" editable {...props}/>
      <ChannelTextInput label="subtitle" source="content.subtitle" editable {...props}/>
      <ChannelTextInput label="description_button" source="content.description_button" editable {...props}/>
      <ChannelTextInput label="description" source="content.description" editable {...props}/>
      <TextInput source="tag" validate={required()} fullWidth/>
      <SelectInput
        source="status"
        choices={config.capacity_status}
        validate={required()}
        fullWidth
      />
      <NumberInput source="capacity_full" validate={required()} fullWidth/>
      <NumberInput source="sort_order" />
    </>
  )
}

export default CapacityPoolForm
