import * as React from 'react'

import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { BooleanInput, Datagrid, EditButton, Filter, List, SearchInput, TextField, Pagination } from 'react-admin'
import ListActions from '../Components/Toolbar/ListActions'
import exporter from '../Components/exporter'
import ListActionToolbar from '../Components/Toolbar/ListActionToolbar'
import statusRowStyle from '../Components/statusRowStyle'

import grey from '@material-ui/core/colors/grey'
import green from '@material-ui/core/colors/green'

const GeolocationFilter = props => (
  <Filter {...props}>
    <SearchInput source="q" alwaysOn/>
    <BooleanInput source="enabled" initialValue={true}/>
  </Filter>
)

const GeolocationList = props => {
  useDefineAppLocation('geolocation.list')
  return (
    <>
      <List
        {...props}
        actions={<ListActions columnPreference="geolocation.list.columns" />}
        sort={{ field: 'pk', order: 'DESC' }}
        exporter={(data) => exporter(data, 'Geolocation')}
        filters={<GeolocationFilter/>}
        perPage={50}
        pagination={<Pagination rowsPerPageOptions={[25, 50, 100]}/>}
        empty={false}
      >
        <Datagrid
          rowStyle={(record) => record ? statusRowStyle(record.enabled ? green[500] : grey[500]) : null}
          rowClick='edit'
          optimized
        >
          <TextField source='ident'/>
          <ListActionToolbar>
            <EditButton />
          </ListActionToolbar>
        </Datagrid>
      </List>
    </>
  )
}

export default GeolocationList
