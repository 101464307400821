import {
  useGetList
} from 'react-admin'
import LocalDate from '../../../Components/LocalDate'
import { PropTypes } from 'prop-types'

const OldestChannelTokenField = props => {
  const { channelId } = props
  const { data, ids, loading, error, total } = useGetList(
    'channeltoken',
    { page: 1, perPage: 1 },
    { field: 'expiration', order: 'ASC' },
    { channel: channelId }
  )
  if (loading) return 'Loading'
  if (error) return 'ERROR'
  if (total > 0 && data[ids[0]] !== undefined) return LocalDate(data[ids[0]].expiration)
  return 'No token'
}

OldestChannelTokenField.propTypes = {
  channelId: PropTypes.number
}

export default OldestChannelTokenField
