import * as React from 'react'
import { AutocompleteInput, ReferenceInput, required, TextInput } from 'react-admin'

const SupplierBaseForm = () => (
  <>
    <TextInput source="name" validate={required()} fullWidth/>
    <TextInput source="email" validate={required()} fullWidth/>
    <TextInput source="booking_email" fullWidth/>
    <TextInput source="phone" fullWidth/>
    <ReferenceInput source="parent" reference="supplier" fullWidth>
      <AutocompleteInput optionText="name"/>
    </ReferenceInput>
    {/* <TextInput source="mancub_URL" fullWidth/> */}
    {/* <TextInput source="mancub_channel_key" fullWidth/> */}
  </>
)

export default SupplierBaseForm
