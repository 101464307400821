import * as React from 'react'
import { ReferenceField, FunctionField, Button, Link } from 'react-admin'
import { PriceInfoField } from '../../../Components'
import { ExitToApp } from '@material-ui/icons'
import PropTypes from 'prop-types'

const OrderInfoPanel = props => {
  return (
    <>
       <h3>Order (REF: {props.record.code})</h3>
      <ReferenceField source="channel" reference="channel" link={false}>
        <FunctionField render={(record) => <p>Channel: {record.ident}</p>} />
      </ReferenceField>
      {props.record.customer === null
        ? null
        : <ReferenceField source="customer" reference="customer" link={false}>
          <FunctionField render={(record) => <p>Customer: {record.name}</p>} />
        </ReferenceField>
      }
      {props.record.channel_user === null
        ? null
        : <ReferenceField source="channel_user" reference="channeluser" link={false}>
          <FunctionField render={(record) => <p>Channel user: {record.mail}</p>} />
        </ReferenceField>
      }
      <span>Order total price: </span>
      <PriceInfoField {...props} /><br/><br/>
      <Button
        color="primary"
        component={Link}
        to={`/order/${props.record.id}`}
        label="Go to order"
      >
        <ExitToApp />
      </Button>
    </>
  )
}

OrderInfoPanel.propTypes = {
  record: PropTypes.object
}

export default OrderInfoPanel
