import React from 'react'
import { Admin } from '@react-admin/ra-enterprise'
import { theme } from '@react-admin/ra-navigation'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import { Resource, resolveBrowserLocale } from 'react-admin'

import {
  FrenchMessages,
  EnglishMessages,
  CzechMessages
} from './Translations'

import { Dashboard } from './Dashboard/index'

import {
  Payment,
  AccountingDocument,
  CurrencyExchange
} from './Accounting'

import {
  Channel,
  ChannelText
} from './ChannelManagement'

import {
  Documents,
  Images,
  MenuItem,
  MenuItemTree,
  Redirect,
  Publication
} from './Cms'

import {
  Customer,
  ChannelUser
} from './Customers'

import {
  Basket,
  Order,
  OrderItem
} from './Orders'

import {
  Booking,
  Expenditure,
  ServiceBase,
  ServiceOffering,
  ServiceOfferingCalendar,
  ServiceOfferingConfig,
  ServiceOfferingTemplate,
  ServiceRealization,
  ServiceRealizationCalendar
} from './Services'

import {
  Supplier,
  SupplierMessage
} from './Suppliers'

import Geolocation from './Geolocation'
import Contact from './Contacts'

import adminuser from './AdminUser'

import customLayout from './customLayout'
import customRoutes from './customRoutes'
import dataProvider, { AUTH_URL } from './dataProvider'
import { reducer } from '@react-admin/ra-tree'
import { tokenAuthProvider } from 'ra-data-django-rest-framework'
import MyLogoutButton from './customLogout'

import cacheDataProviderProxy from './customCacheProvider'

const messages = {
  fr: FrenchMessages,
  en: EnglishMessages,
  cs: CzechMessages
}

const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  resolveBrowserLocale()
)

const App = () => (
  <Admin
    i18nProvider={i18nProvider}
    customRoutes={customRoutes}
    dataProvider={cacheDataProviderProxy(dataProvider)}
    dashboard={Dashboard}
    customReducers={reducer}
    authProvider={tokenAuthProvider({
      obtainAuthTokenUrl: AUTH_URL
    })}
    layout={customLayout}
    logoutButton={MyLogoutButton}
    theme={theme}
  >
    {(permissions) => [
      <Resource name="mancubconfig" key="mancubconfig_resource" />,
      <Resource name="contact" key="contact_resource" {...Contact} />,
      <Resource name="currencyexchange" key="currencyexchange_resource" {...CurrencyExchange}/>,
      <Resource name="basket" key="basket_resource" {...Basket} />,
      <Resource name="booking" key="booking_resource" {...Booking} />,
      <Resource name="buypriceitem" key="buypriceitem_resource" />,
      <Resource name="capacitypool" key="capacitypool_resource" />,
      <Resource
        name="capacitypooltemplate"
        key="capacitypooltemplate_resource"
      />,
      <Resource name="channel" key="channel_resource" {...Channel} />,
      <Resource name="channelsnippet" key="channelsnippet_key" />,
      <Resource name="channeltemplate" key="channeltemplate_key" />,
      <Resource
        name="channeltext"
        key="channeltext_resource"
        {...ChannelText}
      />,
      <Resource name="channeltoken" key="channeltoken_resource" />,
      <Resource
        name="channeluser"
        key="channeluser_resource"
        {...ChannelUser}
      />,
      <Resource name="channelusertoken" key="channelusertoken_resource" />,
      <Resource name="customer" key="customer_resource" {...Customer} />,
      <Resource
        name="expenditure"
        key="expenditure_resource"
        list={Expenditure}
      />,
      <Resource name="documents" key="documents_resource" {...Documents} />,
      <Resource name="images" key="images_resource" {...Images} />,
      <Resource
        name="geolocation"
        key="geolocation_resource"
        {...Geolocation}
      />,
      <Resource name="menu" key="menu_resource" />,
      <Resource name="menuitem" key="menuitem_resource" {...MenuItem} />,
      <Resource name="menutree" key="menutree_resource" list={MenuItemTree} />,
      <Resource
        name="offeringcalendar"
        key="calendar_resource"
        list={ServiceOfferingCalendar}
      />,
      <Resource name="order" key="order_resource" {...Order} />,
      <Resource name="orderitem" key="orderitem_resource" {...OrderItem} />,
      <Resource
        name="publication"
        key="publication_resource"
        {...Publication}
      />,
      <Resource
        name="publicationsnippetdata"
        key="publicationsnippetdata_resource"
      />,
      <Resource
        name="publicationtemplate"
        key="publicationtemplate_resource"
      />,
      <Resource
        name="realizationcalendar"
        key="calendar_resource"
        list={ServiceRealizationCalendar}
      />,
      <Resource name="redirect" key="redirect_resource" {...Redirect} />,
      <Resource name="sellpriceitem" key="sellpriceitem_resource" />,
      <Resource
        name="servicebase"
        key="servicebase_resource"
        {...ServiceBase}
      />,
      <Resource
        name="serviceoffering"
        key="serviceoffering_resource"
        {...ServiceOffering}
      />,
      <Resource
        name="serviceofferingconfig"
        key="serviceofferingconfig_resource"
        {...ServiceOfferingConfig}
      />,
      <Resource
        name="serviceofferingtemplate"
        key="serviceofferingtemplate_resource"
        {...ServiceOfferingTemplate}
      />,
      <Resource name="servicesetup" key="servicesetup_resource" />,
      <Resource
        name="servicesetupattribute"
        key="servicesetupattribute_resource"
      />,
      <Resource
        name="servicerealization"
        key="servicerealization_resource"
        {...ServiceRealization}
      />,
      <Resource
        name="servicerealizationpicker"
        key="servicerealizationpicker_resource"
        {...ServiceRealization}
      />,
      <Resource name="datatag" key="datatag_resource" />,
      <Resource name="supplier" key="supplier_resource" {...Supplier} />,
      <Resource name="servicesupplier" key="servicesupplier_resource"/>,
      <Resource name="snippetfield" key="snippetfield_resource"/>,
      <Resource
        name="suppliermessage"
        key="suppliermessage_resource"
        {...SupplierMessage}
      />,
      <Resource name="tag" key="tag_resource" />,
      <Resource name="ticket" key="ticket_resource" />,
      <Resource name="ticketattribute" key="ticketattribute_resource" />,
      // Accounting
      <Resource name="payment" key="payment_resource" {...Payment} />,
      <Resource
        name="accountingdocument"
        key="accountingdocument_resource"
        {...AccountingDocument}
      />,
      permissions === 'admin'
        ? (
        <Resource name="adminuser" key="adminuser_resource" {...adminuser} />
          )
        : null
    ]}
  </Admin>
)

export default App
