import * as React from 'react'
import {
  Edit,
  required,
  SelectInput,
  SaveButton,
  FormWithRedirect,
  TextField,
  ReferenceField,
  FunctionField,
  ListContextProvider,
  Datagrid,
  Button
} from 'react-admin' // eslint-disable-line import/no-unresolved
import { Grid, Typography } from '@material-ui/core'
import { DateTimeInput, EditActions, PriceInfoField } from '../../Components'
import { PropTypes } from 'prop-types'
import { useDefineAppLocation } from '@react-admin/ra-navigation'
import { WithProps } from '../../Utils'
import SaveDeleteToolbar from '../../Components/Toolbar/SaveDeleteToolbar'
import { JsonInput } from 'react-admin-json-view'
import RichTextInput from 'ra-input-rich-text'
import DateField from '../../Components/Datefield'
import getConfig from '../../Components/core_config'
import CapacityPoolList from '../CapacityPool/Components/CapacityPoolList'

import { Link } from 'react-router-dom'
import lodashkeyBy from 'lodash/keyBy'
import EditIcon from '@material-ui/icons/Edit'

import ReferenceManyFieldAccordion from '../../Components/Fields/ReferenceManyFieldAccordion'

const CapacityPoolField = (props) => {
  return (
    <>
      <Typography variant="h5">Capacity pools</Typography>
      <CapacityPoolList
        columnsPref="capacitypool.config.columns"
        target="service_realization"
        initialValues={{ service_realization: props.record.id }}
      />
    </>
  )
}

const ServiceSetupExpand = props => {
  const { record, ...rest } = props
  return (
    <ListContextProvider value={{
      data: lodashkeyBy(record.tickets, 'id'),
      ids: record.tickets.map(({ id }) => id),
      total: record.tickets.length,
      page: 1,
      perPage: 10000,
      setPage: 1,
      currentSort: { field: 'id', order: 'ASC' },
      resource: 'ticket',
      basePath: 'ticket'
    }}>
      <Datagrid {...rest}>
        <FunctionField label="Attributes" render={(record) =>
          record.content.attributes.map(attribute => attribute.value.length > 0 ? <div key={attribute.ident}>{attribute.ident}: {attribute.value}</div> : null)
        }/>
        <FunctionField label="Sell price items" render={(record) =>
          record.content.sell_price_items.map(spi => <div key={spi.ident}>{spi.ident}: {spi.amount} {spi.currency}</div>)
        }/>
      </Datagrid>
    </ListContextProvider>
  )
}

const ServiceRealizationEditForm = (props) => {
  useDefineAppLocation('order.servicerealization.edit')
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <FormWithRedirect
      {...props}
      render={formProps => (
        <form>
        <div style={{ padding: 20 }}>
        <Grid container spacing={3} style={{ width: '100%' }} {...props}>
          <Grid item xs={12}>
            <SaveButton {...formProps}/>
          </Grid>
          <Grid container spacing={5} item xs={12}>
            <Grid item xs={4}>
              <Typography variant="h5">General</Typography>
              <br/>
              <Typography variant="base1">
                Service Base: <ReferenceField
                  source="service_base"
                  reference="servicebase"
                >
                  <TextField source="ident" fullWidth/>
                </ReferenceField>
              </Typography>
              <br/>
              <br/>
              <SelectInput
                choices={config.service_realization_status}
                source="status"
                fullWidth
              />
              <DateTimeInput source="start" validate={required()} fullWidth/>
            </Grid>
            <Grid item xs={8}>
              <ReferenceManyFieldAccordion
                title="Service offerings"
                reference="serviceoffering"
                target="service_realizations"
                defaultExpanded={true}
              >
                <TextField source="ident"/>
              </ReferenceManyFieldAccordion>
              <ReferenceManyFieldAccordion
                title="Expenditures"
                reference="expenditure"
                target="service_realization"
              >
                <ReferenceField source="buy_price_item" reference="buypriceitem" link={false}>
                  <TextField source="ident"/>
                </ReferenceField>
                <ReferenceField label="Supplier" source="supplier" reference="supplier" link={false}>
                  <TextField source="name"/>
                </ReferenceField>
                <PriceInfoField label="Amount"/>
              </ReferenceManyFieldAccordion>
              <ReferenceManyFieldAccordion
                title="Order items"
                reference="servicesetup"
                target="service_realization"
                expand={<ServiceSetupExpand/>}
                noEdit
              >
                <FunctionField label="Number of tickets" render={(record) => record.tickets.length}/>
                <ReferenceField label="Order" source="order_item" reference="orderitem" link={false}>
                  <ReferenceField source="order" reference="order" link={false}>
                    <FunctionField render={(record) =>
                      <TextField source="code" component={Link} to={`/order/${record.id}`}/>
                    }/>
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField label="Checkout at" source="order_item" reference="orderitem" link={false}>
                  <ReferenceField source="order" reference="order" link={false}>
                    <FunctionField render={record => <DateField record={record} source="checkout_at" showTime/>}/>
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField label="Customer" source="order_item" reference="orderitem" link={false}>
                  <ReferenceField source="order" reference="order" link={false}>
                    <ReferenceField source="customer" reference="customer" link={false}>
                      <TextField source="name"/>
                    </ReferenceField>
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField label="Beneficier" source="order_item" reference="orderitem" link={false}>
                  <ReferenceField source="order" reference="order" link={false}>
                    <TextField source="beneficier"/>
                  </ReferenceField>
                </ReferenceField>
                <ReferenceField label="Order status" source="order_item" reference="orderitem" link={false}>
                  <ReferenceField source="order" reference="order" link={false}>
                    <TextField source="status"/>
                  </ReferenceField>
                </ReferenceField>
                <FunctionField render={(record) =>
                  <Button
                    startIcon={<EditIcon/>}
                    label="EDIT"
                    component={Link}
                    to={`/orderitem/${record.order_item}`}
                  />
                }/>
              </ReferenceManyFieldAccordion>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <CapacityPoolField {...props}/>
          </Grid>
          <Grid item xs={12}>
            <RichTextInput source="technical_note" />
            <JsonInput source="content" />
          </Grid>
        </Grid>
        </div>
        <SaveDeleteToolbar {...formProps} />
        </form>
      )}
    />
  )
}

const ServiceRealizationEdit = (props) => (
  <Edit actions={<EditActions />} {...props}>
    <WithProps>
      {({ record, ...props }) => (
        <ServiceRealizationEditForm record={record} {...props} />
      )}
    </WithProps>
  </Edit>
)

ServiceRealizationEditForm.propTypes = {
  record: PropTypes.object,
  id: PropTypes.number.isRequired
}

ServiceSetupExpand.propTypes = {
  record: PropTypes.object
}

CapacityPoolField.propTypes = {
  record: PropTypes.object
}

export default ServiceRealizationEdit
