import * as React from 'react'

import OrderList from './Components/OrderList'
import { FilterWithSave } from '@react-admin/ra-preferences'
import getConfig from '../../Components/core_config'
import {
  DateInput,
  TextInput,
  ReferenceInput,
  AutocompleteInput,
  SelectInput
} from 'react-admin' // eslint-disable-line import/no-unresolved

import { useDefineAppLocation } from '@react-admin/ra-navigation'
// import { DateTimeInput } from '../../Components'

const CheckoutRangeFilter = props => {
  return (
    <>
      <DateInput source="checkout_at_after" label="Order from" resource="order"/>
      <DateInput source="checkout_at_before" label="Order to" resource="order"/>
    </>
  )
}

const RealizationRangeFilter = props => {
  return (
    <>
      <DateInput source="realization_start_after" label="Realization from" resource="order"/>
      <DateInput source="realization_start_before" label="Realization to" resource="order"/>
    </>
  )
}

const OrderFilter = props => {
  const config = getConfig()
  if (config === 'ERROR' || config === 'Loading') return 'Loading...'
  return (
    <FilterWithSave {...props}>
      <TextInput source="q" label="ra.action.search" alwaysOn resettable />
      <SelectInput source="status" choices={config.order_status}/>
      <SelectInput source="order_type" choices={config.order_type}/>
      <ReferenceInput source="channel" reference="channel">
        <AutocompleteInput optionText="ident" />
      </ReferenceInput>
      <ReferenceInput source="customer" reference="customer">
        <AutocompleteInput optionText="email" />
      </ReferenceInput>
      <ReferenceInput source="service_base" reference="servicebase" alwaysOn>
        <AutocompleteInput optionText="ident"/>
      </ReferenceInput>
      <ReferenceInput source="supplier" reference="supplier" alwaysOn>
        <AutocompleteInput optionText="name"/>
      </ReferenceInput>
      <CheckoutRangeFilter label="Checkout range" alwaysOn/>
      <RealizationRangeFilter label="Realization range" alwaysOn/>
    </FilterWithSave>
  )
}

const OrderFullList = props => {
  useDefineAppLocation('order.list')

  return (
    <OrderList
      filter={{ admin_orders: true }}
      filters={<OrderFilter/>}
      sort={{ field: 'checkout_at', order: 'DESC' }}
      omitColumns={['id', 'technical_note', 'customer_note', 'modified_at', 'created_at', 'user']}
      columnsPref="order.list.columns"
      colorFull
      {...props}
    />
  )
}

export default OrderFullList
